import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldCheckboxToggle,
  FormFieldGallery,
  FormFieldSelect,
} from '../Form';


const imgProportionOptions = ['vertical-square', 'vertical-wide'].map(option => ({ label: option, value: option }));
const designOptions = [
  { value: 'sliders-xl', label: 'Sliders large' },
  { value: 'sliders-xs', label: 'Sliders small' },
  { value: 'grid-xs', label: 'Grid small' },
  { value: 'grid-md', label: 'Grid medium' }
];

export default {
  Render(props) {
    const {
      onChange,
      fieldsRequired
    } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);

    return (<FormFieldGallery 
      {...props}
      fieldsRequired={fieldsRequired}
      title={title}
      placeholder={title}
      {...param}
      {...style}
      name={_fieldName}
    />);
  },

  RenderInputParams: ({ values, param }) => (<>
    <FormField
      name="param.folder"
      title="Carpeta de almacenamiento para archivos"
      placeholder="Carpeta de almacenamiento para archivos"
      showLabel={true}
    />
    <FormFieldSelect
      name="param.imgProportion"
      title="Proporcion de imagenes"
      showLabel={true}
      options={imgProportionOptions}
    />
    <FormFieldCheckboxToggle
      name="param.doResize"
      title="Redimensionar imágenes"
      textTrue="Si, redimensionar imágenes"
      textFalse="No, subirlas con tamaño completo"
      colorFalse="gray-500"
      defaultValue={true}
    />
    <FormField
      name="param.maxSize"
      title="Tamaño máximo de los archivos en MB"
      placeholder="Tamaño máximo de los archivos en MB"
      showLabel={true}
      type="number"
    />
  </>),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderShowParams: ({ values }) => (<>
    <FormFieldSelect
      name="style.designList"
      title="Diseño para listas"
      placeholder="Diseño para listas"
      options={designOptions}
    />
    <FormFieldSelect
      name="style.designShow"
      title="Diseño para vista de detalles"
      placeholder="Diseño para vista de detalles"
      options={designOptions}
    />
    <FormField
      name="style.classToApply"
      title="Clases a aplicar"
      placeholder="Clases a aplicar"
    />
  </>),
  
  RenderInputPreview: ({ values, formFieldProps }) => (
    <FormFieldGallery {...formFieldProps} />
  ),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}