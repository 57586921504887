import _ from 'lodash';
import { useLayer } from '../useLayer';
import BtnSignUpModal from '../../../components/ui/BtnSignUpModal';
import { Fragment } from 'react';
import IonContactBTN from '../../../components/ui/IonContactBTN';
import { IonButton } from '@ionic/react';
import { whatsappObjectFields } from './BlockPageFooterBContactsLinksFixedCta';
import { compileStringTemplate, lineBreaks } from '../../../libs/utils';


const parseStringToObject = (string) => {
  try {
    // Eliminar comentarios de la cadena
    const cleanedString = string.replace(/\/\/.*|\/\*[\s\S]*?\*\//g, '');
    // Convertir la cadena a un objeto de JavaScript
    const parsedObject = JSON.parse(cleanedString);
    return parsedObject;
  } catch (error) {
    console.error('Error parsing string to object:', error);
    return null;
  }
};

export const sectionCardBtnsFields = {
  'ctaLabel': {
    type: 'text',
    name: 'Etiqueta',
    descriptionText: 'Texto a mostrar'
  },
  'ctaType': {
    type: 'select',
    name: 'Tipo',
    options: [{
      value: 'link',
      label: 'Enlace'
    }, {
      value: 'whatsapp',
      label: 'Whatsapp'
    }, {
      value: 'signup',
      label: 'Registro de ingreso al panel'
    }]
  },
  'ctaLink': {
    type: 'object',
    name: 'Tipo: Enlace',
    fields: {
      'ctaUrl': {
        type: 'text',
        name: 'Enlace: URL'
      },
      'ctaTarget': {
        type: 'select',
        name: 'Enlace: Destino',
        options: [{
          value: '_self',
          label: 'Misma ventana'
        }, {
          value: '_blank',
          label: 'Nueva ventana'
        }]
      },
    }
  },
  'ctaWhatsapp': {
    type: 'object',
    name: 'Tipo: Whatsapp',
    fields: whatsappObjectFields
  },
  'ctaSignUp': {
    type: 'object',
    name: 'Tipo: Registro de ingreso al panel',
    fields: {
      'modalTitle': {
        type: 'text',
        name: 'Registro: Título'
      },
      'modalContent': {
        type: 'textArea',
        name: 'Registro: Contenido'
      }
    }
  },
  'btnSize': {
    type: 'select',
    name: 'Botón: Tamaño',
    options: [{
      value: 'small',
      label: 'Pequeño'
    }, {
      value: 'medium',
      label: 'Mediano'
    }, {
      value: 'large',
      label: 'Grande'
    }]
  },
  'btnColor': {
    type: 'select',
    name: 'Botón: Color',
    options: [{
      value: 'primary',
      label: 'Primario'
    }, {
      value: 'secondary',
      label: 'Secundario'
    }, {
      value: 'tertiary',
      label: 'Terciario'
    }, {
      value: 'success',
      label: 'Éxito'
    }, {
      value: 'warning',
      label: 'Advertencia'
    }, {
      value: 'danger',
      label: 'Peligro'
    }, {
      value: 'light',
      label: 'Claro'
    }, {
      value: 'medium',
      label: 'Medio'
    }, {
      value: 'dark',
      label: 'Oscuro'
    }]
  },
  'btnFill': {
    type: 'select',
    name: 'Botón: Relleno',
    options: [{
      value: 'solid',
      label: 'Sólido'
    }, {
      value: 'outline',
      label: 'Contorno'
    }, {
      value: 'clear',
      label: 'Claro'
    }]
  },
};

export default function () {
  const classesByLayer = {
    "card": "mx-auto max-w-lg sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg",
    "container": "text-center mx-auto sm:max-w-sm",
    "title": "text-4xl md:text-4xl font-bold font-brand-main text-brand-primary-contrast",
    "subtitle": "text-lg lg:text-xl font-bold font-brand-main text-brand-primary-contrast md:mb-2",
    "content": "mt-4 mx-auto max-w-xs px-4 pb-4 text-lg font-brand-main",
  };
  
  const styleByLayerScreen = {
    "card": {
      "base": {
        marginLeft: "auto",
        marginRight: "auto"
      },
      "sm": {
        maxWidth: "20rem" // sm:max-w-xs
      },
      "md": {
        maxWidth: "24rem" // md:max-w-sm
      },
      "lg": {
        maxWidth: "28rem" // lg:max-w-md
      },
      "xl": {
        maxWidth: "32rem" // xl:max-w-lg
      }
    },
    
    "title": {
      "base": {
        fontSize: "2.25rem", // text-4xl
        fontWeight: "bold", // font-bold
        fontFamily: "brand-main", // font-brand-main
        color: "var(--ion-color-primary-contrast)" // text-brand-primary-contrast
      },
      "md": {
        fontSize: "2.25rem" // md:text-4xl (remains the same as base)
      }
    },
    "subtitle": {
      "base": {
        fontSize: "1.125rem", // text-lg
        fontWeight: "bold", // font-bold
        fontFamily: "brand-main", // font-brand-main
        color: "var(--ion-color-primary-contrast)" // text-brand-primary-contrast
      },
      "md": {
        marginBottom: "0.5rem" // md:mb-2
      },
      "lg": {
        fontSize: "1.25rem" // lg:text-xl
      }
    },
    "content": {
      "base": {
        marginTop: "1rem", // mt-4
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "20rem", // max-w-xs
        paddingLeft: "1rem", // px-4
        paddingRight: "1rem", // px-4
        paddingBottom: "1rem", // pb-4
        fontSize: "1.125rem", // text-lg
        fontFamily: "brand-main" // font-brand-main
      }
    }
  };    
  
  const presetsMap = {
    'default': {
      title: 'Predeterminado',
      description: 'Capas vacías',
      params: {
        style: {
          classesByLayer: JSON.stringify(classesByLayer, ' ', 2),
          styleByLayerScreen: JSON.stringify(styleByLayerScreen, ' ', 2)
        }
      }
    }
  };

  return {
    type: 'sectionCardBtns',
    variant: 'default',
    title: 'Por defecto',
    description: 'Incluye una grilla de Tarjetas de contenido, ocupa todo el largo y alto de la pantalla, pueden definirse margenes y fondos',
    template: BlockSectionCardBtns,
    presets: presetsMap,
    params: {
      'style': {
        name: 'Estilo',
        type: 'object',
        fields: {
          'classesByLayer': {
            name: 'Clases por Capa',
            type: 'textArea',
            descriptionText: 'Actualmente referencia del diseño, se debe pasar al objeto de estilos por capa para que sean aplicados',
            helpText: 'Capas: { card: "" }'
          },
          'styleByLayerScreen': {
            name: 'Clases por Capa',
            type: 'textArea',
            descriptionText: 'Objeto de estilo por capa de react',
            helpText: 'Capas: { "card": { "base": { /* react style object */ }, "xs": {}, ...,"2xl": {} } }'
          }
        }
      },
    },
    strings: {
      'items': {
        type: 'collection',
        name: 'Botones',
        fields: sectionCardBtnsFields
      }
    }
  }    
};

export function BtnsList(props) {
  let { items, specDesign, history, instance } = props;

  if (!items || items.length === 0) {
    return null;
  }

  return (
    items?.map(({
      ctaLabel,
      ctaType,
      ctaLink,
      ctaWhatsapp,
      ctaSignUp,
      btnSize,
      btnColor,
      btnFill
    }, index) => {
      let whatsappNumber = ctaWhatsapp?.useBrandPhone ? (specDesign.stringsVars?.brand?.callNumber || specDesign.stringsVars?.brand?.whatsappNumber) : ctaWhatsapp?.phoneNumber;
      let linkUrl = ctaLink?.ctaUrl ? compileStringTemplate(ctaLink.ctaUrl, specDesign.stringsVars) : '';
      let linkType = _.startsWith(linkUrl, 'http') ? 'out' : 'in';
      return (
        <Fragment key={index}>
          {ctaType === "whatsapp" ? (
            <IonContactBTN label={ctaLabel} number={whatsappNumber} message={lineBreaks(compileStringTemplate(ctaWhatsapp?.phoneMessage, specDesign.stringsVars))} fill={btnFill} color={btnColor} size={btnSize} />
          ) : null}
          {ctaType === "signup" && ctaSignUp ? (
            <BtnSignUpModal strings={{
              ctaLabel: lineBreaks(compileStringTemplate(ctaSignUp.ctaLabel, specDesign.stringsVars)),
              modalTitle: lineBreaks(compileStringTemplate(ctaSignUp.modalTitle, specDesign.stringsVars)),
              modalContent: lineBreaks(compileStringTemplate(ctaSignUp.modalContent, specDesign.stringsVars))
            }} history={history} instance={instance} design="small" />
          ) : null}
          {ctaType === "link" && linkType === "in" && linkUrl ? (
            <IonButton routerLink={linkUrl} fill={btnFill} color={btnColor} size={btnSize}>
              {lineBreaks(compileStringTemplate(ctaLabel, specDesign.stringsVars))}
            </IonButton>
          ) : null}
          {ctaType === "link" && linkType === "out" && linkUrl ? (
            <IonButton href={linkUrl} target={ctaLink?.ctaTarget} fill={btnFill} color={btnColor} size={btnSize}>
              {lineBreaks(compileStringTemplate(ctaLabel, specDesign.stringsVars))}
            </IonButton>
          ) : null}
        </Fragment>
      );
    })
  );
}

export function BlockSectionCardBtns(props) {
  let { items, style, specDesign, history, instance } = props;
  let styleOf = style?.styleByLayerScreen ? parseStringToObject(style.styleByLayerScreen) : {};
  let { getStyles } = useLayer();

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div style={getStyles(styleOf.container)}>
      <BtnsList items={items} history={history} instance={instance} />
    </div>
  );
}