import { useEffect, useRef } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import useSmoothHorizontalScroll from "use-smooth-horizontal-scroll";

export const HorizontalCardPicker = ({ 
  itemWidth,
  itemHeight,
  openIndex,
  onSelect,
  prevIndex,
  nextIndex,
  items,
  Render,
  className
}) => {
  const { scrollContainerRef, handleScroll, scrollTo } = useSmoothHorizontalScroll();
  const trackRef = useRef();
  const paddingX = 20;

  useEffect(() => {
    if (scrollContainerRef?.current && trackRef.current?.childNodes?.length && openIndex > -1) {
      const element = trackRef.current?.childNodes[openIndex];
      const elementRect = element.getBoundingClientRect();
      const containerRect = scrollContainerRef.current.getBoundingClientRect();
  
      // Desplazarse si el lado izquierdo del elemento no es visible
      if (elementRect.left < containerRect.left) {
        scrollTo(scrollContainerRef.current.scrollLeft + (elementRect.left - containerRect.left) - itemWidth/2 - paddingX);
      } 
      // Desplazarse si el lado derecho del elemento no es visible
      else if (elementRect.right > containerRect.right) {
        scrollTo(scrollContainerRef.current.scrollLeft + (elementRect.right - containerRect.right) + itemWidth/2 + paddingX);
      }
    }
  }, [openIndex, scrollContainerRef, itemWidth]);  

  const handleSnapScroll = (event) => {
    const scrollLeft = event.target.scrollLeft;
    const scrollIndex = Math.round(scrollLeft / itemWidth);
    const newScrollLeft = scrollIndex * itemWidth;

    // Smoothly scroll to the closest item
    event.target.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth',
    });
  };

  return (
    <div className={`relative overflow-y-hidden ${className || ''}`} style={{ height: itemHeight }}>
      <div className="absolute z-10 top-0 left-0 flex flex-row items-center align-middle bg-gradient-to-l from-transparent via-white to-white" style={{ height: itemHeight }}>
        <button type="button" onClick={prevIndex} className="w-5 h-5 p-1 bg-brand-dark rounded-full text-base font-bold text-brand-dark-contrast">
          <FaAngleLeft alt="Anterior" className="w-3 h-3" />
        </button>
      </div>
      <div 
        className="overflow-x-scroll snap-x snap-mandatory" 
        ref={scrollContainerRef} 
        onScroll={handleScroll} 
        onMouseUp={handleSnapScroll} 
        onTouchEnd={handleSnapScroll}
        style={{ scrollSnapType: 'x mandatory', paddingLeft: paddingX, paddingRight: paddingX }}
      >
        {items?.length ? (
          <div
            ref={trackRef}
            className="flex flex-row"
            style={{ width: itemWidth * items?.length, marginRight: window.innerWidth - itemWidth }}
          >
            {items?.map((item, index) => (
              <div 
                key={index} 
                className="snap-center" 
                style={{ scrollSnapAlign: 'center', width: itemWidth }}
              >
                <Render item={item} index={index} openIndex={openIndex} onSelect={onSelect} />
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className="absolute z-10 top-0 right-0 flex flex-row items-center align-middle bg-gradient-to-r from-transparent via-white to-white" style={{ height: itemHeight }}>
        <button type="button" onClick={nextIndex} className="w-5 h-5 p-1 bg-brand-dark rounded-full text-base font-bold text-brand-dark-contrast">
          <FaAngleRight alt="Siguiente" className="w-3 h-3" />
        </button>
      </div>
    </div>
  );
};
