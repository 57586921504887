import _ from 'lodash';
import { lineBreaks } from '../../libs/utils';

export const required = (value) => (value ? undefined : "Requerido");

export const FieldLabel = (props) => {
  let {
    name,
    input,
    meta,
    fieldsRequired,
    title,
    showLabel = true,
    disabled,
    showClearBtn = true,
    showDescriptionText = true,
    descriptionText,
    classes,
    Toolbar,
    onSelect
  } = props;
  const hasError = (meta.touched || input.value || _.includes(fieldsRequired, name)) && meta.error;

  return (
    showLabel && title ? (<>
      <div className={classes?.fieldLabel}>
        <span className={`mr-3 ${ hasError ? 'text-red-600' : '' }`}>
          {title}
          {hasError ? '*' : ''}
        </span>

        {Toolbar && <Toolbar />}

        {showClearBtn && !disabled && input?.value ? (
          <button
            type="button"
            className="px-1.5 py-0.5 font-regular text-xs bg-gray-100 text-gray-400 rounded-full hover:text-gray-800 inline-block"
            onClick={() => {
              input?.onChange(null);
              onSelect && onSelect(null);
            }}
          >
            X
          </button>
        ) : ''}
      </div>
      {showDescriptionText && descriptionText ? (
        <div className="-mt-0.5 mb-2 text-gray-600 text-sm text-left">{lineBreaks(descriptionText)}</div>
      ) : null}
    </>) : null
  );
}

export const RequiredByFieldsList = ({ input, name, fieldsRequired }) => {
  if (!fieldsRequired) return '';
  if (_.includes(fieldsRequired, name) && !input.value) {
    return (
      <div className="pt-0 text-sm text-red-600">*Requerido.</div>
    )
  }
  return ''
}

export const ShowFieldHelp = ({ input, meta, name, fieldsRequired, showHelpText = true, helpText, ...props }) => {
  const helpTextToUse = helpText || props?.taxonomyType?.helpText;
  return (
    <>
      {showHelpText && helpTextToUse ? (
        <div className="mt-2 text-gray-600 text-sm text-left">{lineBreaks(helpTextToUse)}</div>
      ) : null}
      {(input?.value || meta.touched) && meta.error !== ' ' ? (
        <div className="text-red-600" style={{fontSize: 12}}>{meta.error}</div>
      ) : ''}
    </>
  )
}