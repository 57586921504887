import _ from 'lodash';


const SwitchInputB = ({
  value,
  onChange,
  colorTrue = 'blue-500',
  colorFalse = 'gray-500',
  textTrue,
  textFalse,
  disabled
}) => {
  const trueColor = value ? colorTrue : colorFalse;

  return (
    <div className={`inline-block cursor-pointer relative ${disabled ? 'opacity-60' : ''}`} onClick={() => !disabled && onChange(!value)}>
      <label htmlFor="toggle" className="inline-flex top-1.5 relative items-center mr-5 cursor-pointer">
        <input
          type="checkbox"
          checked={value}
          onChange={onChange}
          disabled={disabled}
          className="sr-only peer"
        />

        <div
          className={`w-11 h-6 bg-${trueColor} rounded-full peer peer-focus:ring-0 peer-focus:ring-${trueColor}
            peer-checked:after:translate-x-full peer-checked:after:border-white 
            after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-${trueColor} 
            after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-${trueColor}`}
        ></div>
      </label>
      <div className={`inline-flex relative -ml-3 -top-0.5 text-sm ${value ? 'font-medium text-' + colorTrue : 'font-normal text-' + colorFalse}`}>
        {value ? textTrue : textFalse}
      </div>
    </div>
  );
}

export default SwitchInputB;