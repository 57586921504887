import { Capacitor } from "@capacitor/core";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import toast from 'react-hot-toast';
import _ from 'lodash';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import {
  // createUserWithEmailAndPassword,
  // signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCredential,
  // sendPasswordResetEmail,
  getAuth
} from "firebase/auth";
import { auth, addUserProperties } from "../../firebase";
import Model from "../../libs/ModelClass";
import config from "../../config";
import { assignDeep, chunkArray } from "../../libs/utils";
import { getInstanceFromLocation, withPrefix } from "../instance/utilsInstance";


const authContext = createContext();

export const errorCodes = {
  userNotFound: 'No está registrado en el sistema',
  userNotAccess: 'No tiene acceso al sistema',
  userNotRoles: 'No tiene roles válidos',

  roleNotFound: 'No existe el rol',
  credentialNotFound: 'No existe la credencial',
  instanceNotFound: 'No existe la instancia'
}

export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error("There is no Auth provider");
  return context;
};

/**
 * @param email
 * @param phoneNumber
 * @returns userDoc, permissionsMap, roleOfMainDoc, roleOfInstanceDoc, 
 * credentialOfMainDoc, credentialOfInstanceDoc
 * mainInstanceDoc, instanceDoc, credentialsDocs
 */
export const readUserCredentials = async ({ email, phone, instance, throwErrors }) => {
  if (!email && !phone) {
    return false;
  }

  const UserProfile = Model.extend('usersProfiles');
  const Credential = Model.extend('credentials');
  const Instance = Model.extend('instances');
  const Role = Model.extend('roles');
  let userDoc, permissionsMap, rolesDoc, roleOfMainDoc, roleOfInstanceDoc, 
      credentialOfMainDoc, credentialOfInstanceDoc,
      mainInstanceDoc, instanceDoc, credentialsDocs;
  let instancesDocs = [];

  // get user
  if (email) {
    userDoc = await UserProfile.filterByAttributes({ email, deleted: 'false' });
    userDoc = userDoc?.length ? userDoc[0] : userDoc;
    if (!userDoc?.data?.email && throwErrors) {
      throw new Error(errorCodes.userNotFound);
    }
  }
  else if (phone) {
    userDoc = await UserProfile.filterByAttributes({ phone, deleted: 'false' });
    userDoc = userDoc?.length ? userDoc[0] : userDoc;
    if (!userDoc?.data?.phone && throwErrors) {
      throw new Error(errorCodes.userNotFound);
    }
  }

  if (!userDoc?.data?.id) {
    return {};
  }
  
  // fetch all credentials
  credentialsDocs = await Credential.filterByAttributes({ profile: userDoc?.id, isActive: 'true', deleted: 'false' });

  // fetch allowed instances
  const instancesIdsChunks = chunkArray(_.map(credentialsDocs, 'data.instanceId'), 30);
  for (const idsChunk of instancesIdsChunks) {
    let docs = await Instance.filterByAttributes({ id: { in: idsChunk }, deleted: 'false' });
    instancesDocs = instancesDocs.concat(docs);
  }

  // get credentials of current instance
  mainInstanceDoc = _.find(instancesDocs, (doc) => doc.data.hash === 'main');
  credentialOfMainDoc = _.find(credentialsDocs, (doc) => doc.data.instanceId === mainInstanceDoc.id);
  instanceDoc = _.find(instancesDocs, (doc) => doc.data.hash === instance);
  credentialOfInstanceDoc = _.find(credentialsDocs, (doc) => doc.data.instanceId === instanceDoc?.id);

  // verify if user has access to main
  if (!credentialOfMainDoc?.data?.isActive && throwErrors) {
    throw new Error(errorCodes.userNotAccess);
  }

  // get role of credential of main instance
  roleOfMainDoc = await Role.findById(credentialOfMainDoc.data.roles);
  if (!roleOfMainDoc?.data && throwErrors) {
    throw new Error(errorCodes.userNotRoles);
  }
  permissionsMap = roleOfMainDoc?.data?.permissions || {};

  // get role of credential of current instance
  if (instance !== 'main' && credentialOfInstanceDoc) {
    roleOfInstanceDoc = await Role.findById(credentialOfInstanceDoc.data.roles);
    if (!roleOfInstanceDoc?.data && throwErrors) {
      throw new Error(errorCodes.userNotRoles);
    }
    permissionsMap = assignDeep({}, permissionsMap);
    permissionsMap = assignDeep(permissionsMap, roleOfInstanceDoc?.data?.permissions);
  }

  // role of reference
  rolesDoc = roleOfInstanceDoc || roleOfMainDoc;
  
  // user data from firebase
  const fireUser = getAuth()?.currentUser;

  // userSpecss
  return {
    userDoc,
    permissionsMap,
    rolesDoc,
    roleOfMainDoc,
    roleOfInstanceDoc,
    instanceDoc,
    mainInstanceDoc,
    credentialOfMainDoc,
    credentialOfInstanceDoc,
    credentialsDocs,
    instancesDocs,
    fireUser: fireUser?.user ? fireUser.user : fireUser
  };
};

// for first signup
export const createCustomerUserWithCredentials = async ({ email, phone, instance }) => {
  if (!email && !phone) {
    return false;
  }
  const UserProfile = Model.extend('usersProfiles');
  const Credential = Model.extend('credentials');
  const Instance = Model.extend('instances');
  const Role = Model.extend('roles');
  let userDoc, permissionsMap, rolesDoc, roleOfMainDoc, roleOfInstanceDoc, 
  credentialOfMainDoc, credentialOfInstanceDoc,
  mainInstanceDoc, instanceDoc, credentialsDocs;
  let instancesDocs = [];

  // get instance of main
  mainInstanceDoc = await Instance.whereOne('hash', '==', 'main');
  instancesDocs = [mainInstanceDoc];

  if (instance !== 'main') {
    // get instance
    instanceDoc = await Instance.whereOne('hash', '==', instance);
    if (!instanceDoc?.data?.hash) {
      throw new Error(errorCodes.instanceNotFound);
    }
    instancesDocs.push(instanceDoc);
  }
  
  // create user
  userDoc = await UserProfile.create({
    email, phone,

    // record entrance to the platform
    firstInstanceId: (instanceDoc || mainInstanceDoc).id
  });

  // get user role for main
  roleOfMainDoc = await Role.filterOne({
    nameSlug: config.modules.user.userDefaultRoleSlug,
    deleted: 'false'
  });
  if (!roleOfMainDoc) {
    throw new Error(errorCodes.roleNotFound);
  }

  // set permissions for main
  permissionsMap = roleOfMainDoc?.data?.permissions || {};
  
  // create credential for main
  credentialOfMainDoc = await Credential.create({
    profile: userDoc.id,
    instanceId: mainInstanceDoc.id,
    roles: roleOfMainDoc?.id,
    isActive: true,
    status: 'approved'
  });

  credentialsDocs = [credentialOfMainDoc];

  // create for instance
  if (instance !== 'main') {
    // get user role for instance
    roleOfInstanceDoc = await Role.filterOne({
      nameSlug: withPrefix(instance, config.modules.user.userDefaultRoleSlugForInstances),
      instanceId: instanceDoc.id,
      deleted: 'false'
    });
    if (!roleOfInstanceDoc) {
      throw new Error(errorCodes.roleNotFound);
    }

    // set permissions for instance
    permissionsMap = assignDeep({}, permissionsMap);
    permissionsMap = assignDeep(permissionsMap, roleOfInstanceDoc?.data?.permissions);

    // create credential for instance
    credentialOfInstanceDoc = await Credential.create({
      profile: userDoc.id,
      instanceId: instanceDoc.id,
      roles: roleOfInstanceDoc?.id,
      isActive: false,
      status: 'pending'
    });
    credentialsDocs.push(credentialOfInstanceDoc);
  }

  // role of reference
  rolesDoc = roleOfInstanceDoc || roleOfMainDoc;
  
  // user data from firebase
  const fireUser = getAuth()?.currentUser;

  // userSpecss
  return {
    userDoc,
    permissionsMap,
    rolesDoc,
    roleOfMainDoc,
    roleOfInstanceDoc,
    instanceDoc,
    mainInstanceDoc,
    credentialOfMainDoc,
    credentialOfInstanceDoc,
    credentialsDocs,
    instancesDocs,
    fireUser: fireUser?.user ? fireUser.user : fireUser
  };
};

// TODO
export const grantAccessToInstance = async ({ userDoc, instance }) => {
  // TODO
};

export function AuthProvider(props) {
  let { children } = props;
  const location = useLocation();
  const [ instance, setCurrentInstance ] = useState(getInstanceFromLocation(location.pathname));
  // userSpecs
  const [ user, setUser ] = useState(null); // become {} ance loaded

  const refreshUser = async () => {
    const userAuth = await readUserCredentials({ 
      email: user?.userDoc?.data?.email, 
      phone: user?.userDoc?.data?.phone,
      throwErrors: false,
      instance
    });
    setUser(userAuth || {});
  };

  const refreshInstance = async (newInstanceDoc) => {
    let instance = newInstanceDoc.data.hash;
    setCurrentInstance(instance);
    if (!user) {
      return;
    }
    const userAuth = await readUserCredentials({ 
      email: user?.userDoc?.data?.email, 
      phone: user?.userDoc?.data?.phone,
      throwErrors: false,
      instance
    });

    setUser(userAuth || {});


    // // find credentialOfInstanceDoc of userSpecs
    // let credentialOfInstanceDoc = user.credentialsDocs.find(doc => doc.data.instanceId === newInstanceDoc.id);
    // // fetch credential of instance in case of a recently created instance
    // if (!credentialOfInstanceDoc) {
    //   const CredentialModel = Model.extend('credentials');
    //   credentialOfInstanceDoc = await CredentialModel.whereOne('instanceId', '==', newInstanceDoc.id);
    // }
    // let roleOfInstanceDoc = null;
    // let permissionsMap = user.roleOfMainDoc.data.permissions;
    // // update roleOfInstanceDoc of credentialOfInstanceDoc
    // if (credentialOfInstanceDoc && newInstanceDoc.data.hash !== 'main') {
    //   const Role = Model.extend('roles');
    //   roleOfInstanceDoc = await Role.findById(credentialOfInstanceDoc?.data?.roles);
    //   permissionsMap = assignDeep({}, permissionsMap);
    //   permissionsMap = assignDeep(permissionsMap, roleOfInstanceDoc?.data?.permissions || {});
    // }
    // // update userSpecs
    // setUser({ 
    //   ...user,
    //   permissionsMap,
    //   instanceDoc: newInstanceDoc,
    //   credentialOfInstanceDoc,
    //   roleOfInstanceDoc
    // });
  };

  // const signup = (email, password) => {
  //   return createUserWithEmailAndPassword(auth, email, password);
  // };

  // const login = (email, password) => {
  //   return signInWithEmailAndPassword(auth, email, password);
  // };

  const loginWithGoogleAndVerify = async (onlyLogin) => {
    let signedResult;
    if (Capacitor.isNativePlatform()) {
      // Create credentials on the native layer
      const result = await FirebaseAuthentication.signInWithGoogle();
      // Link on the web layer using the id token
      const credential = GoogleAuthProvider.credential(result.credential?.idToken);
      const auth = getAuth();
      signedResult = await signInWithCredential(auth, credential);
    } else {
      const googleProvider = new GoogleAuthProvider();
      signedResult = await signInWithPopup(auth, googleProvider);
    }
    if (onlyLogin) {
      return signedResult;
    }
    if (signedResult?.user?.email || signedResult?.user?.phoneNumber) {
      const userWithCredentials = await getOrCreateUserDataByEmailOrPhone({ 
        email: signedResult?.user?.email, 
        phone: signedResult?.user?.phoneNumber
      });
      setUser(userWithCredentials || {}); // mark as loaded but without user
      return userWithCredentials;
    }
  };

  /**
   * Obtiene los datos del usuario según el *email* ó *phone number*
   * si no existe entonces genera un *customer account* 
   * @param phone
   * @returns userDoc, credentialDoc, rolesDoc
   */
  const getOrCreateUserDataByEmailOrPhone = async ({ email, phone }) => {
    try {
      const userWithCredentials = await readUserCredentials({ email, phone, withRoles: true, instance, throwErrors: true });
      if (userWithCredentials) {
        return userWithCredentials;
      } else {
        return false;
      }
    } catch (error) {
      if (error.message === errorCodes.userNotFound) {
        // create customer account
        try {
          const userWithCredentials = await createCustomerUserWithCredentials({ email, phone, instance });
          return userWithCredentials;
        } catch (error) {
          console.error(error);
          return false;
        }
      }
      else if (error.message === errorCodes.userNotAccess || error.message === errorCodes.userNotRoles) {
        await logout();
        toast.error(errorCodes.userNotAccess);
        return false;
      }
      else {
        console.error(error);
        return false;
      }
    }
  };

  const logout = async () => {
    if (Capacitor.isNativePlatform()) {
      // Sign out on the native layer
      await FirebaseAuthentication.signOut();
    }
    // Sign out on the web layer
    const auth = getAuth();
    await signOut(auth);
    // remove user related data
    setUser({}); // empty object to mark as loaded anyway
    window.localStorage.clear();
  };

  // const resetPassword = async (email) => sendPasswordResetEmail(auth, email);

  useEffect(() => {
    if (user?.userDoc?.data) {
      return user.userDoc.onSnapshot(newDoc => {
        setUser({ ...user, userDoc: newDoc });
      });
    }
  }, [user?.userDoc?.id]);

  useEffect(() => {
    if (user?.credentialOfMainDoc?.data) {
      return user.credentialOfMainDoc.onSnapshot(async newDoc => {
        if (user.credentialOfMainDoc.data.roles !== newDoc.data.roles) {
          window.location.reload();
        }
      });
    }
  }, [user?.credentialOfMainDoc?.id]);

  useEffect(() => {
    if (user?.credentialOfInstanceDoc?.data) {
      return user.credentialOfInstanceDoc.onSnapshot(async newDoc => {
        if (
          user.credentialOfInstanceDoc.data.roles !== newDoc.data.roles
          || user.credentialOfInstanceDoc.data.isActive !== newDoc.data.isActive
          || user.credentialOfInstanceDoc.data.status !== newDoc.data.status
        ) {
          window.location.reload();
        }
      });
    }
  }, [user?.credentialOfInstanceDoc?.id]);

  useEffect(() => {
    if (user?.rolesDoc?.data && user.rolesDoc.data.nameSlug !== 'guest') {
      return user.rolesDoc.onSnapshot(newDoc => {
        setUser({ ...user, rolesDoc: newDoc });
      });
    }
  }, [user?.rolesDoc?.id]);

  ////////////////////////////////
  //
  // Cuando cambia el authState 
  // se busca el user o se crea el user
  // 
  ////////////////////////////////
  // log user params analytics
  // set user data state
  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {
      // attach funnel param to log
      let params = {
        'role': currentUser ? 'logged' : 'guest'
      };
      const searchParams = new URLSearchParams( location.search.split('?')[1] );
      params.funnel = searchParams.has('funnel') 
        ? searchParams.get('funnel')
        : 'none';
      addUserProperties(params); // analytics
      // set user data
      try {
        const userAuth = await readUserCredentials({ 
          email: currentUser?.email, 
          phone: currentUser?.phoneNumber,
          throwErrors: false,
          instance
        });
        if (
          userAuth?.userDoc?.id
        ) {
          setUser(userAuth || {}); // mark as loaded but without user
        }
        else {
          setUser({});
        }
      } catch (e) {
        kickOut(e);
      }
    });
  }, []);

  const kickOut = (e) => {
    if (_.includes(location.pathname, "/a")) {
      window.location.href = '/'; // force go out
      e?.message && toast.error(e.message);
    }
    console.error(e);
  };
  
  return (
    <authContext.Provider
      value={{
        refreshUser,
        refreshInstance,
        // signup,
        // login,
        user,
        userAuth: user,
        logout,
        loginWithGoogleAndVerify,
        getOrCreateUserDataByEmailOrPhone,
        // resetPassword,
      }}
    >
      {children}
    </authContext.Provider>
  );
}