import _ from 'lodash';
import config from '../../config';
import { RouteEntityCrudForm } from '../entity/RouteEntityCrudForm';
import toast from 'react-hot-toast';
import { urlPrefix } from '../instance/utilsInstance';


const credentialsEntitySlug = config.modules.user.credentialsEntitySlug;

export const RouteMembersUpdate = (props) => {
  let { 
    instance,
    parsedParams,
    history,
    user
  } = props;

  let backRoute = {
    url: urlPrefix(instance, `/a/credentials/members`),
    title: 'Miembros'
  };

  const context = {
    entitySlug: credentialsEntitySlug,
    docId: parsedParams.docId,
    defaultBackRoute: backRoute.url,
    breadcrumbs: [
      backRoute,
      {
        title: "Formulario de Miembro"
      }
    ],
    onBeforeSave: (modifiedFormValues) => {
      // modifiedFormValues.ownerId = ownerId;
    },
    onAfterSave: async (newDoc) => {
      try {
        if (newDoc) {
          // block access to denied members
          if (newDoc.data.status !== 'approved') {
            newDoc.data.isActive = false;
          }
          await newDoc.save();
        }
        history.push(backRoute.url);
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      }
    }
  };
  return (
    <RouteEntityCrudForm
      {...props}
      context={context}
      exclude={[ ]}
    />
  );
}
