import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldSelect,
  FormFieldTextArea,
  FormFieldCustom,
  RawInputTextAreaMarkdown,
  RawInputTextAreaNotion
} from '../Form';
import RawInputTextAreaCodeSimple from '../Form/RawInputTextAreaCodeSimple';


// TODO richWYSIWYG

export default { 
  Render(props) {
    const {
      onChange,
      fieldsRequired
    } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);
    
    if(design === "simple" || design === "default") {
      return(
        <FormFieldTextArea 
          placeholder={title}
          {...props}
          {...style}
          {...param}
          onSelect={onChange}
          name={_fieldName}
        />
      )
    }

    else if (design === "notion") {
      return (
        <FormFieldCustom 
          {...props}
          {...style}
          {...param}
          Render={RawInputTextAreaNotion}
          name={_fieldName}
        />
      )
    }

    else if (design === "richMarkdown") {
      return (
        <FormFieldCustom 
          {...props}
          {...style}
          {...param}
          Render={RawInputTextAreaMarkdown}
          name={_fieldName}
        />
      )
    }

    else if (design === "codeSimple") {
      return (
        <FormFieldCustom 
          {...props}
          {...style}
          {...param}
          Render={RawInputTextAreaCodeSimple}
          name={_fieldName}
        />
      )
    }

    return null;
  },

  RenderInputParams: ({ values, param }) => (
    <>
      <FormField
        name="param.minLength"
        title="Longitud mínima"
        type="number"
        showLabel={true}
        maxValue={values.param && parseInt(values.param.maxLength)}
      />
      <FormField
        name="param.maxLength"
        title="Longitud máxima"
        type="number"
        showLabel={true}
        minValue={values.param && parseInt(values.param.minLength)}
      />
      <FormFieldSelect
        name="style.design"
        title="Diseño"
        placeholder="Diseño"
        showLabel={true}
        options={[
          { value: 'simple', label: 'Simple' },
          { value: 'notion', label: 'Enriquecido: Notion' },
          { value: 'richMarkdown', label: 'Enriquecido: Markdown' },
          { value: 'codeSimple', label: 'Editor de código simple'}
        ]}
      />
    </>
  ),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (
    <>
      <FormFieldSelect
        name="show.textArea.operator"
        title="Operador"
        placeholder="Operador"
        options={[
          { value: 'hasLength', label: 'Tiene contenido' },
          { value: 'isEmpty', label: 'Está vacío' },
          { value: 'eq', label: 'es igual' },
        ]}
      />
      {values.show?.text?.operator === 'eq' ? (
        <FormField
          name="show.textArea.value"
          title="Valor"
          placeholder="valor"
        />
      ) : ''}
    </>
  ),
  
  RenderShowParams: ({ values }) => (null),
  
  RenderInputPreview: ({ values, formFieldProps }) => (null),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}