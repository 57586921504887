import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { Fragment } from 'react';
import IonBtnLoading from '../ui/IonBtnLoading';
import { EntityTaxonomyForm, inputClasses } from '../EntityTaxonomyForm';


const ItemForm = (props) => {
  let {
    instance,
    item,
    index,
    name,
    fields,
    onDelete,
    moveUp,
    moveDown
  } = props;

  const handleDelete = () => {
    onDelete(item);
  };

  const fieldsPrefixed = useMemo(() => {
    return _.map(fields, (field) => ({ 
      ...field,
      fieldName: `${name}[${index}][${field.fieldName}]`
    }));
  }, [fields]);

  return (
    <div className="my-2 p-2 rounded-md shadow-sm border border-gray-300">
      {/* actions */}
      <div className="flex flex-row place-content-between">
        <div className="">
          <button type="button" className="text-gray-500 text-xs" onClick={() => moveUp(item)}>
            ▲
          </button>
          <button type="button" className="text-gray-500 text-xs" onClick={() => moveDown(item)}>
            ▼
          </button>
        </div>

        <button type="button" onClick={handleDelete} className="font-bold text-red-700">
          x
        </button>
      </div>

      {/* form */}
      <div className="">
        <EntityTaxonomyForm
          instance={instance}
          taxonomyTypesDataList={fieldsPrefixed}
          classes={inputClasses}
          form={props.form}
        />
      </div>
    </div>
  );
};

const RawInputCollectionForm = (props) => {
  let { 
    instance,
    value,
    onChange
  } = props;
  const [items, setItems] = useState(value);
  const [listId] = useState(nanoid());

  useEffect(() => {
    setItems(value);
  }, [value]);

  const handleAddItem = () => {
    const newItem = { 
      // id: nanoid()
    };
    const newItems = [...(items || []), newItem];
    setItems(newItems);
    onChange(newItems);
  };

  const handleItemChange = (index, newItem) => {
    const updatedItems = [...items];
    updatedItems[index] = newItem;
    setItems(updatedItems);
    onChange(updatedItems);
  };

  const handleItemDelete = (itemToDelete) => {
    const updatedItems = items.filter((item) => item !== itemToDelete);
    setItems(updatedItems);
    onChange(updatedItems);
  };

  const moveUp = (item) => {
    const newOrder = Array.from(items);
    const originalIndex = newOrder.indexOf(item);
    if (originalIndex !== -1) {
      const [movedItem] = newOrder.splice(originalIndex, 1);
      newOrder.splice(originalIndex - 1, 0, movedItem);
      setItems(newOrder);
      onChange(newOrder);
    }
  };    

  const moveDown = (item) => {
    const newOrder = Array.from(items);
    const originalIndex = newOrder.indexOf(item);
  
    if (originalIndex !== -1 && originalIndex < newOrder.length - 1) {
      const [movedItem] = newOrder.splice(originalIndex, 1);
      newOrder.splice(originalIndex + 1, 0, movedItem);
      setItems(newOrder);
      onChange(newOrder);
    }
  };    

  return (
    <div>
      {items && items?.map((item, index) => (
        <Fragment key={`${listId}-${index}`}>
          <ItemForm
            {...props}
            index={index}
            item={item}
            onChange={(newItem) => handleItemChange(index, newItem)}
            onDelete={handleItemDelete}
            moveUp={moveUp}
            moveDown={moveDown}
          />
        </Fragment>
      ))}

      <div className="space-x-2 flex items-center">
        <label className="uppercase inline-block text-xs">
          Agregar
        </label>
        <IonBtnLoading
          label="Nuevo"
          onClick={handleAddItem}
          color="secondary"
          size="small"
          fill="solid"
          className="text-xs"
        />
      </div>
    </div>
  );
};

export default RawInputCollectionForm;
