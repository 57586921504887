import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import { compileStringTemplate, lineBreaks } from "../../../libs/utils";


export default function () {
  return {
    type: 'textGrid',
    variant: 'extended',
    template: TextGridAExtended,
    params: {
      
    },
    // fields of text tab
    strings: {
    // {items: [{title,subtitle,content}]}
    'items': {
      type: 'collection',
      name: 'items',
      fields: {
        'title': {
          type: 'text',
          name: 'Título'
        },
        'subtitle': {
          type: 'text',
          name: 'Subtítulo'
        },
        'content': {
          type: 'text',
          name: 'Contenido'
        },
      }
    },
    }
  };
};

export function TextGridAExtended({ items, specDesign }) {
  return (
    <div className="px-8 my-40 bg-white text-left">
      <div className="container-width">
        <div className="flex flex-wrap gap-24 lg:gap-44">
          {items?.map(({title, subtitle, content}, index) => (
            <div key={index}> 
              {title ? (
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
                  <h1 className='text-black font-brand-main font-semibold text-4xl lg:text-5xl mb-2'>
                    {lineBreaks(compileStringTemplate(title, specDesign.stringsVars))}
                  </h1>
                </AnimationAddClassWhenVisible>
              ) : null}
              {subtitle ? (
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
                  <h2 className='text-black font-brand-main font-semibold text-xl lg:text-2xl mb-2'>
                    {lineBreaks(compileStringTemplate(subtitle, specDesign.stringsVars))}
                  </h2>
                </AnimationAddClassWhenVisible>
              ) : null}
              {content ? (
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                  <p className='text-2xl lg:text-4xl text-gray-400 font-semibold font-brand-main'>
                    {lineBreaks(compileStringTemplate(content, specDesign.stringsVars))}
                  </p>
                </AnimationAddClassWhenVisible>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
