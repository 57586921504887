import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useScrollTo, useScrollUtils } from "../panel/useScrollUtils";
import { IonContent, IonPage, IonRefresher, IonRefresherContent } from "@ionic/react";
import { MenuHome } from "../../customModules/layout/MenuHome";
import useURLVariables from "./useURLVariables";


export function LayoutHome(props) {
  let {
    mainSectionClass,
    CustomMenuHome,
    showRefresher = true,
    children,
    specDesign,
    scrollEffect = "normal"
  } = props;
  const location = useLocation();
  const { scrollToDelay } = useScrollTo();
  const { elementToScrollRef, handleScroll, resetScroll } = useScrollUtils();
  useURLVariables();

  useEffect(() => {
    scrollToDelay('main');
    resetScroll();
  }, [location.pathname]);

  const handleRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 400);
  };

  return (
    <IonPage id="main">
      <IonContent
        ref={elementToScrollRef}
        scrollEvents={true}
        onIonScroll={handleScroll}
        className={`layout-main-section ${scrollEffect ? `scroll-effect-${scrollEffect}` : ''} ${mainSectionClass || ''}`}
        fullscreen
      >
        {showRefresher ? (
          <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
        ) : null}
        {/* main menu */}
        {CustomMenuHome ? (
          <CustomMenuHome {...props} specDesign={specDesign} />
        ) : (
          <MenuHome {...props} specDesign={specDesign} />
        )}
        {/* content */}
        {children}
      </IonContent>
    </IonPage>
  );
}