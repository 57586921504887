import { Link, useParams } from 'react-router-dom';
import { useRef, useState } from 'react';
import _ from 'lodash';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import SectionCrudModel from "../../components/SectionCrudModel";
import SectionCrudObject from "../../components/SectionCrudObject";
import { useTaxonomyTypesByEntityId } from './TaxonomyType';
import {
  FormField,
  FormFieldSelect,
} from '../../components/Form';
import FilterMenu from './FilterMenu';
import { useEffect } from 'react';
import IonBtnLoading from '../../components/ui/IonBtnLoading';
import SectionCrudForm from '../../components/SectionCrudForm';


const FilterMenuForm = (entityId) => {
  return function FilterMenuForm ({
    doc, // FilterMenu doc
    onClose,
    onSave,
    // defined on Content
    fieldsRequired,
    onValidation,    
  }) {
    const formRef = useRef();
    const [isSaving, setIsSaving] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
      const { entityTaxonomyTypes } = useTaxonomyTypesByEntityId({ entityId });

    const getTaxonomyTypeById = (entityId) => {
      return entityTaxonomyTypes.find((doc) => doc.id === entityId);
    };

    const instanceFilterTaxonomy = (filterDoc) => {
      let taxonomyTypeDoc = getTaxonomyTypeById(filterDoc.id);
      return new FilterMenu({
        id: filterDoc.id,
        name: taxonomyTypeDoc?.data?.name,
        taxonomyType: taxonomyTypeDoc?.data,
        filter: sanitizeTaxonomyTypeBeforeSave(filterDoc)
      });
    }

    const sanitizeTaxonomyTypeBeforeSave = (filterDoc) => {
      return filterDoc?.data?.filter || filterDoc;
    }

    useEffect(() => {
      setSelectedItems( doc?.data?.filters?.map(filter => instanceFilterTaxonomy(filter)) || [] );
    }, [entityId, entityTaxonomyTypes]);

    const doFormSubmit = () => {
      return formRef.current?.submit();
    };
  
    const handleSubmit = async (values) => {
      // sanitize data
      delete values.taxonomy; // select input model
      values.filters = selectedItems.map(filter => sanitizeTaxonomyTypeBeforeSave(filter));
      setIsSaving(true);
      await onSave(values);
      setIsSaving(false);
      toast.success('Los datos se han guardado correctamente');
    };

    const getTaxonomyTypeOptions = () => {
      const taxonomyTypesFiltered = entityTaxonomyTypes.filter(taxonomyTypes => !taxonomyTypes?.data?.deleted);
      return taxonomyTypesFiltered.map(doc => ({ value: doc.id, label: doc.data.name }));
    }

    const selectAllTaxonomies = () => {
      const taxonomyTypesFiltered = entityTaxonomyTypes.filter(taxonomyTypes => !taxonomyTypes?.data?.deleted);
      setSelectedItems(
        taxonomyTypesFiltered.map(taxonomyDoc => instanceFilterTaxonomy({ id: taxonomyDoc.id }))
      );
    };

    ////////////////////////////////////////////////////////////////
    // Form of Edit filterMenu.filters populated
    ////////////////////////////////////////////////////////////////
    // filterMenu.filters = [
    //   { 
    //     // populated from taxonomyType
    //     id, name, taxonomyType: { nameSlug, type, style, show, ... },
    //
    //     selectedFilters: {
    //       [nameSlug]:
    //         // mode simpleInput
    //           string | number | boolean | object
    //         // mode singleOperator
    //           { operator, value }
    //         // mode singleRange
    //           { startOperator, startValue, endOperator, endValue }
    //         // mode rangeSelect
    //           { rangeIndex: number}
    //     }
    //
    //     // persisted filter data params
    //     filter: { 
    //       id, // same as taxonomyType.id
    //       mode,
    //       style: { classes: { container } }
    //
    //       // options for mode rangeSelect
    //       ranges: [ { rangeName, startOperator, startValue, endOperator, endValue } ]
    //     }, 
    //   }
    // ]

    const fieldsRequiredMenuFilter = [
      // 'filter.config'
    ];
    const onValidationMenuFilter = (values, errors) => {
      // if (!values.filter?.config) {
      //   errors.filter = errors.filter || {};
      //   errors.filter.config = ' ';
      // }
    };
    const FormInputFields = (props) => {
      const { form, values, handleSubmit, submitting, fieldsRequired } = props;

      return (<>
        <FormField name="name" title="Nombre" disabled={true} placeholder="Nombre" {...props} />
        {/* <FormField name="filter.config" title="Config" placeholder="" {...props} /> */}

        {/* 
          TODO
          
          Heredar .filter de TaxonomyType
          
          Heredar .show de TaxonomyType
            Permitir modificar
            En ambos casos si no está taxonomyTypeToWatch
              en la lista de filtros, mostrar una alerta

          Heredar .style de TaxonomyType
            Permitir modificar 
            
        */}

        {/* <div className="rounded bg-gray-200 p-2 mt-5">
          <RenderFilterParam {...{form, values}} />
          <div className="mt-4 p-3 bg-white rounded">
            <div className="pb-2 border-b border-gray-200">
              <label className=" font-semibold">Vista previa</label>
            </div>
            <div className="my-10">
              <RenderFilter {...{
                form,
                values,
                taxonomyType: values?.taxonomyType
              }} />
            </div>
          </div>
        </div> */}

        {/* <pre>{JSON.stringify(values, 2, '  ')}</pre> */}
      </>);
    };

    const ListItem = (({ doc }) => (<>
      <span className="">
        {doc?.data?.name}
        {doc?.data?.taxonomyType?.deleted ? (
          <span className="text-red-400"> - eliminado</span>
        ) : null}
      </span>
    </>));


    ////////////////////////////////////////////////////////////////
    // Form of Filter Menu
    ////////////////////////////////////////////////////////////////
    // filterMenu { 
    //   name, 
    //   filters: TaxonomyType[]
    // }

    const validateForm = (values) => {
      const errors = {};
      onValidation(values, errors);
      return errors;
    };

    const handleSaveTaxonomy = (formValues) => {            
      setSelectedItems((prevItems) => {
        const updatedItems = [...prevItems];
        const index = updatedItems.findIndex((doc) => doc.id === formValues.id);
        if (index !== -1) {
          updatedItems[index].data = { ...formValues };
        }
        return updatedItems;
      });
    };            
    
    const handleDeleteTaxonomy = (docToDelete) => {
      setSelectedItems((prevItems) => {
        const updatedItems = prevItems.filter((doc) => doc.id !== docToDelete.id);
        return updatedItems;
      });
    };            

    const handleReorderTaxonomy = (reorderedItems) => {
      // Mapear los elementos reordenados a un nuevo arreglo con los elementos actualizados
      const updatedItems = reorderedItems.map((doc) => {
        const selectedTaxonomy = selectedItems.find((selectedItem) => selectedItem.id === doc.id);
        return selectedTaxonomy;
      });
      setSelectedItems(updatedItems);
    };          
    
    const onTaxonomySelected = (form) => ((selectedTaxonomyId) => {
      const taxonomyDoc = getTaxonomyTypeById(selectedTaxonomyId);
      const taxonomyToAdd = instanceFilterTaxonomy({ id: taxonomyDoc.id });
      if (!selectedItems.some((doc) => doc.id === selectedTaxonomyId)) {
        setSelectedItems([...selectedItems, taxonomyToAdd]);
      }
      form.change('taxonomy', null);
    });

    return (
      <div key={doc.id} className="bg-white rounded p-4 m-4">
        <Form
          onSubmit={handleSubmit}
          initialValues={doc ? doc.data : {}}
          validate={validateForm}
          render={({ form, handleSubmit, submitting, values }) => {
            formRef.current = form;
            return (
              <form onSubmit={handleSubmit} className="space-y-8">
                {/* FilterMenu form */}
                <FormField name="name" title="Nombre" placeholder="Nombre" fieldsRequired={fieldsRequired} />
                <FormField name="nameSlug" title="ID" fieldsRequired={fieldsRequired} 
                  onChange={(value) => {
                    form.change('nameSlug', _.camelCase(value || ''));
                  }}
                />

                {/* Select taxonomies */}
                <div className="p-3 rounded bg-gray-300">
                  <div className="mb-2 text-blue-500">
                    <FormFieldSelect
                      name="taxonomy"
                      title="Agregar"
                      options={getTaxonomyTypeOptions()}
                      onSelect={onTaxonomySelected(form)} />
                  </div>
                  <div onClick={selectAllTaxonomies} className="inline-block cursor-pointer px-2 py-1 text-sm bg-blue-500 text-white rounded-md">
                    Agregar todos
                  </div>
                  <div className="ml-2 inline-block text-gray-600">
                    Asigna reemplazando la lista
                  </div>
                </div>
                {/* <pre>{JSON.stringify(selectedItems, 2, '   ')}</pre>
                <pre>{JSON.stringify(values, 2, '   ')}</pre> */}
              </form>
            )
          }}
        />

        {/* Selected taxonomies crud */}
        {selectedItems && selectedItems.length ? (
          <div className="mt-8">
            <SectionCrudObject
              editStyle="onsite"
              title="Atributos seleccionados"
              docs={selectedItems}
              reorder={true}

              // shows
              showBtnAdd={false}
              showToast={false}

              // validations
              fieldsRequired={fieldsRequiredMenuFilter}
              onValidation={onValidationMenuFilter}
              
              // callbacks 
              onSave={handleSaveTaxonomy}
              onDelete={handleDeleteTaxonomy}
              onReorder={handleReorderTaxonomy}
              
              // UI
              ListItem={ListItem}
              FormInputFields={FormInputFields}
              FormSection={SectionCrudForm}

              // classes
              classNameFormSection=""
            />
          </div>
        ) : ''}

        {/* Actions */}
        <div className={`!mt-10 pt-4 border-t border-gray-300 grid grid-cols-2 gap-4`}>
          <IonBtnLoading
            color="light"
            size="small"
            onClick={onClose}
            label="Cerrar"
          />
          <IonBtnLoading
            type="button"
            size="small"
            onClickAsync={doFormSubmit}
            disabled={formRef.current?.getState().submitting || isSaving}
            label={isSaving ? 'Guardando...' : 'Guardar'}
          />
        </div>
      </div>
    );
  };
}

export function Content({ entityId }) {
  const handleBeforeSave = (doc) => {
    doc.entityId = entityId;
  };

  const fetchItemsFilterMenu = async ({ setDocs }) => {
    let docs = await FilterMenu.where('entityId', '==', entityId);
    docs = docs.filter(doc => !doc.data.deleted);
    setDocs(docs || []);
  };

  const fieldsRequired = ['name'];

  const onValidation = (values, errors) => {
    if (!values.name) {
      errors.name = ' ';
    }
  };

  const ListItem = ({ doc }) => (<div className=''>
    <span className="block font-semibold text-gray-700">{doc.data?.name}</span>
    <span className="text-sm inline text-sky-600">{doc.data?.nameSlug}</span>
  </div>);

  return (
    <SectionCrudModel
      model={FilterMenu}
      entitySlug="filterMenu"
      editStyle="modal"
      dataMode="all"
      listStyle="dragable"
      title={"Menú de Filtros"}
      reorder={false}
      navigateTo={(viewType, doc) => (viewType === 'form' && `/a/config/entity-creator/${doc ? doc.id : 'new'}/form`)}
      // validatiion
      fieldsRequired={fieldsRequired}
      onValidation={onValidation}
      // callbacks 
      fetchItems={fetchItemsFilterMenu}
      handleBeforeSave={handleBeforeSave}
      // UI
      ListItem={ListItem}
      FormSection={FilterMenuForm(entityId)}
    />
  );
}

export function RouteEntityCreatorFilterMenu() {
  const { entityId } = useParams();

  return (
    <LayoutAdmin>
      {/* path */}
      <div className="flex mb-4">
        <Link to="/a"><h2 className="text-xl font-thin mr-3">Admin</h2></Link>
        <Link to={`/a/config/entity-creator/${entityId}/form`}><h2 className="text-xl font-thin mr-3">Entidad</h2></Link>
        <h2 className="text-xl font-semibold">Menú de Filtros</h2>
      </div>

      <Content entityId={entityId}></Content>
    </LayoutAdmin>
  );
}
