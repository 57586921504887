import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { mapOutline } from 'ionicons/icons';
import RawInputGPS, { RawInputGPSWithPlaces } from './RawInputGPS';
import { FieldLabel, required, ShowFieldHelp } from './formParts';
import config from '../../config';
import RawInputGPSzone from './RawInputGPSzone';
import { DistanceSlider } from './RawInputGPSbyPlaces';
import { IonButton, IonIcon } from '@ionic/react';

const FormFieldGPS = (props) => {
  let {
    name,
    title,
    className,
    required: isRequired = false,
    accuracyType = 'exact',
    includePlace,
    showDistance,
    showClearBtn = true
  } = props;
  const validate = isRequired ? required : undefined;

  const handleButtonClick = (input) => () => {
    if (input.value) {
      input.onChange(null); // Set input value to null if it has a value
    } else {
      const val = config.defaultLocationGPS || { lat: 0, lng: 0 };
      if (showDistance) {
        val.distance = config.modules.panel.gps.defaultDistanceForInput;
      }
      input.onChange(val); // Set input value to default location or specific value
    }
  };

  const handleDistanceChange = (input) => (value) => {
    input.onChange({
      ...input.value,
      distance: value
    });
  };

  const handlePositionChange = (input) => (value) => {
    const val = { 
      lat: value?.lat,
      lng: value?.lng
    };
    if (showDistance) {
      val.distance = input.value?.distance || config.modules.panel.gps.defaultDistanceForInput;
    }
    if (includePlace) {
      val.place = value.place;
    }
    input.onChange(val);
  };

  return (
    <div className={`pb-3 ${className}`}>
      <Field
        name={name}
        validate={validate}
        >
        {({ input, meta }) => {
          return (
            <>
              <FieldLabel
                {...{...props, input, meta}}
                showClearBtn={false}
                Toolbar = {() => (<>
                  {/* Botón para seleccionar o borrar ubicación */}
                  {showClearBtn ? (
                    <IonButton
                      type="button"
                      onClick={() => handleButtonClick(input)({ input })}
                      size="small"
                      color="secondary"
                      fill="solid"
                    >
                      <IonIcon icon={mapOutline} className="text-lg" slot="start"></IonIcon>
                      {input.value ? 'Borrar' : 'Seleccionar'}
                    </IonButton>
                  ) : null}
                </>)}
              />
              {input.value ? (
                <div className="mt-2">
                  {includePlace ? (<>
                    {accuracyType === 'exact' ? (
                      <RawInputGPSWithPlaces {...props} value={input.value} onChange={handlePositionChange(input)} />
                    ) : null}
                    {accuracyType === 'zone' ? (
                      <RawInputGPSWithPlaces Render={RawInputGPSzone} {...props} value={input.value} onChange={handlePositionChange(input)} />
                    ) : null}
                  </>) : (<>
                    {accuracyType === 'exact' ? (
                      <RawInputGPS {...props} value={input.value} onChange={handlePositionChange(input)} />
                    ) : null}
                    {accuracyType === 'zone' ? (
                      <RawInputGPSzone {...props} value={input.value} onChange={handlePositionChange(input)} />
                    ) : null}
                  </>)}
                  {showDistance ? (
                    <DistanceSlider
                      value={input?.value?.distance}
                      onChange={handleDistanceChange(input)}
                      classes={{ fieldContainer: 'mt-2 px-2 py-2 font-thin text-sm', fieldLabel: 'font-normal !text-black' }}
                    />
                  ) : null}
                </div>
              ) : null}
              {/* Render error if any */}
              {meta.touched && meta.error && (
                <div className="text-red-600" style={{ fontSize: 12 }}>
                  {meta.error}
                </div>
              )}
              {/* Error */}
              <ShowFieldHelp {...props} name={name} input={input} meta={meta} required={isRequired} />
            </>
          )
        }}
      </Field>
    </div>
  );
};

export default FormFieldGPS;
