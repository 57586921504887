import _ from 'lodash';
import { trimSlash } from '../../libs/utils';


export const withOutPrefix = (entitySlug = '') => {
  let newSlug = entitySlug.split('.');
  if (newSlug.length > 1) {
    return newSlug[1];
  }
  return newSlug[0];
};


export const getPrefix = (entitySlug, defaultValue) => {
  let slugParts = entitySlug?.split('.');
  if (slugParts?.length > 1) {
    return slugParts[0];
  }
  return defaultValue || null;
};


export const withPrefix = (prefix, entitySlug, separator = '.') => {
  if (prefix) {
    return `${prefix}${separator}${entitySlug}`;
  }
  return entitySlug;
};


export const urlPrefix = (prefix, url) => {
  if (!url) {
    throw new Error('url is required');
  }
  if (prefix) {
    return `/${trimSlash(prefix)}/${trimSlash(url)}`;
  }
  return `/${trimSlash(url)}`;
};


export const getNewPrefix = (entitySlug, instanceDoc, module) => {
  if (instanceDoc.data.hash === 'main' && module.scope === 'global') {
    return withOutPrefix(entitySlug);
  }
  if (instanceDoc.data.hash === 'main' && module.scope === 'instance') {
    return `main.${withOutPrefix(entitySlug)}`;
  }
  if (instanceDoc.data.hash !== 'main' && module.scope === 'instance') {
    return `${instanceDoc.data.hash}.${withOutPrefix(entitySlug)}`;
  }
};


export const getInstanceFromLocation = (pathname) => {
  if (!pathname) {
    pathname = document.location.pathname;
  }
  let currentInstance = pathname.split('/')[1];
  if (
    !currentInstance 
    || currentInstance === 'a'
    || currentInstance === 'm'
    || currentInstance === 'login'
    || currentInstance === 'install'
  ) {
    currentInstance = 'main';
  }
  return currentInstance;
};


export const instanceParts = (nameSlug = '') => {
  const instanceParts = nameSlug.split('.');
  const instance = instanceParts?.length ? instanceParts[0] : null;
  const entitySlug = nameSlug;
  const moduleEntitySlug = instance ? instanceParts[1] : entitySlug;
  return {
    instance,
    entitySlug,
    moduleEntitySlug
  };
};