import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldSelect,
  FormFieldCheckbox,
  FormFieldCheckboxToggle
} from '../Form';
import {
  // Boolean
  FilterRenderBoolean,
  FilterCreatorBoolean,
} from '../Filters';

export default {
  Render(props) {
    const {
      onChange,
      fieldsRequired
    } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);

    return (
      <FormFieldCheckboxToggle 
        title={title}
        fieldsRequired={fieldsRequired}
        {...props}
        {...style}
        {...param}
        name={_fieldName}
      />
    );
  },

  RenderFilter(props) {
    return <FilterRenderBoolean {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorBoolean {...props} />
  },

  RenderInputParams: ({ values, param }) => (<>
    <div className="mt-4 mb-4 border-b border-gray-400">
      <FormFieldCheckbox
        name="param.showCondition"
        title="Condición de visibilidad en vistas"
      />
    </div>
    <FormFieldCheckboxToggle
      name="param.defaultValue"
      title="Valor por defecto"
      switchType="b" // "b"
      colorFalse="gray-400"
      textTrue={values.param?.textTrue || "Activo"}
      textFalse={values.param?.textFalse || "Inactivo"}
    />
    <FormField
      name="param.textTrue"
      title="Texto activado"
    />
    <FormField
      name="param.textFalse"
      title="Texto inactivo"
    />
    <FormFieldSelect
      name="style.design"
      title="Diseño"
      placeholder="Diseño"
      options={[
        { value: 'toggle', label: 'Switch' },
        // { value: 'checkbox', label: 'checkbox' }
      ]}
    />
  </>),

  RenderShowConditionParams: ({ values, taxonomyToWatch }) => null,

  RenderShowParams: ({ values }) => null,

  RenderInputPreview: ({ values, formFieldProps }) => {
    if (values.style?.design === 'toggle') {
      return (<FormFieldCheckboxToggle {...formFieldProps} />);
    }
    if (values.style?.design === 'checkbox') {
      return (<FormFieldCheckbox {...formFieldProps} />);
    }
    return '';
  },
 
  getDefaultTaxonomyTypeDesign: () => ('toggle')
}