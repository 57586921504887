import React from 'react';
import IonBtnLoading from './IonBtnLoading';
import { IonButton } from '@ionic/react';


export default function ModalAlert({
  text,
  title,
  subtitle,
  onConfirm,
  onCancel,
  confirmLabel = 'Confirmar',
  cancelLabel = 'Cancelar',
  confirmClass = 'bg-brand-yellow',
  children
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-[1000]">
      <div className="fixed inset-0 bg-black opacity-50"></div> {/* Overlay */}
      <div className="bg-white rounded-lg p-6 shadow-md relative mx-5 max-w-sm text-center">
        {(title || text) ? (
          <p className="mt-6 mb-10 text-lg font-normal text-black">{title || text}</p>
        ) : null}
        {subtitle ? (
          <p className="mb-4 text-sm text-gray-500">{subtitle}</p>
        ) : null}
        {children ? (
          <div className="mb-4 text-sm text-black">{children}</div>
        ) : null}
        <div className="flex place-content-between">
          {onConfirm ? (
            <IonBtnLoading
              label={confirmLabel}
              onClickAsync={onConfirm}
              color="primary"
              size="small"
              fill="solid"
            />
          ) : null}
          {onCancel ? (
            <IonButton
              onClick={onCancel}
              color="dark"
              size="small"
              fill="clear"
            >
              {cancelLabel}
            </IonButton>
          ) : null}
        </div>
      </div>
    </div>
  );
}
