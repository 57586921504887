import _ from "lodash";
import { routePageStack } from "../../layout/RouteLayoutBlock";
import { specDesign, stringsMapLayoutBlocks } from "../../specSite";
import HeaderMiniBlock from "./Blocks/HeaderMini";


const specStack = [
  {
    strings: stringsMapLayoutBlocks.headerMini,
    template: HeaderMiniBlock,
    params: stringsMapLayoutBlocks.headerMini.params
  },
  {
    type: 'productGrid',
    variant: 'withFilters',
    // strings: blocks.headerHero,
    params: {
      filterMenuSlug: "publicSearch",
      filterPath: "/",
      viewPath: "/m/products/view",
      cartPath: "/m/carts/view",
      // imgProportion: 'vertical-square'
    }
  },
  {
    strings: stringsMapLayoutBlocks.ctaFooter,
    template: HeaderMiniBlock,
    params: stringsMapLayoutBlocks.ctaFooter.params
  },
  {
    type: 'pageFooter',
    variant: 'linksFixedCTA',
    strings: stringsMapLayoutBlocks.footer,
    // params: { showCTA: false }
  }
];

export const RouteProductFilter = routePageStack({ specDesign, specStack });