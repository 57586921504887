import { useState } from "react";
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import { 
  FaQuoteLeft,
  FaArrowLeft,
  FaArrowRight
} from "react-icons/fa";
import { lineBreaks } from "../../../libs/utils";


export default function () {
  return {
    type: 'reviews',
    variant: 'grid',
    template: BlockReviewsAGrid
  };
};

export function BlockReviewsAGrid({ strings }) {
  const [startIndex, setStartIndex] = useState(0);
  const testimonialsPerPage = 2;
  const totalPages = Math.ceil(strings.items.length / testimonialsPerPage);

  const handleNext = () => {
    const newIndex = startIndex + testimonialsPerPage;
    setStartIndex(newIndex >= strings.items.length ? 0 : newIndex);
  };

  const handlePrevious = () => {
    const newIndex = startIndex - testimonialsPerPage;
    setStartIndex(newIndex < 0 ? strings.items.length - testimonialsPerPage : newIndex);
  };

  return (
    <div className="px-4 mb-40 mt-40 text-black">
      <div className="container-width">
        {/* Titles */}
        <AnimationAddClassWhenVisible classToAdd="animate-fadeIn" classDefault="duration-1000 delay-[100ms]">
          <div className="text-left px-4">
            {strings.title ? (<h3 className="text-4xl md:text-4xl font-brand-main font-bold">{lineBreaks(strings.title)}</h3>) : null}
            {strings.subtitle ? (<h3 className="text-lg lg:text-xl font-bold font-brand-main text-gray-500 md:mb-2">{lineBreaks(strings.subtitle)}</h3>) : null}
            {strings.content ? (<p className="mt-4 mx-auto px-8 text-lg font-brand-main">{lineBreaks(strings.content)}</p>) : null}
          </div>
        </AnimationAddClassWhenVisible>
        {/* Testimonials */}
        <AnimationAddClassWhenVisible classToAdd="animate-fadeIn" classDefault="duration-1000 delay-[300ms]">
          <div className="mt-8 px-4">
            <div className={`grid gap-6 ${totalPages > 1 ? 'lg:grid-cols-2' : ''}`}>
              {strings.items.slice(startIndex, startIndex + testimonialsPerPage).map((item, index) => (
                <div key={index} className="review-item p-4 border-brand-medium border bg-white rounded-lg">
                  <div className="review-title text-lg md:text-xl font-semibold flex items-center leading-tight font-brand-main text-brand-primary">
                    <FaQuoteLeft className="text-xl mr-2.5 ml-7 w-7" />
                    <h3 className="">{lineBreaks(item.title)}</h3>
                  </div>
                  <div className="mt-4 md:text-lg font-brand-secondary">{lineBreaks(item.content)}</div>
                </div>
              ))}
            </div>
            {totalPages > 1 && (
              <div className="flex justify-center gap-4 text-xl mt-8">
                <button
                  className={`hover:underline ${startIndex === 0 ? 'text-brand-medium' : 'text-brand-primary'}`}
                  onClick={handlePrevious}
                  disabled={startIndex === 0}
                >
                  <FaArrowLeft />
                </button>
                <button
                  className={`text-xl hover:underline ${startIndex >= strings.items.length - testimonialsPerPage ? 'text-brand-medium' : 'text-brand-primary'}`}
                  onClick={handleNext}
                  disabled={startIndex >= strings.items.length - testimonialsPerPage}
                >
                  <FaArrowRight />
                </button>
              </div>
            )}
          </div>
        </AnimationAddClassWhenVisible>
      </div>
    </div>
  );
}
