/////////////////////////////////////////
// Theme specification
/////////////////////////////////////////

export default {
  /////////////////////////////////////////
  // FONTS
  /////////////////////////////////////////
  fonts: {
    mainFont: 'GT Walsheim Pro',
    mainFontAlt: 'sans-serif',
    secondaryFont: 'Lato',
    secondaryFontAlt: 'sans-serif',
  },

  /////////////////////////////////////////
  // COLORS
  /////////////////////////////////////////
  colors: {
    /** primary **/
    'primary': '#211cf4',
    'primary-contrast': '#ffffff',
    'primary-shade': '#130FB9',
    'primary-tint': '#625EF9',

    /** secondary **/
    'secondary': '#1ac3ff',
    'secondary-contrast':'white',
    'secondary-shade': '#d7e6f8',
    'secondary-tint': '#41a0eb',

    /** tertiary **/
    'tertiary': '#9e67f7',
    'tertiary-contrast': '#932a7d',
    'tertiary-shade': '#995656',
    'tertiary-tint': '#c9a0a0',

    "success": "#3cb371",
    "success-contrast": "#ffffff",
    "success-shade": "#349b62",
    "success-tint": "#4bce82",
    "warning": "#ffd700",
    "warning-contrast": "#000000",
    "warning-shade": "#e6be00",
    "warning-tint": "#ffdd33",
    "danger": "#dc143c",
    "danger-contrast": "#ffffff",
    "danger-shade": "#c31238",
    "danger-tint": "#e04a6c",
    "dark": "#000000",
    "dark-contrast": "#ffffff",
    "dark-tint": "#929292",
    "dark-shade": "#000000",
    "medium": "#808080",
    "medium-contrast": "#ffffff",
    "medium-tint": "#8c8c8c",
    "medium-shade": "#737373",
    "light": "#cccccc",
    "light-contrast": "#000000",
    "light-tint": "#EAEAEA",
    "light-shade": "#D2D2D2"
  },
};