import { registerParts } from "./BlockStack";
import PartBackground, { backgroundClasses } from "./parts/PartBackground";
import PartBtnList, { btnListClasses } from "./parts/PartBtnList";
import PartLogo from "./parts/PartLogo";


registerParts([
  PartBackground,
  PartBtnList,
  PartLogo
]);

export const PartsClasses = () => {
  const classes = `
    ${backgroundClasses}
    ${btnListClasses}
  `;
  return <div className={classes}></div>;
}
