import { Field } from 'react-final-form';
import _ from 'lodash';
import { FieldLabel, ShowFieldHelp } from './formParts';
import dayjs from 'dayjs';
import { IonDatetime, IonDatetimeButton, IonModal } from '@ionic/react';
import { nanoid } from 'nanoid';
import { useMemo } from 'react';


const FormFieldDate = (props) => {
  let {
    name,
    title,
    placeholder,
    fieldsRequired = null,
    showLabel = true,
    showClearBtn = true,
    validate = null,
    disabled = false,
    // formatter = (value) => value,
    maxDate = null,
    minDate = null,
    UnderInput = null,
    onChange = null,
    onBlur = null,
    includeTime = false,
    // defaultValue,
    design = "block"
  } = props;

  const elementId = useMemo(() => nanoid(), []);

  const validateField = (value) => {
    let error;
    
    if (value !== undefined) {
      
    }
    
    if (!error && validate && typeof validate === 'function') {
      error = validate(value);
    }
    
    return error;
  };
  
  return (
    <div className={`${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={validateField}>
        {({ input, meta }) => {
          let dateValue = includeTime 
            ? dayjs(input.value || new Date()).format('YYYY-MM-DDTHH:mm:ss')
            : dayjs(input.value || new Date()).format('YYYY-MM-DD');

          const setValue = (newDate) => {
            if (includeTime) {
              input.onChange(newDate);
            }
            else {
              input.onChange(dayjs(newDate).format('YYYY-MM-DD'));
            }
          };          

          return (
            <>
              <FieldLabel {...props} input={input} meta={meta} />
              {design === 'button' ? (<>
                <IonDatetimeButton datetime={elementId} className="place-content-start"></IonDatetimeButton>

                <IonModal keepContentsMounted={true}>
                  <IonDatetime
                    id={elementId}
                    presentation={includeTime ? "date-time" : "date"}
                    className="mx-auto z-0"
                    value={dateValue}
                    onIonChange={e => setValue(e.target.value)}
                    max={maxDate}
                    min={minDate}
                  >
                  </IonDatetime>
                </IonModal>
              </>

              ) : null}
              {design === 'block' ? (
                <div className="p-0.5 border border-gray-200 rounded-md relative z-0">
                  <IonDatetime
                    presentation={includeTime ? "date-time" : "date"}
                    className="mx-auto z-0"
                    value={dateValue}
                    onIonChange={e => setValue(e.target.value)}
                    max={maxDate}
                    min={minDate}
                  >
                  </IonDatetime>
                </div>
              ) : null}
              {UnderInput && (<UnderInput input={input} />)}
              {/* error */}
              <ShowFieldHelp {...props} name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default FormFieldDate;