import { Carousel } from 'react-responsive-carousel';
import { AnimationAddClassWhenVisible } from '../../../components/ui/AnimationAddClassWhenVisible';
import { lineBreaks } from '../../../libs/utils';


export default function () {
  return {
    type: 'textGrid',
    variant: 'extendedCarousel',
    template: TextGridCExtendedCarousel
  };
};

const ItemCarrousel = ({ items, design = 'square', className }) => {
  let classes = {};
  if (design === 'square') {
    classes.img = "h-[450px] sm:h-[450px] lg:h-[450px] object-cover md:object-top";
  }
  if (design === 'horizontal') {
    classes.img = "h-[300px] sm:h-[300px] lg:h-[400px] object-cover md:object-top";
  }

  if (!items?.length) {
    return null;
  }

  return (
    <Carousel showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true} className={className}>
      {items?.map((item, index) => (
        <div key={index}>
          <img 
          src={item.src} 
          alt={item.title}
          className={classes.img}
          />
            <div className="absolute bottom-4 sm:bottom-1 w-full mx-auto md:space-y-5 pb-24 md:pb-32 lg:pb-32 xl:pb-36">
              <h3 className='text-brand-primary-contrast font-brand-main font-black px-4 text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl uppercase drop-shadow-md '>{lineBreaks(item.title)}</h3>
              <p className=' text-white font-black font-brand-main px-4 text-lg uppercase drop-shadow-md '>{lineBreaks(item.subtitle)}</p>
            </div>
        </div>
      ))}
    </Carousel>
  );
};

export function TextGridCExtendedCarousel({ strings }) {

    const classes = {
      container: "py-16 sm:py-20 lg:py-28 px-8 sm:px-8 xl:px-[10vw] 2xl:px-[15vw]",
      content: "flex flex-col md:flex-row lg:items-center gap-4 sm:gap-8",
      title: "text-black font-bold font-brand-main text-3xl lg:text-[42px] xl:text-[43px] 2xl:text-[45px] leading-tight",
      subtitle: "pb-4 text-gray-400 font-bold font-brand-main text-3xl lg:text-[42px] xl:text-[43px] 2xl:text-[45px] leading-tight",
      text: "pb-6 text-gray-700 font-brand-secondary opacity-80 text-xl md:text-[22px] 2xl:text-2xl leading-relaxed ",
    };

    return (
      <div id="beneficios" className="text-left">

        {/* Main items[0] */}
        <div className={`bg-gradient-to-br from-brand-secondary to-brand-primary bg-fixed`}>
          <div className={classes.container}>
            <div className={classes.content}>
              {strings.items[0].images?.length ? (
                <div className="md:basis-1/2">
                  <ItemCarrousel 
                    items={strings.items[0].images} 
                    design="horizontal" 
                    className="shadow-brand-primary-contrast/20 border border-gray-200 rounded-lg overflow-hidden shadow-lg md:shadow-2xl"
                  />
                </div>
              ) : null}

              <div className="md:basis-1/2 order-last content-center">
                <div className="pt-4">
                  <div className="text-left lg:text-base text-sm lg:text-start">
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[200ms]">
                      <h3 className={`${classes.title} text-white`}>{lineBreaks(strings.items[0].title)}</h3>
                    </AnimationAddClassWhenVisible>
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                      <div className="">
                        <h3 className={`${classes.subtitle} text-white/70`}>{lineBreaks(strings.items[0].subtitle)}</h3>
                        {/* <p className={`${classes.text} md:hidden text-white`}>{lineBreaks(strings.items[0].content)}</p> */}
                      </div>
                    </AnimationAddClassWhenVisible>
                  </div>
                </div>
              </div>
            </div>

            <div className="md:mt-8">
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                <p className={`${classes.text} text-white`}>{lineBreaks(strings.items[0].content)}</p>
              </AnimationAddClassWhenVisible>
            </div>
          </div>
        </div>

        {/* second items[1] */}
        <div className={`${classes.container} bg-white text-black`}>
          <div className={classes.content}>
            {strings.items[0].images?.length ? (
              <div className="md:basis-1/2">
                <ItemCarrousel
                  items={strings.items[1].images}
                  design="horizontal"
                  className="shadow-gray-300 border border-gray-200 rounded-lg overflow-hidden shadow-lg md:shadow-2xl"
                />
              </div>
            ) : null}

            <div className="md:basis-1/2 order-last md:order-first content-center">
              <div className="pt-4">
                <div className="text-left lg:text-base text-sm lg:text-start">
                  <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[200ms]">
                    <h3 className={`${classes.title}`}>{lineBreaks(strings.items[1].title)}</h3>
                  </AnimationAddClassWhenVisible>
                  <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                    <div className="">
                      <h3 className={`${classes.subtitle}`}>{lineBreaks(strings.items[1].subtitle)}</h3>
                      {/* <p className={`${classes.text} md:hidden`}>{lineBreaks(strings.items[1].content)}</p> */}
                    </div>
                  </AnimationAddClassWhenVisible>
                </div>
              </div>
            </div>
          </div>

          <div className="md:mt-8">
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
              <p className={`${classes.text}`}>{lineBreaks(strings.items[1].content)}</p>
            </AnimationAddClassWhenVisible>
          </div>
        </div>
        
        {/* third items[2] */}
        {strings.items[2] ? (
          <div className={`${classes.container} bg-white text-black`}>
            <div className={classes.content}>
              {strings.items[2].images?.length ? (
                <div className="">
                  <ItemCarrousel
                    items={strings.items[2].images}
                    design="horizontal"
                    className="shadow-gray-300 border border-gray-200 rounded-lg overflow-hidden shadow-lg md:shadow-2xl"
                  />
                </div>
              ) : null}

              <div className="md:basis-1/2 order-last md:order-first content-center">
                <div className="pt-4">
                  <div className="text-left lg:text-base text-sm lg:text-start">
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[200ms]">
                      <h3 className={`${classes.title}`}>{lineBreaks(strings.items[2].title)}</h3>
                    </AnimationAddClassWhenVisible>
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                      <div className="">
                        <h3 className={`${classes.subtitle}`}>{lineBreaks(strings.items[2].subtitle)}</h3>
                        {/* <p className={`${classes.text} md:hidden`}>{lineBreaks(strings.items[2].content)}</p> */}
                      </div>
                    </AnimationAddClassWhenVisible>
                  </div>
                </div>
              </div>
            </div>

            <div className="md:mt-8">
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                <p className={`${classes.text}`}>{lineBreaks(strings.items[2].content)}</p>
              </AnimationAddClassWhenVisible>
            </div>
          </div>
        ) : null}

      </div>
    );
  }
  