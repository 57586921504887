import { useState } from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { AnimationAddClassWhenVisible } from '../../../components/ui/AnimationAddClassWhenVisible';
import { lineBreaks, openWhatsApp } from '../../../libs/utils';


export default function () {
  return {
    type: 'ctaMenu',
    variant: 'accordionHero',
    template: CtaMenuBAccordionHero
  };
};

export function CtaMenuBAccordionHero({ strings }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="px-4 py-20 bg-brand-secondary-shade text-center">
      <div className="md:max-w-4xl xl:max-w-6xl mx-auto grid gap-4 grid-flow-row grid-cols-1 md:grid-cols-2 lg:gap-8">
        {/* Left */}
        <div className="pb-5 md:pt-10 md:text-left">
          {/* Titles */}
          <AnimationAddClassWhenVisible classToAdd="animate-fadeIn" classDefault="duration-1000 delay-[100ms]">
            <div className="text-center">
              <h3 className="text-lg lg:text-xl uppercase font-bold font-brand-main md:mb-2">{lineBreaks(strings.title)}</h3>
              <h3 className="text-4xl md:text-4xl font-brand-main text-brand-primary font-bold">{lineBreaks(strings.subtitle)}</h3>
              <p className="mt-4 mx-auto max-w-xs px-4 pb-4 text-lg font-brand-main">{lineBreaks(strings.content)}</p>
            </div>
          </AnimationAddClassWhenVisible>
          {/* img */}
        </div>
        {/* Right */}
        <AnimationAddClassWhenVisible classToAdd="animate-fadeIn" classDefault="duration-1000 delay-[300ms]">
          <div className="">
            <div className="grid gap-4 grid-flow-row grid-cols-1 md:grid-cols-1">
              {strings.items.map((item, index) => (
                <div key={index} className="bg-white border-gray-200 border shadow-sm hover:shadow-lg hover:border-gray-300 rounded-xl">
                  <div className={`accordion-item ${index === activeIndex ? 'active' : ''}`}>
                    <button type="button" className="accordion-title py-4 block w-full font-semibold md:text-lg cursor-pointer hover:underline" onClick={() => handleAccordionClick(index)}>
                      {lineBreaks(item.title)}
                    </button>
                    {index === activeIndex && (
                      <div className="accordion-content px-4 pt-2 pb-4 md:text-lg">
                        {lineBreaks(item.content)}
                        <div className='flex mt-4 justify-center'>
                          {item.ctaType === "whatsapp" && (
                            <a href={openWhatsApp({ message: item.contactMessage }, true)} target="_blank" className={`
                              bg-brand-dark rounded-lg text-white px-2 py-1 md:text-lg flex items-center hover:bg-brand-tertiary
                            `}>
                              <FaWhatsapp className="text-xl text-white mr-1.5" />
                              {lineBreaks(item.ctaLabel)}
                            </a>
                          )}
                          {item.ctaType === "link" && (
                            <a href={item.ctaUrl} target="_blank" className={`
                              bg-brand-dark rounded-lg text-white px-2 py-1 md:text-lg hover:bg-slate-600
                            `}>
                              {lineBreaks(item.ctaLabel)}
                            </a>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </AnimationAddClassWhenVisible>
      </div>
    </div>
  );
}
