import { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import _ from 'lodash';
import SwitchInputB from './SwitchInputB';
import { ShowFieldHelp } from './formParts';

const SelectSwitch = (props) => {
  let {
    mode = 'string', // input.value mode string || array
    input, 
    meta,
    options,
    disabled = false,
  } = props;

  const getValue = () => {
    if (mode === 'string') {
      if (input.value.length > 0) {
        return _.compact( input.value.split(',') );
      }
      return '';
    }
    return input.value || [];
  }

  const setValue = (inputValue) => {
    if (mode === 'string') {
      if (inputValue.length > 0) {
        inputValue = _.compact( inputValue ).join(',');
        input.onChange(inputValue);
      }
      input.onChange(null);
    }
    input.onChange(inputValue);
  }

  const [selectedOptions, setSelectedOptions] = useState(getValue());

  useEffect(() => {
    if (!input.value) {
      setSelectedOptions([]);
      setValue([]);
    }
  }, [input.value]);

  const handleOptionChange = (optionValue) => {
    let updatedOptions;
    if (selectedOptions.includes(optionValue)) {
      updatedOptions = selectedOptions.filter((value) => value !== optionValue);
    } else {
      updatedOptions = [...selectedOptions, optionValue];
    }
    updatedOptions = _.compact(updatedOptions);
    if (_.size(updatedOptions)) {
      setSelectedOptions(updatedOptions);
      setValue(updatedOptions);
    } else {
      setSelectedOptions([]);
      setValue([]);
    }
  };  

  return (
    <div className={`flex gap-4 ${props?.classes?.fieldInput}`}>
      {options && options.map((option) => (
        <SwitchInputB
          key={option.value}
          value={selectedOptions && selectedOptions.includes(option.value)}
          onChange={() => handleOptionChange(option.value)}
          textTrue={option.label}
          textFalse={option.label}
          colorTrue="blue-700"
          disabled={disabled}
        />
      ))}
    </div>
  );
}

const FormFieldMultiSelectSwitch = (props) => {
  const {
    name,
    title,
    fieldsRequired = null,
    validate = null,
    disabled = false,
    showLabel = true,
    onSelect = (() => null),
  } = props;
  
  return (
    <div className={`${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={validate}>
        {({ input, meta }) => {

          return (
            <>
              {showLabel && title ? (
                <div className={props?.classes?.fieldLabel}>
                  <span
                    className={`font-semibold mr-3 ${
                      meta.touched && meta.error
                        ? 'text-red-600'
                        : ''
                    }`}
                  >
                    {title}
                    {meta.touched && meta.error ? '*' : ''}
                  </span>
                </div>
              ) : ''}
              
              <SelectSwitch {...props} {...{input, meta}} />
              
              {/* error */}
              <ShowFieldHelp {...props} name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
              {/* <RequiredByFieldsList
                name={name}
                input={input}
                fieldsRequired={fieldsRequired}
              /> */}
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default FormFieldMultiSelectSwitch;