import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import dayjs from 'dayjs';
import { IonIcon } from '@ionic/react';
import {
  gridOutline
} from 'ionicons/icons';
import { useEntityFullBySlug } from '../entity/Entity';
import { compileStringTemplate, getImageURL, priceFormat, trimSlash, withProvider } from '../../libs/utils';
import { EntityDocListView } from '../../components/EntityDocView';
import BadgeLoading from '../../components/ui/BadgeLoading';
import { urlPrefix, withPrefix } from '../instance/utilsInstance';
import { CartProvider, useCart } from './useCartData';
import { cartsPathsFields, imgProportionField } from './BlockProductGridWithFilters';
import IonContactBTN from '../../components/ui/IonContactBTN';
import { whatsappObjectFields } from '../blockStack/blocks/BlockPageFooterBContactsLinksFixedCta';
import IonCopyBtn from '../../components/IonCopyBtn';
import { ShowCategoryFields } from './CartItemThumbGrid';
import { getConfig } from '../../config';
import { useAsyncMemo } from 'use-async-memo';


export default function () {
  return {
    type: 'cartView',
    variant: 'public',
    template: CartView,
    params: {
      ...cartsPathsFields,
      imgProportion: imgProportionField,
      'sendCartLink': {
        type: 'object',
        name: 'Whatsapp: Enlace del pedido',
        descriptionText: 'Mensaje avisando sobre nuevo pedido',
        fields: whatsappObjectFields
      }
    }
  };
};

const bagId = 0;

const Content = (props) => {
  let {
    specDesign,
    instance,
    cartId,
    config,
    Model,
    history,
    match,
    viewPath,
    cartPath,
    sendCartLink
  } = props;

  const {
    isDetailsOpen, setIsDetailsOpen, openDetailsDrawer,
    entityMap, CartModel, ItemMainModel, ItemVariantModel, CategoryModel, TypeModel, 
    fetchItems, items, itemsTypes, itemsCategories,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    // cartDoc, 
    saveNewCart, saveCurrentCart, closeCart,
    doPopulateBags,
    totalPriceItems
  } = useCart();

  const cartsEntitySlug = entityMap.carts.entitySlug;
  const variantsEntitySlug = entityMap.cartItemVariants.entitySlug;
  const [ bagItemsDetails, setBagItemsDetails ] = useState([]);
  const [ cartDoc, setCartDoc ] = useState();
  const entitySpecs = useEntityFullBySlug({ entitySlug: variantsEntitySlug });
  const [ categoryFieldsById, setCategoryFieldsById ] = useState(null);

  const categoryFieldsDocList = useAsyncMemo(async () => {
    let docs = await Model.extend(entityMap.cartItemFields?.entitySlug || withPrefix(instance, getConfig().modules.cart.cartItemFieldsEntitySlug))
      .filterByAttributes({ deleted: 'false' });
    let categoryFieldsById = {};
    docs.forEach((doc) => {
      // data to doc format
      doc.data.fields = doc.data.fields.map((data) => (new Model(data)));
      categoryFieldsById[doc.id] = doc;
    });
    setCategoryFieldsById(categoryFieldsById);
    return docs;
  }, []);
  
  let whatsappNumber = sendCartLink?.useBrandPhone ? specDesign.stringsVars?.brand?.whatsappNumber : sendCartLink?.phoneNumber;
  let sendCartLinkMessage = compileStringTemplate(sendCartLink?.phoneMessage, {
    brand: specDesign.stringsVars.brand,
    cart: {
      link: `${config.getURLprefix()}${urlPrefix(instance, `${trimSlash(cartPath)}/`)}/#/cartId/${cartId}`
    }
  });

  useEffect(() => {
    fetchCartDetailsData();
  }, [match.url]);

  const fetchCartDetailsData = async () => {
    const CartModel = Model.extend(cartsEntitySlug);
    const VariantsModel = Model.extend(variantsEntitySlug);
    const cartDoc = await CartModel.findById(cartId);
    if (!cartDoc) { return; }
    const itemsInBag = cartDoc?.data?.itemsInBags ? cartDoc.data.itemsInBags[bagId].bagItems : [];
    const populatedItemsInBag = [];
    for (const itemInBag of itemsInBag) {
      const itemDoc = await VariantsModel.findById(itemInBag.params.variantId);
      populatedItemsInBag.push({
        ...itemInBag,
        itemDoc
      });
    }
    setBagItemsDetails(populatedItemsInBag);
    setCartDoc(cartDoc);
  };

  const goToItemByParams = (mainId, variantId, params) => {
    history.push(urlPrefix(instance, `${trimSlash(viewPath)}/#/main/${mainId}/variant/${variantId}`));
  };

  if (!cartDoc) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (
    <div className="py-12">

      {/* title */}
      <div className="text-center">
        <h3 className="text-lg">
          <span className="text-brand-dark-shade">Pedido</span>
          <span className="ml-3 px-3 py-1.5 font-mono bg-brand-dark-shade text-white rounded-md">{cartDoc.id}</span>
        </h3>
        <div className={`mt-2 py-0.5 text-sm text-brand-dark-shade`}>{dayjs(cartDoc?.data?.createdAt).format('LLLL')}</div>
      </div>

      {/* details */}
      {/* whatsapp */}
      <div className="mt-10 max-w-xl mx-auto p-5 flex flex-col border-2 border-brand-dark-shade rounded-lg text-center text-stone-500 shadow">
        <h3 className="text-2xl font-semibold text-brand-dark-shade shaked-cascade-1">Avisá a la tienda</h3>
        <span className="text-sm text-brand-dark-shade shaked-cascade-2">Directamente por WhatsApp o copia y pega el enlace de pedido manualmente</span>
        {/* btns */}
        <div className="mt-8 flex flex-col md:flex-row place-items-center w-full">
          <div className="w-full sm:w-1/2">
            <IonContactBTN
              message={sendCartLinkMessage}
              label={"Abrir WhatsApp"}
              classes={{ btnContainer: 'mt-4 mx-auto' }}
              number={whatsappNumber}
              size="medium"
            />
          </div>
          <div className="w-full sm:w-1/2">
            <IonCopyBtn
              text={sendCartLinkMessage}
              size="medium"
            />
          </div>
        </div>
      </div>

      <div className="mt-10 px-3 max-w-3xl mx-auto">

        <div className="text-center mb-8">
          <h3 className="text-md font-semibold text-gray-500">Total del pedido</h3>
          <div className="text-lg font-semibold">{priceFormat(cartDoc?.data?.total)}</div>
        </div>

        <h3 className="mb-4 text-md font-semibold text-center text-gray-500">
          Productos seleccionados
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-5 items-center cursor-default">
          {bagItemsDetails.map((itemInBag, index) => {
            const variantDoc = itemInBag.itemDoc;
            const variantDocWithParams = { id: variantDoc.id, data: { ...variantDoc.data, ...itemInBag.params } };
            let categoryDoc = categoryFieldsById[variantDoc.data['itemCategory_fieldsId']];

            return (
              <EntityDocListView
                key={index}
                {...entitySpecs}
                doc={variantDoc}
                classes={{
                  fieldLabel: "hidden"
                }}
                render={({ ViewData, mainAttr, mainImgAttr }) => (
  
                  <div className="flex flex-row gap-4 border border-gray-300 rounded-xl overflow-hidden relative grow">
                    {/* image xs */}
                    <img src={variantDoc?.data && getImageURL(variantDoc.data['variantImgs'][0], 'md')} 
                      className="h-64 w-44 object-cover cursor-pointer"
                      onClick={() => goToItemByParams(itemInBag.id, variantDoc.id, itemInBag.params)}
                    /> 
                    {/* data */}
                    <div className="bg-white flex-1 py-4 cursor-pointer flex flex-col gap-3" 
                      onClick={() => goToItemByParams(itemInBag.id, variantDoc.id, itemInBag.params)}
                    >
                      <div className="">
                        <ViewData field="name" classes={{ fieldContainer: 'pb-0.5 px-1 md:text-lg lg:text-xl font-semibold leading-tight underline' }} />
                        <ViewData field="price" classes={{ fieldContainer: 'text-sm sm:text-md md:text-lg text-gray-600' }} decorator={priceFormat} />
                      </div>

                      {/* params */}
                      <div className="flex flex-row flex-wrap gap-2 items-start">
                        {/* 
                          show data of categoryFieldsDoc
                        */}
                        <ShowCategoryFields
                          item={variantDocWithParams}
                          categoryDoc={categoryDoc}
                          instance={instance}
                          entitySlug={variantsEntitySlug}
                        />
                      </div>

                      <div className="">
                        <h3 className="text-xs uppercase font-semibold text-gray-500">Cantidad</h3>
                        <div className="text-sm uppercase font-semibold">{itemInBag.qty}</div>
                      </div>

                      <div className="">
                        <h3 className="text-xs font-semibold text-gray-500">Total</h3>
                        <div className="text-sm uppercase font-semibold">{priceFormat(itemInBag.qty * itemInBag.price)}</div>
                      </div>
                    </div>
                  </div>
                  
                )}
              />
            );
          })}
        </div>
      </div>

    </div>
  );
};

export const CartView = withProvider(CartViewRaw, CartProvider);

function CartViewRaw(props) {
  let {
    instance,
    history,
    data,
    parsedParams,
    // entitySlug,
    // action,
    // config,
    user,
    isAllowed,
    Model,
    isMinBreakpointActive,
    location,
    filterMenuSlug,
    filterPath,
    viewPath,
    cartPath,
    showFilters,
    imgProportion,
    classes = {}
  } = props;

  return (
    <div className={`my-4 md:my-6 pb-4 px-4 container-width-wide ${classes.blockContainer}`}>
      
      <Link to={urlPrefix(instance, filterPath)} className="flex flex-row items-center gap-2 text-left text-sm text-brand-primary font-semibold hover:underline" >
        <IonIcon icon={gridOutline} className="text-xl text-brand-primary"></IonIcon>
        Ver Catálogo
      </Link>

      <hr className="border-brand-primary my-4 md:my-6" />

      {parsedParams.cartId ? (<>
        <Content {...props} cartId={parsedParams.cartId} />
      </>) : ''}
    </div>
  );
}