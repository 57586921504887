export default function () {
  return {
    slug: 'layoutHome',
    label: 'Layout Home',
    scope: 'global',
    bundles: ['core'],
    entities: {},
    permissions: [],
    components: {}
  };
};