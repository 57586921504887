import {useMemo } from 'react';
import _ from 'lodash';
import { objectFormToTaxonomyTypes } from './ObjectForm';
import { DocCard } from './docCardsVariants';


const ObjectShow = (props) => {
  let { instance, entitySlug, fields, value, classes={} } = props;

  const taxonomyTypesDocList = useMemo(() => {
    let fieldsDataList = objectFormToTaxonomyTypes(fields);
    return fieldsDataList.map((data, index) => ({
      id: index,
      data: {
        ...data,
        nameSlug: data.fieldName
      }
    }));
  }, [fields]);

  return (
    <DocCard
      {...props}
      instance={instance}
      doc={value?.data ? value : { data: value, id: 0 }}
      entitySpecs={{
        entitySlug,
        taxonomyTypesDocList
      }}
      classes={{
        ...classes
      }}
      outstandingOnly={false}
    />
  );
};

export default ObjectShow;