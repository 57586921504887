import { LayoutAdmin } from "../panel/LayoutAdmin";
import Model, { useStateSingleResult } from "../../libs/ModelClass";
import { useModule } from '../../libs/ModuleContext';
import { IonButton, IonFab, IonFabButton, IonFabList, IonIcon, IonTitle } from '@ionic/react';
import { 
  ellipsisVerticalOutline,
  createOutline
} from 'ionicons/icons';
import { useEntityFullBySlug } from './Entity';
import { useAsyncMemo } from "use-async-memo";
import { DocCard } from "./docCardsVariants";
import { usePanel } from "../panel/usePanel";


const RelViewHasOne = ({ isAllowed, localDoc, localEntity, foreignEntity, attachPrefix }) => {
  const entitySpecs = useEntityFullBySlug({ entitySlug: foreignEntity?.data?.nameSlug });
  const relDoc = useAsyncMemo(async () => {
    if (entitySpecs?.taxonomyTypesDocList) {
      // get foreign related taxonomy
      const field = entitySpecs.taxonomyTypesDocList.find((taxonomyTypeDoc) => {
        let result = taxonomyTypeDoc?.data?.type === 'selectOneEntityDocument' 
          && taxonomyTypeDoc?.data.param?.entityNameSlug === localEntity?.data.nameSlug;
        return result;
      });
      // get related document
      if (field) {
        let query = {};
        query[field.data.nameSlug] = localDoc.id;
        const relDoc = await entitySpecs.ExtendedModel.filterByAttributes(query);
        return relDoc?.length ? relDoc[0] : null;
      }
    }
  }, [entitySpecs?.foreignEntity?.id, entitySpecs?.taxonomyTypesDocList]);
  
  return (
    relDoc?.data ? (<>
      <div className="">
        <h2 className="mb-2 text-sm text-gray-500 font-semibold">{foreignEntity?.data?.name}</h2>
        <DocCard doc={relDoc} entitySpecs={entitySpecs} 
          UnderItem={() => (<>
            <div className="my-2 border-b border-gray-300"></div>
            <ListBtns {...{
              isAllowed,
              entitySlug: foreignEntity?.data?.nameSlug,
              entityId: foreignEntity?.id,
              docId: relDoc.id
            }} />
          </>)}
        />
      </div>
    </>) : null
  );
};

const ListBtns = ({ isAllowed, entitySlug, entityId, docId, attachPrefix }) => {
  return (
    <div className="">
      {isAllowed(entitySlug, ['update']) ? (
        <IonButton  size="small" fill="clear" color="primary"
        routerLink={attachPrefix(`/a/entity/${entitySlug}/${docId}/form`)}>
          Editar
        </IonButton>
      ) : null}
      {/* {isAllowed(entitySlug, ['delete']) ? (
        <button type="button" onClick={() => history.push(`/a/entity/${entitySlug}/${docId}`)}
        className="px-2 py-0.5 text-sm bg-red-200 text-red-900 rounded-md">
          Eliminar
        </button>
      ) : null} */}
    </div>
  );
};

const SpeedDial = ({ isAllowed, entitySlug, entityId, docId, attachPrefix }) => {
  return (
    <IonFab slot="fixed" vertical="bottom" horizontal="end" className="fixed xs:right-4 xs:bottom-4">
      {isAllowed(entitySlug, ['update']) ? (<>
        <IonFabButton color="warning">
          <IonIcon icon={ellipsisVerticalOutline}></IonIcon>
        </IonFabButton>
        <IonFabList side="top">
          <IonFabButton routerLink={attachPrefix(`/a/entity/${entitySlug}/${docId}/form`)}>
            <IonIcon icon={createOutline}></IonIcon>
          </IonFabButton>
        </IonFabList>
      </>) : null}
      {/* {isAllowed(entitySlug, ['delete']) ? (
        <button type="button" onClick={() => history.push(`/a/entity/${entitySlug}/${docId}`)}
        className="px-2 py-0.5 text-sm bg-red-200 text-red-900 rounded-md">
          Eliminar
        </button>
      ) : null} */}
    </IonFab>
  );
};

export function Content({ entityDoc, history, match, attachPrefix }) {
  const entitySlug = entityDoc?.data?.nameSlug;
  const { entityId, docId } = match.params;
  const { isAllowed } = useModule();
  let { doc, ...entitySpecs } = useEntityFullBySlug({ entitySlug, docId });
  
  const entitiesDocList = useAsyncMemo(async () => {
    if (entitySpecs?.EntityModel) {
      let records = await entitySpecs.EntityModel.getAll();
      records = records?.filter((doc) => doc.data?.deleted !== true);
      return records;
    }
  }, [doc, entitySpecs?.entityDoc]);

  const getRelDocs = (relType) => {
    let relSlugs = entitySpecs?.entityDoc?.data['relSlugs' + relType];
    relSlugs = relSlugs?.split(', ');
    const relDocs = relSlugs?.map((entitySlug) => {
      return entitiesDocList?.find((entityDoc) => entityDoc?.data?.nameSlug === entitySlug);
    });
    return relDocs;
  };

  // const relsBelongsTo = getRelDocs('BelongsTo');
  // const relsBelongsToMany = getRelDocs('BelongsToMany');
  const relsHasOne = getRelDocs('HasOne');
  // const relsHasMany = getRelDocs('HasMany');

  return (
    <div className="p-2 pb-24">
      <div className="">
        {/* <SpeedDial {...{isAllowed, entitySlug, entityId, docId, attachPrefix}} /> */}
        {/* Entity card */}
        {/* <h2 className="mb-2 text-sm text-gray-500 font-semibold">{entitySpecs?.entityDoc?.data?.name}</h2> */}
        {/* <IonTitle className="">{entitySpecs?.entityDoc?.data?.name}</IonTitle> */}
        <DocCard {...{doc, entitySpecs}}
          UnderItem={(<div>
            <div className="my-2 border-b border-gray-300"></div>
            <ListBtns {...{isAllowed, entitySlug, entityId, docId, attachPrefix}} />
          </div>)}
        />
      </div>
      {/*
        Related cards
      */}
      {/* hasOne */}
      {relsHasOne?.map((entityDoc, index) => (
        <RelViewHasOne key={index}
          {...{isAllowed, attachPrefix}}
          localDoc={doc}
          localEntity={entitySpecs.entityDoc}
          foreignEntity={entityDoc}
        />
      ))}
    </div>
  );
}

export function RouteEntityCrudShow(props) {
  let { context } = props;
  const { entitySlug } = props.match.params;
  const { attachPrefix } = usePanel();
  const entityDoc = useStateSingleResult({
    Model: Model.extend('entities'), 
    nameSlug: entitySlug
  });
  const listUrl = attachPrefix('/a/entity/' + entityDoc?.data.nameSlug + '/list');
  const breadcrumbs = [
    {
      url: listUrl,
      title: entityDoc?.data?.name
    },
    {
      title: "Detalles"
    }
  ];

  return (
    <LayoutAdmin 
      history={props.history}
      defaultHref={context?.defaultBackRoute || listUrl}
      breadcrumbs={breadcrumbs}
    >
      {entityDoc && <Content entityDoc={entityDoc} attachPrefix={attachPrefix} {...props} />}
    </LayoutAdmin>
  );
}
