import _ from 'lodash';
import React, { lazy, Suspense } from 'react';


const RawInputCodeSimple = lazy(() => import('./RawInputCodeSimple'));

const RawInputTextAreaCodeSimple = (props) => {
  let { value, onChange } = props;
  
  return (
    <Suspense fallback={<div>Cargando editor...</div>}>
      <RawInputCodeSimple value={value} onChange={onChange} />
    </Suspense>
  );
};

export default RawInputTextAreaCodeSimple;
