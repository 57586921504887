import { Field } from 'react-final-form';
import _ from 'lodash';
import { useState, useEffect } from 'react';

import { required, RequiredByFieldsList, ShowFieldHelp } from './formParts';


const FormFieldCheckbox = (props) => {
  let {
    name,
    title,
    className,
    fieldsRequired=null,
    validate=null,
    disabled=false,
    formatter=null
  } = props;
  
  if (!formatter) {
    formatter = (value) => value
  }

  return (
    <div className={`pb-3 ${className}`}>
      <Field name={name} validate={(args) => validate && validate(args)}>
        {({ input, meta }) => (<>
          <div className="my-1">
            <label className="flex flex-row content-center text-left gap-2">
              <input
                type="checkbox"
                checked={formatter(input.value) ? true : false} 
                onChange={input.onChange}
                disabled={disabled}
                className="w-5 h-5 relative"
              />
              <span className={`${meta.touched && meta.error ? 'text-red-600' : ''}`}>
                {title}{(meta.touched && meta.error || (_.includes(fieldsRequired, name) && !input.value)) ? '*' : ''}
              </span>
            </label>
            {/* error */}
            <ShowFieldHelp {...props} name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
          </div>
        </>)}
      </Field>
    </div>
  );
}

export default FormFieldCheckbox;