import _ from 'lodash';
import React, { lazy, Suspense } from 'react';


const RawInputNotion = lazy(() => import('./RawInputNotion'));

const RawInputTextAreaNotion = (props) => {
  return (
    <Suspense fallback={<div>Cargando...</div>}>
      <RawInputNotion {...props} />
    </Suspense>
  );
};

export default RawInputTextAreaNotion;
