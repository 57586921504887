import _ from 'lodash';
import config from '../../config';
import { RouteEntityCrudForm } from '../entity/RouteEntityCrudForm';
import { usePanel } from '../panel/usePanel';
import Model, { useStateSingleResult } from '../../libs/ModelClass';
import { useAsyncMemo } from 'use-async-memo';
import { useEffect, useState } from 'react';
import { InstanceInlineSelectorForMain } from './InstanceSelector';
import BadgeLoading from '../../components/ui/BadgeLoading';
import { FormInstanceBrandWizard } from './FormInstanceBrandWizard';


const instancesBrandsEntitySlug = config.modules.instances.instancesBrandsEntitySlug;
const InstancesBrandsModel = Model.extend(instancesBrandsEntitySlug);
const instancesEntitySlug = config.modules.instances.instancesEntitySlug;
const InstanceModel = Model.extend(instancesEntitySlug);

export const RouteInstanceBrandEdit = (props) => {
  let {
    parsedParams,
    history,
    showInstanceSelector = true
  } = props;
  const { selectedInstance } = usePanel();
  const [ selectedInner, setSelectedInner ] = useState(selectedInstance);
  const [ newBrandDoc, setNewBrandDoc ] = useState(null);
  const entityDoc = useStateSingleResult({
    Model: Model.extend('entities'), 
    nameSlug: instancesBrandsEntitySlug
  });

  useEffect(() => {
    setSelectedInner(selectedInstance);
  }, [selectedInstance]);

  // find or create brand doc
  const brandDoc = useAsyncMemo(async () => {
    if (newBrandDoc) {
      return newBrandDoc;
    }
    if (selectedInner?.id) {
      let doc = await InstancesBrandsModel.whereOne('instanceId', '==', selectedInner?.id);
      if (!doc) {
        doc = await InstancesBrandsModel.create({
          instanceId: selectedInner?.id
        });
      }
      return doc;
    }
    return null;
  }, [selectedInner?.id]);


  // for Main instance
  // show instance selector for global entities from main instance
  let TitleToolbarRightToUse;
  if (selectedInstance?.data.hash === 'main' && showInstanceSelector) {
    TitleToolbarRightToUse = (() => (
      entityDoc?.data && !entityDoc.data.isInstance && (
        <InstanceInlineSelectorForMain selectedInstance={selectedInner} setSelectedInstance={setSelectedInner} />
      )
    ));
  }

  if (
    !selectedInner?.id
    || !brandDoc?.id
  ) {
    return (
      <div className="pt-32 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  // assign new brand doc to state
  const onWizardUpdateDoc = ({ doc }) => {
    setNewBrandDoc(doc);
  };
  
  let ContentRender = null;
  // show wizard form
  if (
    brandDoc?.id && !(
      brandDoc.data.mainStringsId || 
      brandDoc.data.colorPaletteId || 
      brandDoc.data.mainPartsId || 
      brandDoc.data.layoutBlocksId
    )
  ) {
    ContentRender = () => (
      <FormInstanceBrandWizard
        {...props}
        context={context}
        selectedInstance={selectedInner}
        doc={brandDoc}
        onFinish={onWizardUpdateDoc}
      />
    )
  }

  const context = {
    entitySlug: instancesBrandsEntitySlug,
    docId: parsedParams.docId,
    breadcrumbs: [
      {
        title: "Branding de Instancia"
      }
    ],
    onAfterSave: () => {
      // do nothing
    }
  };

  return (
    <RouteEntityCrudForm
      {...props}
      doc={brandDoc}
      context={context}
      selectedInstance={selectedInner}
      TitleToolbarRight={TitleToolbarRightToUse}
      ContentRender={ContentRender}
    />
  );
}
