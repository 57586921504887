import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldCheckboxToggle,
  FormFieldDate,
  FormFieldSelect
} from '../Form';
import {
  // Date
  FilterRenderDate,
  FilterCreatorDate,
} from '../Filters';


export default {
  Render(props) {
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);

    if (taxonomyTypeData.readOnly) {
      return (
        <FormField
          {...props}
          {...style}
          {...param}
          name={_fieldName}
          disabled={true}
        />
      );
    }
    if(design === "compact" || design === "default") {
      return (
        <FormFieldDate
          {...props}
          {...style}
          {...param}
          name={_fieldName}
          design="button"
        />
      );
    }
    else if (design === "extense") {
      return (
        <FormFieldDate
          {...props}
          {...style}
          {...param}
          name={_fieldName}
          design="block"
        />
      );
    }
  },
  
  RenderFilter(props) {
    return <FilterRenderDate {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorDate {...props} />
  },

  RenderInputParams: ({ values, param }) => (
    <>
      <FormFieldCheckboxToggle
        name="includeTime"
        title="Incluir hora y minutos"
        showLabel={true}
      />
    </>
  ),
  
  RenderShowParams: ({ values }) => (
    <>
      <FormField
        name="show.format"
        title="Formato dayjs().format()"
        placeholder="Formato dayjs().format()"
        showLabel={true}
      />
      <FormFieldSelect
        name="style.design"
        title="Diseño"
        placeholder="Diseño"
        options={[
          { value: 'compact', label: 'Compacto' },
          { value: 'extense', label: 'Extenso' },
        ]}
      />
    </>
  ),

  RenderShowPreview: ({ values }) => (null),
  
  RenderInputPreview: ({ values, formFieldProps }) => (
    <FormField {...formFieldProps} type="date" />
  ),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}