import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { 
  key
} from 'ionicons/icons';
import { RouteApiKeySection } from "./RouteApiKeySection";


export const AdminConfigAfterLinksList = ({ isAllowed, user, history }) => {
  return (<>
    <div className="">
      {isAllowed('apiKey', ['section']) ? (
        <IonItem routerLink="/a/entity/apiKey" routerDirection="forward">
          <IonIcon icon={key}></IonIcon>
          <IonLabel className="ml-2">Llaves de acceso a la API</IonLabel>
        </IonItem>
      ) : null}
    </div>
  </>
  );
};

export default function () {
  return {
    name: 'Llaves de acceso a la API',
    slug: 'apiKey',
    scope: 'instance',
    bundles: ['starter'],
    entities: [
      'apiKey'
    ],
    permissions: [
      { slug: 'apiKey', label: 'apiKey' },
    ],
    routesAdmin: {
      'apiKey': {
        'section': {
          permission: { resource: 'apiKey', action: ['section'] },
          Content: RouteApiKeySection
        },
      }
    },
    components: {
      main: {
        AdminConfigAfterLinksList
      },
    }
  };
};