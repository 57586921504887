import { createContext, useContext, useEffect, useState } from "react";
import applyLayoutDesign from "../../modules/layoutHome/applyLayoutDesign";


const layoutHomeContext = createContext();

export const useLayoutHome = () => {
  const context = useContext(layoutHomeContext);
  if (!context) throw new Error("There is no LayoutHome provider");
  return context;
};

export function LayoutHomeProvider({ children }) {
  const [layoutDesign, innerSetLayoutDesign] = useState();
  const [ offsetBottom, setOffsetBottom ] = useState(false);
  
  const setLayoutDesign = (desingDef) => {
    if (desingDef) {
      applyLayoutDesign(desingDef);
      innerSetLayoutDesign(desingDef);
    } else {
      innerSetLayoutDesign({});
    }
  };

  return (
    <layoutHomeContext.Provider
      value={{
        layoutSetted: !!layoutDesign,
        layoutDesign,
        setLayoutDesign,
        offsetBottom, setOffsetBottom
      }}
    >
      {children}
    </layoutHomeContext.Provider>
  );
}
