import _ from 'lodash';
import { useAsyncMemo } from "use-async-memo";
import Model from "../../libs/ModelClass";
import { parseStringToObject } from "../../libs/utils";
import ObjectForm from "../entity/ObjectForm";
import { inputClasses } from "../../components/EntityTaxonomyForm";
import { withPrefix } from "../instance/utilsInstance";
import { creditForeignField } from "./dataTypePaymentConfig";
import PaymentsList, { calculatePayments } from "./PaymentsList";
import dayjs from "dayjs";


const contractsModelsEntitySlug = "contractsModels";

export const afterRead = async (props, docData) => {
  if (!docData.id)  {
    docData.issuedDate = docData.issuedDate || dayjs().format('YYYY-MM-DD');
    docData.firstDueDate = docData.firstDueDate || dayjs().format('YYYY-MM-DD');
    docData.status = "active";
  }
  return docData;
};

export const afterSave = async (props, savedDoc) => {
  let {
    doc,
    formValues,
    isEdit,
    instance
  } = props;
  if (!formValues.id && formValues.modelId)  {
    // calc payments
    const contractId = doc.id;
    const contractModelId = formValues.modelId;
    const contractModelDoc = await Model.extend(withPrefix(instance, contractsModelsEntitySlug)).findById(contractModelId);
    const paymentConfig = contractModelDoc?.data.paymentConfig;
    let { payments, totalAmount, totalInterest } = calculatePayments(paymentConfig, formValues);
    // save payments
    payments.forEach((payment) => {
      payment.customerId = doc.data.customerId;
      payment.contractId = contractId;
      payment.contractModelId = contractModelId;
    });
    let PaymentsModel = Model.extend(withPrefix(instance, 'payments'));
    await PaymentsModel.createManyBatch(payments);
    // update doc
    doc.data.totalAmount = totalAmount;
    doc.data.totalInterest = totalInterest;
    await doc.save();
  }
};

export const FormAppendColumnRight = (props) => {
  let {
     values,
     doc,
     instance,
     entityDoc,
     entitySlug,
     taxonomyTypesDocList,
     isAllowed,
     formProps
  } = props;
  const contractModelId = values.modelId;
  
  const contractModelDoc = useAsyncMemo(async () => {
    if (!contractModelId) { return null; }
    return await Model.extend(withPrefix(instance, contractsModelsEntitySlug)).findById(contractModelId);
  }, [values.modelId]);

  const paymentConfig = contractModelDoc?.data.paymentConfig;
  let fieldsToUse = _.cloneDeep(creditForeignField.fields);

  // default values
  if (paymentConfig?.startStrategy === 'customFirstDueDate') {
    fieldsToUse.firstDueDate.defaultValue = dayjs().date();
    fieldsToUse.firstDueDate.readOnly = false;
    fieldsToUse.firstDueDate.maxDate = dayjs().set('date', 28).format('YYYY-MM-DD');
  }
  else {
    Reflect.deleteProperty(fieldsToUse, 'firstDueDate');
  }
  
  return (
    contractModelDoc ? (
      (
        contractModelDoc?.data.paymentType === 'credit'
        && paymentConfig
      ) ? (<>
        <div className={`${inputClasses.renderListContainer} !gap-0`}>
          <ObjectForm
            {...props}
            {...formProps}
            fields={fieldsToUse}
          />
        </div>
      </>) : null
    ) : null
  );
};

export const FormAppend = (props) => {
  let {
    instance,
    values
  } = props;

  const contractModelId = values.modelId;

  const contractModelDoc = useAsyncMemo(async () => {
    if (!contractModelId) { return null; }
    return await Model.extend(withPrefix(instance, contractsModelsEntitySlug)).findById(contractModelId);
  }, [values.modelId]);

  const paymentConfig = contractModelDoc?.data.paymentConfig;

  return (
    <div className="w-full mt-10">
      <PaymentsList
        {...props}
        paymentConfig={paymentConfig}
        contractData={values}
      />
    </div>
  );
};

export const ContractModelForeignForm = (props) => {
  let {
    form,
    values,
    doc,
    instance,
    entityDoc,
    entitySlug,
    taxonomyTypeDoc,
    taxonomyTypesDocList,
    isAllowed
  } = props;
  const relatedEntitySlug = taxonomyTypeDoc?.data?.param?.entityNameSlug;
  const contractModelId = values.modelId;
  
  const contractModelDoc = useAsyncMemo(async () => {
    if (!contractModelId) { return null; }
    return await Model.extend(relatedEntitySlug).findById(contractModelId);
  }, [values.modelId]);
  
  const parsedFields = contractModelDoc?.data.foreignFields ? parseStringToObject(contractModelDoc?.data.foreignFields) : null;
  
  return (<>
    {contractModelDoc ? (
      <div className={inputClasses.renderListContainer}>
        
        {parsedFields ? (
          <ObjectForm
            {...props}
            fields={parsedFields}
          />
        ) : null}
      </div>
    ) : null}
  </>);
};