import { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { FaRegTimesCircle, FaSpinner, FaCloudUploadAlt, FaStar } from 'react-icons/fa'
import { uploadFile, deleteImageFile } from '../../firebase';
import { resizeFile, getImageURL, trimSlash } from '../../libs/utils';
import config from '../../config';
import { Capacitor } from '@capacitor/core';
import usePhoto from '../../modules/panel/usePhoto';
import { nanoid } from 'nanoid';
import slugify from 'slugify';
import toast from 'react-hot-toast';
import { getInstanceFromLocation, getPrefix } from '../../modules/instance/utilsInstance';

/**
 * RawInputGallery Component
 * @param {Array} value - An array of image file names (e.g., ['imag1-12312312.jpg', 'imag2-4234243.jpg']) representing the attached files hashes.
 * @param {Function} onChange - A function to handle the change event and update the value by adding the fileHash.
 * @param {String} folder - The folder name where the files will be stored.
 * @param {Array} dimensions - An array of objects defining the image dimensions for resizing.
 * @param {Boolean} doResize - A boolean indicating whether to resize the images.
 * @param {Number} maxSize - The maximum size (in bytes) allowed for the uploaded files.
 * @returns {React.Component} - The RawInputGallery component.
 */
const RawInputGallery = (props) => {
  let {
    value = [],
    onChange,
    onAfterChange,
    folder,
    dimensions,
    doResize,
    maxSize,
    formApi
  } = props;
  let dimensionsToUse = dimensions || config.imageDimensions;
  dimensionsToUse = _.isArray(dimensionsToUse) ? dimensionsToUse : [dimensionsToUse];
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [uploading, setUploading] = useState(0);
  const { openPhotoOptions, convertCameraPhotoToFile } = usePhoto();
  const inputId = useMemo(() => 'dropzone-file-' + nanoid(), []);
  let instance = getPrefix(props.entitySlug) || props.instance || 'main';
  let folderToUse = trimSlash(folder || config.imgFolder);

  useEffect(() => {
    if (!_.isArray(value)) {
      value = [];
      setAttachedFiles([]);
    }
    setAttachedFiles(value);
  }, [value]);

  const attachFile = async (files) => {
    const totalSteps = files.length * dimensionsToUse.length; // Cantidad total de redimensiones por cada archivo
    setUploading(1);
    try {
      // check maxSize
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (maxSize && file.size > maxSize*1024*1024) {
          throw new Error(`File size exceeds the maximum allowed size of ${maxSize} MB mega bytes.`);
        }
      }
      // resize and upload files
      const newFiles = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const hash = nanoid(12);
        const format = file.type.split('/')[1];
        const fileNameSlug = slugify(file.name.split('.')[0], { lower: true, replacement: '-', remove: /[*+~.()'"!:@]/g });
        let filePath = `${fileNameSlug}-${hash}.${format}`;
        newFiles.push(filePath);
        if (doResize) {
          for (let j = 0; j < dimensionsToUse.length; j++) {
            const { width, height, suffix } = dimensionsToUse[j];
            const fileData = await resizeFile({ file, width, height, format });
            let filePath = `${fileNameSlug}-${hash}${suffix}.${format}`;
            await uploadFile(fileData, `/${instance}/${folderToUse}/${filePath}`);
            setUploading(((i * dimensionsToUse.length) + j + 1) * (100 / totalSteps)); // Calcula el porcentaje de avance
          }
        } 
        else {
          await uploadFile(file, `/${instance}/${folderToUse}/${filePath}`);
        }
      }
      const updatedFilesNames = [...attachedFiles, ...newFiles];
      setAttachedFiles(updatedFilesNames);
      onChange(updatedFilesNames);
      onAfterChange && await onAfterChange({ updatedFilesNames, files, formApi });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
    setUploading(0);
  };

  const unattachFile = async (fileHashToUnattach) => {
    try {
      await deleteImageFile(instance, `/${folderToUse}/${fileHashToUnattach}`, doResize);
    } catch (error) {
      console.log(error);
    }
    const newAttachedFiles = attachedFiles.filter((file) => file !== fileHashToUnattach);
    setAttachedFiles(newAttachedFiles);
    onChange(newAttachedFiles);
  };

  const markAsMain = (fileHash) => {
    const newAttachedFiles = attachedFiles.filter((file) => file !== fileHash);
    newAttachedFiles.unshift(fileHash);
    setAttachedFiles(newAttachedFiles);
    onChange(newAttachedFiles);
  };

  const appTakePhoto = async () => {
    if (!uploading && Capacitor.isNativePlatform()) {
      const newPhotos = await openPhotoOptions();
      if (newPhotos?.photos) {
        const files = [];
        for (const i in newPhotos.photos) {
          files[i] = await convertCameraPhotoToFile(newPhotos.photos[i]);
        }
        await attachFile(files);
      }
      else {
        const file = await convertCameraPhotoToFile(newPhotos);
        await attachFile([file]);
      }
    }
  };

  return (
    <div className="grid grid-flow-row grid-cols-3 gap-4">
      {/* file list */}
      {_.size(attachedFiles) ? attachedFiles.map((fileHash, index) => (
        <div key={fileHash} className="w-24 h-24 mr-2.5 rounded-lg hover:shadow-md relative">
          <img src={getImageURL(fileHash, doResize ? 'xs' : null, folderToUse, instance)} key={fileHash} className={
            `w-full h-full object-cover rounded-lg ${index === 0 ? 'border-4 border-yellow-400' : 'border border-gray-500'}`
          }/>

          <button type="button" onClick={() => markAsMain(fileHash)} className="absolute left-1 bottom-1 bg-yellow-200 p-0.5 rounded-full">
            <FaStar className='fill-yellow-800' />
          </button>
          <button type="button" onClick={() => unattachFile(fileHash)} className="absolute right-1 bottom-1 bg-red-200 p-0.5 rounded-full">
            <FaRegTimesCircle className='fill-red-900' />
          </button>
        </div>
      )) : null}
      
      {/* new file */}
      <label htmlFor={inputId} 
        className="flex flex-col justify-center items-center w-24 h-24
        rounded-lg border-2 cursor-pointer text-gray-600
        bg-gray-400 hover:bg-gray-500 border-gray-500"
        onClick={appTakePhoto}
      >
        <div className="flex flex-col justify-center items-center">
          {!uploading ? (<>
            <FaCloudUploadAlt className='w-8 h-8' />
          </>) : (<>
            <FaSpinner className='w-8 h-8' />
            <span className="pt-1.5 text-sm font-semibold text-gray-600">{Math.round(uploading)} %</span>
          </>)}
            {/* <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
            <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 2800x2800px)</p> */}
        </div>
        {!Capacitor.isNativePlatform() ? (
          <input onChange={(e) => !uploading && attachFile(e.target.files)} id={inputId} type="file" className="hidden" multiple />
        ) : null}
      </label>
    </div>
  );
}

export default RawInputGallery;