import {useMemo } from 'react';
import _ from 'lodash';
import { objectFormToTaxonomyTypes } from './ObjectForm';
import { DocCard, DocRow } from './docCardsVariants';


export const ObjectList = (props) => {
  let { instance, entitySlug, design, fields, values, classes={} } = props;

  const taxonomyTypesDocList = useMemo(() => {
    let fieldsDataList = objectFormToTaxonomyTypes(fields);
    return fieldsDataList.map((data, index) => ({
      id: index,
      data: {
        ...data,
        nameSlug: data.fieldName
      }
    }));
  }, [fields]);

  if (design === 'grid') {
    return _.map(values, (data, index) => {
      return (
        <DocCard
          {...props}
          key={index}
          instance={instance}
          doc={data?.data ? data : { data, id: index }}
          entitySpecs={{
            entitySlug,
            taxonomyTypesDocList
          }}
          classes={{
            ...classes
          }}
          outstandingOnly={false}
        />
      );
    });
  }

  else if (design === 'table') {
    return (
      <div className="overflow-x-scroll overflow-y-scroll max-h-96">
        <table className="w-full text-left">
          {/* first row */}
          <thead className="sticky top-0 bg-white z-10">
            <tr>
              {taxonomyTypesDocList.map(({ id, data }) => (
                <th className="px-4 py-1" key={id}>
                  {data.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {_.map(values, (data, index) => (
              <DocRow
                key={index}
                instance={instance}
                doc={{ data, id: index }}
                entitySpecs={{
                  entitySlug,
                  taxonomyTypesDocList
                }}
                classes={{
                  row: '',
                  firstCol: '',
                  col: 'px-2 py-1',
                  ...classes
                }}
                outstandingOnly={false}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }  
};

export default ObjectList;