import { useState } from "react";
import { getConfig } from '../../config';
import { addLogEvent } from '../../firebase';
import { openWhatsApp } from '../../libs/utils';
import { IonButton, IonIcon } from '@ionic/react';
import { logoWhatsapp } from 'ionicons/icons';


export default function IonContactBTN (props) {
  const config = getConfig();
  let {
    className,
    number = config.whatsappNumber || config.number, 
    message = config.whatsAppTextBTN(),
    label = 'Whatsapp',
    afterSend = () => {},
    size='small',
    fill='solid',
    color='primary'
  } = props;

  const openWA = () => {
    addLogEvent('contact_btn'); // analytics
    openWhatsApp({ 
      number, 
      message
    });
    afterSend();
  }

  /////// design A
  return (<>
    <IonButton 
      type="button"
      onClick={() => openWA()}
      size={size}
      fill={fill}
      color={color}
    >
      <IonIcon icon={logoWhatsapp} size="small" slot="start"></IonIcon>
      {label}
    </IonButton>
  </>);
}