import _ from "lodash";
import { routePageStack } from "../../layout/RouteLayoutBlock";
import { specDesign, stringsMapLayoutBlocks } from "../../specSite";
import HeaderMiniBlock from "./Blocks/HeaderMini";


const specStack = [
  {
    strings: stringsMapLayoutBlocks.headerMini,
    template: HeaderMiniBlock,
    params: stringsMapLayoutBlocks.headerMini.params
  },
  {
    type: 'cartView',
    variant: 'public',
    params: {
      filterPath: "/",
      viewPath: "/m/products/view",
      // imgProportion: 'vertical-wide'
    },
  },
  {
    strings: stringsMapLayoutBlocks.ctaFooter,
    template: HeaderMiniBlock,
    params: stringsMapLayoutBlocks.ctaFooter.params
  },
  {
    type: 'pageFooter',
    variant: 'linksFixedCTA',
    strings: stringsMapLayoutBlocks.footer,
    // params: { showCTA: false }
  }
];

export const RouteCartView = routePageStack({ specDesign, specStack });
