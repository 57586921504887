import { FaMailBulk, FaMapMarkerAlt, FaPhone, FaRegClock, FaWhatsapp } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import { lineBreaks } from "../../../libs/utils";


export default function () {
  return {
    type: 'contactCta',
    variant: 'centered',
    template: BlockContactCtaACentered
  };
};

const Icon = ({ type, ...props }) => {
  if (type === 'whatsapp') {
    return <FaWhatsapp {...props}></FaWhatsapp>
  }
  else if (type === 'phone') {
    return <FaPhone {...props}></FaPhone>
  }
  else if (type === 'email') {
    return <IoIosMail {...props}></IoIosMail>
  }
  else if (type === 'address') {
    return <FaRegClock {...props}></FaRegClock>
  }
  else if (type === 'location') {
    return <FaMapMarkerAlt {...props}></FaMapMarkerAlt>
  }
  return null;
};

export function BlockContactCtaACentered({ strings }) {
  return (
    <div className="px-4 py-20 bg-brand-primary-tint text-brand-dark text-center">
      {/* Left */}
      <div className="container-width">
        <AnimationAddClassWhenVisible classToAdd="animate-fadeIn">
          <div className="text-center">
            {strings.title ? (<h3 className="text-lg lg:text-xl px-14 uppercase font-bold font-brand-main md:mb-2">{lineBreaks(strings.title)}</h3>) : null}
            {strings.subtitle ? (<h3 className="text-4xl md:text-4xl px-4 font-brand-main text-brand-primary font-bold">{lineBreaks(strings.subtitle)}</h3>) : null}
            {strings.content ? (<p className="mt-4 mx-auto px-8 pb-4 text-lg font-brand-main">{lineBreaks(strings.content)}</p>) : null}
          </div>
        </AnimationAddClassWhenVisible>
        
        <div className="mt-4 flex flex-row place-content-around place-items-center items-center flex-wrap">
          {strings.items?.map((item, index) => (
            <div key={index} className={`basis-1/2 p-2`}>
              <div key={index} className={`
                bg-white border-brand-medium border rounded-xl py-4 shadow-sm cursor-pointer 
                hover:bg-brand-primary hover:text-brand-primary-contrast hover:shadow-xl hover:-translate-y-0.5 hover:scale-105 
                transition duration-300
              `}>
                <Icon type={item.iconType} className="h-6 w-10 pr-3 inline-block" />
                <a href={item.url} target="_blank" className="font-semibold">
                  {lineBreaks(item.title)}
                </a>
              </div>
            </div>
          ))}
        </div>

        <div className="font-brand-secondary space-y-4 p-4 md:px-20 md:py-10">
          {strings.phoneNumber ? (
            <div className="flex items-start gap-2">
              <div className="">
                <FaWhatsapp size={25}></FaWhatsapp>
              </div>
              <div className="text-base md:text-lg text-left space-x-2">
                <span className="font-semibold">Teléfono:</span>
                <span>
                  {lineBreaks(strings.phoneNumber)}
                </span>
              </div>
            </div>
          ) : null}

          {strings.email ? (
            <div className="flex items-start gap-2">
              <div className="">
                <IoIosMail size={25}></IoIosMail>
              </div>
              <div className="text-base md:text-lg text-left space-x-2">
                <span className="font-semibold">Email:</span>
                <span>
                  {lineBreaks(strings.email)}
                </span>
              </div>
            </div>
          ) : null}

          {strings.schedule ? (
            <div className="flex items-start gap-2">
              <div className="">
                <FaRegClock size={25}></FaRegClock>
              </div>
              <div className="text-base md:text-lg text-left space-x-2">
                <span className="font-semibold">Horarios:</span>
                <span>
                  {lineBreaks(strings.schedule)}
                </span>
              </div>
            </div>
          ) : null}

          {strings.address ? (
            <div className="flex items-start gap-2">
              <div className="">
                <FaMapMarkerAlt size={25}></FaMapMarkerAlt>
              </div>
              <div className="text-base md:text-lg text-left space-x-2">
                <span className="font-semibold">Dirección:</span>
                <span>
                  {lineBreaks(strings.address)}
                </span>
              </div>
            </div>
          ) : null}
        </div>
      </div>

    </div>
  );
}
