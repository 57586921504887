import { lineBreaks } from "../../../../libs/utils";
import { Background } from "../../../../modules/blockStack/parts/PartBackground";


export default function HeaderMiniBlock(props) {
  const { data, mainBg } = props;

  return (
    <div id='inicio'>      
      <Background params={mainBg}>
        <div className="py-16 space-y-10 w-full min-h-[h-[350px] lg:min-h-[450px] xl:min-h-[500px] flex flex-col place-content-center">
          {data.images?.length ? (
            <div className="">
              <img src={data.images[0].imgUrl} className="max-h-[250px] max-w-xs mx-auto object-cover" alt={data.images[0].imgTitle} />
            </div>
          ) : null}
          <div className="container-width mx-auto text-center space-y-2 md:space-y-3 w-full text-white font-brand-main font-black">
            <div className="text-3xl lg:text-5xl drop-shadow-[0_10px_8px_rgba(0,0,0,0.5)]">
              {data.title ? (<h3 className='px-4'>{lineBreaks(data.title)}</h3>) : null}
              {data.subtitle ? (<p className='px-4'>{lineBreaks(data.subtitle)}</p>) : null}
            </div>
            <div className="text-lg lg:text-xl">
              {data.description ? (<p className='px-4 mt-4'>{lineBreaks(data.description)}</p>) : null}
            </div>
          </div>
        </div>
      </Background>
    </div>
  );
}
