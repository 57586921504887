import { withProvider } from "../../libs/utils";
import { OmniChatTrack, OmniChatInput } from "./OmniChat";
import { SecretaryProvider } from "./useSecretary";


const LayoutAdminUnderContent = withProvider((props) => {
  return (<>
    {/* <OmniChatInput {...props} />
    <OmniChatTrack {...props} /> */}
  </>);
}, SecretaryProvider);

export default function () {
  return {
    name: 'Secretaria',
    slug: 'secretary',
    scope: 'global',
    bundles: ['core'],
    permissions: [
      // { slug: 'secretary', label: 'Secretaria', type: 'system', actionsToAdd: ['section'] }
    ],
    components: {
      main: {
        LayoutAdminUnderContent
      },
    }
  };
};