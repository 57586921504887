import toast from 'react-hot-toast';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import config from '../config';
import { IonButton, IonIcon } from '@ionic/react';
import { clipboardOutline } from 'ionicons/icons';


export default function IonCopyBtn(props) {
  let {
    text,
    productId,
    className,
    color="light",
    fill="solid",
    size="small"
  } = props;
  let textToCopy = "";
  if ( text ) {
    textToCopy = text;
  }
  else if (productId) {
    textToCopy = config.getProductLink(productId);
  }

  return (
    <CopyToClipboard 
    text={textToCopy}
    onCopy={() => toast('Enlace copiado al portapapeles', { icon: 'ℹ️' })}>
      <IonButton
        type="button"
        {...{ className, color, fill, size }}
      >
        <IonIcon icon={clipboardOutline} size="small" slot="start"></IonIcon>
        Copiar enlace
      </IonButton>
    </CopyToClipboard>
  )
}