import { useRef, useEffect, useState } from 'react';
import { CupertinoPane } from 'cupertino-pane';


export const usePanelSheet = (paneConfig, effectListener = []) => {
  const panelRef = useRef(null);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [paneInstance, setPaneInstance] = useState(null);

  useEffect(() => {
    let pane;
    if (!paneInstance) {
      pane = new CupertinoPane(panelRef.current, paneConfig);
      pane.device.ionic = false;
      setPaneInstance(pane);
    }
    return () => (pane || paneInstance)?.destroy();
  }, [paneInstance, ...effectListener]);

  const openPanel = () => {
    paneInstance?.present({ animate: true });
    setIsPaneOpen(true);
  };

  const closePanel = () => {
    paneInstance?.destroy({ animate: true });
    setIsPaneOpen(false);
  };

  return { panelRef, isPaneOpen, setIsPaneOpen, paneInstance, openPanel, closePanel };
};