import _ from 'lodash';
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { LayoutAdmin } from "../panel/LayoutAdmin";
import { useAuth } from '../../modules/user/AuthContext';
import { FaUser } from "react-icons/fa";
import { useState } from 'react';
import toast from 'react-hot-toast';
import config from '../../config';
import { IonButton, IonCard, IonCardContent } from '@ionic/react';
import useEntityTaxonomyForm, { inputClasses } from '../../components/EntityTaxonomyForm';
import { Form } from 'react-final-form';
import { usePartOfModule } from '../../components/Module/PartOfModule';
import { urlPrefix } from '../instance/utilsInstance';
import { usePanel } from '../panel/usePanel';


const userEntitySlug = config.modules.user.usersEntitySlug;
const excludeFiedls = ['userId', 'email', 'status', 'comment', 'createdAt', 'modifiedAt'];

export function SetCustomerData({ 
  mode = 'update', // 'update'
  onFinish = () => {},
  history
}) {
  const { user } = useAuth();
  const { instance } = usePanel();
  const [ userData, setUserData ] = useState(user.userDoc.data);
  const [ hasErrors, setHasErrors ] = useState(false);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ fieldsRequired, setFieldsRequired ] = useState([]);
  const { EntityTaxonomyForm } = useEntityTaxonomyForm({ 
    entityId: 'new',
    entitySlug: userEntitySlug,
    instance,
    fieldsRequired, 
    setFieldsRequired
  });

  const userProfileBeforeSave = usePartOfModule({
    type: 'main',
    action: 'userProfileBeforeSave'
  });
  const userProfileOnRedirect = usePartOfModule({
    type: 'main',
    action: 'userProfileOnRedirect'
  });

  const handleSubmitForm = async (values) => {
    // nothing
  };

  const handleCompleteWizard = (values) => async () => {
    if (
      _.size(checkValidateUser(values))
    ) {
      toast.error('Debe completar los datos');
      return;
    }
    // usuario
    user.userDoc.data = {
      ...user.userDoc.data,
      ...values
    };
    // save data
    setIsSaving(true);
    // module hook
    userProfileBeforeSave && (await userProfileBeforeSave(user, values));
    await user.userDoc.save();
    if (mode === 'register') {
      // module hook
      if (userProfileOnRedirect) {
        await userProfileOnRedirect(user, history, config);
      } 
      else {
        history.push(urlPrefix(instance, config.modules.panel.mainPath));
      }
    }
    else if (mode === 'update') {
      toast.success('Datos actualizados');
      history.go(-1);
    }
    setIsSaving(false);
    onFinish();
  };

  const tabChanged = ({ prevIndex, nextIndex }) => {};

  const checkValidateUser = (values, errors) => {
    //// example:
    // if (
    //   values?.gps
    //   && values?.name?.length >= 5
    //   && values?.name?.length <= 30
    // ) {
    //   return error;
    // }
    return errors;
  };
  const errorUser = () => {
    toast.error('Debe completar los datos.');
  };

  const validateForm = (values) => {
    const errors = {};
    // all required fields
    fieldsRequired?.forEach(field => {
      if (!values[field]) {
        errors[field] = ' ';
      }
    });
    checkValidateUser(values, errors);
    setHasErrors(Object.keys(errors).length > 0);
    return errors;
  };

  const renderContent = ({ form, values, handleSubmit, submitting }) => {
    return (
      <form onSubmit={(event) => {
        // TODO: prevent submit on pressing enter on a field
        event.preventDefault(); // No funciona
      }}>
        <FormWizard
          stepSize="xs"
          shape="circle"
          onComplete={handleCompleteWizard(values)}
          onTabChange={tabChanged}
          backButtonTemplate={(handleNext) => (
            <IonButton color="light" size="small" onClick={handleNext}>
              Anterior
            </IonButton>
          )}
          nextButtonTemplate={(handleNext) => (
            <IonButton color="secondary" size="small" onClick={handleNext} className="float-right">
              Siguiente
            </IonButton>
          )}
          finishButtonTemplate={(handleComplete) => (
            <IonButton color="primary" size="small" onClick={handleComplete} className="float-right" disabled={submitting || isSaving || hasErrors}>
              {mode === 'register' ? 'Ingresar' : 'Actualizar'}
            </IonButton>
          )}
        >

          <FormWizard.TabContent
            title="Usuario"
            icon={(<FaUser />)}
            // isValid={}
            // validationError={mode === 'update' && errorNumber}
          >
            <IonCard className="m-0">
              <IonCardContent>
                <EntityTaxonomyForm
                  exclude={excludeFiedls}
                  values={values}
                  classes={inputClasses}
                  overrideParams={{
                    showLabel: true,
                    showClearBtn: true
                  }}
                />
              </IonCardContent>
            </IonCard>
          </FormWizard.TabContent>

        </FormWizard>
      </form>
    );
  };

  return (<>
    <Form
      onSubmit={handleSubmitForm}
      initialValues={userData}
      validate={validateForm}
      render={renderContent}
    />
  </>);
}


export function AdminUserSetCustomerData(props) {
  const { user } = useAuth();

  return (
    <LayoutAdmin
      history={props.history}
      defaultHref={`/a/user/data`}
      title="Datos del usuario"
      {...props}
    >
      {user?.userDoc?.id ? <SetCustomerData mode={props.mode} history={props.history} /> : null}
    </LayoutAdmin>
  );
}
