import React from 'react';
import { Field } from 'react-final-form';
import RawInputGallery from './RawInputGallery';
import { FieldLabel, required, ShowFieldHelp } from './formParts';


const FormFieldGallery = (props) => {
  let {
    name,
    required: isRequired = false
  } = props;
  const validate = isRequired ? required : undefined;

  return (
    <div className={`${props?.classes?.fieldContainer || ''}`}>
      <Field
      name={name}
      validate={validate}>
        {({ input, meta }) => (<>
          <FieldLabel {...props} input={input} meta={meta} />
          <div className="mt-2">
            <RawInputGallery
              {...props}
              value={input.value}
              onChange={input.onChange}
            />
          </div>
          {/* Render error if any */}
          {meta.touched && meta.error && <div className="text-red-600" style={{ fontSize: 12 }}>{meta.error}</div>}
          {/* Error */}
          <ShowFieldHelp {...props} name={name} input={input} meta={meta} required={isRequired} {...props} />
        </>)}
      </Field>
    </div>
  );
};

export default FormFieldGallery;
