import _ from 'lodash';
import config from '../../config';
import { getImageURL, priceFormat } from "../../libs/utils";
import { EntityDocListView } from "../../components/EntityDocView";
import { EntityGetOneDocumentMemo } from "../../components/EntityGetOneDocument";
import { getPrefix, withPrefix } from '../instance/utilsInstance';


export const UnderListItemTitleForCartItems = ({ doc, entitySlug, ViewData }) => {
  let instanceHash = getPrefix(entitySlug);
  if (!doc) { 
    return null;
  }
  
  return (<>
    {/* thumb img */}
    <div className="flex flex-row gap-2 place-content-start">
      <EntityGetOneDocumentMemo
        entitySlug={withPrefix(instanceHash, config.modules.cart?.cartItemVariantsEntitySlug)} // retrocompatible
        docWhere={{ field: 'mainItemId', value: doc.id}}
        placeholder={(<div />)}
        EmptyRender={() => (
          (doc.data.mainImgs?.length)  ? (
            <div>
              <div className="w-[105px]">
                <img src={getImageURL((doc.data.mainImgs)[0], 'xs')} 
                className="rounded h-[105px] w-full object-cover" /> {/* image xs */}
              </div>
            </div>
          ) : null
        )}
      >
        {(props) => (
          <div>
            {(doc.data.mainImgs?.length || props?.doc.data.variantImgs?.length)  ? (
              <div className="w-[105px]">
                <img src={getImageURL((doc.data.mainImgs?.length ? doc.data.mainImgs : props?.doc.data.variantImgs)[0], 'xs')} 
                className="rounded h-[105px] w-full object-cover" /> {/* image xs */}
              </div>
            ) : null}
          </div>
        )}
      </EntityGetOneDocumentMemo>


      <div className="">
        {/* name */}
        {(doc.data.name && doc.data.name !== ' ') ? (
          <ViewData field="name" force={true} classes={{ fieldContainer: '!text-sm uppercase font-semibold text-brand-dark', fieldLabel: '!hidden' }} />
        ) : (doc.data.itemCategory ? (
          <EntityGetOneDocumentMemo
          entitySlug={withPrefix(instanceHash, config.modules.cart?.cartItemCategoriesEntitySlug)} // retrocompatible
          docId={doc.data.itemCategory}>
            {entitySpecs => (
              <EntityDocListView {...entitySpecs} render={({ ViewData }) => (
                <ViewData field="nameSingle" force={true} taxonomyTypesDocList={entitySpecs.taxonomyTypesDocList} classes={{ fieldContainer: '!text-sm uppercase font-semibold text-brand-dark', fieldLabel: 'hidden' }} />
              )} />
            )}
          </EntityGetOneDocumentMemo>
        ) : null)}

        {/* others */}
        <ViewData field="itemCategory" force={true} classes={{ fieldContainer: 'text-sm uppercase font-normal text-brand-medium-shade' }} />
        <ViewData field="mainAvailable" force={true} classes={{ fieldContainer: 'text-sm uppercase font-normal text-brand-medium-shade', fieldValueTrue: 'text-brand-success' }} />
        <ViewData field="price" force={true} 
          classes={{
            fieldContainer: 'text-sm uppercase font-normal text-brand-medium-shade',

          }}
        />
      </div>
    </div>
  </>);
};