import { useEffect, useState } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import Model from '../../libs/ModelClass';
import { getJson } from '../../libs/utils';

const CountriesModel = Model.extend('i18nCountries');
const StatesModel = Model.extend('i18nStates');
const CitiesModel = Model.extend('i18nCities');


const RawInputSelectCity = (props) => {
  const {
    value,
    onChange
  } = props;
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [countrySelected, setCountrySelected] = useState();
  const [stateSelected, setStateSelected] = useState();
  const [citySelected, setCitySelected] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isFirst, setIsFirst] = useState(true);
  const [valueSelected, setValueSelected] = useState({});

  useEffect(() => {
    fetchAndPopulate();
  }, [value]);

  useEffect(() => {
    countrySelected?.value && fetchStates();
  }, [countrySelected]);

  useEffect(() => {
    stateSelected?.value && fetchCities();
  }, [stateSelected]);

  useEffect(() => {
    if (isFirst) { return; }
    if (
      valueSelected?.country
      && valueSelected?.state
      && (valueSelected?.city || valueSelected?.city === 'state-as-city')
    ) {
      onChange({
        ...valueSelected,
        labels: getLabels()
      });
    } 
    else {
      onChange(null);
    }
  }, [countrySelected, stateSelected, citySelected]);

  const getLabels = () => {
    const labels = [];
    if (countrySelected) {
      labels.push(countrySelected.label);
    }
    if (stateSelected) {
      labels.push(stateSelected.label);
    }
    if (citySelected && valueSelected.city !== 'state-as-city') {
      labels.push(citySelected.label);
    }
    return labels;
  }
  
  const fetchAndPopulate = async () => {
    setIsLoading(true);
    await fetchCountries();
    if (!_.size(valueSelected) && _.size(value)) {
      setValueSelected(value);
      // populate
      if (value?.country) {
        const countryDoc = await CountriesModel.findOneBy('countryId', value.country);
        setCountrySelected({ value: value.country, label: getCountryTranslatedLabel(countryDoc), doc: countryDoc});
      }
      if (value?.state) {
        const stateDoc = await StatesModel.findOneBy('stateId', value.state);
        setStateSelected({ value: value.state, label: stateDoc?.data?.name, doc: stateDoc});
      }
      if (value?.city !== 'state-as-city') {
        const cityDoc = await CitiesModel.findOneBy('cityId', value.city);
        setCitySelected({ value: value.city, label: cityDoc?.data?.name, doc: cityDoc});
      }
    }
    // clear
    if (!_.size(value)) {
      setCountrySelected(null);
      setStateSelected(null);
      setCitySelected(null);
      setValueSelected(null);
    }
    setIsLoading(false);
    setIsFirst(false);
  };

  const getCountryTranslatedLabel = (cityDoc) => {
    if (cityDoc?.data?.translations && cityDoc.data.translations['es']) {
      var userLang = navigator.language || navigator.userLanguage; 
      return cityDoc.data.translations[ userLang?.split('-')[0] || 'es' ];
    }
    return cityDoc.data.name;
  };

  const fetchCountries = async () => {
    if (countriesOptions?.length > 0) {
      return countriesOptions;
    }
    const ExtendedModel = Model.extend('i18nCountries');
    let countriesDoc = await getJson('/countries.json');
    let options = countriesDoc.map(data => {
      const doc = new ExtendedModel(data);
      return {
        value: doc.data.countryId,
        label: getCountryTranslatedLabel(doc),
        doc
      };
    });
    setCountriesOptions(options);
  };

  const fetchStates = async () => {
    setIsLoading(true);
    let statesDoc = await StatesModel.filterByAttributes({ 
      country_id: countrySelected.value
    });
    let statesOptions = statesDoc.map(doc => {
      return {
        value: doc.data.stateId,
        label: doc.data.name,
        doc
      };
    });
    setStatesOptions(statesOptions);
    setIsLoading(false);
  };

  const fetchCities = async () => {
    setIsLoading(true);
    let citiesDoc = await CitiesModel.filterByAttributes({
      state_id: stateSelected.value
    });
    let citiesOptions = citiesDoc.map(doc => {
      return {
        value: doc.data.cityId,
        label: doc.data.name,
        doc
      };
    });
    if (!citiesOptions.length) {
      // set city as state-as-city
      setValueSelected({ ...valueSelected, city: 'state-as-city' });
    }
    setCitiesOptions(citiesOptions);
    setIsLoading(false);
  };

  const selectCountry = (option) => {
    setCountrySelected(option);
    setStateSelected(null);
    setCitySelected(null);
    setValueSelected({
      ...valueSelected,
      country: option?.value || null,
      countryIso2: option?.doc?.data?.iso2 || null,
      state: null,
      city: null
    });
  };

  const selectState = (option) => {
    setStateSelected(option);
    setCitySelected(null);
    setValueSelected({
      ...valueSelected,
      state: option?.value || null,
      city: null
    });
  };

  const selectCity = (option) => {
    setCitySelected(option);
    setValueSelected({ 
      ...valueSelected,
      city: option?.value || null
    });
  };

  return (<>
    <div className='mb-2 relative z-20'>

      {/* Country Select */}
      {countriesOptions?.length ? (
        <Select
          className="w-full shadow"
          isClearable
          isMulti={false}
          options={countriesOptions}
          placeholder="País"
          isDisabled={isLoading}
          isLoading={isLoading}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: 'lightgray',
              primary: 'darkgray',
            },
          })}
          value={countrySelected}
          onChange={option => selectCountry(option)}
          menuPortalTarget={document.body}
        />
      ) : null}

      {/* State Select */}
      {countrySelected ? (
        <Select
          className="w-full shadow mt-2"
          isClearable
          isMulti={false}
          options={statesOptions}
          placeholder="Estado / Provincia / Departamento"
          isDisabled={isLoading}
          isLoading={isLoading}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: 'lightgray',
              primary: 'darkgray',
            },
          })}
          value={stateSelected}
          onChange={option => selectState(option)}
          menuPortalTarget={document.body}
        />
      ) : null}

      {/* City Select */}
      {stateSelected && (valueSelected?.city !== 'state-as-city') ? (
        <Select
          className="w-full shadow mt-2"
          isClearable
          isMulti={false}
          options={citiesOptions}
          placeholder="Ciudad"
          isDisabled={isLoading}
          isLoading={isLoading}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: 'lightgray',
              primary: 'darkgray',
            },
          })}
          value={citySelected}
          onChange={option => selectCity(option)}
          menuPortalTarget={document.body}
        />
      ) : null}

    </div>
  </>);
};

export default RawInputSelectCity;