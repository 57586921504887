import React from 'react';
import { Field } from 'react-final-form';
import _ from 'lodash';
import { FieldLabel, ShowFieldHelp } from './formParts';
import { onChangeButtonsHandler } from '../DataTypes/multiselect';
import {
  checkmarkOutline
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { getContrastColor } from '../../libs/utils';


const requireContrast = (color) => (
  getContrastColor(color, 0.90) !== 'white'
);

export const SelectButtonsWithColors = (props) => {
  let {
    colors,
    current,
    onSelect,
    className
  } = props;

  const getCurrentDef = () => {
    return colors.find(colorDef => colorDef.value === current);
  }

  return (
    <div className={`${className}`}>
      <div className="text-md font-bold">
        {getCurrentDef()?.label}
      </div>
      <div className="mt-2 flex flex-row flex-wrap gap-3 justify-center place-items-center">
        {colors && colors.map((option) => (
          <button
            type='button'
            onClick={() => onSelect(option.value)}
            key={option.value}
          >
            <div
              className={`
                rounded-full
                ${option.value === current ? 'w-8 h-8' : 'w-4 h-4'}
              `}
              style={{
                backgroundColor: option.color
              }}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export const FieldViewSelectButtonsWithColors = (props) => {
  let {
    value,
    taxonomyType,
    showLabel = true,
  } = props;

  const { name, param } = taxonomyType?.data;
  const options = param.options;
  const multiple = _.isArray(value) || (_.isObject(value) && _.size(value));

  const isSelected = (option) => {
    return multiple
            ? _.includes(value, option.value) // array
            // ? value[option.value] // true map
            : value === option.value;
  };

  return (
    <div className={`${props?.classes?.fieldViewContainer || ''}`}>
      {showLabel && name ? (
        <label className={props?.classes?.fieldViewLabel}>
          <span className={`font-semibold mr-3`}>
            {name}
          </span>
        </label>
      ) : ''}

      <div className={`flex flex-row flex-wrap gap-2`}>
        {options && options.map((option) => (
          isSelected(option) ? (
            <div
              key={option.value}
              className={`
                p-1.5 flex content-center gap-1 w-5 h-5 rounded-full 
                ${requireContrast(option.color) ? 'border border-gray-200' : ''}
              `}
              style={{
                backgroundColor: option.color
              }}
            />
          ) : null  
        ))}
      </div>
    </div>
  );
};

const FormFieldSelectButtonsWithColors = (props) => {
  let {
    name,
    title,
    options,
    fieldsRequired = null,
    validate = null,
    disabled = false,
    showLabel = true,
    onSelect = (() => null),
    multiple = false,
  } = props;

  const validateField = (value) => {
    let error;

    if (fieldsRequired?.includes(name)) {
      if (multiple) {
        if (!value || value.length === 0) {
          error = 'Debes seleccionar al menos una opción';
        }
      } else {
        if (value === undefined || value === '') {
          error = 'Este campo es obligatorio';
        }
      }
    }

    if (!error && validate && typeof validate === 'function') {
      error = validate(value);
    }

    return error;
  };

  return (
    <div className={`pb-3 ${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={(args) => validateField(args)}>
        {({ input, meta }) => {
          const onChange = onChangeButtonsHandler(input, multiple, onSelect);

          const isSelected = (option, input) => {
            return multiple
                    ? _.includes(input?.value, option.value) // array
                    // ? value[option.value] // true map
                    : input?.value === option.value;
          }

          const getSelectedLabels = () => {
            if (multiple) {
              let labels = [];
              options?.forEach(({ value, label }) => {
                //// Array
                if (_.includes(input.value, value)) {
                  labels.push(label);
                }

                //// True map
                // if (input.value[value]) {
                //   labels.push(label);
                // }
              });
              return labels.join(', ');
            }
            else {
              return options?.find(({ value, label }) => {
                return input.value === value
              })?.label;
            }
          }

          return (
            <>
              <FieldLabel {...props} input={input} meta={meta} />
              <div className={props?.classes?.fieldInput || ''}>
                <div className="mb-1.5">
                  {getSelectedLabels()}
                </div>
                <div className={`flex flex-row flex-wrap gap-2`}>
                  {options && options.map((option) => (
                    <button
                      type="button"
                      key={option.value}
                      onClick={() => onChange(option.value)}
                      className={`
                        rounded-full
                        ${multiple
                          ? _.includes(input?.value, option.value) // array
                          // ? input?.value[option.value] // true map
                            ? 'shadow-lg'
                            : 'shadow-sm hover:scale-[1.05]'
                          : input.value === option.value
                            ? 'shadow-lg'
                            : 'shadow-sm hover:scale-[1.05]'
                        }
                      `}
                      style={{ 
                        border: requireContrast(option.color) ? '1px solid #9ca3af' : '0px'
                      }}
                    >
                      <div
                        className={`
                          p-1.5 rounded-full border-gray-200
                          flex content-center gap-1
                        `}
                        style={{ 
                          border: requireContrast(option.color) ? '3px solid' : '4px solid',
                          borderColor: option.color,
                          backgroundColor: isSelected(option, input) ? option.color : 'white'
                        }}
                      >
                        {isSelected(option, input) ? (
                          <div
                            className="w-5 h-5 flex content-center"
                          >
                            <IonIcon 
                              size='small' 
                              icon={checkmarkOutline} 
                              style={{ 
                                color: getContrastColor(option.color || '#ffffff')
                              }}
                            />
                          </div>
                        ) : (
                          <div className='w-5 h-5 flex content-center'></div>
                        )}
                      </div>
                    </button>
                  ))}
                </div>
              </div>
              {/* help */}
              <ShowFieldHelp {...props} name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
              {/* <RequiredByFieldsList
                name={name}
                input={input}
                fieldsRequired={fieldsRequired}
              /> */}
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default FormFieldSelectButtonsWithColors;