import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from 'react';
import MarkdownEditor, { commands, getExtraCommands } from '@uiw/react-md-editor';


const Tab = ({ children, active, onClick }) => {
  const tabClassName = active ? 'bg-gray-200 rounded-md' : '';

  return (
    <div className={`px-4 py-1.5 text-sm cursor-pointer ${tabClassName}`} onClick={onClick}>
      {children}
    </div>
  );
};

const TabPanel = ({ active, className, children }) => {
  if (!active) return null;

  return <div className={`${className} mt-4`}>{children}</div>;
};

const help = {
  name: "help",
  keyCommand: "help",
  buttonProps: { "aria-label": "Insert help" },
  icon: (
    <svg viewBox="0 0 16 16" width="12px" height="12px">
      <path
        d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8Zm.9 13H7v-1.8h1.9V13Zm-.1-3.6v.5H7.1v-.6c.2-2.1 2-1.9 1.9-3.2.1-.7-.3-1.1-1-1.1-.8 0-1.2.7-1.2 1.6H5c0-1.7 1.2-3 2.9-3 2.3 0 3 1.4 3 2.3.1 2.3-1.9 2-2.1 3.5Z"
        fill="currentColor"
      />
    </svg>
  ),
  execute: (state, api) => {
    window.open("https://tutorialmarkdown.com/sintaxis", "_blank");
  }
};

const RawInputTextAreaMarkdown = ({ value, onChange }) => {
  const [text, setText] = useState(value);
  const [activeTab, setActiveTab] = useState('edit');

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleChange = (newText) => {
    setText(newText);
    onChange(newText);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const excludedCommands = ['edit', 'live', 'preview'];
  const customCommands = [...commands.getCommands(), help];

  return (
    <div className="pt-2">
      <div className="flex space-x-2">
        <Tab active={activeTab === 'edit'} onClick={() => handleTabClick('edit')}>
          Editar
        </Tab>
        <Tab active={activeTab === 'preview'} onClick={() => handleTabClick('preview')}>
          Visualizar
        </Tab>
      </div>

      <TabPanel active={activeTab === 'edit'}>
        <MarkdownEditor
          value={text}
          onChange={handleChange}
          height={400}
          commands={customCommands}
          commandsFilter={(command) => {
            return !excludedCommands.includes(command.name) ? command : false;
          }}
          preview={'edit'}
        />
      </TabPanel>

      <TabPanel active={activeTab === 'preview'} className="prose lg:prose-sm">
        <ReactMarkdown>{text}</ReactMarkdown>
      </TabPanel>
    </div>
  );
};

export default RawInputTextAreaMarkdown;
