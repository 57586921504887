// import { RoutePoliticaPrivacidad } from "./RoutePoliticaPrivacidad";
// import { RouteSeguridadDeDatos } from "./RouteSeguridadDeDatos";
// import { RouteWebInstitucional } from "./RouteWebInstitucional";
// import { RouteCatalogo } from "./RouteCatalogo";
import { RouteCartView } from "./cartStatic/RouteCartView";
import { RouteProductView } from "./cartStatic/RouteProductView";
import { RouteProductFilter } from "./cartStatic/RouteProductFilter";
import { RouteCatalogoFree } from "./RouteCatalogoFree";


export default function ({ setPermissions }) {
  return {
    slug: 'pages',
    label: 'Páginas públicas',
    entities: {},
    permissions: [],
    components: {},
    routesPublic: {
      'page': {
        // 'politica-de-privacidad': {
        //   Content: RoutePoliticaPrivacidad
        // },
        // 'seguridad-de-datos': {
        //   Content: RouteSeguridadDeDatos
        // }
        'catalogo': {
          Render: RouteCatalogoFree
        }
      },
      'products': {
        'filter': {
          Render: RouteProductFilter
        },
        'view': {
          Render: RouteProductView
        },
      },
      'carts': {
        'view': {
          Render: RouteCartView
        }
      }
    },
  };
};
