import { useEffect, useState } from 'react';
import _ from 'lodash';
import { FaCartPlus } from 'react-icons/fa';
import IonBtnLoading from '../../components/ui/IonBtnLoading';
import { ShowAmount, toAmount } from '../../components/Form/utilsCurrency';
import IonContactBTN from '../../components/ui/IonContactBTN';
import { IonButton } from '@ionic/react';
import { useCart } from './useCartData';


export const QtyButtons = (props) => {
  let {
    itemDoc,
    itemInBag,
    bagId,
    itemId,
    params,
    price,
    onChange = _.noop,
    onRemoveItem = _.noop,
    showIncludedLabel = true,
    whatsappNumber,
    askInstantDeliveryMessage,
    classes = {}
  } = props;

  const {
    isDetailsOpen, setIsDetailsOpen, openDetailsDrawer,
    entityMap, CartModel, ItemMainModel, ItemVariantModel, CategoryModel, TypeModel, 
    fetchItems, items, itemsTypes, itemsCategories,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    doPopulateBags,
    totalPriceItems
  } = useCart();

  const [ qtyInBag, setQtyInBag ] = useState( itemInBag?.qty || 1 );
  
  const isEditing = itemInBag?.qty !== qtyInBag;

  const priceQty = price?.rawQty ? price.rawQty : price;

  useEffect(() => {
    setQtyInBag(itemInBag?.qty || 1);
  }, [itemInBag?.qty]);

  const restOne = () => {
    if (qtyInBag > 1) {
      setQtyInBag( qtyInBag - 1 );
    }
  };

  const addOne = () => {
    setQtyInBag( qtyInBag + 1 );
  };

  const setVal = (e) => {
    let newVal = parseInt(e.target.value);
    if (newVal > 1) {
      setQtyInBag( newVal );
    }
  };

  const updateItemToBag = async () => {
    try {
      await setItemToBag(bagId, itemId, qtyInBag, params, itemDoc);
    } catch (error) {
      console.error(error);
    }
    await onChange();
  };

  const removeItemFromBag = async () => {
    try {
      await setItemToBag(bagId, itemId, 0, params, itemDoc);
    } catch (error) {
      console.error(error);
    }
    await onChange();
  };

  const Ask = () => {
    return (
      whatsappNumber ? (
        <IonContactBTN
          message={askInstantDeliveryMessage}
          label={"Preguntar"}
          classes={{ btnContainer: 'mx-auto' }}
          number={whatsappNumber}
          color="medium"
          fill="solid"
          size="small"
        />
      ) : null
    );
  };

  return (
    <>
      {/* Qty */}
      <div className={`${classes.fieldContainer}`}>
        <h3 className={`mb-1 ${classes.fieldLabel}`}>Cantidad</h3>
        <div className="w-28 rounded-sm cursor-point border-2 border-brand-medium flex flex-row">
          {/* remove */}
          <button type="button" className="p-0.5 bg-brand-medium font-semibold text-xl w-8"
          disabled={qtyInBag < 1}
          onClick={restOne}>
            <div className="-mt-1">-</div>
          </button>
          <input type="number" min={1} className="py-0.5 pl-3 font-semibold text-base text-center w-full text-black"
          value={qtyInBag} onChange={setVal} />
          {/* add */}
          <button type="button" className="p-0.5 bg-brand-medium font-semibold text-xl w-8"
          onClick={addOne}>
            <div className="-mt-1">+</div>
          </button>
        </div>
      </div>

      {/* Amount */}
      <div className={`${classes.fieldContainer}`}>
        <h3 className={`mb-1 ${classes.fieldLabel}`}>Total</h3>
        <div className={`text-base ${isEditing ? '' : '' }`}>
          <ShowAmount amount={toAmount(qtyInBag * priceQty)} />
        </div>
      </div>
      
      <div className={`flex flex-col gap-1.5 ${classes.actionContainer}`}>
        {itemInBag ? (
          <div className="grid grid-flow-col grid-cols-2 gap-2">
            {isEditing ? (
              <IonBtnLoading 
                label="Modificar"
                icon={<FaCartPlus className="mr-2 h-4 w-4 inline"/>}
                onClickAsync={() => updateItemToBag()}
                className={`block`} 
                type="button"
                color="light"
                fill="solid"
                size="small"
              />
            ) : (
              showIncludedLabel ? (
                <IonButton
                  disabled={true}
                  color="light"
                  fill="outline"
                  size="small"
                >
                  Incluído
                </IonButton>
              ) : null
            )}

            <div className="flex flex-col gap-1">
              <IonBtnLoading 
                label="Quitar"
                onClickAsync={async () => { 
                  await removeItemFromBag(); 
                  onRemoveItem(bagId, itemId, params, itemDoc);
                }} 
                className={`block`}
                type="button"
                color="light"
                fill="solid"
                size="small"
              />

              <Ask />
            </div>
          </div>
        ) : (
          isEditing ? (
            <div className="grid grid-flow-col grid-cols-2 gap-2">
              <IonBtnLoading
                label="Agregar"
                icon={<FaCartPlus className="h-5 w-5 inline"/>}
                onClickAsync={async () => { await updateItemToBag(bagId, itemId, qtyInBag, params, itemDoc); }} 
                className={`inline-block`}
                type="button"
                color="light"
                fill="solid"
                size="small"
              />

              <Ask />
            </div>
          ) : null
        )}
      </div>
    </>
  );
};