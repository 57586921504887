import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { EntityTaxonomyForm, inputClasses } from '../EntityTaxonomyForm';
import { IonButton, IonModal, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton as IonButtonIonic } from '@ionic/react';


const RawInputObjectForm = (props) => {
  let { 
    title,
    name,
    fields
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  
  const fieldsPrefixed = useMemo(() => {
    return _.map(fields, (field) => ({
      ...field,
      fieldName: `${name}[${field.fieldName}]`
    }));
  }, [fields]);
  
  return (
    <div className="">
      <div className="text-brand-medium text-sm">
        {_.map(fields, (field, index) => {
          let value = _.get(props.value, field.fieldName);
          if (!value) { return null; }
          return (
            <div className="" key={index}>
              {/* {field.type === 'collection' ? (
                <>{field.name}: {_.forEach(field.fields, (subfield) => {
                  let value = _.get(props.value, field.fieldName, subfield.fieldName);
                  return (
                    <>{field.name}: {JSON.stringify(value, ' ')}</>
                  );
                })}</>
              ) : null} */}
              {/* {field.type !== 'collection' ? (
              ) : null} */}
              <>{field.name}: {JSON.stringify(value, ' ')}</>
            </div>
          );
        })}
      </div>
      <IonButton 
        onClick={() => setIsOpen(true)}
        type="button"
      >
        {title}
      </IonButton>
      <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              <IonButtonIonic onClick={() => setIsOpen(false)}>Cerrar</IonButtonIonic>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="p-2 mb-64">
            <EntityTaxonomyForm
              taxonomyTypesDataList={fieldsPrefixed}
              classes={inputClasses}
              form={props.form}
            />
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default RawInputObjectForm;