import _ from 'lodash';
import { usePanel } from '../panel/usePanel';
import { IonAlert } from '@ionic/react';
import { urlPrefix } from './utilsInstance';


export const AlertBilling = ({ userAuth, history }) => {
  const { selectedInstance, instance, isInstanceActive, showActivationAlert, setShowActivationAlert } = usePanel();
  
  if (
    isInstanceActive
    || userAuth?.userDoc.id !== selectedInstance?.data.ownerId
  ) {
    return null;
  }

  return (
    <IonAlert
      isOpen={showActivationAlert}
      backdropDismiss={false}
      keyboardClose={false}
      header="Requiere pago del servicio"
      subHeader="Su cuenta está vencida"
      message={`
        Su cuenta ha alcanzado el límite de facturación y requiere pago para continuar utilizando los servicios. 
        Por favor, revise el estado de su cuenta y actualice su información de facturación para evitar interrupciones en el servicio.
      `}
      buttons={[
        {
          text: 'Ver estado de cuenta',
          role: 'confirm',
          handler: () => {
            // go to billing page
            setShowActivationAlert(false);
            history.push(urlPrefix(instance, '/a/instancesBilling/update'));
          },
        }
      ]}
    />
  );
};