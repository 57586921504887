import { withOutPrefix, withPrefix } from '../instance/utilsInstance';
import permissionsDataType from './permissionsDataType';
import { RouteInstanceMembers } from './RouteInstanceMembers';
import { RouteMembersUpdate } from './RouteMembersUpdate';


const UnderListItemTitleForCredentials = ({ ViewData }) => (
  <ViewData field="roles" force={true} classes={{ fieldContainer: 'text-xs bg-brand-medium px-2 py-0.5 rounded-full inline-block', fieldLabel: 'text-brand-medium-contrast', fieldValue: 'text-brand-medium-contrast' }} />
);

// const UnderListItemTitleForUsersProfiles = ({ ViewData }) => (<></>);

export default function () {
  return {
    slug: 'user',
    name: 'Usuarios',
    scope: 'global',
    bundles: ['core'],
    entities: [
      'roles',
      'usersProfiles',
      'credentials'
    ],
    permissions: [
      { slug: 'credentials', label: 'Credenciales', actionsToAdd: [
        'members', 
        'instance:members',
        'instance:read', 'instance:list', 'instance:update', 'instance:delete',
        'owner:read', 'owner:create', 'owner:list'
      ] },
      { slug: 'usersProfiles', label: 'Usuarios', actionsToAdd: [
        'owner:read', 'owner:list', 'owner:update', 'instance:read', 'instance:list'
      ] },
      { slug: 'roles', label: 'Cargos', instanceCrud: true }
    ],
    components: {
      'credentials': {
        crud: {
          UnderListItemTitle: UnderListItemTitleForCredentials
        }
      },
      'usersProfiles': {
        // crud: {
        //   UnderListItemTitle: UnderListItemTitleForUsersProfiles
        // }
      },
      'roles': {
        codedDataTypes: {
          permissions: permissionsDataType // RenderFormField
        }
      },
    },
    routesAdmin: {
      'credentials': {
        'members': {
          permission: { resource: 'credentials', action: ['members', 'instance:members'] },
          Content: RouteInstanceMembers
        },
        'membersEdit': {
          permission: { resource: 'credentials', action: ['update', 'instance:update'] },
          Content: RouteMembersUpdate
        },
      }
    },
    events: {
      'roles': {
        beforeSave: async ({ instanceDoc, entityDoc }, formValues) => {
          // attach instanceHash as roleSlug suffix
          if (instanceDoc && !entityDoc?.data.isInstance && instanceDoc?.data.hash !== 'main') {
            formValues.nameSlug = withPrefix(instanceDoc.data.hash, withOutPrefix(formValues.nameSlug));
          }
          return formValues;        
        }
      }
      // 'usersProfiles': {
      //   beforeSave: async ({ taxonomyTypesDocList }, formValues) => {
      //     let modifiedFormValues = formValues;
      //     return modifiedFormValues;        
      //   }
      // }
    }
  };
};