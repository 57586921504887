import { processRenderProps } from './dataTypeUtils';
import {
  FormFieldSelect,
  FormFieldListItems,
  FormFieldSelectButtons,
  FormFieldSelectButtonsWithColors,
} from '../Form';
import {
  // Select
  FilterRenderSelect,
  FilterCreatorSelect,
} from '../Filters';


export default {
  Render(props) {
    const {
      onChange,
      fieldsRequired
    } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);
    
    if(design === "select" || design === "default") {
      return(
        <FormFieldSelect 
          title={title}
          placeholder={title}
          {...props}
          {...param}
          name={_fieldName}
          onSelect={onChange}
          fieldsRequired={fieldsRequired}
        />
      )
    }

    else if (design === "buttons") {
      return (
        <FormFieldSelectButtons 
          title={title}
          placeholder={title}
          {...props}
          {...param}
          name={_fieldName}
          onSelect={onChange}
          fieldsRequired={fieldsRequired}
        />
      )
    }

    else if (design === "buttonsColors") {
      return (
        <FormFieldSelectButtonsWithColors
          title={title}
          {...props}
          {...param}
          name={_fieldName}
          fieldsRequired={fieldsRequired}
        />
      )
    }
    
    return null;
  },

  RenderFilter(props) {
    return <FilterRenderSelect {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorSelect {...props} />
  },

  RenderInputParams({ values, param }) {
    return (<>
      <FormFieldListItems
        name="param.options"
        title="Opciones"
        placeholder="Opciones"
        typesToShow={{ color: true, label: true, value: true }}
      />
      <FormFieldSelect
        name="style.design"
        title="Diseño"
        placeholder="Diseño"
        options={[
          { value: 'select', label: 'Select' },
          { value: 'buttons', label: 'Buttons' },
          { value: 'buttonsColors', label: 'Buttons colored' }
        ]}
      />
    </>);
  },
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => null,
  
  RenderShowParams: ({ values }) => null,
  
  RenderInputPreview: ({ values, formFieldProps }) => {
    if (values?.style?.design === 'select') {
      return (<FormFieldSelect {...formFieldProps} />);
    }
    if (values?.style?.design === 'buttons') {
      return (<FormFieldSelectButtons {...formFieldProps} />);
    }
    if (values?.style?.design === 'buttonsColors') {
      return (<FormFieldSelectButtonsWithColors {...formFieldProps} />);
    }
    return '';
  },

  getDefaultTaxonomyTypeDesign: () => ('select')
}