import _ from 'lodash';
import { useEffect, useState } from "react";
import Model from "../../libs/ModelClass";
import config from "../../config";
import specTheme from "../../customModules/specTheme";


export const useFetchBrand = (props) => {
  let { instance } = props;
  const [ brandSpecs, setBrandSpecs ] = useState();
  const [ brandDocs, setBrandDocs ] = useState();

  useEffect(() => {
    fetchBrand();
  }, [instance]);

  const fetchBrand = async () => {
    const InstanceModel = Model.extend(config.modules.instances.instancesEntitySlug);
    const BrandModel = Model.extend(config.modules.instances.instancesBrandsEntitySlug);
    const ColorsPaletteModel = Model.extend(config.modules.instances.instancesColorsPalettesEntitySlug);
    const LayoutBlocksModel = Model.extend(config.modules.instances.instancesLayoutBlocksEntitySlug);
    const MainPartsModel = Model.extend(config.modules.instances.instancesMainPartsEntitySlug);
    const MainStringsModel = Model.extend(config.modules.instances.instancesMainStringsEntitySlug);
    const instanceDoc = await InstanceModel.findOneBy('hash', instance);
    if (instanceDoc) {
      const brandDoc = await BrandModel.findOneBy('instanceId', instanceDoc.id);
      if (brandDoc) {
        // fetch in parallel and spread values
        const [colorPalette, layoutBlocksDoc, mainPartsDoc, mainStringsDoc] = await Promise.all([
          ColorsPaletteModel.findById(brandDoc.data.colorPaletteId),
          LayoutBlocksModel.findById(brandDoc.data.layoutBlocksId),
          MainPartsModel.findById(brandDoc.data.mainPartsId),
          MainStringsModel.findById(brandDoc.data.mainStringsId)
        ]);
        let brandData = {
          instance: instanceDoc?.data,
          brand: brandDoc?.data,
          colorPalette: colorPalette?.data,
          layoutBlocks: layoutBlocksDoc?.data,
          mainParts: mainPartsDoc?.data,
          mainStrings: mainStringsDoc?.data
        };
        setBrandSpecs({
          colors: _.defaults(colorPalette?.data.colorsPalette, specTheme.colors),
          stringsVars: {
            instance: instanceDoc?.data,
            brand: mainStringsDoc?.data,
          },
          logo: {
            profileImg: brandDoc.data.profileImg?.length ? brandDoc.data.profileImg[0] : null,
            logoDark: brandDoc.data.logoDark?.length ? brandDoc.data.logoDark[0] : null,
            logoLight: brandDoc.data.logoLight?.length ? brandDoc.data.logoLight[0] : null
          },
          fonts: {
            main: mainPartsDoc.data.mainFont,
            secondary: mainPartsDoc.data.secondaryFont
          },
          parts: {
            menuLogo: {
              ...layoutBlocksDoc.data.menuLogo
            }
          },
          blocks: {
            header: layoutBlocksDoc.data.blocksHeader,
            footer: layoutBlocksDoc.data.blocksFooter
          }
          // mainParts: mainPartsDoc?.data.,
          // mainStrings: mainStringsDoc?.data.
        });
        setBrandDocs(brandData);
      }
    } else {
      setBrandSpecs({});
      setBrandDocs({});
    }
  };

  return {
    brandSpecs,
    brandDocs
  };
};