import { Field } from 'react-final-form';
import { useState, useEffect } from 'react';
import FormFieldSelect from './FormFieldSelect';
import Model from '../../libs/ModelClass';
import { sortDocsByField } from '../../libs/utils';

/**
 * Componente de campo de formulario para seleccionar un documento de un modelo.
 * @param {string} name - Nombre del campo en el formulario.
 * @param {string} title - Título del campo.
 * @param {modelName} modelName - Nombre del modelo utilizado para obtener las opciones.
 * @param {string} className - Clases CSS adicionales para el contenedor del campo.
 * @param {string} placeholder - Texto de marcador de posición para la opción predeterminada.
 * @param {Array} fieldsRequired - Campos adicionales requeridos para mostrar el error.
 * @param {function} validate - Función de validación personalizada para el campo.
 * @param {boolean} disabled - Indica si el campo está deshabilitado.
 * @param {boolean} showLabel - Indica si se muestra o no la etiqueta del campo.
 * @param {function} onSelect - Función de devolución de llamada cuando se selecciona una opción.
 * @param {boolean} editMode - Indica si el componente está en modo de edición.
 * @param {string} initialValue - Valor inicial del campo cuando no está en modo de edición.
 * @returns {JSX.Element} Componente de campo de formulario para seleccionar un documento de un modelo.
 */
const FormFieldSelectDocumentModel = (props) => {
  let {
    name,
    title,
    modelName,
    queryGetter = (async (ExtendedModel) => {
      const docs = await ExtendedModel.getAll();
      return sortDocsByField(docs, 'sort');
    }),
    optionsMapper,
    className,
    placeholder,
    fieldsRequired = null,
    validate = null,
    disabled = false,
    showLabel = true,
    onSelect = ((selectedId, selectedItem) => null),
    editMode = false,
    initialValue = '',
  } = props;
  const [options, setOptions] = useState([]);

  optionsMapper = optionsMapper || ((docs) => {
    return docs.map((doc) => ({
      value: doc?.id,
      label: doc?.data?.name,
      doc
    }));
  });

  useEffect(() => {
    const fetchData = async () => {
      const ExtendedModel = Model.extend(modelName);
      const docs = await queryGetter(ExtendedModel);
      const docsOptions = optionsMapper(docs);
      setOptions(docsOptions || []);
    };

    fetchData();
  }, [modelName]);

  // console.log(title, modelName, options)

  return (
    <FormFieldSelect
      {...props}
      name={name}
      title={title}
      options={options}
      className={className}
      placeholder={placeholder}
      fieldsRequired={fieldsRequired}
      validate={validate}
      disabled={disabled}
      showLabel={showLabel}
      onSelect={(selectedId) => {
        const selectedItem = options.find((option) => option?.value === selectedId);
        onSelect && onSelect(selectedId, selectedItem);
      }}
      editMode={editMode}
      initialValue={initialValue}
    />
  );
};

export default FormFieldSelectDocumentModel;