import { useEffect } from "react";
import { errorCodes, useAuth } from "../../modules/user/AuthContext";
import { LayoutPublic } from "../panel/LayoutPublic";
import { Capacitor } from "@capacitor/core";
import BadgeLoading from "../../components/ui/BadgeLoading";
import config from "../../config";
import BtnLoading from "../../components/ui/BtnLoading";
import { IonIcon, isPlatform } from "@ionic/react";
import { 
  logoGoogle
} from 'ionicons/icons';
import toast from "react-hot-toast";
import { usePartOfModule } from "../../components/Module/PartOfModule";
import { useAsyncMemo } from "use-async-memo";
import Model from "../../libs/ModelClass";


export const useLoginUtils = (userAuth, autoRedirect, loginWithGoogleAndVerify, history, instance) => {
  let basePath = instance ? `/${instance}` : '';
  let panelPath = basePath + config?.modules?.panel?.mainPath;
  let userDataPath = basePath + config?.modules?.user?.userSetDataPath;

  const userConditionToLogin = usePartOfModule({
    type: 'main',
    action: 'userConditionToLogin'
  });
  
  const redirectOnLogin = async (userWithCredentials) => {
    if (!userWithCredentials) {
      return null;
    }
    // verify user conditions to login
    let hasCustomerData = userConditionToLogin ? (await userConditionToLogin(userWithCredentials, history)) : true;
    if (!userWithCredentials?.userDoc?.data?.name || !(userWithCredentials?.userDoc?.data?.phone && userWithCredentials?.userDoc?.data?.email)) {
      hasCustomerData = false;
    } 
    // redirect to dashboard of profile
    if (hasCustomerData) {
      const urlParams = new URLSearchParams(window?.location?.search);
      const redirect = urlParams.get('redirect');
      if (redirect) {
        return window.location.href = redirect;
      }
      history.push(panelPath);
    } 
    // redirect to define customer data
    else {
      history.push(userDataPath);
    }
  };

  useEffect(() => {
    if (userAuth?.credentialOfMainDoc && userAuth?.userDoc.id) {
      if (userAuth?.credentialOfMainDoc?.data?.isActive === false) {
        autoRedirect && redirectOnLogin(null);
      }
      else {
        autoRedirect && redirectOnLogin(userAuth);
      }
    }
  }, [userAuth]);

  const handleGoogleSignin = async () => {
    try {
      let userWithCredentials = await loginWithGoogleAndVerify();
      await redirectOnLogin(userWithCredentials);
      history.push();
    } catch (error) {
      if (error.message.indexOf("12501") >= 0) {
        toast.error("Debe seleccionar un Gmail.");
        return;
      }
      else if (error.message.indexOf("closed") >= 0) {
        toast.error("Ha cerrado la ventana de selección de Gmail.");
        return;
      } else {
        toast.error(error.message);
      }
    }
  };

  return {
    redirectOnLogin,
    handleGoogleSignin
  };
}

export function Login(props) {
  let { history, match } = props;
  const { userAuth, loginWithGoogleAndVerify } = useAuth();
  const { instance } = match.params;
  const { redirectOnLogin, handleGoogleSignin } = useLoginUtils(
    userAuth,
    true,
    loginWithGoogleAndVerify,
    history,
    instance
  );
  const instanceDoc = useAsyncMemo(() => (Model.extend('instances').whereOne('hash', '==', instance || 'main')), [instance]);
  const attachPrefix = (url) => {
    return match.params?.instance ? `/${match.params.instance}${url}` : url;
  }
  return (
    <LayoutPublic
      showGoBack={!Capacitor.isNativePlatform()}
      defaultHref={Capacitor.isNativePlatform() ? null : attachPrefix("/")}
      {...props}
      forceBack={true}
      Title={() => (
        <div className="flex items-center" style={{ height: isPlatform('ios') ? '44px' : '56px' }}>
          <h1 className="text-lg font-brand-main font-brand-dark font-semibold">{instanceDoc?.data.name || config.platformName}</h1>
        </div>
      )}
    >
      {!userAuth ? (
        <div className="pt-14 flex place-content-center content-center items-center">
          <BadgeLoading className="text-brand-dark" />
        </div>
      ) : (
        <div className="ion-padding flex flex-col items-center">

          <h2 className="mt-10 block text-black text-sm uppercase font-bold mb-2">Iniciar sesión</h2>

          <p className="mt-6 text-sm text-center">
            <BtnLoading
              label="Iniciar con Gmail"
              icon={<IonIcon icon={logoGoogle} className="!-mb-1 w-5 h-5 text-red-600" />}
              onClickAsync={handleGoogleSignin}
              className="block !items-start !justify-start px-3 py-2 w-[230px] !bg-gray-100 !text-gray-900 shadow-md !no-underline hover:underline"
              colorClass="text-gray-400 hover:text-gray-700"
            />
          </p>

          <div className="absolute bottom-4 font-normal text-xs text-center mt-14 text-gray-400">{config.version}</div>
        </div>
      )}
    </LayoutPublic>
  );
}

