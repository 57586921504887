import _ from 'lodash';
import { useEffect, useState } from 'react';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import { InstanceInlineSelectorForMain } from './InstanceSelector';
import { usePanel } from '../panel/usePanel';
import { useModule } from '../../libs/ModuleContext';
import BadgeLoading from '../../components/ui/BadgeLoading';
import Model from '../../libs/ModelClass';
import config from '../../config';
import { useEntityFullBySlug } from '../entity/Entity';
import { IonButton, IonLabel } from '@ionic/react';
import { DocCard } from '../entity/docCardsVariants';
import { useAsyncMemo } from 'use-async-memo';
import { EntityCrudForm } from '../entity/RouteEntityCrudForm';


const instancesEntitySlug = config.modules.instances.instancesEntitySlug;
const InstanceModel = Model.extend(instancesEntitySlug);

export function Content(props) {
  let {
    entitySpecs,
    selectedInstance,
    parsedParams,
    entitySlug, 
    action,
    config,
    user,
    isAllowed, 
    Model,
    ModuleModel,
    isMinBreakpointActive, 
    location, 
    history,
    match
  } = props;
  const { instancesDocs, setInstancesDocs, attachPrefix } = usePanel();
  const moduleLibs = useModule();
  const [ moduleToInstall, setModuleToInstall ] = useState();

  if (!selectedInstance?.data || !entitySpecs?.entityDoc) {
    return (
      <div className="pt-32 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  };

  const context = {
    onAfterSave: (updatedDoc) => {
      let instanceDoc = instancesDocs.find(doc => doc.id === updatedDoc.id);
      instanceDoc.data = updatedDoc.data;
      setInstancesDocs([ ...instancesDocs ]);
    }
  };

  return (
    <div className="space-y-10 mb-32">
      {/* formulario de edición mostrando isVisible y deshabilitando hash */}
      <EntityCrudForm
        {...props}
        entityDoc={entitySpecs?.entityDoc}
        attachPrefix={attachPrefix}
        docId={selectedInstance.id}
        context={context}
        paramsByFieldSlug={{
          hash: { 
            disabled: true,
            showClearBtn: false
          }
        }}
      />


      {/* formulario para transferir instancia */}
    </div>
  )
}

export function RouteInstanceConfig(props) {
  const { selectedInstance } = usePanel();
  const [ selectedInner, setSelectedInner ] = useState(selectedInstance);

  useEffect(() => {
    setSelectedInner(selectedInstance);
  }, [selectedInstance]);

  const entitySpecs = useEntityFullBySlug({ 
    entitySlug: instancesEntitySlug
  });

  // show instance selector for Main instance only
  let TitleToolbarRightToUse;
  if (selectedInstance?.data.hash === 'main') {
    TitleToolbarRightToUse = (() => (
      <InstanceInlineSelectorForMain selectedInstance={selectedInner} setSelectedInstance={setSelectedInner} />
    ));
  }

  return (
    <LayoutAdmin 
      history={props.history} 
      breadcrumbs={[{
        title: "Configuración de Instancia"
      }]}
      TitleToolbarRight={TitleToolbarRightToUse}
    >
      {!selectedInner ? (
        <div className="">Seleccione una instancia</div>
      ) : (
        <Content {...props} entitySpecs={entitySpecs} selectedInstance={selectedInner} />
      )}
    </LayoutAdmin>
  );
}
