import _ from 'lodash';
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../user/AuthContext";
import Model from '../../libs/ModelClass';
import config from '../../config';
import toast from 'react-hot-toast';


const conversationsEntitySlug = config.modules.message.conversationsEntitySlug;
const ConversationsModel = Model.extend(conversationsEntitySlug);
const messagesEntitySlug = config.modules.message.messagesEntitySlug;
const MessagesModel = Model.extend(messagesEntitySlug);

export const useMessages = ({ contextConfig }) => {
  const { user } = useAuth();
  const [ loading, setLoading ] = useState(false);
  const [ messages, setMessages ] = useState([]);
  const [ conversationDoc, setConversationDoc ] = useState();
  const [ context, setContext ] = useState({
    ...contextConfig
    // place: 'section' || 'conversation',
    // host: 'profile' || 'user',
    // profileId,
    // userId
  });
  // const {
  //   publicProfileOfUser
  // } = useMeetingData();
  const publicProfileOfUser = null;

  // Chat params
  const chatParams = {
    onSend: sendMessage,
    senderAsHost: context.host
  };
  // const chatParams = useMemo(() => {
  //   let params = {
  //     onSend: sendMessage,
  //     senderAsHost: context.host
  //   };
  //   return params;
  // }, [context, conversationDoc, publicProfileOfUser]);
  
  // messages subscription
  useEffect(() => {
    if (context?.place === 'conversation' && user?.userDoc?.id) {
      return fetchMessagesSubscriptor();
    }
  }, [user?.userDoc?.id, publicProfileOfUser?.id, context]);
  
  // conversation fetch
  useEffect(() => {
    if (_.size(context) && user?.userDoc?.id) {
      fetchConversation();
    }
  }, [user?.userDoc?.id, publicProfileOfUser?.id, context]);

  // conversation subscription
  useEffect(() => {
    if (conversationDoc) {
      return conversationDoc.onSnapshot((newDoc) => {
        setConversationDoc(newDoc);
      });
    }
  }, [conversationDoc?.id]);

  // mark receibed messages as readed
  useEffect(() => {
    if (messages?.length) {
      markRecievedAsReaded();
    }
  }, [conversationDoc?.data?.lastAt, messages]);

  const fetchMessagesSubscriptor = () => {
    let filters = {};
    // user is host
    if (context?.host === 'user') {
      filters.userId = user.userDoc.id;
      filters.profileId = context.profileId;
    } 
    // profile is host
    else if (context?.host === 'profile') {
      filters.userId = context.userId;
      filters.profileId = publicProfileOfUser.id;
    }
    if (!(filters.userId && filters.profileId)) {
      return;
    }
    const unsubscribe = MessagesModel.filterByAttributesOnSnapshot(
      filters,
      {
        // limit: 300,
        // orderBy: { field: 'createdAt', direction: 'desc' },
      }, (filteredDocs) => {
        // TODO scroll chat content to bottom
        filteredDocs = _.sortBy(filteredDocs, 'data.createdAt');
        filteredDocs = _.reverse(filteredDocs);
        setMessages(filteredDocs);
      }
    );
    return () => {
      unsubscribe();
    };
  };

  const fetchConversation = async () => {
    let filters = {};
    // user is host
    if (context?.host === 'user') {
      filters.userId = user.userDoc.id;
      filters.profileId = context.profileId;
    } 
    // profile is host
    else if (context?.host === 'profile' && publicProfileOfUser?.id) {
      filters.userId = context.userId;
      filters.profileId = publicProfileOfUser.id;
    }
    if (!(filters.userId && filters.profileId)) {
      return;
    }
    let conversationDoc = await ConversationsModel.filterByAttributes( filters, { limit: 1 });
    conversationDoc = conversationDoc?.length ? conversationDoc[0] : null;
    // create doc
    if (!conversationDoc) {
      conversationDoc = await ConversationsModel.create({
        ...filters,
        started: false
      });
    }
    setConversationDoc(conversationDoc);
  };

  const markRecievedAsReaded = async () => {
    if (messages?.length && conversationDoc) {
      await markConversationAsReaded(conversationDoc);
    }
  };

  const markConversationAsReaded = async (conversationDoc) => {
    // mark as readed by host
    if (context?.host === 'user' && conversationDoc?.data.lastSender === 'profile') {
      conversationDoc.data.userReaded = true;
      return await conversationDoc.save();
    }
    if (context?.host === 'profile' && publicProfileOfUser?.id && conversationDoc?.data.lastSender === 'user') {
      conversationDoc.data.profileReaded = true;
      return await conversationDoc.save();
    }
  };

  async function sendMessage ({ text, type }) {
    if (!conversationDoc) {
      return;
    }
    setLoading(true);
    let newMessage = {
      message: text,
      type
    };
    // user is host
    if (context?.profileId) {
      newMessage.userId = user.userDoc.id;
      newMessage.profileId = context.profileId;
      newMessage.sender = 'user';
    } 
    // profile is host
    else if (context?.userId) {
      newMessage.userId = context.userId;
      newMessage.profileId = publicProfileOfUser.id;
      newMessage.sender = 'profile';
    }
    try {
      // save message
      const messageDoc = await MessagesModel.create(newMessage);
      // update conversation
      conversationDoc.data = {
        ...conversationDoc.data,
        started: true,
        lastAt: messageDoc.data.createdAt,
        lastText: messageDoc.data.message, // contemplate types
        lastSender: context.host,
        userReaded: false,
        profileReaded: false,
      };
      await conversationDoc.save();
      // TODO scroll chat content to bottom
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
    setLoading(false);
  };

  return {
    loading,
    setLoading,
    ConversationsModel,
    MessagesModel,
    setContext,
    context,
    sendMessage,
    chatParams,
    publicProfileOfUser,
    conversationDoc,
    messages
  };
}