import _ from 'lodash';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import Model from "../../libs/ModelClass";
import config from "../../config";


const filterMenuSlug = "panelSearch";

const packagesEntitySlug = config.modules.delivery.packagesEntitySlug;
const PackageModel = Model.extend(packagesEntitySlug);


const getMap = ({ user, isAllowed }) => {
  let map = {
    listShowUser: isAllowed('usersProfiles', 'list'),
    limitResults: false
  };

  map.filtersPatch = (filters) => {
    if (map.listShowUser) {
      return filters;
    }
    filters.userId = user.userDoc.id;
    return filters;
  };

  return map;
};

export const usePermissionsMap = (props) => {
  return getMap(props);
};

export const RouteApiKeySection = ({ parsedParams, user, isAllowed, Model, module, action, isMinBreakpointActive, location, history }) => {  
  const permissionsMap = usePermissionsMap({ user, isAllowed });
  
  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={config.modules.panel.configPath}
      title="Agentes Virtuales"
    >
      <div className="ion-padding">
        
      </div>
    </LayoutAdmin>
  );
};