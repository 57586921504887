import _ from 'lodash';
import { useAsyncMemo } from "use-async-memo";
import Model from "../../libs/ModelClass";
import { inputClasses } from "../../components/EntityTaxonomyForm";
import { withPrefix } from "../instance/utilsInstance";
import Select from 'react-select'
import dayjs from "dayjs";
import { useMemo, useState } from 'react';
import BadgeLoading from '../../components/ui/BadgeLoading';
import ContractsList from './ContractsList';


const contractsEntitySlug = "contracts";
const contactsEntitySlug = "contacts";
const usersProfilesEntitySlug = "usersProfiles";
const creditCollectionsEntitySlug = "creditCollections";

export const afterRead = async (props, docData) => {
  if (!docData.id)  {
    docData.paidDate = docData.paidDate || dayjs().format('YYYY-MM-DD');
  }
  return docData;
};

// export const afterSave = async (props, savedDoc) => {
//   let {
//     doc,
//     formValues,
//     isEdit,
//     instance
//   } = props;
//   if (!formValues.id && formValues.modelId)  {
//     // calc payments
//     const contractId = doc.id;
//     const contractId = formValues.modelId;
//     const contractModelDoc = await Model.extend(withPrefix(instance, contractsEntitySlug)).findById(contractId);
//     const paymentConfig = contractModelDoc?.data.paymentConfig;
//     let { payments, totalAmount, totalInterest } = calculatePayments(paymentConfig, formValues);
//     // save payments
//     payments.forEach((payment) => {
//       payment.customerId = doc.data.customerId;
//       payment.contractId = contractId;
//       payment.contractId = contractId;
//     });
//     let PaymentsModel = Model.extend(withPrefix(instance, 'payments'));
//     await PaymentsModel.createManyBatch(payments);
//     // update doc
//     doc.data.totalAmount = totalAmount;
//     doc.data.totalInterest = totalInterest;
//     await doc.save();
//   }
// };

export const FormPrependSelector = (props) => {
  let {
    instance,
    values,
    form
  } = props;

  const [ filterText, setFilterText ] = useState('');
  const [ innerValue, setInnerValue ] = useState();

  const allDocsByEntity = useAsyncMemo(async () => {
    // estructura de contractsDocs y contactsDocs: { id, data: { ... } }
    let contractsDocs = await Model.extend(withPrefix(instance, contractsEntitySlug)).filterByAttributes({ deleted: 'false' });
    let contactsDocs = await Model.extend(withPrefix(instance, contactsEntitySlug)).filterByAttributes({ deleted: 'false' });
    let usersProfilesDocs;
    if (instance === 'main') {
      usersProfilesDocs = await Model.extend(usersProfilesEntitySlug).filterByAttributes({});
    }
    return {
      contracts: contractsDocs,
      contacts: contactsDocs,
      usersProfiles: usersProfilesDocs
    };
  }, []);

  const docsByEntity = useMemo(() => {
    if (allDocsByEntity) {
      let toCompare = filterText.toLowerCase();
      let contracts = allDocsByEntity.contracts.filter(doc => doc.data.num?.toString().includes(toCompare));
      let contacts = allDocsByEntity.contacts.filter(doc => 
        doc.data.name?.toLowerCase().includes(toCompare)
        || doc.data.citizenNumber?.toString().includes(toCompare)
        || doc.data.email?.includes(toCompare)
        || doc.data.phone?.toString().includes(toCompare)
      );
      let usersProfiles;
      if (instance === 'main') {
        usersProfiles = allDocsByEntity.usersProfiles.filter(doc => 
          doc.data.name?.toLowerCase().includes(toCompare)
          || doc.data.email?.includes(toCompare)
          || doc.data.phone?.toString().includes(toCompare)
        );
      }
      return {
        contracts,
        contacts,
        usersProfiles
      };
    }
    return null;
  }, [allDocsByEntity, filterText]);

  const options = useMemo(() => {
    if (docsByEntity) {
      return [
        {
          label: 'Contraciones',
          options: docsByEntity.contracts.map(doc => ({
            label: `Contrato: ${doc.data.num}`,
            value: doc.id,
            entity: 'contracts'
          }))
        },
        {
          label: 'Contactos',
          options: docsByEntity.contacts.map(doc => {
            let label = `Cliente: ${doc.data.name}`;
            if (doc.data.citizenNumber) {
              label += ` C.I.: ${doc.data.citizenNumber}`;
            }
            return {
              label,
              value: doc.id,
              entity: 'contacts'
            };
          })
        },
        {
          label: 'Usuarios',
          options: docsByEntity.usersProfiles.map(doc => {
            let label = `Usuario: ${doc.data.name}`;
            if (doc.data.email) {
              label += ` Email: ${doc.data.email}`;
            }
            if (doc.data.phone) {
              label += ` Tel: ${doc.data.phone}`;
            }
            return {
              label,
              value: doc.id,
              entity: 'usersProfiles'
            };
          })
        }
      ];
    }
    return [];
  }, [docsByEntity]);
  
  const setValues = (selectedOption) => {
    setInnerValue(selectedOption);
    if (selectedOption.entity === 'contracts') {
      form.change('contractId', selectedOption.value);
      form.change('customerId', null);
      form.change('userId', null);
    }
    else if (selectedOption.entity === 'contacts') {
      form.change('contractId', null);
      form.change('customerId', selectedOption.value);
      form.change('userId', null);
    }
    else if (selectedOption.entity === 'usersProfiles') {
      form.change('contractId', null);
      form.change('customerId', null);
      form.change('userId', selectedOption.value);
    }
  };

  return (
    <div className={inputClasses?.fieldContainer || ''}>
      {/* filter */}
      <div className={inputClasses?.fieldLabel}>
        <span>Documento</span>
      </div>

      {!docsByEntity ? (
        <div className="flex place-content-start font-brand-main">
          <BadgeLoading className="text-brand-dark" />
        </div>
      ) : (
        <Select
          options={options}
          isMulti={false}
          placeholder={`Seleccionar`}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: 'lightgray',
              primary: 'darkgray',
            },
          })}
          value={innerValue}
          onChange={setValues}
          isDisabled={false}
          menuPortalTarget={document.body}
          onInputChange={(text) => setFilterText(text)}
        />
      )}
    </div>
  );
};

export const FormAppendRelatedData = (props) => {
  let {
     values,
     doc,
     instance,
     entityDoc,
     entitySlug,
     taxonomyTypesDocList,
     isAllowed,
     formProps
  } = props;
  const contractId = values.contractId;
  const customerId = values.customerId;
  const userId = values.userId;
  
  // const contractDoc = useAsyncMemo(async () => {
  //   if (!contractId) { return null; }
  //   return await Model.extend(withPrefix(instance, contractsEntitySlug)).findById(contractId);
  // }, [contractId]);

  // const customerDoc = useAsyncMemo(async () => {
  //   if (!customerId) { return null; }
  //   return await Model.extend(withPrefix(instance, contactsEntitySlug)).findById(customerId);
  // }, [customerId]);

  // const userDoc = useAsyncMemo(async () => {
  //   if (!userId) { return null; }
  //   return await Model.extend(usersProfilesEntitySlug).findById(userId);
  // }, [userId]);

  // const allContractsDocs = useAsyncMemo(async () => {
  //   if (!customerDoc?.id && !userDoc?.id) { return null; }
  //   let query = {
  //     deleted: 'false'
  //   };
  //   if (customerDoc) {
  //     query.customerId = customerDoc.id;
  //   }
  //   if (userDoc) {
  //     query.userId = userDoc.id;
  //   }
  //   return await Model.extend(withPrefix(instance, contractsEntitySlug)).filterByAttributes(query);
  // }, [customerDoc]);

  // console.log('under', contractDoc, customerDoc);

  return (
    <div className="">
      <ContractsList
        {...props}
        showSelector={true}
        customerId={values.customerId}
        userId={values.userId}
      />
    </div>
  );
};

export const FormAppendColumnRight = (props) => {
  return (
    <div className="">
      FormAppendColumnRight
    </div>
  );
};
