import _ from 'lodash';
import { createContext, useContext, useEffect, useState } from "react";
import { boot } from '../modules/index';
import { useAuth } from "../modules/user/AuthContext";
import { events as dataTypesEvents } from '../components/DataTypes';
import { withOutPrefix } from '../modules/instance/utilsInstance';


const moduleContext = createContext();

export const useModule = () => {
  const context = useContext(moduleContext);
  if (!context) throw new Error("There is no Module provider");
  return context;
};

export function ModuleProvider({ children }) {
  const [modules, setModules] = useState();
  const [actionsByResource, setActionsByResource] = useState();
  const { userAuth } = useAuth();

  useEffect(() => {
    if (!modules) {
      const { modules, actionsByResource } = boot();
      setModules(modules);
      setActionsByResource(actionsByResource);
    }
  }, [modules, userAuth]);

  const isAllowed = (resource, actions) => {
    if (!modules?.length || !userAuth?.rolesDoc) {
      return false;
    }
    let actionsToFire = _.isArray(actions) ? actions : [actions];
    return !!_.intersection(userAuth?.permissionsMap[resource], actionsToFire)?.length;
  };

  const fireEventReducer = async (event, param, result) => {
    if (!modules?.length || !userAuth?.rolesDoc) {
      console.error('missing roles')
      return false;
    }
    let returnValue = result;
    // fire DataType listeners for all entities
    if (dataTypesEvents[event]) {
      returnValue = await dataTypesEvents[event](param, returnValue);
    };
    // fire Module listeners by entitySlug
    if (modules) {
      let { entitySlug } = param;
      for (let module of modules) {
        let listener = _.get(module, ['events', withOutPrefix(entitySlug), event]);
        if (listener) {
          returnValue = await listener(param, returnValue);
        }
      }
    };
    return returnValue;
  };

  return (
    <moduleContext.Provider
      value={{
        modules,
        actionsByResource,
        isAllowed,
        fireEventReducer,
        user: userAuth,
        userAuth
      }}
    >
      {modules && actionsByResource ? children : null}
    </moduleContext.Provider>
  );
}
