import { Form } from 'react-final-form';
import _ from 'lodash';
import { 
  MenuStyleCompact,
  MenuStyleCollapsible,
  MenuStyleCollapsibleDrawer
} from "../components/Filters";
import { withRouter } from 'react-router-dom';


const FormContent = (props) => {
  let {
    // filterMenuTaxonomyTypes, // [ FilterPopulated { id, filter, taxonomyType }]
    // form,
    // values,
    // handleSubmit,
    // onFormClear,
    // classes,
    // Components
    style = 'search-collapsible'
  } = props;

  if (style === 'search-collapsible-drawer') {
    return <MenuStyleCollapsibleDrawer {...props} />;
  }
  if (style === 'search-collapsible') {
    return <MenuStyleCollapsible {...props} />;
  }
  else if (style === 'search-expanded') {
    return <MenuStyleCollapsible {...props} isAlwaysExpanded={true} />;
  }
  else if (style === 'search-compact') {
    return <MenuStyleCompact {...props} />;
  }
  // style 'selector-compact', 'selector-collapsible' selector no redirige
}

const EntityFilterMenuDisplay = (props) => {
  let {
    // entitySlug, 
    // filterMenuSlug,
    filterMenuTaxonomyTypes,
    filterData={},
    onFormChange,
    // Components, paramsByFieldSlug, onFormClear, style, classes, defaultPath <= for FormContent
  } = props;

  const sanitizeValues = (values) => {
    const sanitizedValues = { ...values };
    for (const prop in sanitizedValues) {
      const value = sanitizedValues[prop];
      if (value === undefined || value === null || value === false || _.size(value) === 0) {
        delete sanitizedValues[prop];
      }
      if (Array.isArray(value) && value.length === 0) {
        delete sanitizedValues[prop];
      }
    }
    return sanitizedValues;
  };

  const handleSubmit = (values) => {
    onFormChange && onFormChange(sanitizeValues(values));
  };

  return (
    filterMenuTaxonomyTypes ? (
      <Form
      initialValues={filterData} // prefijo para campos del form
      onSubmit={handleSubmit}>
        {(formProps) => (<>
          <FormContent {...formProps} {...{
            filterMenuTaxonomyTypes,
            ...props
          }}  />
        </>)}
      </Form>
    ) : null
  );
};

export default withRouter(EntityFilterMenuDisplay);
