import _ from 'lodash';
import toast from 'react-hot-toast';
import { EntityDocListView } from '../../components/EntityDocView';
import { compileStringTemplate, getImageURL, openWhatsApp, trimSlash } from "../../libs/utils";
import { useEntityFullBySlug } from '../entity/Entity';
import { usePanelSheet } from "../../components/ui/PanelSheet";
import { getConfig } from '../../config';
import { QtyButtons } from './QtyButtons';
import { useCart } from './useCartData';
import { useEffect, useRef } from 'react';
import { urlPrefix } from '../instance/utilsInstance';
import IonBtnLoading from '../../components/ui/IonBtnLoading';
import { ShowAmount, toAmount } from '../../components/Form/utilsCurrency';
import { ShowCategoryFields } from './CartItemThumbGrid';
import { useLayoutHome } from '../layoutHome/useLayoutHome';
import useBreakpoints from '../../libs/useBreakpoints';


const bagId = 0; // main bag

export const CurrentCartDetailsDrawer = (props) => {
  let { 
    instance,
    categoryFieldsById,
    goToItemByParams,
    imgProportion = 'vertical-square'
  } = props;
  const {
    isDetailsOpen, setIsDetailsOpen, openDetailsDrawer,
    entityMap, CartModel, ItemMainModel, ItemVariantModel, CategoryModel, TypeModel, 
    fetchItems, items, itemsTypes, itemsCategories,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    doPopulateBags,
    totalPriceItems
  } = useCart();
  const variantsEntitySlug = entityMap.cartItemVariants.entitySlug;
  const mainBag = getBagById(bagId);
  const entitySpecs = useEntityFullBySlug({ entitySlug: variantsEntitySlug });
  const { taxonomyTypesDocList, mainAttr, mainImgAttr } = entitySpecs;
  // const { getMenuHeight } = useScrollUtils();
  const { panelRef, paneInstance, isPaneOpen, setIsPaneOpen, openPanel, closePanel } = usePanelSheet({
    breaks: {
      top: { 
        enabled: true, 
        height: (window.innerHeight || 900) - 80,
        bounce: true
      },
      middle: { enabled: false },
      bottom: { enabled: false },
    },
    events: {
      onDidDismiss: () => {
        setIsDetailsOpen(false);
      },
      onBackdropTap: () => {
        setIsDetailsOpen(false);
      }
    },
    initialBreak: 'top',
    backdrop: true,
    showDraggable: false,
    dragBy: ['.no-draggable'],
    draggableOver: false,
    fastSwipeClose: true,
    topperOverflow: false,
    bottomClose: true,
    buttonDestroy: true
  });

  useEffect(() => {
    if (isDetailsOpen) {
      openPanel();
    }
    else {
      closePanel();
    }
  }, [isDetailsOpen]);

  const doGoToItemByParams = (mainId, variantId, params) => {
    goToItemByParams && goToItemByParams({ mainId, variantId, params })
    closePanel();
  };

  const onRemoveItem = () => {
    if (!mainBag.itemsInBag.length) {
      setIsDetailsOpen(false);
    }
  };

  return (<>
    {/* Detalles del pedido */}
    <div className="drawer-details">
      <div ref={panelRef} className="pb-64 w-full h-screen bg-white">
        {mainBag?.itemsInBag?.length ? (<>
          <div className="px-3 pt-1 pb-1 w-full bg-white z-20 fixed shadow-md">
            <h3 className="text-xl font-bold text-center">
              Detalles del pedido
            </h3>
          </div>

          <div className="px-3 xs:px-4 sm:px-6 md:px-7 lg:px-8 pt-12">
            <h3 className="mb-4 text-md font-semibold text-center">
              Productos seleccionados
            </h3>
            <div className="grid grid-cols-1 gap-4 md:gap-4 items-start cursor-default">
              {categoryFieldsById && mainBag.itemsInBag.map((itemInBag, index) => {
                const variantDoc = itemInBag.itemDoc;
                
                if (!variantDoc) {
                  return null;
                }

                const variantDocWithParams = { id: variantDoc.id, data: { ...variantDoc.data, ...itemInBag.params } };
                let categoryDoc = categoryFieldsById[variantDoc.data['itemCategory_fieldsId']];
                
                return (
                  <EntityDocListView
                    key={index}
                    {...entitySpecs}
                    refreshers={[ itemInBag ]}
                    doc={variantDoc}
                    classes={{
                      fieldLabel: "hidden"
                    }}
                    render={({ ViewData, mainAttr, mainImgAttr, classes }) => (
                      <div className="flex flex-row text-left relative grow lg:px-4 pb-4 md:pb-2 lg:pb-4 border-b border-brand-light last:border-0">

                        {/* item card */}
                        <div className="basis-1/2 xs:basis-2/5 md:basis-2/5">
                          <button
                            type="button"
                            onClick={() => doGoToItemByParams(itemInBag.id, variantDoc.id, itemInBag.params)}
                            className="border border-gray-300 rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-all"
                          >
                            <img 
                              src={variantDoc?.data && getImageURL(variantDoc?.data['variantImgs'][0], 'xs')} 
                              className={`${imgProportion} w-full object-cover cursor-pointer`}
                            />
                          </button>
                        </div>

                        {/* data */}
                        <div className="basis-1/2 xs:basis-3/5 md:basis-3/5">
                          <div className="my-2 px-2 md:px-4 lg:px-6 md:space-y-2">
                            <div className="cursor-pointer" onClick={() => doGoToItemByParams(itemInBag.id, variantDoc.id, itemInBag.params)}>
                              <ViewData field="name" classes={{ fieldContainer: 'px-1 md:text-lg lg:text-xl font-semibold leading-tight underline' }} />
                              <ViewData field="price" classes={{ fieldContainer: 'text-sm sm:text-md md:text-lg text-gray-600 lg:my-0' }} />
                            </div>
                            {/* params */}
                            <div className="flex flex-row flex-wrap gap-2 items-start">
                              {/* 
                                show data of categoryFieldsDoc
                              */}
                              <ShowCategoryFields
                                item={variantDocWithParams}
                                categoryDoc={categoryDoc}
                                instance={instance}
                                entitySlug={variantsEntitySlug}
                              />
                            </div>
                            <div className="flex flex-row flex-wrap gap-2 md:gap-4">
                              <QtyButtons {...{
                                bagId, setItemToBag, itemInBag, 
                                itemId: itemInBag.id,
                                params: itemInBag.params,
                                price: variantDoc?.data.price,
                                onChange: doPopulateBags,
                                onRemoveItem,
                                showIncludedLabel: false,
                                classes: {
                                  fieldContainer: '',
                                  fieldLabel: 'block text-black !text-xs !font-semibold',
                                  actionContainer: 'min-w-full'
                                }
                              }} />
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    )}
                  />
                );
              })}
            </div>
          </div>
        </>) : null}
      </div>
    </div>

  </>);
};

export const CurrentCartDetails = (props) => {
  let { 
    cartPath,
    history,
    instance,
    specDesign,
    sendCartLink,
    classes = {}
  } = props;
  const {
    isDetailsOpen, setIsDetailsOpen, openDetailsDrawer,
    ItemMainModel, ItemVariantModel, CategoryModel, TypeModel, 
    fetchItems, items, itemsTypes, itemsCategories,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    doPopulateBags,
    totalPriceItems
  } = useCart();

  let { isMinBreakpointActive, brkPnt } = useBreakpoints();
  const { setOffsetBottom } = useLayoutHome();
  const toolbarRef = useRef(null);
  const itemsInBag = getItemsOfBag(bagId);

  // show float: si es LG y esta abierto
  // show float: si es sm y tiene algo
  const showFloat = (isMinBreakpointActive('lg') && isDetailsOpen) 
                    || (!isMinBreakpointActive('lg') && !!itemsInBag?.length);
  // show inline: si es lg y tiene algo
  const showInline = isMinBreakpointActive('lg') && itemsInBag?.length;

  useEffect(() => {
    if (
      showFloat
    ) {
      setOffsetBottom( toolbarRef.current?.clientHeight ? toolbarRef.current?.clientHeight + 16 : 0 );
    }
    else {
      setOffsetBottom(0);
    }
    return () => {
      setOffsetBottom(0);
    }
  }, [isDetailsOpen, brkPnt, showFloat]);

  let whatsappNumber = sendCartLink?.useBrandPhone ? specDesign.stringsVars?.brand?.whatsappNumber : sendCartLink?.phoneNumber;
  let sendCartLinkMessage = compileStringTemplate(sendCartLink?.phoneMessage, {
    brand: specDesign.stringsVars.brand,
    cart: {
      link: `${getConfig().getURLprefix()}${urlPrefix(instance, `${trimSlash(cartPath)}/`)}/#/cartId/${cartDoc?.id}`
    }
  });

  const confirm = async () => {
    history.push(urlPrefix(instance, `${trimSlash(cartPath)}/#/cartId/${cartDoc?.id}`));
    setTimeout(() => {
      toast('Se confirmó el pedido', { icon: "👍🏻" });
    }, 3000);
    closeCart();
    setTimeout(() => {
      toast('Avisá a la tienda', { icon: '📨' });
    }, 6000);
    setTimeout(() => {
      toast('Se abrirá WhatsApp en breve', { icon: '📨' });
    }, 8000);
    setTimeout(() => {
      openWhatsApp({
        number: whatsappNumber, 
        message: sendCartLinkMessage
      });
    }, 12000);
  };

  const TotalPanel = ({ toolbarRef, classes }) => {
    return (
      <div
        ref={toolbarRef}
        className={`${classes.totalContainer}`}
      >
        {/* fixed footer */}
        {/* <div className={`min-w-40 mr-8 flex place-content-end ${classes.contactBtn}`}>
          <ContactBTN className={'mb-4 lg:mb-4 bg-brand-dark'} />
        </div> */}
        {/* card */}
        <div className={`
          text-center py-4 ${classes.totalCard}
        `}>
          <h3 className="mb-1.5 shaked-cascade-1 text-base font-semibold uppercase leading-none tracking-wide">
            Total
          </h3>
          <h3 className="mb-3 text-xl font-normal leading-none tracking-wide">
            <ShowAmount amount={toAmount(totalPriceItems)}></ShowAmount>
          </h3>

          {!isDetailsOpen ? (
            <IonBtnLoading 
              label="Detalles del pedido"
              onClickAsync={openDetailsDrawer} 
              withLoading 
              className="shaked-cascade-2 mx-auto inline-block"
              color="primary"
              size="medium"
              fill="solid"
            />
          ) : (
            <IonBtnLoading
              label="Confirmar"
              onClickAsync={confirm} 
              withLoading 
              className="shaked-cascade-2 mx-auto inline-block"
              color="primary"
              size="medium"
              fill="solid"
            />
          )}
        </div>
      </div>
    );
  };

  return (<>
    {/* Confirmation pane */}

    {/* show float: si es LG y esta abierto */}
    {/* show float: si es sm y tiene algo */}
    {showFloat ? (
      <TotalPanel toolbarRef={toolbarRef} classes={{
        totalContainer: `!z-[320] !fixed left-0 w-screen bottom-0 bg-white shadow-xl`, 
        totalCard: 'border-t-2 border-brand-medium'
      }} />
    ) : null}

    {/* show inline: si es lg y tiene algo */}
    {showInline ? (
      <TotalPanel classes={{
        totalContainer: 'relative w-full',
        totalCard: 'shadow-md rounded-lg bg-brand-dark text-brand-light py-6',
        contactBtn: 'lg:hidden'
      }} />
    ) : null}
  </>);
};