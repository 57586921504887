import { processRenderProps } from './dataTypeUtils';
import {
  FormFieldCustom,
  FormFieldSelect,
  RawInputPhone,
} from '../Form';


const valueFormatter = (value) => {
  if (typeof value === 'undefined' || value === null) {
    return;
  }
  return value.toString();
};

export default { 
  Render(props) {
    const {
      onChange,
      fieldsRequired
    } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);

    return (
      <FormFieldCustom
        title={title}
        placeholder={title}
        {...props}
        {...style}
        {...param}
        name={_fieldName}
        fieldsRequired={fieldsRequired}
        Render={RawInputPhone}
      />
    );
  },
  
  RenderFilter(props) { return null; },

  RenderFilterParam(props) {return null; },

  valueFormatter,

  RenderInputParams: ({ values, param }) => (null),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderShowParams: ({ values }) => (null),
  
  RenderInputPreview: ({ values, formFieldProps }) => (
    <FormFieldCustom
      Render={RawInputPhone}
      {...formFieldProps}
    />
  ),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}