import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldTextId,
} from '../Form';
import {
  // Text
  FilterRenderText,
  FilterCreatorText,
} from '../Filters';

const valueFormatter = (value) => {
  if (typeof value === 'undefined' || value === null || isNaN(value)) {
    return null; // or return another appropriate default value when the input is undefined, null, or not a valid number.
  }

  try {
    const parsedValue = parseInt(value, 10); // Convert the value to an integer.
    return isNaN(parsedValue) ? null : parsedValue; // Return the parsed value if it is a valid number, or another default value if the parsing fails.
  } catch (error) {
    console.error('Error occurred while formatting value:', error); // Log the error for debugging purposes.
    return null; // or return another appropriate default value if an error occurs during parsing.
  }
};

export default {
  Render(props) {
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);
    param.formatter = valueFormatter;

    return (
      <FormFieldTextId
        type="text"
        placeholder="Identificador de texto único"
        {...props}
        {...style}
        {...param}
        name={_fieldName}
      />
    );
  },
  
  RenderFilter(props) {
    return <FilterRenderText {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorText {...props} />
  },

  valueFormatter,

  RenderInputParams: ({ values, param }) => (
    <>
      <FormField
        name="param.minLength"
        title="Longitud mínima"
        type="number"
        maxValue={values.param && parseInt(values.param.maxLength)}
      />
      <FormField
        name="param.maxLength"
        title="Longitud máxima"
        type="number"
        minValue={values.param && parseInt(values.param.minLength)}
      />
    </>
  ),

  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),

  RenderShowParams: ({ values }) => (null),

  RenderInputPreview: ({ values, formFieldProps }) => (null)
}