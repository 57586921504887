import { nanoid } from "nanoid";
import BadgeLoading from "../../components/ui/BadgeLoading";
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import applyLayoutDesign from "../../modules/layoutHome/applyLayoutDesign";
import { withProvider } from "../../libs/utils";
import * as specSite from "../../customModules/specSite";


const layoutHomeContext = createContext();

export const useDesignStack = () => {
  const context = useContext(layoutHomeContext);
  if (!context) throw new Error("There is no DesignStack provider");
  return context;
};

export function DesignStackProvider({ children }) {
  const [layoutDesign, innerSetLayoutDesign] = useState();

  const setLayoutDesign = (desingDef) => {
    if (desingDef) {
      // TODO aplicar design only for children
      applyLayoutDesign(desingDef);
      innerSetLayoutDesign(desingDef);
    } else {
      innerSetLayoutDesign({});
    }
  };

  return (
    <layoutHomeContext.Provider
      value={{
        layoutSetted: !!layoutDesign,
        layoutDesign,
        setLayoutDesign
      }}
    >
      {children}
    </layoutHomeContext.Provider>
  );
}

export function DesignWrapper (props) {
  let {
    specDesign,
    children
  } = props;
  const elementId = useMemo(() => nanoid(5), []);
  const elementRef = useRef(null);
  const { layoutSetted, setLayoutDesign } = useDesignStack();

  useEffect(() => {
    setLayoutDesign(specDesign || specSite.specDesign);
  }, [specDesign]);

  if (!layoutSetted) {
    return (
      <div className="py-10 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }
 
  return (
    <div ref={elementRef} id={'design-' + elementId}>
      {children}
    </div>
  );
}

export const DesignWrapperProvider = withProvider(DesignWrapper, DesignStackProvider);