import _ from 'lodash';
import { AdminHomeContent } from './AdminTabHome';
import { RouteInstanceSection } from './RouteInstanceSection';
import { RouteInstanceCreate } from './RouteInstanceCreate';


export default function () {
  return {
    slug: 'usersSections',
    name: 'Secciones de usuarios',
    scope: 'global',
    bundles: ['core'],
    entities: [
      'usersSections'
    ],
    permissions: [
      { slug: 'usersSections', label: 'Secciones de usuarios', actions: ['tab:usersSections', 'tab:instances'] },
    ],
    components: {
      main: {
        AdminHomeContent
      }
    },
    routesAdmin: {
      'usersSections': {
        'instances': {
          permission: { resource: 'usersSections', action: 'tab:instances' },
          Content: RouteInstanceSection
        },
        'instanceCreate': {
          permission: { resource: 'usersSections', action: 'tab:instances' },
          Content: RouteInstanceCreate
        },

        // 'instances': {
        //   permission: { resource: 'usersSections', action: 'tab:usersSections' },
        //   Content: RouteInstanceBrandSection
        // },
      },
    }
  };
};