import { Field } from 'react-final-form';
import _ from 'lodash';
import { useState, useEffect } from 'react';

import { required, RequiredByFieldsList, ShowFieldHelp } from './formParts';


const SwitchInputA = ({
  value,
  onChange,
  colorFalse = 'gray-400',
  colorTrue = 'blue-500',
  textTrue,
  textFalse,
  disabled,
}) => {
  return (
    <div className="inline-block cursor-pointer" onClick={() => onChange(!value)}>
      <span className={`mr-1 text-md font-medium ${value ? 'text-' + colorFalse : 'text-' + colorTrue}`}>{textFalse}</span>
      <label htmlFor="toggle" className="inline-flex top-1.5 relative items-center mr-5 cursor-pointer">
        <input
          type="checkbox"
          checked={value}
          onChange={onChange}
          disabled={disabled}
          className="sr-only peer"
        />

        <div
          className={`w-11 h-6 bg-${value ? colorTrue : colorFalse} rounded-full peer peer-focus:ring-4 peer-focus:ring-${value ? colorTrue : colorFalse}
            peer-checked:after:translate-x-full peer-checked:after:border-white 
            after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-${value ? colorTrue : colorFalse} 
            after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-${value ? colorTrue : colorFalse}`}
        ></div>
      </label>
      <span className={`-ml-3 text-md ${value ? 'font-medium text-' + colorTrue : 'font-normal text-' + colorFalse}`}>{textTrue}</span>
    </div>
  );
}

export default SwitchInputA;