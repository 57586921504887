import _ from 'lodash';
import { useMemo, useState } from 'react';
import { ChromePicker } from 'react-color';
import { IonIcon, IonLabel, IonPopover } from '@ionic/react';
import { colorFillOutline } from 'ionicons/icons';
import { getContrastColor } from '../../libs/utils';
import { colorGroups, colorModes } from '../../modules/layoutHome/applyLayoutDesign';
import config from '../../config';


const RawInputColor = (props) => {
  let {
    value,
    onChange,
    showPalette = true,
    showTitle = false,
    title,
    defaultValue,
    // colors
  } = props;
  const buttonId = useMemo(() => _.uniqueId('input-color-'), []);
  const [isOpen, setIsOpen] = useState(false);
  let colorsMap = props.colors || config.colors;

  const togglePopover = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const assignChromeColor = (value) => {
    onChange(value.hex);
  };
  const assignNamedColor = (colorName, colorGroup, colorMode) => {
    onChange(colorName);
    setIsOpen(false);
  };

  function getColorHexByName (colorName) {
    const color = colorsMap[colorName];
    return color;
  };

  function getValueHex(value, defaultValue) {
    if (value?.hex) { return value.hex; }
    if (_.isString(value)) {
      return getColorHexByName(value) || value || defaultValue;
    }
    return defaultValue;
  }

  return (
    <>
      <button
        id={buttonId}
        onClick={togglePopover}
        className="inline-flex rounded-r items-center gap-2 text-sm text-center"
        type="button"
      >
        <div
          // Mostrar el círculo con el color de fondo
          className="w-8 h-8 rounded-full cursor-pointer flex items-center place-content-center"
          style={{ backgroundColor: getValueHex(value, '#ffffff') }}
        >
          <IonIcon slot="icon-only" size='small' icon={colorFillOutline} style={{
            color: getContrastColor(getValueHex(value, '#ffffff'))
          }} />
        </div>
        <IonLabel>
          {value || getValueHex(value)}
        </IonLabel>
      </button>
      <IonPopover
        trigger={buttonId}
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        side="bottom"
        alignment="start"
        className={`${showPalette ? 'wide' : ''}`}
      >
        <div className="">
          {showTitle ? (
            <div className="flex-1 p-2 text-sm font-semibold text-gray-600">
              {title}
            </div>
          ) : null}
          <div className="flex flex-row">
            <div className="">
              <ChromePicker
                color={getValueHex(value, '#ffffff')} // Color predeterminado o el color actual
                onChange={assignChromeColor}
                disableAlpha={true}
                className="!shadow-none"
              />
            </div>
            {showPalette ? (
              <div className="border-gray-300 border-l !border-t-0 flex flex-row gap-2 flex-wrap overflow-y-scroll h-[225px]">
                {colorGroups.map((colorGroup, index) => (
                  <div key={index} className="p-2 text-xs uppercase font-semibold text-gray-600">
                    {colorGroup}
                    <div className="mt-1 flex flex-row gap-1 flex-wrap">
                      {[colorGroup, ...colorModes].map((colorMode, index) => {
                        const colorName = colorGroup === colorMode ? colorGroup : `${colorGroup}-${colorMode}`;
                        const color = colorMode === colorGroup ? colorsMap[colorGroup] : colorsMap[`${colorGroup}-${colorMode}`];
                        return (
                          <button
                            key={index}
                            type="button" 
                            className="w-8 h-8 rounded-full cursor-pointer border border-solid border-gray-300"
                            style={{ backgroundColor: color }}
                            title={`${colorGroup}-${colorMode}`}
                            onClick={() => {
                              assignNamedColor(colorName, colorGroup, colorMode);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </IonPopover>
    </>
  );
};

export default RawInputColor;
