import _ from 'lodash';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import { RouteEntityCrud } from '../entity/RouteEntityCrud';
import config from '../../config';
import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonNote, IonToolbar } from '@ionic/react';
import {
  enterOutline,
  paperPlaneOutline,
  layersOutline
} from 'ionicons/icons';
import { urlPrefix } from '../instance/utilsInstance';


const instancesEntitySlug = config.modules.instances.instancesEntitySlug;

export const RouteInstanceList = (props) => {
  let { parsedParams, history, user } = props;
  const ownerId = user.userDoc.id;
  const context = {
    entitySlug: instancesEntitySlug,
    docId: parsedParams.docId,
    goToForm: (doc) => (`/a/usersSections/instanceForm/docId/${doc ? doc.id : 'new'}`),
    fetchItems: async ({ model, setDocs }) => {
      let docs = await model.filterByAttributes({ ownerId, deleted: 'false' });
      docs = _.orderBy(docs, ['data.createdAt'], ['desc']);
      setDocs(docs);
    },
    ListBtns: ({ doc }) => {
      return <>
        <IonButton
        size="small"
        fill="clear"
        color="secondary"
        routerLink={urlPrefix(doc.data.hash, `/`)}>
          <IonIcon icon={paperPlaneOutline} size="small" slot="start"></IonIcon>
          Portada
        </IonButton>
        <IonButton
        size="small"
        fill="clear"
        color="medium"
        routerLink={urlPrefix(doc.data.hash, `/a/panel/`)}>
          <IonIcon icon={enterOutline} size="small" slot="start"></IonIcon>
          Panel
        </IonButton>
      </>
    }
  };
  
  return (
    <RouteEntityCrud
      {...props}
      context={context}
      withLayout={false}
      showEmptyMessage={false}
      showToolbar={false}
      showBtnAdd={false}
      showBtnUpdate={false}
      showBtnDelete={false}
      showBtnMove={false}
    />
  );
}

export const CreateInstanceBtn = (props) => {
  return (<>
    <IonToolbar inset={true}>
      <IonItem routerLink="/a/usersSections/instanceCreate" lines="none">
        <IonIcon icon={layersOutline} className="mr-2" slot="start"></IonIcon>
        <IonButton color="primary" size="small" fill="outline">
          Registrar Instancia
        </IonButton>
      </IonItem>
    </IonToolbar>
    <IonNote color="medium" className="!mt-4">
      <span className="text-brand-primary mr-1">
        Demo de 7 días.
      </span>
      Dispone de tu web con catálogo registrando una instancia.
    </IonNote>
  </>);
};

export const ListInstancesBtn = (props) => {
  return (<>
    <IonToolbar inset={true}>
      <IonItem routerLink="/a/usersSections/instances" lines="none">
        <IonIcon icon={layersOutline} className="mr-2" slot="start"></IonIcon>
        <IonButton color="primary" size="small" fill="clear">
          Listar Instancias
        </IonButton>
      </IonItem>
    </IonToolbar>
  </>);
};

export function Content(props) {
  let {
    entitySpecs,
    parsedParams,
    entitySlug, 
    action,
    config,
    user,
    isAllowed, 
    Model,
    ModuleModel,
    isMinBreakpointActive, 
    location, 
    history,
    match
  } = props;
  
  return (
    <div className="space-y-10 mb-32 p-2 md:p-4">
      <CreateInstanceBtn />
      <RouteInstanceList {...props} />
    </div>
  )
}

export function RouteInstanceSection(props) { 
  return (
    <LayoutAdmin 
      history={props.history} 
      breadcrumbs={[{
        title: "Instancias"
      }]}
    >
      <Content {...props} />
    </LayoutAdmin>
  );
}
