import { useEntityFullBySlug } from '../entity/Entity';
import { LayoutAdmin } from '../panel/LayoutAdmin';
import BadgeLoading from '../../components/ui/BadgeLoading';
import _ from 'lodash';
import { inputClasses } from '../../components/EntityTaxonomyForm';
import { getConfig } from '../../config'
import SectionCrudForm from '../../components/SectionCrudForm';
import toast from 'react-hot-toast';
import { withPrefix } from '../instance/utilsInstance';
import ObjectShow from '../entity/ObjectShow';
import { paymentInstanceFields } from '../instance/RouteInstanceBilling';
import { IonFooter } from '@ionic/react';
import ObjectForm from '../entity/ObjectForm';
import { addDurationArray, paymentFields } from './PaymentsList';
import dayjs from 'dayjs';


export const Content = (props) => {
  let { instance, entitySpecs, parsedParams, action, config, userAuth, isAllowed, Model, ModuleModel, isMinBreakpointActive, location, history, match, settings } = props;
  const { doc, taxonomyTypesDocList, fieldsRequired } = entitySpecs;
  const entitySlug = getConfig().modules.sales.paymentsEntitySlug;

  const fieldsToEdit = {
    status: {
      ...paymentFields.status
    }
  };

  const handleSave = async (formValues) => {
    try {
      doc.data.moderatedBy = userAuth.userDoc.id;
      doc.data.moderatedAt = new Date();
      if (formValues.status === 'paid') {
        doc.data.status = 'paid';
        // calc new maxActivationDate
        // al vencimiento del payment sumarle la maxActivationDate
        const instanceDoc = await Model.extend(getConfig().modules.instances.instancesEntitySlug).findById(doc.data['meta_instanceId']);
        const bundlesActivations = await settings.getRemote(instanceDoc.data.hash, 'bundles.maxActivation');
        let currentMaxActivationDate = dayjs(_.get(bundlesActivations, 'store'));
        let newMaxActivationDate = addDurationArray(dayjs(doc.data['dueDate']), doc.data['meta_serviceDuration']);
        // actualizar maxActivationDate, si es after de currentActivationDate
        if (newMaxActivationDate.isAfter(currentMaxActivationDate)) {
          newMaxActivationDate = newMaxActivationDate.format('YYYY-MM-DD');
          doc.data.meta_maxActivationDate = newMaxActivationDate;
          await settings.setRemote(instanceDoc.data.hash, 'bundles.maxActivation', {
            store: newMaxActivationDate
          });
          toast.success(`Fecha de activación actualizada hasta ${newMaxActivationDate}`);
        }
        await doc.save();
        toast.success('Pago aprobado');
      } 
      else if (formValues.status === 'rejected') {
        doc.data.status = 'rejected';
        await doc.save();
        toast.error('Pago rechazado');
        toast.error('Comunicarse con el cliente');
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };
  
  const onValidation = (values, errors) => {
    // if (
    //   (values?.status === 'reject' || values?.status === 'commented')
    //   && !values.comment
    // ) {
    //   errors.comment = 'Debe aclarar el motivo';
    // }
  };

  return (
    <div className="pb-[50vh]">
      <div className="p-2">
        <ObjectShow
          {...props}
          value={doc}
          entitySlug={entitySlug}
          fields={_.omit(paymentInstanceFields, ['num'])}
        />
      </div>

    {/* actions btns */}
      <IonFooter className="fixed bottom-0 z-50 w-full bg-white border-t-2 border-brand-secondary">
        <SectionCrudForm
          editStyle={'onsite'}
          doc={doc}
          onSave={handleSave}
          fieldsRequired={fieldsRequired}
          onValidation={onValidation}
          FormInputFields={() => (
            <div className={`${inputClasses.renderListContainer} !gap-0`}>
              <ObjectForm
                {...props}
                entitySlug={entitySlug}
                fields={fieldsToEdit}
                overrideParams={{
                  showLabel: true,
                  showClearBtn: false
                }}
                paramsByFieldSlug={{
                  'status': {
                    style: {
                      design: 'buttons'
                    }
                  }
                }}
              />
            </div>
          )}
          showToast={true}
          showCloseButton={false}
          showTitle={false}
          saveBtnLabel="Actualizar"
        />
      </IonFooter>
    </div>
  );
}

export function RoutePaymentsVerify(props) {
  let { parsedParams, userAuth, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history } = props;

  const entitySpecs = useEntityFullBySlug({ 
    entitySlug: withPrefix('main', config.modules.sales.paymentsEntitySlug),
    docId: parsedParams?.id
  });

  return (
    <LayoutAdmin
      history={history} 
      // defaultHref={'/a/verifications/section'}
      title={"Verificación #" + (entitySpecs?.doc?.data?.id || '')}
    >
      {entitySpecs?.isLoading ? (
        <div className="pt-10 flex place-content-center content-center items-center font-brand-main">
          <BadgeLoading className="text-brand-primary" />
        </div>
      ) : (
        entitySpecs?.doc?.data ? (
          <div className="">
            <Content {...props} entitySpecs={entitySpecs} />
          </div>
        ) : null
      )}
    </LayoutAdmin>
  );
}