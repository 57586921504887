import React, { useEffect, useState } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Circle, GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import config from '../../config';
import { accuracyLevels } from '../DataTypes/gps';


const circleColor = '#1ac3ff';

const RawInputGPSzone = (props) => {
  let {
    value,
    onChange,
    autolocation,
    accuracyLevel,
    mapZoom = 11
  } = props;

  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(mapZoom);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.googleMapApiKey,
    libraries: ['places'],
  });

  // based on https://www.movable-type.co.uk/scripts/geohash.html
  const adjustPosition = (lat, lng) => {
    // accuracyLevel based on geohash, adjust by - 3
    const roundedLat = parseFloat(lat.toFixed(accuracyLevel - 3));
    const roundedLng = parseFloat(lng.toFixed(accuracyLevel - 3));
  
    const roundedPosition = {
      lat: roundedLat,
      lng: roundedLng,
    };
    return roundedPosition;
  }

  useEffect(() => {
    if (value && value?.lat !== parseFloat(value?.lat).toFixed(accuracyLevel - 3)) {
      const positionAdjusted = adjustPosition(value?.lat, value?.lng);

      onChange && onChange(positionAdjusted);
    }
  }, []);

  useEffect(() => {
    if (isLoaded && map) {
      const input = document.createElement('input');
      input.type = 'text';
      input.placeholder = 'Buscar lugar';
      input.style.marginTop = '10px';
      input.style.marginLeft = '10px';
      input.style.padding = '8px 20px';
      input.style.fontSize = '15px';
      input.style.width = '80%';
      input.style.borderRadius = '0.25rem';
      input.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';

      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

      const searchBox = new window.google.maps.places.SearchBox(input);
      const handleSearchResult = () => {
        const places = searchBox.getPlaces();
        if (places && places.length > 0) {
          const positionAdjusted = adjustPosition(places[0].geometry.location.lat(), places[0].geometry.location.lng());
          onChange && onChange(positionAdjusted);
        }
      };
      searchBox.addListener('places_changed', handleSearchResult);

      if (autolocation) {
        handleCurrentLocation();
      }
      
      map.addListener('dragend', () => {
        onChange(map.getCenter().toJSON());
      });
      map.addListener('zoom_changed', () => {
        setZoom(map.getZoom());
      });
    }
  }, [isLoaded, map, autolocation]);

  const initializeMap = (map) => {
    setMap(map);
  };

  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const positionAdjusted = adjustPosition(position.coords.latitude, position.coords.longitude);
          onChange && onChange(positionAdjusted);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error('La geolocalización no es compatible en este navegador.');
    }
  };

  const handleMoveMarker = (event) => {
    const position =  event.latLng.toJSON();
    const positionAdjusted = adjustPosition(position?.lat, position?.lng);
    onChange( positionAdjusted );
  }

  return (
    <div className="relative">
      {isLoaded && (
        <GoogleMap
          center={value}
          mapContainerStyle={{ height: '400px', width: '100%' }}
          options={{
            zoom: zoom,
            zoomControl: true,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            rotateControl: false,
            scaleControl: false,
          }}
          onLoad={initializeMap}
        >
          <Marker position={value} draggable onDragEnd={handleMoveMarker} />
           {/* Circle para representar la precisión */}
           {value && (
            <Circle
              center={value}
              radius={accuracyLevels.find(level => level.value === accuracyLevel)?.distanceMeters} 
              options={{
                fillColor: circleColor,
                fillOpacity: 0.2,
                strokeColor: circleColor,
                strokeOpacity: 1,
                strokeWeight: 1,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
              }}
            />
           )}
        </GoogleMap>
      )}

      <button
        type="button"
        onClick={handleCurrentLocation}
        className="absolute bottom-6 left-2 bg-white rounded border border-gray-300 shadow-md px-4 py-2 flex flex-row gap-2 items-center"
      >
        <FaMapMarkerAlt />
        Ubicación de zona
      </button>
    </div>
  );
};

export default RawInputGPSzone;
