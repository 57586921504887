import { RouteRender } from "../../modules/blockStack/RouteRender";
import { RouteHomeStatic } from "./RouteHomeStatic";


export const RouteHomeRender = (props) => {
  const instance = props.match.params.instance;

  if (!instance) {
    return <RouteHomeStatic {...props} />
  }

  return (
    <RouteRender
      {...props} 
      pageSlug="home"
      instance={instance}
    />
  );
};