import { useLocation } from "react-router-dom";
import { parseAttrValParams } from "./utils";
import { useMemo } from "react";

export const useHashParams = () => {
  const location = useLocation();
  
  const parsedParams = useMemo(() => {
    return parseAttrValParams( location.hash.replace('#/', '') );
  }, [location.hash]);

  return parsedParams;
}