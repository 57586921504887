import _ from 'lodash';
import PhoneInput from 'react-phone-input-2';
import config from '../../config';
import { useAuth } from '../../modules/user/AuthContext';


const RawInputPhone = (props) => {
  const {
    value,
    onChange
  } = props;
  const { user } = useAuth();
  return (
    <PhoneInput
      country={_.get(user, ['userDoc', 'data', config.modules.user.fieldCity, 'countryIso2'])?.toLowerCase() || config.phoneCountry}
      className="w-full"
      value={value}
      onChange={onChange}
    />
  );
};

export default RawInputPhone;