import { memo } from 'react';
import { useEntityFullBySlug } from '../modules/entity/Entity';


const EntityGetOneDocument = ({
  entitySlug,
  docId,
  docWhere,
  EmptyRender,
  children,
  placeholder = null
}) => {
  let param = useEntityFullBySlug({ docId, docWhere, entitySlug });

  const _placeholder = placeholder || <span className="rounded-full bg-gray-100 text-gray-100 text-xs px-1.5">cargando</span>

  if (typeof children !== 'function') {
    return null;
  } 
  else if (param?.entityDoc && param?.doc) {
    return children(param);
  } 
  else if (!param?.entityDoc) {
    return  _placeholder;
  }
  else if (param?.doc === null) {
    if (EmptyRender) {
      return <EmptyRender />;
    } else {
      return null;
    }
  }
};

export const EntityGetOneDocumentMemo = memo(EntityGetOneDocument, (prevProps, nextProps) => {
  return (
    prevProps.docId === nextProps.docId
    || prevProps.docWhere === nextProps.docWhere
    || prevProps.entitySlug === nextProps.entitySlug
  );
});


export default EntityGetOneDocument;
