import _ from "lodash";
import { useEffect, useState } from "react";
import { useAsyncMemo } from 'use-async-memo';
import filterDataToQueryFormatter from "../../components/Filters/filterDataToQueryFormatter";
import getModelQueryCache from "../../libs/ModelQueryCache";
import Model from "../../libs/ModelClass";
import toast from "react-hot-toast";


export const useFilterPagination = (props) => {
  const {
    entitySpecs = {},
    queryParams,
    hardFilter,
    isEnabled
  } = props;
  const entitySlug = props.entitySlug || entitySpecs.entitySlug;
  const { filterMenuTaxonomyTypes, taxonomyTypesDocList } = entitySpecs;
  const [ currentPage, setPage ] = useState(1);
  const [ perPage, setPerPage ] = useState(30);
  const [ lastQueryString, setLastQueryString ] = useState(null);
  const [ isLoadingFilters, setLoading ] = useState(true);
  const [ pagesData, setPages ] = useState(null);
  const [ isEmptyResults, setIsEmptyResults ] = useState(false);
  const [ ItemQueryLibrary, setItemQueryLibrary ] = useState(null);

  useEffect(() => {
    if (!ItemQueryLibrary) {
      const ModelExtended = Model.extend(entitySlug);
      setItemQueryLibrary(getModelQueryCache(ModelExtended, true));
    }
  }, [entitySlug]);

  const fetchFilteredDocs = async (queryParams = {}, page) => {
    if (!isEnabled) { return; }
    try {
      setLoading(true);
      let filterQuery = filterDataToQueryFormatter(queryParams, filterMenuTaxonomyTypes);
      if (hardFilter) {
        filterQuery = await hardFilter(filterQuery);
      }
      setLastQueryString(JSON.stringify(filterQuery));
      let fetchedPages = await ItemQueryLibrary.filterAndGetPages(filterQuery, {
        page: page,
        limit: perPage,
        // orderBy: { field: 'createdAt', direction: 'desc' },
      });
      fetchedPages = fetchedPages.map(page => (
        page.filter(doc => {
          return !doc.data?.deleted;
        })
      ));
      setIsEmptyResults(fetchedPages.length === 1 && fetchedPages[0].length === 0);
      setPages(fetchedPages);
      setLoading(false);
    } catch (error) {
      if (error.message?.includes('requires an index')) {
        console.log(error);
        toast.error('Se requiere crear índices');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filterMenuTaxonomyTypes?.length) {
      // scrollTo(blockId, 0, 300);
      setTimeout(() => {
        fetchFilteredDocs(queryParams, currentPage);
      }, 300);
    }
  }, [currentPage]);

  useEffect(() => {
    if (filterMenuTaxonomyTypes?.length && ItemQueryLibrary) {
      setPage(1); // Reset to first page when queryParams change
      fetchFilteredDocs(queryParams, 1);
    }
  }, [entitySlug, queryParams, filterMenuTaxonomyTypes, ItemQueryLibrary]);

  const totalPages = useAsyncMemo(() => {
    const calcTotalPages = async (lastQueryString) => {
      if (!lastQueryString) { return null; }
      const ModelExtended = Model.extend(entitySlug);
      const count = await ModelExtended.filterByAttributesCount(JSON.parse(lastQueryString));
      return count / perPage;
    };
    return calcTotalPages(lastQueryString);
  }, [lastQueryString]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setPage(currentPage + 1);
    }
  }

  return {
    currentPage,
    setPage,
    isLoadingFilters,
    totalPages,
    pagesData,
    isEmptyResults,
    nextPage
  };
};