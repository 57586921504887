import { useEffect, useMemo, useRef, useState } from "react";
import _ from 'lodash';
import SectionCrudModel from "../../components/SectionCrudModel";
import Model from "../../libs/ModelClass";
import { EntityTaxonomyForm, inputClasses } from "../../components/EntityTaxonomyForm";
import { useListDoc } from "../entity/docCardsVariants";
import { usePanel } from "../panel/usePanel";
import { getPrefix, withPrefix } from "../instance/utilsInstance";
import BadgeLoading from "../../components/ui/BadgeLoading";
import { usePrevious } from "../../libs/usePrevious";
import toast from "react-hot-toast";
import config, { getConfig } from "../../config";
import { IonCard } from "@ionic/react";
import { useAsyncMemo } from "use-async-memo";
import { objectFormToTaxonomyTypes } from "../entity/ObjectForm";
import { EntityDocListView } from "../../components/EntityDocView";
import { useEntityFullBySlug } from "../entity/Entity";


// export const syncVariantDocsWithMain = async () => {
//   const docs = await ItemMainModel.getAll();
//   for (const mainDoc of docs) { 
//     const oldDocs = await ItemVariantsModel.filterByAttributes({
//       mainItemId: mainDoc.id
//     });
//     await ItemVariantsModel.updateMany(oldDocs, {
//       name: mainDoc?.data?.name,
//       price: mainDoc?.data?.price,
//       mainAvailable: mainDoc?.data?.mainAvailable,
//       itemType: mainDoc?.data?.itemType,
//       itemCategory: mainDoc?.data?.itemCategory,
//       mainImgs: null
//     });
//     console.log('updated', mainDoc?.data?.name, mainDoc?.data?.price, oldDocs?.length)
//   }
// };

// export const syncVariantDocsSizes = async () => {
//   const docs = await ItemMainModel.getAll();
//   let i = 0;
//   for (const mainDoc of docs) { 
//     const oldDocs = await ItemVariantsModel.filterByAttributes({
//       mainItemId: mainDoc.id
//     });
//     if (oldDocs?.length) {
//       for (const doc of oldDocs) {
//         if (doc.data.sizes?.xs) {
//           delete doc.data.sizes.xs;
//           doc.data.sizes.xp = true;
//           console.log(i++, 'updated xs => xp')
//         }
//         if (doc.data.sizes?.s) {
//           delete doc.data.sizes.s;
//           doc.data.sizes.p = true;
//           console.log(i++, 'updated s => p')
//         }
//         if (doc.data.sizes?.l) {
//           delete doc.data.sizes.l;
//           doc.data.sizes.g = true;
//           console.log(i++, 'updated l => g')
//         }
//         if (doc.data.sizes?.xl) {
//           delete doc.data.sizes.xl;
//           doc.data.sizes.xg = true;
//           console.log(i++, 'updated xl => xg')
//         }
//         await doc.save();
//       }
//     }
//   }
// };

export const updateVariantDocs = async ({ doc, isEdit, entitySlug, taxonomyTypesDocList }, formValues) => {
  const mainItemDoc = doc;
  let { itemCategoryDoc, categoryFieldsDoc, ...mainData } = formValues;
  let instanceHash = getPrefix(entitySlug);
  const ItemVariantsModel = Model.extend(withPrefix(instanceHash, config.modules.cart.cartItemVariantsEntitySlug));
  // apply main name
  if ((!formValues['name'] || formValues['name'] === ' ') && formValues['itemCategory']) {
    formValues['name'] = itemCategoryDoc?.data?.nameSingle || itemCategoryDoc?.data?.name || null;
  }
  // update variants
  if (isEdit && mainItemDoc?.id) {
    // extract main values
    const modifiers = {};
    taxonomyTypesDocList.forEach(taxonomyType => {
      modifiers[taxonomyType.data.nameSlug] = formValues[taxonomyType.data.nameSlug];
    });
    modifiers['itemCategory_fieldsId'] = formValues['itemCategory_fieldsId'];
    const oldDocs = await ItemVariantsModel.filterByAttributes({
      mainItemId: mainItemDoc.id
    });
    await ItemVariantsModel.updateMany(oldDocs, modifiers);
  }
  // clean main data from category fields of first variant
  else {
    categoryFieldsDoc.data.fields.forEach(taxonomyTypeData => {
      Reflect.deleteProperty(mainData, taxonomyTypeData.nameSlug);
    });
  }
  // data to save
  return mainData;
};

export const createFirstVariant = async ({ doc, formValues, modifiedFormValues, instanceDoc, isEdit, entitySlug, taxonomyTypesDocList }, savedDoc) => {
  const mainItemDoc = savedDoc;
  let { itemCategoryDoc, categoryFieldsDoc } = formValues;
  let instanceHash = instanceDoc.data.hash;
  // crear primera variante
  if (!isEdit && mainItemDoc?.id) {
    // extract main values
    const modifiers = {};
    const copyData = taxonomyType => {
      modifiers[taxonomyType.data.nameSlug] = formValues[taxonomyType.data.nameSlug];
    };
    taxonomyTypesDocList.forEach(copyData);
    categoryFieldsDoc.data.fields.forEach(copyData);
    // set main id and save
    modifiers.mainItemId = mainItemDoc.id;
    modifiers.variantAvailable = formValues.mainAvailable;
    modifiers.variantImgs = formValues.variantImgs;
    modifiers['itemCategory_fieldsId'] = formValues['itemCategory_fieldsId'];
    const ItemVariantsModel = Model.extend(withPrefix(instanceHash, config.modules.cart.cartItemVariantsEntitySlug));
    await ItemVariantsModel.create(modifiers);
  }
};

export const deleteVariantDocs = async ({ doc, entitySlug }) => {
  let instanceHash = getPrefix(entitySlug);
  const ItemVariantsModel = Model.extend(withPrefix(instanceHash, config.modules.cart.cartItemVariantsEntitySlug));
  const mainItemDoc = doc;
  const modifiers = {
    deleted: true
  };
  const oldDocs = await ItemVariantsModel.filterByAttributes({
    mainItemId: mainItemDoc.id
  });
  await ItemVariantsModel.updateMany(oldDocs, modifiers);
};

export const VariantsCRUDList = (props) => {
  const {
    instance,
    entityMap,
    mainItemDoc,
    mainTaxonomyTypesDocsList,
    isAllowed,
    itemCategoryDoc,
    categoryFieldsDoc
  } = props;
  const variantsEntitySlug = entityMap.cartItemVariants.entitySlug;
  const ItemVariantsModel = Model.extend(entityMap.cartItemVariants.entitySlug);
  const ItemMainModel = Model.extend(entityMap.cartItems.entitySlug);

  const [fieldsRequired, setFieldsRequired] = useState([]);
  const variantsEntitySpecs = useEntityFullBySlug({ entitySlug: variantsEntitySlug });

  const variantsTaxonomyTypes = useMemo(() => {
    let taxonomyTypesDocList = [];
    // prevent render main taxonomy types on variant list
    variantsEntitySpecs?.taxonomyTypesDocList?.forEach((variantTaxonomyTypeDoc) => {
      const isMainTaxonomyType = mainTaxonomyTypesDocsList.find((mainTaxonomyTypeDoc) => {
        return mainTaxonomyTypeDoc.data.nameSlug === variantTaxonomyTypeDoc.data.nameSlug;
      });
      if (!isMainTaxonomyType) {
        taxonomyTypesDocList.push(variantTaxonomyTypeDoc);
      }
    });
    // add categoryFieldsDoc
    categoryFieldsDoc.data.fields.forEach((taxonomyType) => {
      // exclude image field
      if (taxonomyType.data.nameSlug === 'variantImgs') { return; }
      taxonomyTypesDocList.push(taxonomyType);
    });
    return taxonomyTypesDocList;
  }, [variantsEntitySpecs, mainTaxonomyTypesDocsList, categoryFieldsDoc]);

  // set required fields to validate of categoryFields
  useEffect(() => {
    let requiredFields = [];
    categoryFieldsDoc.data.fields.forEach((taxonomyType) => {
      if (taxonomyType.data.required && !fieldsRequired.includes(taxonomyType.data.nameSlug)) {
        requiredFields.push(taxonomyType.data.nameSlug);
      }
    });
    if (requiredFields.length) {
      requiredFields = _.union(fieldsRequired, requiredFields);
      setFieldsRequired(requiredFields);
    }
  }, [variantsTaxonomyTypes, categoryFieldsDoc]);

  const onValidation = (values, errors) => {
    // all required fields
    fieldsRequired.forEach(field => {
      if (!values[field]) {
        errors[field] = ' '; 
      }
    });
  };

  const fetchItems = async ({ setDocs }) => {
    if (!mainItemDoc.id) {
      setDocs([]);
      return;
    }
    let docs = await ItemVariantsModel.filterByAttributes({ mainItemId: mainItemDoc.id, deleted: 'false' });
    setDocs(docs);
  };

  const handleBeforeSave = async (doc) => {
    if (mainItemDoc.id && mainTaxonomyTypesDocsList?.length) {
      doc.mainItemId = mainItemDoc.id;
      doc['itemCategory_fieldsId'] = itemCategoryDoc.data.fieldsId;
      const refreshMainItemDoc = await ItemMainModel.findById(mainItemDoc.id);
      // assing main attr values
      mainTaxonomyTypesDocsList.forEach(taxonomyType => {
        if (taxonomyType.data) {
          doc[taxonomyType.data.nameSlug] = refreshMainItemDoc?.data[taxonomyType.data.nameSlug];
        }
      });
    }
  };
  
  const ListItem = useListDoc({
    entitySpecs: variantsEntitySpecs,
    isAllowed,
    // outstandingOnly: true,
    ExtraFields: ({ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug, classes }) => (<>
      {/* 
        show data of categoryFieldsDoc
      */}
      {categoryFieldsDoc ? (
        <EntityDocListView
          doc={doc}
          instance={instance}
          entitySlug={entitySlug}
          taxonomyTypesDocList={categoryFieldsDoc.data.fields}
          outstandingOnly={false}
          viewType="show"
          classes={classes}
          render={({ doc, ViewData, mainAttr, mainImgAttr, classes }) => (
            categoryFieldsDoc.data.fields.map((taxonomyType) => (
              taxonomyType.data.nameSlug !== 'variantImgs' ? (
                <ViewData key={taxonomyType.data.nameSlug} field={taxonomyType.data.nameSlug} classes={{ fieldContainer: `text-xs font-normal` }} />
              ) : null
            ))
          )}
        />
      ) : null}
    </>),
    classes: {
      img: '!h-[105px] !w-[105px]'
    }
  });

  {/* 
    show form
  */}
  const FormInputFields = (({ values }) => (<>
    <EntityTaxonomyForm
      instance={instance}
      entitySlug={variantsEntitySlug}
      fieldsRequired={fieldsRequired}
      taxonomyTypesDocList={variantsTaxonomyTypes}
      values={values}
      classes={inputClasses}
    />
  </>));

  return (
    mainItemDoc?.id ? (
      <SectionCrudModel
        model={ItemVariantsModel}
        entitySlug={variantsEntitySlug}
        editStyle="modal"
        listStyle="list"
        title="Variantes del Item"
        subtitle="Grupos de cualidades"
        // validatiion
        fieldsRequired={fieldsRequired}
        onValidation={onValidation}
        // callbacks 
        fetchItems={fetchItems}
        handleBeforeSave={handleBeforeSave}
        // add UI
        ListItem={ListItem}
        FormInputFields={FormInputFields}
      />
    ) : null
  );
};

// VariantsCRUD
export const VariantsCRUD = (props) => {
  const { values, doc, entitySlug, isAllowed, formApi } = props;
  const mainTaxonomyTypesDocsList = props.taxonomyTypesDocList;
  const { settings } = usePanel();
  const instanceHash = getPrefix(entitySlug) || 'main';
  const isSettingsLoaded = settings.loadScope(instanceHash);
  const entityMap = settings.get(instanceHash, 'modules.cart')?.entities;
  const listRef = useRef(null);
  const mainItemDocData = values ? { id: values.id, data: values } : doc;

  const itemCategoryId = values.itemCategoryId;

  const itemCategoryDoc = useAsyncMemo(async () => {
    if (!itemCategoryId) { return null; }
    const doc = await Model.extend(entityMap.cartItemCategories.entitySlug).findById(itemCategoryId);
    formApi.change('itemCategoryDoc', doc);
    return doc;
  }, [itemCategoryId]);

  const categoryFieldsDoc = useAsyncMemo(async () => {
    if (!itemCategoryDoc) { return null; }
    let doc = await Model.extend(
                  entityMap.cartItemFields?.entitySlug 
                  || withPrefix(instanceHash, getConfig().modules.cart.cartItemFieldsEntitySlug)
                ).findById(itemCategoryDoc.data.fieldsId);
    // add image field
    let extraFields = objectFormToTaxonomyTypes({
      variantImgs: {
        type: 'gallery',
        name: 'Fotos',
        doResize: true
      }
    });
    doc.data.fields.unshift(extraFields[0]);
    // data to doc format
    doc.data.fields = doc.data.fields.map((data, index) => ({ id: index, data }));
    // attach temp data to form
    formApi.change('categoryFieldsDoc', doc);
    return doc;
  }, [itemCategoryDoc]);

  // show toast and scroll when doc is created
  const previousValue = usePrevious(mainItemDocData?.id);
  useEffect(() => {
    if (mainItemDocData?.id && _.isUndefined(previousValue)) {
      setTimeout(() => {
        toast('Agrega variantes al producto', { icon: "📦" });
        listRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [mainItemDocData?.id]);

  if (!isSettingsLoaded || !entityMap || (itemCategoryId && !categoryFieldsDoc)) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (
    <div ref={listRef} className={`mt-6`}>
      <div >
        {(!mainItemDocData?.id || !mainTaxonomyTypesDocsList) ? (
          <>
            {categoryFieldsDoc?.data?.fields?.length ? (
              <EntityTaxonomyForm
                instance={instanceHash}  
                entitySlug={entitySlug}
                taxonomyTypesDocList={categoryFieldsDoc.data.fields}
                values={values}
                isAllowed={isAllowed}
                userAuth={props.userAuth}
                classes={inputClasses}
              />
            ) : (
              <div className="">
                <div className={inputClasses.fieldLabel}>
                  Atributos
                </div>
                <div className="">
                  Seleccione una categoría para ver los atributos
                </div>
              </div>
            )}
          </>
        ) : (
          <IonCard className="mx-0 my-0 mt-6">
            <VariantsCRUDList
              instance={instanceHash}
              entityMap={entityMap}
              mainItemDoc={mainItemDocData}
              mainTaxonomyTypesDocsList={mainTaxonomyTypesDocsList}
              isAllowed={isAllowed}
              itemCategoryDoc={itemCategoryDoc}
              categoryFieldsDoc={categoryFieldsDoc}
            />
          </IonCard>
        )}
      </div>
    </div>
  );
};