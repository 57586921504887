import { useEffect, useRef, useState } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonLabel, IonMenu, IonModal, IonPage, IonTitle, IonToolbar, useIonModal } from "@ionic/react";
import Drawer from 'react-modern-drawer'
import { FormChangeSubscriber } from '../Form';
import { SelectedValuesList, FormFiltersRender } from '.';
import useBreakpoints from '../../libs/useBreakpoints';
import { useScrollUtils } from '../../modules/panel/useScrollUtils';
import { getSelectedFilters } from './SelectedValuesList';
import SimpleBar from 'simplebar-react';


const MenuStyleCollapsibleDrawer = (props) => {
  let {
    filterMenuTaxonomyTypes,
    form,
    values,
    handleSubmit,
    classes,
    isAlwaysExpanded = false,
    Components,
    history,
    totalCount
  } = props;
  const { isMinBreakpointActive } = useBreakpoints();
  const { scrollPosition, getMenuHeight } = useScrollUtils();
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isFilterBarSmOnTop, setIsFilterBarSmOnTop ] = useState(false);
  const [ isFilterBarLgOnTop, setIsFilterBarLgOnTop ] = useState(false);
  const [ isFilterBarLgOnBottom, setIsFilterBarLgOnBottom ] = useState(false);
  const filterBarSmRef = useRef(null);
  const filterBarLgRef = useRef(null);
  const filterBarLgContentRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    setIsFilterBarSmOnTop(filterBarSmRef.current?.getBoundingClientRect().top < getMenuHeight());
    setIsFilterBarLgOnTop(filterBarLgRef.current?.getBoundingClientRect().top < getMenuHeight());
    setIsFilterBarLgOnBottom(getFilterBarLgOnBottom());
  }, [scrollPosition]);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  };
  
  const getFilterBarLgOnBottom = () => {
    let mContainer = containerRef.current?.getBoundingClientRect();
    let menu = getMenuHeight();
    let mFilterContent = filterBarLgContentRef.current?.contentEl?.getBoundingClientRect();    
    return (mContainer?.bottom - menu - mFilterContent?.height) < 0 ? true : false;
  };
  
  const getFilterBarLgTopMargin = () => {
    let menu = getMenuHeight();
    return isFilterBarLgOnTop ? menu : 0;
  };

  const getFilterBarLgStyle = () => {
    let style = {
      height: window?.innerHeight - getMenuHeight(),
      width: containerRef.current?.getBoundingClientRect().width
    };
    if (isFilterBarLgOnBottom) {
      style.bottom = 0;
      style.position = 'absolute';
    }
    else if (isFilterBarLgOnTop) {
      style.position = 'fixed';
      style.top = getFilterBarLgTopMargin();
    }
    return style;
  };

  let renderFilterParams = {
    showLabel: true,
    showClearBtn: true,
    showDescriptionText: false,
    showHelpText: false,
    style: {
      classes: { 
        filterContainer: 'space-y-4',
        fieldContainer: '',
        fieldLabel: 'mb-2 flex flex-row place-content-between items-center h-6 text-sm',
        fieldInput: 'block w-full',
      }
    }
  };
  
  const onFormChange = (formValues) => {
    if (formValues) {
      handleSubmit(formValues);
    }
  };

  return (
    <div ref={containerRef} className={`lg:min-h-[70vh] h-full relative ${classes?.filterMenuContainer || ''}`}>
      <form onSubmit={handleSubmit}>
        <FormChangeSubscriber onFormChange={onFormChange} />

        {/* filters list sm */}
        {!isMinBreakpointActive('lg') ? (<>
          <div className="flex flex-wrap gap-2 p-4 pb-2">
            <IonButton ref={filterBarSmRef} onClick={toggleDrawer} expand="block" color="secondary" size="small" className="inline-block lg:hidden m-0">
              Filtros
            </IonButton>
            {Components?.UnderSearch ? (
              <Components.UnderSearch />
            ) : null}
            {/* show selected values */}
            <SelectedValuesList {...props} classes={{ itemSelected: "bg-brand-light text-brand-light-contrast px-2.5 py-1 rounded-lg" }} />
            {/* show totalCount */}
          </div>
          {totalCount ? (
            <div className="px-4 mt-2 text-xs text-gray-500 uppercase">{totalCount} resultados</div>
          ) : null}
          <div 
            className={`
              z-[100] flex flex-wrap gap-2 w-full px-4 py-4 left-0 fixed ${isFilterBarSmOnTop ? 'opacity-100' : 'opacity-0'} 
              bg-white/70 backdrop-blur-sm transition-opacity duration-300
            `} 
            style={{ top: getMenuHeight() }}
          >
            <IonButton onClick={toggleDrawer} expand="block" color="secondary" size="small" className={`m-0 inline-block`}>
              Filtros
            </IonButton>
            {Components?.UnderSearch ? (
              <Components.UnderSearch />
            ) : null}
            {/* show selected values */}
            <SelectedValuesList {...props} classes={{ itemSelected: "bg-brand-light text-brand-light-contrast px-2.5 py-1 rounded-lg" }} />
          </div>
        </>) : null}

        {/* filters list lg */}
        {isMinBreakpointActive('lg') ? (<>
          <div ref={filterBarLgRef}></div>
          <SimpleBar 
            ref={filterBarLgContentRef}
            style={getFilterBarLgStyle()}
          >
            <div className={`space-y-6 pt-4 px-4 pb-64`}>
              <IonLabel expand="block" color="secondary" size="small" className="inline-block">
                Filtros
              </IonLabel>
              {totalCount ? (
                <div className="text-xs text-gray-500 uppercase">{totalCount} resultados</div>
              ) : null}
              {Components?.UnderSearch ? (
                <Components.UnderSearch />
              ) : null}
              {/* show selected values */}
              {getSelectedFilters(props)?.length ? (
                <div className="flex flex-wrap gap-2">
                  <SelectedValuesList {...props} classes={{ itemSelected: "bg-brand-light text-brand-light-contrast px-2.5 py-1 rounded-lg" }} />
                </div>
              ) : null}
              {/* form */}
              <FormFiltersRender
                {...props}
                renderFilterParams={renderFilterParams}
                classes={{ selectedFilter: 'text-gray-900 font-semibold' }} // classes al ultimo para sobreescribir props.classes
              />
            </div>
          </SimpleBar>
        </>) : null}

        <Drawer
          open={isMinBreakpointActive('lg') ? false : isOpen}
          onClose={toggleDrawer}
          direction='right'
          className="px-4 py-24 overflow-y-scroll overflow-x-hidden space-y-8"
          size={
            isMinBreakpointActive('md') ? "40vw" :
            isMinBreakpointActive('sm') ? "60vw" : "65vw"
          }
        >
          {totalCount ? (
            <div className="text-xs text-gray-500 uppercase">{totalCount} resultados</div>
          ) : null}
          <div className="flex flex-wrap gap-2">
            <SelectedValuesList {...props} classes={{ itemSelected: "bg-brand-light text-brand-light-contrast px-2.5 py-1 rounded-lg" }} />
          </div>
          <div className="pb-[500px] space-y-6">
            <FormFiltersRender
              {...props}
              renderFilterParams={renderFilterParams}
              classes={{ selectedFilter: 'text-gray-900 font-semibold' }} // classes al ultimo para sobreescribir props.classes
            />
          </div>
        </Drawer>
      </form>
    </div>
  );
}


export default MenuStyleCollapsibleDrawer;