import _ from 'lodash';
import { getConfig } from "../../../config";
import useBreakpoints from "../../../libs/useBreakpoints";
import { getImageURL } from "../../../libs/utils";


export const sizesOptions = ['xxxs', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl'];
export const qualityOptions = ['xs', 'md', 'xl'];

export const logoFields = {
  'logoType': { 
    type: 'select',
    name: 'Imagen',
    options: [{
      value: 'profileImg', label: 'Imagen de perfil'
    }, {
      value: 'logoDark', label: 'Imagen para fondos claros'
    }, {
      value: 'logoLight', label: 'Imagen para fondos oscuros'
    }]
  },
  'logoSizeSm': {
    type: 'select',
    name: 'Móviles: Tamaño:',
    options: sizesOptions
  },
  'logoSizelg': {
    type: 'select',
    name: 'Escritorio: Tamaño:',
    options: sizesOptions
  },
  'imgSizeSm': {
    type: 'select',
    name: 'Móviles: Calidad de imagen:',
    options: qualityOptions
  },
  'imgSizelg': {
    type: 'select',
    name: 'Escritorio: Calidad de imagen:',
    options: qualityOptions
  }
};

// use requires usePartLogo
export default function () {
  return {
    type: 'logo',
    // template: PartLogo, // TODO refactor PartLogo to use params
    fields: logoFields
  };
};

export const usePartLogo = (props) => {
  let {
    specPart = {},
    specDesign,
    instance
  } = props;
  let { isMinBreakpointActive } = useBreakpoints();
  let {
    logoPath,
    logoSizelg,
    logoSizeSm,
    imgSizeSm,
    imgSizelg,
    logoType
  } = specPart || {};

  let logoSize = isMinBreakpointActive('lg') ? logoSizelg || 'xl' : logoSizeSm || 'md';
  let imgSize = 'full';
  if (logoType === 'profileImg') {
    imgSize = isMinBreakpointActive('lg') ? imgSizelg || 'xl' : imgSizeSm || 'md';
  }
  if (!logoPath) {
    logoPath = specDesign?.logo ? specDesign.logo[logoType] : null;
    logoPath = _.isArray(logoPath) ? logoPath[0] : logoPath;
    logoPath = getImageURL(logoPath, imgSize, 'profilesImages', instance);
  }

  return {
    logoType,
    logoPath,
    logoSize,
    imgSize
  }
};

const getContainerClasses = ({ logoStyle, logoSize }) => {
  let className = '';
  // style
  if (!logoStyle || logoStyle === 'transparent') {
    // nothing
  } 
  else if (logoStyle === 'square') {
    className += 'shadow-lg';
  }
  else if (logoStyle === 'square-rounded-sm') {
    className += 'border-4 border-brand-primary shadow-lg rounded-lg';
  }
  else if (logoStyle === 'square-rounded-md') {
    className += 'border-4 border-brand-primary shadow-lg rounded-2xl';
  }
  else if (logoStyle === 'square-rounded-lg') {
    className += 'border-4 border-brand-primary shadow-lg rounded-3xl';
  }
  else if (logoStyle === 'circle') {
    className += 'border-4 border-brand-primary shadow-lg rounded-full';
  }
  else if (logoStyle === 'circleThin') {
    className += 'border-2 border-brand-primary shadow rounded-full';
  }
  
  className += ' ';

  // size for circles
  if (logoStyle === 'circle' || logoStyle === 'circleThin') {
    // size
    if (logoSize === 'xxxs') {
      className += 'h-8 xl:h-0 w-8 xl:w-10';
    }
    else if (logoSize === 'xxs') {
      className += 'h-10 xl:h-14 w-10 xl:w-14';
    }
    else if (logoSize === 'xs') {
      className += 'h-16 xl:h-20 w-16 xl:w-20';
    }
    else if (logoSize === 'sm') {
      className += 'h-24 xl:h-28 w-24 xl:w-28';
    }
    else if (logoSize === 'md' ||  !logoSize) {
      className += 'h-32 xl:h-36 w-32 xl:w-36';
    }
    else if (logoSize === 'lg') {
      className += 'h-40 xl:h-44 w-40 xl:w-44';
    }
    else if (logoSize === 'xl') {
      className += 'h-48 xl:h-52 w-48 xl:w-52';
    }
    else if (logoSize === '2xl') {
      className += 'h-56 xl:h-60 w-56 xl:w-60';
    }
    else if (logoSize === '3xl') {
      className += 'h-64 xl:h-72 w-64 xl:w-72';
    }
    else if (logoSize === '4xl') {
      className += 'h-72 xl:h-80 w-72 xl:w-80';
    }
  }
  
  // size for svg and png
  else {     
    // size
    if (logoSize === 'xxxs') {
      className += 'h-6 xl:h-8';
    }
    else if (logoSize === 'xxs') {
      className += 'h-9 xl:h-12';
    }
    else if (logoSize === 'xs') {
      className += 'h-14 xl:h-16';
    }
    else if (logoSize === 'sm') {
      className += 'h-20 xl:h-24';
    }
    else if (logoSize === 'md' ||  !logoSize) {
      className += 'h-28 xl:h-32';
    }
    else if (logoSize === 'lg') {
      className += 'h-40 xl:h-44';
    }
    else if (logoSize === 'xl') {
      className += 'h-48 xl:h-52';
    }
    else if (logoSize === '2xl') {
      className += 'h-56 xl:h-60';
    }
    else if (logoSize === '3xl') {
      className += 'h-64 xl:h-72';
    }
    else if (logoSize === '4xl') {
      className += 'h-72 xl:h-80';
    }
  }
  
  return className;
};

export function PartLogo ({
  logoPath,
  logoSize,
  logoStyle,
  showSiteName,
  classes = {},
  specDesign
}) {
  const config = getConfig();
  let siteName = specDesign?.stringsVars?.brand?.siteName || config.siteName;
  return (
    <div className={`mx-auto flex flex-row items-center ${classes.logoContainer}`}>
      <img src={logoPath} alt={siteName} className={`object-cover ${classes.logoIcon} ${getContainerClasses({ logoStyle, logoSize })}`} />
      {showSiteName ? (
        <h2 className="ml-4 text-brand-dark-contrast font-bold font-brand-main text-lg xs:text-xl sm:text-2xl">{siteName}</h2>
      ) : null}
    </div>
  );
}

export function LayoutLogo (props) {
  // if (props.design === 'light') {
  //   return (
  //     <PartLogo {...props} />
  //   );
  // }
  return (
    <PartLogo {...props} />
  );
}