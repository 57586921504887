import { useModule } from "../../libs/ModuleContext";
import _ from 'lodash';
import { instanceParts } from "../../modules/instance/utilsInstance";

/*
Example:

  const RouteOfModule = usePartOfModule({
    type: 'routeAdmin',
    entitySlug,
    action,
    param: { 
      Model,
      config,
      isAllowed, 
      isMinBreakpointActive, 
      location, 
      navigate, 
      module, 
      action, 
      parsedParams
     }
  });
  <RouteOfModule />

  <PartOfModule
    type="main"
    action="AdminHomeBeforeLinksList"
  />

  <PartOfModule
    type="crud"
    action="ListHeaderExtraActions"
    entitySlug={entitySlug}
    // param={{}}
  />

  <PartOfModule
    type="codedDataType"
    fieldName={nameSlug}
    action="RenderShow"
    entitySlug={entitySlug}
    param={{ displayedValue }} 
  />

  <PartOfModule
    type="crud"
    action="UnderListItemHeader"
    entitySlug={entitySlug}
    param={{ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }} 
  />

  param = {
    // only for usePartOfModule on excecution mode, 
    mode: 'element' || 'function', // just return if not present

    // to find the component
    type,
    entitySlug,
    action,
    fieldName,

    // for component
    param
  }

*/
export default function PartOfModule(props) {
  const moduleLibs = useModule();
  if (props.entitySlug) {
    const { moduleEntitySlug } = instanceParts(props.entitySlug);
    let Component = getPartBySlug(props, moduleLibs.modules, moduleEntitySlug || props.entitySlug);
    return Component ? <Component {...{...props.param, ...moduleLibs}} /> : null;
  }
  else {
    let components = getPartMultiple(props, moduleLibs.modules);
    let ComponentsElements = components?.map((Component, index) => (
      <Component key={index} {...{...props.param, ...moduleLibs}} />
    ));
    return ComponentsElements;
  }
}

export const usePartOfModule = (param) => {
  const { modules, actionsByResource } = useModule();
  const { moduleEntitySlug } = instanceParts(param.entitySlug);

  let Part = param.multiple
                  ? getPartMultiple(param, modules) 
                  : getPartBySlug(param, modules, moduleEntitySlug || param.entitySlug);
  if (!Part) { return null; }
  if (param.mode === 'element') {
    return <Part {...{...param.param, modules, actionsByResource}} />;
  } 
  else if (param.mode === 'function') {
    return Part({ ...param.param, modules, actionsByResource });
  }
  return Part;
}

const getPartMultiple = ({ type, action, fieldName }, modules) => {
  let Parts = [];
  _.forEach(modules, ({ components, slug, routesPublic, routesAdmin }) => {
    let foundedPart;
    // components
    if (type === 'main') {
      foundedPart = _.get(components, ['main', action]);
    }
    if (foundedPart) {
      Parts.push(foundedPart);
    }
  });
  return Parts;
}

const getPartBySlug = ({ type, action, fieldName }, modules, entitySlug) => {
  let Part = null;
  _.forEach(modules, ({ components, routesPublic, routesAdmin }) => {
    let foundedPart;
    // routes
    if (type === 'routePublic') {
      foundedPart = _.get(routesPublic, [entitySlug, action]) || _.get(routesPublic, [entitySlug, 'Render']);
    }
    if (type === 'routeAdmin') {
      foundedPart = _.get(routesAdmin, [entitySlug, action]) || _.get(routesAdmin, [entitySlug, 'Render']);
    }
    // components
    if (type === 'main') {
      foundedPart = _.get(components, ['main', action]);
    }
    if (type === 'hook') {
      foundedPart = _.get(components, [entitySlug, action]);
    }
    if (type === 'crud') {
      foundedPart = _.get(components, [entitySlug, 'crud', action]);
    }
    if (type === 'codedDataType') {
      foundedPart = _.get(components, [entitySlug, 'codedDataTypes', fieldName, action]);
    }
    if (foundedPart) {
      Part = foundedPart;
      return false;
    }
  });
  return Part
}