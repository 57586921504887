import { createContext, useContext, useEffect, useState } from "react";
import _ from 'lodash';
import Model from "../../libs/ModelClass";
import { useSettings } from "../panel/useSettings";
import { getAmountNum } from "../../components/Form/utilsCurrency";


const cartContext = createContext();

export const useCart = () => {
  const context = useContext(cartContext);
  if (!context) throw new Error("There is no Cart provider");
  return context;
};

export function CartProvider(props) {
  const { children, instance } = props;
  const [ isDetailsOpen, setIsDetailsOpen ] = useState(false);
  const settings = useSettings();
  const entityMap = settings.get(instance || 'main', 'modules.cart')?.entities;
  const cartData = useCartData({ entityMap });

  const openDetailsDrawer = async () => {
    await cartData.doPopulateBags();
    setIsDetailsOpen(true);
  };

  return (
    <cartContext.Provider
      value={{
        isDetailsOpen, setIsDetailsOpen, openDetailsDrawer,
        ...cartData
      }}
    >
      {children}
    </cartContext.Provider>
  );
}

const bagId = 0; // main bag

export const useCartData = ({ entityMap }) => {
  // Instanciar Modelos de Carts
  // Tener lista de items
  // Tener lista de categorias 
  // Tener lista de tipos
  const CartModel = Model.extend(entityMap.carts.entitySlug);
  const ItemMainModel = Model.extend(entityMap.cartItems.entitySlug);
  const ItemVariantModel = Model.extend(entityMap.cartItemVariants.entitySlug);
  const CategoryModel = Model.extend(entityMap.cartItemCategories.entitySlug);

  const [ cartDoc, setCartDoc ] = useState();
  const [ items, setItems ] = useState([]);
  const [ itemsCategories, setItemsCategories ] = useState();
  const [ bags, setBags ] = useState([]);
  const [ totalPriceItems, setTotalPriceItems ] = useState(0);

  useEffect(() => {
    processItemsData();
  }, []);

  useEffect(() => {
    setBagsAndPopulate();
  }, [cartDoc]);

  useEffect(() => {
    calculateTotalItems(); // Calculate the initial total
  }, [bags]);

  const processItemsData = async () => {
    if ( localStorage.getItem('cartId') ) {
      let cartDoc = await CartModel.findById( localStorage.getItem('cartId') );
      if (cartDoc?.id) {
        setCartDoc(cartDoc);
      }
    }
    // get item categories
    if (!itemsCategories) {
      let docs = await CategoryModel.getAll();
      docs = docs.filter((doc) => {
        return !doc.data.deleted;
      });
      setItemsCategories(docs);
    }
  };

  const setBagsAndPopulate = async () => {
    let itemsInBag = cartDoc?.data?.itemsInBags?.length ? cartDoc.data.itemsInBags[0].bagItems : [];
    const newBags = [{
      itemsInBag
    }];
    setBags(newBags);
    await doPopulateBags(bagId, newBags);
    calculateTotalItems(newBags);
  };

  const doPopulateBags = async (bagId = 0, bagsToUse) => {
    const bag = getBagById(bagId, bagsToUse);
    for (const itemInBag of bag.itemsInBag) {
      if (!itemInBag.itemDoc) {
        itemInBag.itemDoc = await ItemVariantModel.findById(itemInBag.params.variantId);
      }
    }
  };  

  const fetchItems = async (query, options) => {
    let docs = await ItemMainModel.filterByAttributes(query, options);
    docs = docs.filter((doc) => {
      return doc.data.mainAvailable && !doc.data.deleted;
    });
    setItems(docs);
    return docs;
  };

  const calculateTotalItems = (bagsToUse) => {
    bagsToUse = bagsToUse || bags;
    let total = 0;
    bagsToUse.forEach((_, index) => {
      const bagTotal = getBagTotal(index, bagsToUse);
      total += bagTotal;
    });
    setTotalPriceItems(total);
    return total;
  };

  const getBagById = (bagId, bagsToUse) => {
    bagsToUse = bagsToUse || bags;
    return bagsToUse[bagId];
  };

  const getBagTotal = (bagId, bagsToUse) => {
    bagsToUse = bagsToUse || bags;
    const bag = getBagById(bagId, bagsToUse);
    
    if (bag) {
      // Calculate the total quantity of items in the bag
      const total = bag.itemsInBag.reduce((acc, itemInBag) => {
        // const itemDoc = items.find((item) => item.id === itemInBag.id);
        if (itemInBag?.itemDoc) {
          return acc + itemInBag.qty * getAmountNum(itemInBag.itemDoc.data.price);
        }
        return acc;
      }, 0);
      return total;
    }
  
    return 0;
  };  

  const getItemsOfBag = (bagId) => {
    const bag = getBagById(bagId);
  
    if (bag) {
      // Find the item with matching id
      const foundItems = bag.itemsInBag.map((itemInBag) => {
        return {
          ...itemInBag,
          itemDoc: items.find((item) => item.id === itemInBag.id)
        };
      });
  
      return foundItems; // Return the found itemInBag object
    }
  
    return null; // Bag with the specified bagId not found
  };  

  const isItemInBag = (bagId, itemId, params = {}) => {
    const bag = getBagById(bagId);
  
    if (bag) {
      // Find the item with matching id
      const foundItem = bag.itemsInBag.find((itemInBag) => {
        return itemInBag.id === itemId && _.isEqual((itemInBag?.params || {}), params);
      });
      return foundItem;
      // if (foundItem) {
      //   // Attach doc of main item
      //   const itemDoc = items.find((item) => item.id === foundItem.id);
      //   return { ...foundItem, itemDoc }; // Return the found itemInBag object
      // }
    }
  
    return null; // Bag with the specified bagId not found
  };  

  const setItemToBag = (bagId, itemId, qty, params, itemDoc) => {
    // Find the bag by its ID
    const bagIndex = bags.findIndex((bag, index) => index === bagId);

    if (bagIndex === -1) {
      console.error(`Bag with ID ${bagId} not found.`);
      return;
    }
    // Deep clone the bags array to avoid directly modifying the state
    const updatedBags = [...bags];
    
    // Check if the item is already in the bag
    const bag = updatedBags[bagIndex];
    const existingItem = bag.itemsInBag.find((itemInBag) => {
      return itemInBag.id === itemId && _.isEqual((itemInBag?.params || {}), params);
    });    
    
    if (qty === 0) {
      // Remove the item from the bag if qty is set to 0
      if (existingItem) {
        // If withExtraItem is provided, only remove items with the same id and params
        const updatedItemsInBag = bag.itemsInBag.filter((itemInBag) => {
          return !( itemInBag.id === itemId && _.isEqual((itemInBag?.params || {}), params) );
        });
    
        bag.itemsInBag = updatedItemsInBag;
      }
    }
    // If qty is not 0
    else {
      // add the item quantity
      if (!existingItem) {
        bag.itemsInBag.push({
          id: itemId,
          qty,
          params,
          itemDoc
        });
      }
      // update the item quantity
      else {
        const updatedItemsInBag = bag.itemsInBag.map((itemInBag) => {
          if (itemInBag.id === itemId && _.isEqual((itemInBag?.params || {}), params)) {
            // Update the quantity
            return {
              ...itemInBag,
              qty,
              params
            };
          }
          return itemInBag;
        });
        bag.itemsInBag = updatedItemsInBag;
      }
    }
    // Update the bags state with the updated bag
    updatedBags[bagIndex] = bag;
    setBags(updatedBags);
    return saveCurrentCart(updatedBags);
  };

  const saveCurrentCart = async (bagsToUse) => {
    if (cartDoc) {
      bagsToUse = bagsToUse || bags;
      cartDoc.data.itemsInBags = sanitizeItemsBags(bagsToUse);
      cartDoc.data.total = calculateTotalItems(bagsToUse);
      await cartDoc.save();
    }
    else {
      await saveNewCart();
    }
  };

  const saveNewCart = async (bagsToUse) => {
    bagsToUse = bagsToUse || bags;
    const newCart = await CartModel.create({
      itemsInBags: sanitizeItemsBags(bagsToUse),
      total: calculateTotalItems(bagsToUse)
    });
    setCartDoc(newCart);
    localStorage.setItem('cartId', newCart.id);
    return newCart;
  };

  const sanitizeItemsBags = (bagsToUse) => {
    bagsToUse = bagsToUse || bags;
    const itemsInBags = [];
    // sanitize for saving
    bagsToUse.forEach((bag, index) => {
      if (bag.itemsInBag.length) {
        const bagItems = [];
        bag.itemsInBag.forEach(({ id, qty, params, itemDoc }) => {
          // const itemDoc = items.find((item) => item.id === id);
          bagItems.push({ 
            id,
            qty,
            params,
            price: getAmountNum(itemDoc.data.price)
          });
        });
        itemsInBags.push({
          bagItems: bagItems
        });
      }
    });
    return itemsInBags;
  }

  const closeCart = () => {
    localStorage.removeItem('cartId');
    setCartDoc();
    setBags([]);
  };
  
  return { 
    entityMap, CartModel, ItemMainModel, ItemVariantModel, CategoryModel,
    fetchItems, items, itemsCategories,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    doPopulateBags,
    totalPriceItems
  };
}