import { useState } from "react";
import ModalAlert from "../../components/ui/ModalAlert";
import toast from "react-hot-toast";
import { useModule } from "../../libs/ModuleContext";


export const useDeleteDoc = (props) => {
  let {
    selectedDoc, setSelectedDoc, showToast, entitySlug
  } = props;
  const { fireEventReducer } = useModule();
  const [ showDeleteConfirmation, setShowDeleteConfirmation ] = useState(false);

  const DeleteModal = ({ onDelete }) => (
    showDeleteConfirmation && (
      <ModalAlert
        text="¿Estás seguro de que deseas eliminar este elemento?"
        onConfirm={confirmedDelete(onDelete)}
        onCancel={cancelDelete}
        confirmClass="bg-brand-danger text-white"
      />
    )
  );

  const handleDelete = (doc) => {
    setShowDeleteConfirmation(true);
    setSelectedDoc(doc);
  };

  const confirmedDelete = (onDelete) => async () => {
    try {
      await fireEventReducer('beforeDelete', { doc: selectedDoc, entitySlug });
      onDelete && (await onDelete(selectedDoc));
      await selectedDoc.delete();
      setShowDeleteConfirmation(false);
      setSelectedDoc(null);
      showToast && toast.error('Se ha eliminado el documento');
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setSelectedDoc(null);
  };

  return {
    DeleteModal,
    handleDelete,
    confirmedDelete,
    cancelDelete
  };
};