import _ from 'lodash';
import React, { lazy, Suspense } from 'react';


const NotionShow = lazy(() => import('./NotionShow'));

const NotionShowLazy = (props) => {
  return (
    <Suspense fallback={<div>Cargando...</div>}>
      <NotionShow {...props} />
    </Suspense>
  );
};

export default NotionShowLazy;
