import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import VisibilitySensor from 'react-visibility-sensor';
import { FaCartPlus } from 'react-icons/fa';
import { useEntityFullBySlug } from '../entity/Entity';
import { QtyButtons } from './QtyButtons';
import { CartProvider, useCart } from './useCartData';
import { withProvider, trimSlash, compileStringTemplate, getImageURL, removeNullUndefinedAndNan } from '../../libs/utils';
import { EntityDocListView } from '../../components/EntityDocView';
import config from '../../config';
import { CurrentCartDetailsDrawer } from './CurrentCartDetails';
import {
  gridOutline,
  createOutline,
  documentTextOutline,
  closeOutline
} from 'ionicons/icons';
import SimpleBar from 'simplebar-react';
import { Form } from 'react-final-form';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import BadgeLoading from '../../components/ui/BadgeLoading';
import { urlPrefix } from '../instance/utilsInstance';
import { cartsPathsFields, imgProportionField } from './BlockProductGridWithFilters';
import { whatsappObjectFields } from '../blockStack/blocks/BlockPageFooterBContactsLinksFixedCta';
import { HorizontalCardPicker } from '../../components/ui/HorizontalCardPicker';
import { inputClasses } from '../../components/EntityTaxonomyForm';
import ObjectForm from '../entity/ObjectForm';
import { FormChangeSubscriber } from '../../components/Form';


const bagId = 0;

export const askInmediateDeliveryField = {
  type: 'object',
  name: 'Whatsapp: Entrega inmediata',
  descriptionText: 'Mensaje preguntando disponibilidad',
  fields: whatsappObjectFields
};

export default function () {
  return {
    type: 'productView',
    variant: 'expanded',
    template: ProductView,
    params: {
      ...cartsPathsFields,
      'imgProportion': imgProportionField,
      'askInmediateDelivery': askInmediateDeliveryField,
      'sendCartLink': {
        type: 'object',
        name: 'Whatsapp: Enlace del pedido',
        descriptionText: 'Mensaje avisando sobre nuevo pedido',
        fields: whatsappObjectFields
      }
    }
  };
};

const Sidebar = (props) => {
  let {
    bagId,
    mainItemId,
    goToItemByParams,
    currentVariantItemDoc,
    categoryFieldsById,
    variantsDocs,
    selectVariant,
    // variantParams,
    classes,
    askInmediateDelivery,
    specDesign,
    instance,
    viewPath,
    ViewData,
    variantsEntitySlug,
    showCurrentCart,
    dismissModal,
    imgProportion
  } = props;

  const {
    isDetailsOpen, setIsDetailsOpen, openDetailsDrawer,
    entityMap, CartModel, ItemMainModel, ItemVariantModel, CategoryModel, TypeModel, 
    fetchItems, items, itemsTypes, itemsCategories,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    doPopulateBags,
    totalPriceItems
  } = useCart();

  const [ variantParams, setVariantParams ] = useState(props.variantParams || {});
  const itemInBag = isItemInBag(bagId, mainItemId, variantParams);
  let categoryDoc = categoryFieldsById[currentVariantItemDoc?.data['itemCategory_fieldsId']];


  useEffect(() => {    
    if (currentVariantItemDoc.id !== variantParams.variantId) {
      setVariantParams({
        ...variantParams,
        variantId: currentVariantItemDoc.id
      });
    }
  }, [currentVariantItemDoc]);

  const openIndex = useMemo(() => {
    return variantsDocs?.findIndex((variantItem) => variantItem.id === currentVariantItemDoc?.id);
  }, [variantsDocs, currentVariantItemDoc]);

  const {
    taxonomyTypesAsForm, taxonomyTypesAsView, taxonomyTypesAsViewOutstanding, fieldsRequired
  } = useMemo(() => {
    let taxonomyTypesAsForm = [];
    let taxonomyTypesAsView = [];
    let taxonomyTypesAsViewOutstanding = [];
    let fieldsRequired = [];

    categoryDoc?.data.fields.forEach(doc => {
      if (doc.data.nameSlug === 'variantImgs') { return; }
      if (doc.data.isFormToCart) {
        let docToPush = doc.copy();
        if (docToPush.data.required) {
          fieldsRequired.push(docToPush.data.nameSlug);
        }
        // adjust specials dataTypes
        if (docToPush.data.type === 'multiselect') {
          // show only options preselected by variant
          docToPush.data.param.options = docToPush.data.param.options
            .filter(option => 
              currentVariantItemDoc.data[docToPush.data.nameSlug]
                .includes(option.value)
            );
        }
        taxonomyTypesAsForm.push(docToPush);
      } else {
        if (doc.data.outstanding) {
          taxonomyTypesAsViewOutstanding.push(doc);
        }
        else {
          taxonomyTypesAsView.push(doc);
        }
      }
    });

    return {
      taxonomyTypesAsForm, taxonomyTypesAsView, taxonomyTypesAsViewOutstanding, fieldsRequired
    }
  }, [currentVariantItemDoc, categoryDoc]);
  
  const [ hasErrors, setHasErrors ] = useState(true);

  let variantCardWidth, variantCardHeight;
  if (imgProportion === 'vertical-square') {
    variantCardWidth = 70;
    variantCardHeight = 70;
  }
  else if (imgProportion === 'vertical-wide') {
    variantCardWidth = 70;
    variantCardHeight = 160;
  }

  const prevIndex = useCallback(() => {
    if (openIndex > 0) {
      selectVariant(variantsDocs[openIndex - 1].id);
    } else {
      selectVariant(variantsDocs[variantsDocs.length - 1].id); // Si está en el primer elemento, ir al último
    }
  });
  
  const nextIndex = useCallback(() => {
    if (openIndex < variantsDocs.length - 1) {
      selectVariant(variantsDocs[openIndex + 1].id);
    } else {
      selectVariant(variantsDocs[0].id); // Si está en el último elemento, ir al primero
    }
  });

  const openDetailsDrawerAndCloseModal = () => {
    openDetailsDrawer();
    dismissModal();
  };

  const VariantCard = ({ item, index, openIndex, onSelect }) => (
    <div key={index} className="p-1" style={{
      width: variantCardWidth,
      height: variantCardHeight
    }}>
      <div 
      onClick={() => { selectVariant(item.id); }}
      className={`border rounded-md cursor-pointer
        ${index === openIndex ? 'border-brand-dark opacity-100' : 'border-brand-light opacity-50'} transition-colors duration-300
        h-full w-full
      `}
      >
        <img src={item.data?.variantImgs?.length && getImageURL(item.data.variantImgs[0], 'xs')}
        className="rounded h-full w-full object-cover" /> {/* image xs */}
      </div>
    </div>
  );

  const checkValidateForm = (values, errors = {}) => {
    return errors;
  };

  const validateParamsForm = (values) => {
    const errors = {};
    // all required fields
    fieldsRequired?.forEach(field => {
      if (!values[field]) {
        errors[field] = ' ';
      }
    });
    checkValidateForm(values, errors);
    setHasErrors(Object.keys(errors).length > 0);
    return errors;
  };
  
  let whatsappNumber = askInmediateDelivery?.useBrandPhone ? specDesign?.stringsVars?.brand?.whatsappNumber : askInmediateDelivery?.phoneNumber;
  let variantPath = `${urlPrefix(instance, `${trimSlash(viewPath)}/`)}/#/main/${mainItemId}/variant/${currentVariantItemDoc?.id}`;
  let askInstantDeliveryMessage = compileStringTemplate(askInmediateDelivery?.phoneMessage, {
    brand: specDesign?.stringsVars.brand,
    item: { 
      name: currentVariantItemDoc?.data?.name,
      price: currentVariantItemDoc?.data?.price,
      link: `${config.getURLprefix()}${variantPath}`
    }
  });

  return (
    <div className="space-y-8 lg:mx-4 pb-[150px] lg:pb-0">
      <div className="">
        <ViewData field="name" classes={{ fieldLabel: "hidden", fieldContainer: 'text-3xl font-semibold mt-2' }} />
        <ViewData field="price" classes={{ fieldLabel: "hidden", fieldContainer: 'text-base text-gray-500 mt-1 mb-1' }} />  
        {/* 
          isFormToCart as view not outstanding
        */}
        {categoryDoc ? (
          <EntityDocListView
            doc={currentVariantItemDoc}
            instance={instance}
            entitySlug={variantsEntitySlug}
            taxonomyTypesDocList={taxonomyTypesAsView}
            viewType="show"
            classes={classes}
            render={({ doc, ViewData, mainAttr, mainImgAttr, classes }) => (
              taxonomyTypesAsView.map((taxonomyType) => (
                <ViewData
                  key={taxonomyType.data.nameSlug}
                  field={taxonomyType.data.nameSlug}
                />
              ))
            )}
          />
        ) : null}
      </div>

      {/* 
        variants selector
      */}
      {variantsDocs ? (
        <div>
          <label className={inputClasses.fieldLabel}>
            Variantes
          </label>                          
          <HorizontalCardPicker
            items={variantsDocs}
            Render={VariantCard}
            itemWidth={variantCardWidth}
            itemHeight={variantCardHeight}
            openIndex={openIndex}
            prevIndex={prevIndex}
            nextIndex={nextIndex}
            className="lg:-mr-4"
          />
        </div>
      ) : null}

      {/* 
        categoryFieldsDoc
      */}

      {/* 
        isFormToCart as view outstanding
      */}
      {categoryDoc ? (
        <EntityDocListView
          doc={currentVariantItemDoc}
          instance={instance}
          entitySlug={variantsEntitySlug}
          taxonomyTypesDocList={taxonomyTypesAsViewOutstanding}
          viewType="show"
          classes={classes}
          render={({ doc, ViewData, mainAttr, mainImgAttr, classes }) => (
            taxonomyTypesAsViewOutstanding.map((taxonomyType) => (
              <ViewData
                key={taxonomyType.data.nameSlug}
                field={taxonomyType.data.nameSlug}
              />
            ))
          )}
        />
      ) : null}

      {/* 
        isFormToCart as form
      */}
      {taxonomyTypesAsForm.length ? (
        <Form
          onSubmit={() => {}}
          initialValues={variantParams}
          validate={validateParamsForm}
          render={(formProps) => {
            return (
              <form onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}>
                <FormChangeSubscriber onFormChange={setVariantParams} />
                <ObjectForm
                  {...formProps}
                  instance={instance}
                  entitySlug={variantsEntitySlug}
                  taxonomyTypesDocList={taxonomyTypesAsForm}
                  fieldsRequired={fieldsRequired}
                  isFilter={true}
                /> 
              </form>
            );
          }}
        />
      ) : null}

      <div className="fixed z-[100] bottom-0 right-0 w-full lg:w-1/3 xl:max-w-sm p-4 lg:p-2  !mt-0">
        <div className="z-[120] space-y-8 bg-brand-dark rounded-md shadow-2xl">
          {(
            // si no hay variantParams (además de variantId) && hasErrors
            !(_.size(removeNullUndefinedAndNan(variantParams)) > 1)
            && hasErrors
          ) ? (<>
            <div className="p-2 grid grid-flow-row grid-cols-2 gap-2 items-center content-center">
              <div className="text-brand-dark-contrast text-center">
                Seleccioná 👆🏻
              </div>
              <IonButton 
                disabled={true}
                size="medium"
                color="light"
                fill="solid"
              >
                <FaCartPlus className="mr-2 h-5 w-5 inline"/>
                Agregar
              </IonButton>
            </div>
          </>) : (
            <div className="flex flex-row flex-wrap p-3 text-brand-dark-contrast">
              <QtyButtons {...{
                // si no corresponden las cantidades y itemInBag
                itemDoc: currentVariantItemDoc,
                itemId: mainItemId,
                params: variantParams,
                itemInBag, bagId, 
                price: currentVariantItemDoc.data.price,
                whatsappNumber,
                askInstantDeliveryMessage,
                classes: {
                  ...classes,
                  fieldContainer: 'p-2',
                  fieldLabel: 'text-brand-dark-contrast',
                  actionContainer: 'min-w-full p-2'
                }
              }} />
            </div>
          )}
        </div>
        
        {getItemsOfBag(bagId)?.length ? (
          <IonButton
            onClick={openDetailsDrawerAndCloseModal} 
            className="shaked-cascade-2 py-2 block"
            color="primary"
            fill="solid"
            size="medium"
          >
            <IonIcon icon={documentTextOutline} slot="start" size="small"></IonIcon>
            Detalles del pedido
          </IonButton>
        ) : null}

        <div className="lg:hidden -z-10 -mx-4 bottom-0 absolute h-full w-full bg-gradient-to-t from-white to-transparent"></div>
      </div>

      {showCurrentCart ? (
        <CurrentCartDetailsDrawer goToItemByParams={goToItemByParams} />
      ) : null}
    </div>
  );
};

export const ProductView = withProvider(ProductViewRaw, CartProvider);

export function ProductViewRaw(props) {
  let {
    specDesign,
    instance,
    history,
    match,
    data,
    parsedParams,
    // entitySlug,
    // action,
    // config,
    userAuth,
    isAllowed,
    Model,
    isMinBreakpointActive,
    location,
    // block params
    mainItemId, 
    variantItemId,
    variantParams,
    filterMenuSlug,
    viewPath,
    filterPath,
    cartPath,
    showFilters,
    imgProportion = 'vertical-square',
    sendCartLink,
    // show
    design = 'block',
    showCurrentCart = true,
    showGoToFilters = true,
    // data
    categoryFieldsById,
    // callbacks
    goToItemByParams,
    dismissModal,
    classes = {}
  } = props;

  const { 
    isDetailsOpen, setIsDetailsOpen, openDetailsDrawer,
    entityMap, CartModel, ItemMainModel, ItemVariantModel, CategoryModel, TypeModel, 
    fetchItems, items, itemsTypes, itemsCategories,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    doPopulateBags,
    totalPriceItems
  } = useCart();
  const variantsEntitySlug = entityMap.cartItemVariants.entitySlug;
  const entitySpects = useEntityFullBySlug({ entitySlug: variantsEntitySlug });
  mainItemId = mainItemId || parsedParams.main;
  variantItemId = variantItemId || parsedParams.variant;
  variantParams = variantParams || parsedParams.params;
  const [ variantsDocs, setVariantsDocs ] = useState();
  const [ currentVariantItemDoc, setCurrentVariantItemDoc ] = useState();
  
  const simpleBarRef = useRef(null);

  const actionLinkClasses = 'flex flex-row items-center gap-2 text-left text-sm text-brand-primary font-semibold hover:underline';
  const editRoute = urlPrefix(instance, `/a/entity/${ItemMainModel.collectionName}/${currentVariantItemDoc?.data?.mainItemId}/form`);
  
  // const {
  //   // analytics
  //   viewItem
  // } = useData();

  useEffect(() => {
    fetchData();
  }, [mainItemId, variantItemId]);
  
  const fetchData = async () => {
    const VariantsModel = Model.extend(variantsEntitySlug);
    let variantsDocs = await VariantsModel.filterByAttributes({
      mainItemId,
      mainAvailable: 'true',
      variantAvailable: 'true',
      deleted: 'false'
    });
    setVariantsDocs(variantsDocs);
    let currentVariantItemDoc;
    if (variantItemId === 'first') {
      currentVariantItemDoc = variantsDocs[0];
    } else {
      currentVariantItemDoc = variantsDocs.find((variantItem) => variantItem.id === variantItemId);
    }
    setCurrentVariantItemDoc(currentVariantItemDoc);
  };

  const goToItemByParamsToUse = goToItemByParams || (({ mainId, variantId, params }) => {
    if (mainId === mainItemId) {
      selectVariant(variantId, params);
    } else {
      history.push(`${urlPrefix(instance, `${trimSlash(viewPath)}/`)}/#/main/${mainId}/variant/${variantId}`);
    }
  });

  const selectVariant = (variantId, params = {}) => {
    const newVariant = variantsDocs.find((variantItem) => variantItem.id === variantId);
    setCurrentVariantItemDoc(newVariant);
    goToItemByParamsToUse({ mainId: mainItemId, variantId, params });
  };
  
  const onSensor = (_isVisible) => {
    // if (_isVisible && mainItemDoc?.id) {
    //   viewItem(item.id); // analytics
    // }
  };

  const EditLink = () => (isAllowed(variantsEntitySlug, ['update']) ? (
    <Link className={actionLinkClasses} to={editRoute}>
      <IonIcon icon={createOutline} className="text-xl text-brand-primary"></IonIcon>
      Editar
    </Link>
  ) : null);

  const EditBtn = ({ slot, color, fill, size }) => (
    isAllowed(variantsEntitySlug, ['update']) ? (
      <IonButton href={editRoute} {...{ slot, color, fill, size }} >
        <IonIcon slot="start" icon={createOutline} className="text-xl" color={color}></IonIcon>
        Editar
      </IonButton>
    ) : null
  );

  const SidebarMemo = useMemo(() => {
    return ({ ViewData, classes }) => {
      return (
        <Sidebar {...props} {...{
          bagId,
          mainItemId,
          goToItemByParams,
          currentVariantItemDoc,
          categoryFieldsById,
          variantsDocs,
          selectVariant,
          variantParams,
          classes,
          // askInmediateDelivery,
          specDesign,
          instance,
          viewPath,
          ViewData,
          variantsEntitySlug,
          showCurrentCart,
          dismissModal,
          imgProportion
        }} />
      );
    };
  }, [mainItemId, variantItemId, currentVariantItemDoc]);

  const Content = () => (<>
    <SimpleBar
      ref={simpleBarRef}
      style={{ maxHeight: '100%' }}
    >
      <div className={`my-4 md:my-6 px-4 container-width-wide ${classes.blockContainer}`}>
        {design === 'block' && showGoToFilters ? (<>
          <div className="flex flex-row justify-between">
            <Link to={urlPrefix(instance, filterPath)} className={actionLinkClasses}>
              <IonIcon icon={gridOutline} className="text-xl text-brand-primary"></IonIcon>
              Ver más opciones
            </Link>

            <EditLink />
          </div>

          <hr className="border-brand-primary my-4 md:my-6" />
        </>) : null}

        {!currentVariantItemDoc ? (
          <div className="py-12 flex place-content-center content-center items-center font-brand-main">
            <BadgeLoading className="text-brand-dark" />
          </div>
        ) : (<>
          <EntityDocListView
            {...entitySpects}
            outstandingOnly={false}
            doc={currentVariantItemDoc}
            // refreshers={[ itemInBag ]}
            classes={{
              ...inputClasses
            }}
            render={({ ViewData, mainAttr, mainImgAttr, classes }) => (<>
              <VisibilitySensor minTopValue={0} onChange={onSensor}>
                <div className=""> 

                  <div className="lg:hidden mb-4">
                    <ViewData field="name" classes={{ fieldContainer: 'text-2xl font-bold' }} />
                    <ViewData field="price" classes={{ fieldContainer: 'text-lg text-gray-500' }} />
                  </div>

                  <div className="lg:flex pb-32 lg:pb-0">
                    {/* content */}
                    <div className="lg:w-2/3">
                      {/* images */}
                      <ViewData field="variantImgs" classes={{ fieldLabel: "hidden" }} />
                      <ViewData field="description" classes={{ fieldContainer: "mt-8" }} />
                    </div>

                    {/* sidebar */}
                    <div className="my-6 lg:my-0 lg:w-1/3 place-content-start">
                      <SidebarMemo ViewData={ViewData} classes={classes} />
                    </div>
                  </div>
                  
                </div>
              </VisibilitySensor>
            </>)}
          />
        </>)}
      </div>
    </SimpleBar>
  </>);

  if (design === 'block') {
    return <Content />
  }

  return (
    <IonModal 
      isOpen={!!(mainItemId && variantItemId)}
      onDidDismiss={dismissModal}
      className="wide"
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Detalles
          </IonTitle>
          
          <IonButtons slot="end">
            <EditBtn color="dark" fill="clear" size="small" />

            <IonButton onClick={dismissModal} shape="round" color="dark" fill="clear" size="small">
              <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        {mainItemId && variantItemId ? (
          <Content />
        ) : null}
      </IonContent>
    </IonModal>
  );
}

export const ProductViewRawMemo = memo(ProductViewRaw, (prevProps, nextProps) => {
  return prevProps.mainItemId === nextProps.mainItemId 
      && prevProps.variantItemId === nextProps.variantItemId;
});

export const ProductViewMemo = memo(ProductView, (prevProps, nextProps) => {
  return prevProps.mainItemId === nextProps.mainItemId 
      && prevProps.variantItemId === nextProps.variantItemId;
});
