import _ from 'lodash';
import { usePanel } from '../panel/usePanel';
import { InstanceInlineSelector } from './InstanceSelector';
import { IonItem, isPlatform } from "@ionic/react";
import { RouteInstanceBrandEdit } from './RouteInstanceBrandEdit';
import dataTypeFonts from './dataTypeFonts';
import dataTypeColorsPalette from './dataTypeColorsPalette';
import dataTypeBlockStack from './dataTypeBlockStack';
import { RouteInstanceConfig } from './RouteInstanceConfig';
import dataTypeLogo from './dataTypeLogo';
import { RouteInstanceModules } from './RouteInstanceModules';
import { RouteInstanceBilling } from './RouteInstanceBilling';


const LayoutAdminHeaderTitle = ({}) => {
  const { instancesDocs, selectedInstance, setSelectedInstance } = usePanel();

  return (
    <div className="lg:hidden flex content-center" style={{ height: isPlatform('ios') ? '44px' : '56px' }}>
      <InstanceInlineSelector
        {...{ instancesDocs, selectedInstance, setSelectedInstance }}
        design="labelOnly"
        buttonColor="primary"
        buttonFill="solid"
        iconColor="light"
        classes={{ 
          fieldContainer: 'text-white content-center'
        }}
        popoverAlignment="center"
      />
    </div>
  );
};

const SideMenuHeader = () => {
  const { instancesDocs, selectedInstance, setSelectedInstance } = usePanel();

  return (
    <IonItem className="py-1" lines="none" color="dark">
      <InstanceInlineSelector
        {...{ instancesDocs, selectedInstance, setSelectedInstance }}
        buttonColor="primary"
        buttonFill="solid"
        iconColor="light"
        classes={{ fieldContainer: 'flex-row-reverse place-content-start gap-1.5' }}
      />
    </IonItem>
  );
};

export default function () {
  let listActionsToAdd = ['instance:read', 'instance:list', 'instance:create', 'instance:update'];
  return {
    name: 'Instancias',
    slug: 'instances',
    scope: 'global',
    bundles: ['core'],
    entities: [
      'instances',
      'instancesModules',
      'instancesBilling',
      'instancesBrands',
      'instancesColorsPalettes',
      'instancesLayoutBlocks',
      'instancesMainParts',
      'instancesMainStrings'
    ],
    permissions: [
      { slug: 'instances', label: 'Instancias', ownerCrud: true, actionsToAdd: ['config', 'instance:config'] },
      { slug: 'instancesModules', label: 'Paquetes de módulos', actions: ['update', 'instance:update'] },
      { slug: 'instancesBilling', label: 'Pagos del servicio', actions: ['update', 'instance:update'] },
      { slug: 'instancesBrands', label: 'Branding de Instancias', actionsToAdd: ['instance:read', 'instance:create', 'instance:update'] },
      { slug: 'instancesColorsPalettes', label: 'Paletas de colores de Instancias', actionsToAdd: listActionsToAdd },
      { slug: 'instancesLayoutBlocks', label: 'Bloques reutilizables del Layout de Instancias', actionsToAdd: listActionsToAdd },
      { slug: 'instancesMainParts', label: 'Partes principales de Instancias', actionsToAdd: listActionsToAdd },
      { slug: 'instancesMainStrings', label: 'Textos principales de Instancias', actionsToAdd: listActionsToAdd }
    ],
    routesAdmin: {
      'instances': {
        'config': {
          permission: { resource: 'instances', action: ['config', 'instance:config'] },
          Content: RouteInstanceConfig
        }
      },
      'instancesBrands': {
        'update': {
          permission: { resource: 'instancesBrands', action: ['update', 'instance:update'] },
          Content: RouteInstanceBrandEdit
        }
      },
      'instancesModules': {
        'update': {
          permission: { resource: 'instancesModules', action: ['update', 'instance:update'] },
          Content: RouteInstanceModules
        }
      },
      'instancesBilling': {
        'update': {
          permission: { resource: 'instancesBilling', action: ['update', 'instance:update'] },
          Content: RouteInstanceBilling
        }
      },
    },
    components: {
      main: {
        SideMenuHeader,
        LayoutAdminHeaderTitle
      },
      'instancesColorsPalettes': {
        codedDataTypes: {
          colorsPalette: dataTypeColorsPalette
        }
      },
      'instancesLayoutBlocks': {
        codedDataTypes: {
          menuLogo: dataTypeLogo,
          blocksHeader: dataTypeBlockStack,
          blocksFooter: dataTypeBlockStack
        }
      },
      'instancesMainParts': {
        codedDataTypes: {
          mainFont: dataTypeFonts,
          secondaryFont: dataTypeFonts,
          // parts: dataTypeMainParts
        }
      }
    }
  };
};