import { useModule } from './ModuleContext';
import { getEntityFullBySlug } from '../modules/entity/Entity';
import Model from './ModelClass';


export default class ModuleModel extends Model {

  static extend ( entitySlug, moduleLibs ) {
    const ExtendedModel = super.extend( entitySlug );
    ExtendedModel.moduleLibs = moduleLibs;
    return ExtendedModel;
  }

  static async wrappedSaveFn(fn, data) {
    const entitySlug = this.collectionName;
    const { taxonomyTypesDocList } = await getEntityFullBySlug({ entitySlug, includeFilters: false });
    const modifiedDataValues = await this.moduleLibs.fireEventReducer('beforeSave', { entitySlug, taxonomyTypesDocList }, data);
    const savedItem = await super[fn](modifiedDataValues);
    await this.moduleLibs.fireEventReducer('afterSave', { entitySlug, taxonomyTypesDocList }, savedItem);
      if (data.id === 'new') {
        // force save again to ensure that the id is added as a field
        await savedItem.save();
      }
    return savedItem;
  }

  static async create(data) {
    return await this.wrappedSaveFn('create', data);
  }

  static async createOrUpdate(data) {
    return await this.wrappedSaveFn('createOrUpdate', data);
  }
  
  // static async createMany(data) {}
  // static async createWithId(data, id) {}
  // static async saveSort() {}

  // async save() {}
  // async delete() {}
  // async deleteField(fieldName) {}
}


export const useModuleModels = () => {
  const moduleLibs = useModule();

  return {
    ...moduleLibs,

    ModuleModel: (param) => {
      if (param instanceof Model) {
        param.moduleLibs = moduleLibs;
        return param;
      }
      return ModuleModel.extend( param, moduleLibs );
    }
  };
}