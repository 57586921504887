import _ from 'lodash';
import { usePartOfModule } from "../../components/Module/PartOfModule";
import { RouteEntityModules } from './RouteEntityModules';


export const useModuleSettings = ( entitySlug ) => {
  const moduleSettingsFn = usePartOfModule({
    type: 'crud',
    entitySlug,
    action: 'settings',
    param: {}
  });
  const moduleSettings = _.defaults((moduleSettingsFn && moduleSettingsFn()) || {}, {
    title: false,
    reorder: false,
    showBtnAdd: true,
    showBtnShow: true,
    showBtnDelete: true,
    showBtnUpdate: true,
    showBtnMove: true,
    // addButtonPermissionAction,
    // addButtonLabel,    
    redirectOnSave: true
  });

  return {
    moduleSettings
  };
}

export default function ({}) {
  return {
    name: 'Entity',
    slug: 'entity',
    scope: 'global',
    bundles: ['core'],
    entities: [
      'entities',
      'taxonomyTypes',
      'filterMenu'
    ],
    permissions: [
      { slug: 'entities', label: 'Entities', actionsToAdd: ['export'] },
      { slug: 'taxonomyTypes', label: 'TaxonomyTypes' },
      { slug: 'filterMenu', label: 'FilterMenu' }
    ],
    routesAdmin: {
      'entities': {
        'modules': {
          permission: { resource: 'entities', action: 'list' },
          Content: RouteEntityModules
        }
      }
    }
  };
};