import { Field } from 'react-final-form';
import _ from 'lodash';
import { ShowFieldHelp } from './formParts';
import RawInputRelatedListMaterials from './RawInputRelatedListMaterials';


const FormFieldRelatedList = (props) => {
  let {
    type = 'materials', // tasks
    values,
    entitySlug,
    taxonomyType,
    name,
    title,
    placeholder,
    fieldsRequired = null,
    showLabel = true,
    validate = null,
    disabled = false,
    UnderInput = null,
    onChange = null,
  } = props;

  const validateField = (value) => {
    let error;
    if (!error && validate && typeof validate === 'function') {
      error = validate(value);
    }
    return error;
  };
  
  return (
    <div className={`${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={(args) => validateField(args)}>
        {({ input, meta }) => (<div>
          {showLabel ? (
            <div className={props?.classes?.fieldLabel}>
              <span
                className={`font-semibold mr-3 ${
                  meta.touched && meta.error ? 'text-red-600' : ''
                }`}
              >
                {title}
                {(meta.touched && meta.error) || (_.includes(fieldsRequired, name) && !input.value)
                  ? '*'
                  : ''}
              </span>
            </div>
          ) : ''}
          {type === 'materials' ? (
            <RawInputRelatedListMaterials
              {...props}
              input={input}
            />
          ) : null}
          {UnderInput && (<UnderInput input={input} />)}
          {/* error */}
          <ShowFieldHelp {...props} name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
        </div>)}
      </Field>
    </div>
  );
};

export default FormFieldRelatedList;