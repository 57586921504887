import _ from 'lodash';
import { getGeofire } from '../../firebase';
import { processRenderProps } from './dataTypeUtils';
import {
  FormFieldCheckbox,
  FormFieldGPS,
  FormFieldSelect
} from '../Form';
import {
  // GPS
  FilterRenderGPS,
  FilterCreatorGPS,
} from '../Filters';

// distance in km
export const labelelDistance = (distance) => {
  if (distance < 1) {
    return parseInt(distance * 1000) + ' m';
  }
  return distance.toFixed(2) + ' km';
};

export const getDistanceBetween = (locationObj1, locationObj2, labeled) => {
  const location1 = [locationObj1.lat, locationObj1.lng];
  const location2 = [locationObj2.lat, locationObj2.lng];
  const distance = getGeofire().distanceBetween(location1, location2);
  if (labeled) {
    return labelelDistance(distance);
  }
  return distance;
}

// based on https://www.movable-type.co.uk/scripts/geohash.html
export const accuracyLevels = [
  { value: 3, label: '156 km, país o región grande', distanceText: '156 km', distanceMeters: 156000, level: 'País o región grande' },        
  { value: 4, label: '19.5 km, ciudad grande', distanceText: '19.5 km', distanceMeters: 19500, level: 'Ciudad grande' },      
  { value: 5, label: '4.89 km, ciudad o distrito', distanceText: '4.89 km', distanceMeters: 4890, level: 'Ciudad o distrito' },      
  { value: 6, label: '0.61 km, vecindario, calle', distanceText: '0.61 km', distanceMeters: 610, level: 'Vecindario, calle' },      
  { value: 7, label: '153 m, calle individual, edificios grandes', distanceText: '153 m', distanceMeters: 153, level: 'Calle individual, edificios grandes' },      
  { value: 8, label: '19.1 m, árboles individuales, casas', distanceText: '19.1 m', distanceMeters: 19, level: 'Árboles individuales, casas' },      
];

export default {
  Render(props) {
    const {
      onChange,
      fieldsRequired
    } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);

    return (
      <FormFieldGPS
        {...props}
        {...style}
        {...param}
        title={title}
        placeholder={title}
        fieldsRequired={fieldsRequired}
        name={_fieldName}
      />
    );
  },

  RenderFilter(props) {
    return <FilterRenderGPS {...props} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorGPS {...props} />
  },

  RenderFilterPreview({ values }) {
    return <FilterRenderGPS values={values} DataTypeRender={this.Render} />
  },

  RenderInputParams: ({ values, param }) => (
    <>
      <FormFieldCheckbox
        name="param.includePlace"
        title="Incluir datos del lugar"
      />
      <FormFieldSelect
        name="param.accuracyType"
        title="Tipo de precisión"
        placeholder="Tipo de precisión"
        options={[
          { value: 'exact', label: 'Exacta' },
          { value: 'zone', label: 'Aproximada' }
        ]}
        showLabel={true}
      />
      {values?.param?.accuracyType === 'zone' ? (
        <FormFieldSelect
          name="param.accuracyLevel"
          title="Nivel de precisión"
          placeholder="Nivel de precisión"
          options={accuracyLevels}
          showLabel={true}
        />
      ) : ''}
      <FormFieldSelect
        name="param.mapZoom"
        title="Zoom del mapa"
        placeholder="Zoom del mapa"
        showLabel={true}
        options={_.range(1, 23).map(range => ({ value: range, label: range }))}
      />
    </>
  ),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderShowParams: ({ values }) => (null),
  
  RenderInputPreview: ({ values, formFieldProps }) => (
    <FormFieldGPS {...formFieldProps} />
  ),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}