import _ from 'lodash';
import React, { useState } from 'react';
import { IonTitle, IonCard } from '@ionic/react';
import { inputClasses } from '../../components/EntityTaxonomyForm';
import { EntityList } from '../entity/RouteEntityCrud';
import { withPrefix } from '../instance/utilsInstance';
import SwitchInputB from '../../components/Form/SwitchInputB';


const ContractsList = (props) => {
  let {
    title = "Contrataciones",
    instance,
    customerId,
    showSelector,
    onAfterRead,
    hardFilter,
    ExtraFields,
    EmptyContent,
    userId
  } = props;
  const [ selectedDocs, setSelectedDocs ] = useState([]);

  const fetchItems = async ({ model, setDocs }) => {
    if (!customerId && !userId) { return null; }
    let query = {
      deleted: 'false'
    };
    if (customerId) {
      query.customerId = customerId;
    }
    if (userId) {
      query.userId = userId;
    }
    if (hardFilter) {
      query = hardFilter(query);
    }
    let docs = await model.filterByAttributes(query);
    docs = _.sortBy(docs, 'issuedDate');
    if (onAfterRead) {
      docs = await onAfterRead(docs);
    }
    setDocs(docs);
  }

  if (!customerId && !userId) {
    return null;
  }

  const toggleSelectedDoc = (doc) => () => {
    if (selectedDocs.includes(doc)) {
      setSelectedDocs(selectedDocs.filter((d) => d !== doc));
    } else {
      setSelectedDocs([...selectedDocs, doc]);
    }
  };

  return (<>
    {title ? (
      <IonTitle className={`px-0 ${inputClasses.fieldLabel}`}>
        {title}
      </IonTitle>
    ) : null}
    <EntityList
      only={['key', 'issuedDate', 'totalAmount', 'status']}
      {...props}
      entitySlug={withPrefix(instance, 'contracts')}
      fetchItems={fetchItems}
      refreshers={[customerId, userId]}
      goToForm={() => {}}
      // showEmptyMessage={false}
      showToolbar={false}
      showBtnCrud={false}
      ExtraFields={ExtraFields}
      EmptyContent={EmptyContent}
      ItemExtraActions={({ doc }) => (<>
        {showSelector ? (
          <SwitchInputB
            value={selectedDocs.includes(doc)}
            onChange={toggleSelectedDoc(doc)}
            // textTrue="Cobrar"
            // textFalse="Pendiente"
          />
        ) : null}
      </>)}
    />
  </>);
};

export default ContractsList;