import { Field } from 'react-final-form';
import _ from 'lodash';
import { FieldLabel, ShowFieldHelp } from './formParts';
import SwitchInputA from './SwitchInputA';
import SwitchInputB from './SwitchInputB';


const FormFieldCheckboxToggle = (props) => {
  let {
    name,
    title,
    textTrue="Si",
    textFalse="No",
    color,
    colorTrue="blue-700",
    colorFalse="gray-500",
    className="",
    fieldsRequired=null,
    validate=null,
    disabled=false,
    formatter=null,
    showLabel=true,
    switchType="b", // "a"
    defaultValue
  } = props;

  if (!formatter) {
    formatter = (value) => value
  }

  let defaultValueToUse = _.isBoolean(defaultValue) && defaultValue ? true : false;

  return (
    <div className={`${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={(args) => validate && validate(args)} defaultValue={defaultValueToUse}>
        {({ input, meta }) => {
          return (<>
            <>
              <FieldLabel {...props} input={input} meta={meta} />  
              <div className={props?.classes?.fieldInput || ''}>
                {switchType === 'a' ? (
                  <SwitchInputA 
                    value={formatter(input.value) ? true : false} 
                    onChange={input.onChange}
                    {...{
                      colorTrue: colorTrue || color,
                      colorFalse,
                      textTrue,
                      textFalse,
                      disabled
                    }}>
                  </SwitchInputA>
                ) : ''}
  
                {switchType === 'b' ? (
                  <SwitchInputB 
                    value={formatter(input.value) ? true : false} 
                    onChange={input.onChange}
                    {...{
                      colorTrue: colorTrue || color,
                      colorFalse,
                      textTrue,
                      textFalse,
                      disabled
                    }}>
                  </SwitchInputB>
                ) : ''}
              </div>           
  
              {/* error */}
              <ShowFieldHelp {...props} name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
            </>
          </>);
        }}
      </Field>
    </div>
  );
}

export default FormFieldCheckboxToggle;