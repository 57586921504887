import _ from 'lodash';
import { BlockStackCreator } from '../blockStack/PageBlockStackCRUD';


const BlockStackForm = (props) => {
  let { 
    instance,
    entitySlug,
    fieldName,
    value,
    onChange,
    stackType,
  } = props;

  return (
    <BlockStackCreator {...{
      instance,
      entitySlug,
      fieldName,
      stackType: stackType || 'block',
      stack: value,
      onChange,
      showBtnSave: false
    }} />
  );
};

const dataTypeBlockStack = {
  RenderForm: BlockStackForm
};

export default dataTypeBlockStack;