import _ from 'lodash';
import React, { useState, lazy, Suspense } from 'react';

// Usa React.lazy para cargar FontPicker dinámicamente
const FontPicker = lazy(() => import('react-fontpicker-ts'));

const FontsForm = (props) => {
  const { value, onChange } = props;
  const [ showFontPicker, setShowFontPicker ] = useState(false);
  const [ fontWeight, setFontWeight ] = useState(value?.variants ? value?.variants[0].split(',')[1] : 300);
  const [ fontStyle, setFontStyle ] = useState('normal'); // Por defecto, estilo normal
  const exampleTextToUse = props.exampleText || props.taxonomyType?.exampleText || 'Texto de ejemplo';
  const [ exampleText, setExampleText ] = useState(exampleTextToUse);

  const handleShowFontPicker = () => {
    setShowFontPicker(true);
  };

  const handleFontVariantChange = (style, weight) => {
    setFontWeight(weight);
    setFontStyle(style === '1' ? 'italic' : 'normal');
    onChange({ ...value });
  };

  const handleValueChange = (value) => {
    const [style, weight] = value?.variants[0].split(',');
    const variantFontStyle = style === '1' ? 'italic' : 'normal';
    onChange({ ...value });
    setFontWeight(weight);
    setFontStyle(variantFontStyle);
  };

  return (
    <div className="mt-2 space-y-2">
      {showFontPicker ? (
        <Suspense fallback={<div>Cargando selector de fuentes...</div>}>
          <FontPicker
            autoLoad
            defaultValue={value?.fontName}
            fontVariants={handleValueChange}
          />
        </Suspense>
      ) : (
        <button
          onClick={handleShowFontPicker}
          className="bg-gray-500 hover:bg-gray-700 text-white px-4 py-1.5 text-xs rounded-md focus:outline-none"
          type="button"
        >
          Seleccionar Fuente
        </button>
      )}
      {/* Demo de uso de la fuente seleccionada, en h1, h2, h3..., tener en cuenta style.fontWeight */}
      {value?.fontName ? (
        <>
          <div style={{ fontFamily: value.fontName, fontWeight, fontStyle }}>
            <div className="mb-2">
              <div className="flex flex-wrap gap-1">
                {_.sortBy(value?.variants, (variant) => parseInt(variant.split(',')[0])).map((variant, index) => {
                  const [style, weight] = variant.split(',');
                  const variantFontStyle = style === '1' ? 'italic' : 'normal';

                  return (
                    <button
                      key={index}
                      onClick={() => handleFontVariantChange(style, weight)}
                      className={`
                        bg-gray-100 hover:bg-gray-300 px-4 py-1.5 text-xs rounded-md focus:outline-none 
                        ${fontWeight === weight && fontStyle === variantFontStyle ? 'bg-gray-500 text-white' : 'text-gray-500'}
                      `}
                      type="button"
                      style={{ fontWeight: weight, fontStyle: variantFontStyle }}
                    >
                      {`${variantFontStyle} ${weight}`}
                    </button>
                  );
                })}
              </div>
            </div>
            {/* Input para elegir el texto de ejemplo */}
            <input
              type="text"
              placeholder="Texto de Ejemplo"
              className="border border-gray-300 rounded-md px-3 py-1.5 mt-2 focus:outline-none focus:border-blue-500"
              onChange={(e) => setExampleText(e.target.value)}
            />
            <div className="text-black">
              <h1 className="!text-4xl" style={{ fontWeight, fontStyle }}>
                {exampleText || value.fontName}
              </h1>
              <h2 className="!text-3xl" style={{ fontWeight, fontStyle }}>
                {exampleText || value.fontName}
              </h2>
              <h3 className="!text-xl" style={{ fontWeight, fontStyle }}>
                {exampleText || value.fontName}
              </h3>
              <h4 className="!text-lg" style={{ fontWeight, fontStyle }}>
                {exampleText || value.fontName}
              </h4>
              <h5 className="!text-base" style={{ fontWeight, fontStyle }}>
                {exampleText || value.fontName}
              </h5>
              <h6 className="!text-xs" style={{ fontWeight, fontStyle }}>
                {exampleText || value.fontName}
              </h6>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const FontsShow = (props) => {
  const { displayedValue } = props;

  return (
    <div className="">
      <div
        // style={{
        //   fontFamily: displayedValue.fontName,
        //   fontWeight: displayedValue.fontWeight,
        //   fontStyle: displayedValue.fontStyle,
        // }}
        className="text-sm"
      >
        {displayedValue.fontName}
      </div>
    </div>
  );
};

const dataTypeFonts = {
  RenderForm: FontsForm,
  RenderShow: FontsShow,
  RenderShowList: FontsShow
};

export default dataTypeFonts;
