import { useEffect, useRef, useState, useMemo } from 'react';
import IonBtnLoading from '../../components/ui/IonBtnLoading';
import { 
  send,
  checkmark,
  checkmarkDone,
  arrowDownCircle
} from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/react';
import { EntityGetOneDocumentMemo } from '../../components/EntityGetOneDocument';
import { getImageURL, timeFrom } from '../../libs/utils';
// import { ConectionLabel } from '../publicProfile/isOnlineParts';
import dayjs from 'dayjs';
// import EmojiPicker from 'emoji-picker-react';
import { FaRegSmile } from "react-icons/fa";
import { usePanel } from '../panel/usePanel';
import { useSecretary } from '../secretary/useSecretary';


export const ChatInput = (props) => {
  let { 
    text,
    setText,
    onSend,
    inputRef,
    autoFocus = true,
    maxHeight = 300,
    loading
  } = props;
  // let { setGoTo } = usePanel();
  // const [showEmojis, setShowEmojis] = useState(false);

  const handleInputChange = (event) => {
    if (!(event.key === 'Enter' && !event.shiftKey)) {
      setText(event.target.value);
    }
  };

  const handleSend = async () => {
    if (text.trim() !== '' && onSend) {
      await onSend({
        text,
        type: 'text',
      });
      setText('');
      console.log('do scroll')
      // setGoTo({ duration: 100, place: 'bottom' });
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      await handleSend();
    }
  };

  // const toggleEmojiPicker = () => {
  //   setShowEmojis( !showEmojis );
  // };

  // const addEmoji = (emoji) => {
  //   setText( text + emoji.emoji );
  // };

  return (<>
    <div className="w-full flex items-center justify-between py-2 px-4 bg-white">
      <textarea
        autoFocus={autoFocus}
        ref={inputRef}
        type="text"
        placeholder="mensaje..."
        className={`flex-grow bg-transparent outline-none ${loading ? 'text-gray-300' : 'text-gray-800'}`}
        disabled={loading}
        value={text}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        rows="1"
        style={{ minHeight: '28px', maxHeight: `${maxHeight}px` }}
      />
      {/* <IonButton 
        color="medium"
        fill="clear"
        size="small"
        onClick={toggleEmojiPicker}
      >
        <FaRegSmile className="text-2xl text-gray-400" />
      </IonButton> */}
      <IonBtnLoading
        onClickAsync={handleSend}
        className="h-[34px]"
        color={text ? "primary" : "medium"}
        fill="clear"
        size="small"
        icon={<IonIcon icon={send} className="text-2xl" />}
      />
    </div>

    {/* {showEmojis ? (
      <div className="absolute bottom-16 right-6 shadow-lg">
        <EmojiPicker
          lazyLoadEmojis={true}
          onEmojiClick={addEmoji}
        />
      </div>
    ) : null} */}
  </>);
};

export const ChatTrack = (props) => {
  let {
    conversationId,
    messages,
    classes,
    showInput = true,
    senderAsHost,
    UnderMessages,
    trackContainerRef,
    inputRef,
    trackPadding = 0,
    isVisible,
    text
  } = props;
  const [isFirst, setIsFirst] = useState(true);
  const endOfTrackRef = useRef(null);
  const [ goTo, setGoTo ] = useState();
  const [ fromBottom, setFromBottom ] = useState();

  useEffect(() => {
    const handleScroll = () => {
      const elContent = trackContainerRef.current;
      const distanceFromBottom = elContent.scrollHeight - (elContent.scrollTop + elContent.clientHeight);
      setFromBottom(distanceFromBottom);
    };
    trackContainerRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      trackContainerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [trackContainerRef.current]);

  useEffect(() => {
    scrollToBottom({});
  }, [isVisible, endOfTrackRef.current]);

  useEffect(() => {
    if (goTo?.delay) {
      setTimeout(() => {
        setGoTo({ ...goTo, delay: null });
      }, goTo.delay);
    } 
    else {
      if (goTo?.place === 'top') {
        scrollToTop({ duration: goTo.duration });
      }
      else if (goTo?.place === 'bottom') {
        scrollToBottom({ duration: goTo.duration });
      }
    }
  }, [goTo]);

  useEffect(() => {
    if (messages?.length && isFirst) {
      setGoTo({ duration: 300, delay: 100, place: 'bottom' });
      setIsFirst(false);
    }
  }, [messages, conversationId]);
  
  useEffect(() => {
    if (messages?.length && conversationId) {
      setGoTo({ duration: 50, delay: 50, place: 'bottom' });
    }
  }, [conversationId]);

  useEffect(() => {
    if (fromBottom < 200) {
      scrollToBottom({ duration: 100, place: 'bottom' });
    }
  }, [text]);

  const scrollToTop = (duration) => {
    trackContainerRef.current?.scrollToTop(duration);
  }

  const scrollToBottom = ({ duration, delay }) => {
    if (delay) {
      setTimeout(() => {
        endOfTrackRef.current?.scrollIntoView({ duration, behavior: 'smooth' });
      }, delay);
    } else {
      endOfTrackRef.current?.scrollIntoView({ duration, behavior: 'smooth' });
    }
  }
  
  const messagesList = useMemo(() => {
    const isDifferentDay = (index) => {
      const pastMessage = messages[index + 1];
      const currentMessage = messages[index];
      if (currentMessage && pastMessage) {
        return !dayjs(currentMessage.data.createdAt).isSame(pastMessage.data.createdAt, 'day');
      } else {
        return false;
      }
    };

    const renderMessage = (messageDoc, index) => {
      let classes = {
        row: '',
        track: '',
        content: '',
      };

      // system
      if (messageDoc.data.sender === 'system') {
        classes.row = 'px-4 py-0.5';
        classes.track = 'place-items-center';
        classes.content = `
          text-sm py-2 px-4 shadow rounded-xl bg-white text-brand-secondary
        `;
      }
      // host
      else if (messageDoc.data.sender === senderAsHost) {
        classes.row = 'px-4 py-0.5';
        classes.track = 'flex-row-reverse';
        classes.content = `
          text-sm py-2 px-4 shadow rounded-xl
          bg-brand-secondary text-brand-secondary-contrast
        `;
      }
      // foreign
      else {
        classes.row = 'px-4 py-0.5';
        classes.track = '';
        classes.content = `
          text-sm py-2 px-4 shadow rounded-xl bg-brand-secondary-shade text-brand-secondary
        `;
      }
  
      return (
        <div key={messageDoc.id} className={`${classes.row}`}>
          {isDifferentDay(index) ? (
            <div className={`flex fex-row place-content-center`}>
              <div className="text-xs py-1 px-3 shadow rounded-md bg-stone-500 text-stone-100">
                {dayjs(messageDoc.data.createdAt).format('ll')}
              </div>
            </div>
          ) : null}
          <div className={`flex fex-row items-center gap-2 ${classes.track}`}>
            <div className={`${classes.content}`}>
              {messageDoc.data.type === 'text' ? (
                <span>{messageDoc.data.message}</span>
              ) : null}
              {/* ... other types */}
            </div>
            <div className="text-xs text-stone-500">
              {dayjs(messageDoc.data.createdAt).format('hh:mm')}
            </div>
          </div>
        </div>
      );
    };

    return messages.map(renderMessage);
  }, [messages]);

  return (
    <div className={`pb-2 ${classes?.chatContainer}`}>
      <div className="pt-24 flex flex-col-reverse">
        {messagesList}
      </div>
      <div ref={endOfTrackRef}></div>
      {UnderMessages ? (<UnderMessages {...props} />) : null}
      
      {/* go bottom */}
      <div 
        className={`w-full fixed text-center transition-all duration-400 ease-in-out`}
        style={{
          bottom: (fromBottom >= 200) ? `${trackPadding}px` : `0px`
        }}
      >
        <button onClick={() => scrollToBottom({ duration: 400 })}>
          <IonIcon icon={arrowDownCircle} color="secondary" size="large"></IonIcon>
        </button>
      </div>

      {showInput && (
        <ChatInput {...props} />
      )}
    </div>
  );
};
