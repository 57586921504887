import _ from 'lodash';
import { createContext, useContext, useState } from "react";
import { useVerifyVersion } from './useVerifyVersion';
import { useWithSettings } from './useSettings';
import { useWithInstances } from '../instance/useWithInstances';
import { useNotifications } from './useNotifications';
import { useMaxActivation } from '../instance/RouteInstanceBilling';
// import { useContacts } from './useContacts';


const panelContext = createContext();

export const usePanel = () => {
  const context = useContext(panelContext);
  if (!context) throw new Error("There is no Panel provider");
  return context;
};

export function PanelProvider(props) {
  let { match, children } = props;
  const currentInstance = match.params.instance || 'main';
  const [ isUserMenuPopoverOpen, setIsUserMenuPopoverOpen ] = useState(false);
  const { versionCurrent } = useVerifyVersion();
  const settings = useWithSettings({ currentInstance });
  const instances = useWithInstances(props);
  useNotifications();
  // useContacts();

  const maxActivationLibs = useMaxActivation({ 
    instance: currentInstance, 
    settings
  });

  return (
    <panelContext.Provider
      value={{
        instance: currentInstance,
        ...instances, // TODO refactor using instances instead as ...instances
        settings,
        versionCurrent,
        isUserMenuPopoverOpen, setIsUserMenuPopoverOpen,
        ...maxActivationLibs
      }}
    >
      {children}
    </panelContext.Provider>
  );
}
