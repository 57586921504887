import _ from 'lodash';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import { RouteEntityCrud } from '../entity/RouteEntityCrud';
import config from '../../config';
import { urlPrefix } from '../instance/utilsInstance';


const credentialsEntitySlug = config.modules.user.credentialsEntitySlug;

export const RouteCredentialsList = (props) => {
  let { parsedParams, history, user, selectedInstance, instance } = props;
  const context = {
    entitySlug: credentialsEntitySlug,
    docId: parsedParams.docId,
    parsedParams: parsedParams,
    goToForm: (viewType, doc) => {
      return urlPrefix(instance, `/a/credentials/membersEdit/#/docId/${doc ? doc.id : 'new'}`);
    },
    // fetchItems: async ({ model, setDocs }) => {
    //   let docs = await model.filterByAttributes({ instanceId: selectedInstance.id, deleted: 'false' });
    //   docs = _.orderBy(docs, ['data.createdAt'], ['desc']);
    //   setDocs(docs);
    // },
    // ListBtns: ({ doc }) => {
    //   return <>
    //     <IonButton
    //     size="small"
    //     fill="clear"
    //     color="secondary"
    //     routerLink={urlPrefix(doc.data.hash, `/a/panel/`)}>
    //       <IonIcon icon={paperPlaneOutline} size="small" slot="start"></IonIcon>
    //       Visitar
    //     </IonButton>
    //   </>
    // }
  };
  
  return (
    <RouteEntityCrud
      {...props}
      context={context}
      withLayout={false}
      showEmptyMessage={false}
      showToolbar={false}
      showBtnAdd={false}
      showBtnUpdate={true}
      showBtnDelete={true}
      showBtnMove={false}
    />
  );
}

export function Content(props) {
  let {
    entitySpecs,
    parsedParams,
    entitySlug, 
    action,
    config,
    user,
    isAllowed, 
    Model,
    ModuleModel,
    isMinBreakpointActive, 
    location, 
    history,
    match
  } = props;
  
  return (
    <div className="space-y-10 mb-32">
      <RouteCredentialsList {...props} />
    </div>
  )
}

export function RouteInstanceMembers(props) { 
  return (
    <LayoutAdmin 
      history={props.history} 
      breadcrumbs={[{
        title: "Miembros de la Instancia"
      }]}
    >
      <Content {...props} />
    </LayoutAdmin>
  );
}
