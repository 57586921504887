import { Suspense } from "react";
import { PanelWrapped } from "./AppPanel";
import BadgeLoading from "./components/ui/BadgeLoading";


export const AppPanelWrappedAsync = (props) => {
  return (
    <Suspense fallback={(
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    )}>
      <PanelWrapped
        {...props}
      />
    </Suspense>
  );
};