import _ from 'lodash';
import React from 'react';
import { getImageURL, stackClasses } from '../../../libs/utils';
import config from '../../../config';


export default function () {
  return {
    type: 'background',
    template: Background,
    fields: {
      type: {
        name: 'Tipo de fondo',
        type: 'select',
        options: [{
          label: 'Color',
          value: 'color'
        }, {
          label: 'Imagen',
          value: 'image'
        }, {
          label: 'Video',
          value: 'video'
        }, {
          label: 'Gradiente',
          value: 'gradient'
        }]
      },
      bgColor: {
        name: 'Color de fondo',
        type: 'color'
      },
      bgUrl: {
        name: 'Imagen de fondo',
        type: 'gallery'
      },
      bgVideoUrl: {
        name: 'Video de fondo',
        type: 'text'
      },
      // isStatic: { // for programmatic use
      //   type: 'boolean'
      // },
      bgOverlayColor: {
        name: 'Video: Color de superposición',
        type: 'color'
      },
      bgOverlayAlpha: {
        name: 'Video: Opacidad de superposición',
        type: 'number'
      },
      bgColorDirection: {
        name: 'Gradiente: Dirección',
        type: 'select',
        options: [
          { value: 'to top', label: 'Arriba' },
          { value: 'to bottom', label: 'Abajo' },
          { value: 'to left', label: 'Izquierda' },
          { value: 'to right', label: 'Derecha' },
          { value: 'to top left', label: 'Arriba izquierda' },
          { value: 'to top right', label: 'Arriba derecha' },
          { value: 'to bottom left', label: 'Abajo izquierda' },
          { value: 'to bottom right', label: 'Abajo derecha' }
        ]
      },
      bgColorFrom: {
        name: 'Gradiente: Color inicial',
        type: 'color'
      },
      bgColorTo: {
        name: 'Gradiente: Color final',
        type: 'color'
      },
    }
  };
};


export const backgroundClasses = `
  bg-cover 
  bg-center 
  relative 
  absolute 
  inset-0 
  w-full 
  h-full 
  object-cover 
  bg-color 
  from-color 
  to-color 
  z-10
`;


// params={headerBg || { 
//   type: 'video',
//   bgUrl: 'https://cdn.pixabay.com/video/2022/11/11/138588-770315514_large.mp4',
//   bgOverlayColor: '#000',
//   bgOverlayAlpha: 0.4
// }}

// params={{ 
//   type: 'image',
//   bgUrl: 'https://images.unsplash.com/photo-1653832920019-db280240dd41?q=80&w=871&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
//   bgOverlayColor: '#000',
//   bgOverlayAlpha: 0.4
// }}

// params={{ type: 'color', bgColor: '#ccc' }}

// params={{ 
//   type: 'gradient',
//   bgColorFrom: '#211cf4', bgColorTo: '#1ac3ff', bgColorDirection: 'to bottom right'
// }}
export function Background (props) {
  let {
    instance,
    params = {},
    specDesign = {},
    children
  } = props;
  params = params || {};
  let classes = stackClasses(props.classes, params.classes);

  let styles = {};
  let backgroundClasses = '';
  let overlayStyles = {};

  let bgUrl = _.isArray(params.bgUrl) ? params.bgUrl[0] : params.bgUrl;
  bgUrl = (_.startsWith(bgUrl, 'http') || params.isStatic) ? bgUrl : getImageURL(bgUrl, 'xl', null, instance);

  let colorsMap = props.colors || specDesign.colors || config.colors || {};

  const getColorHex = (val) => {
    return val.hex || colorsMap[val] || val;
  };

  if (params.type === 'image') {
    styles.backgroundImage = `url(${bgUrl})`;
    backgroundClasses += ' bg-cover bg-center';

    if (params.bgOverlayColor && params.bgOverlayAlpha) {
      overlayStyles.backgroundColor = getColorHex(params.bgOverlayColor);
      overlayStyles.opacity = params.bgOverlayAlpha;
    }

    return (
      <div className={`relative ${backgroundClasses} ${classes.bgContainer}`} style={styles}>
        {params.type === 'image' && params.bgOverlayColor && params.bgOverlayAlpha && (
          <div className="absolute inset-0" style={overlayStyles}></div>
        )}
        <div className="relative z-10 h-full">{children}</div>
      </div>
    );
  }
  
  else if (params.type === 'video') {
    if (params.bgOverlayColor && params.bgOverlayAlpha) {
      overlayStyles.backgroundColor = getColorHex(params.bgOverlayColor);
      overlayStyles.opacity = params.bgOverlayAlpha;
    }

    return (
      <div className={`relative ${classes.bgContainer}`}>
        <video autoPlay loop muted className="absolute inset-0 w-full h-full object-cover">
          <source src={bgUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {params.bgOverlayColor && params.bgOverlayAlpha && (
          <div className="absolute inset-0" style={overlayStyles}></div>
        )}
        <div className="relative z-10 h-full">{children}</div>
      </div>
    );
  }
  
  else if (params.type === 'color') {
    styles.backgroundColor = getColorHex(params.bgColor);
  }
  
  else if (params.type === 'gradient') {
    const gradientDirection = params.bgColorDirection;

    styles.backgroundImage = `linear-gradient(${gradientDirection}, ${getColorHex(params.bgColorFrom || '#ffffff')}, ${getColorHex(params.bgColorTo || '#ffffff')})`;
  }
  
  return (
    <div className={`${backgroundClasses || ''} ${classes.bgContainer || ''}`} style={styles}>
      {children}
    </div>
  );
}
  
