export default function () {
  return {
    name: 'Contactos',
    slug: 'contacts',
    scope: 'instance',
    bundles: ['starter'],
    entities: [
      'contacts'
    ],
    permissions: [
      { slug: 'contacts', label: 'Contactos', actionsToAdd: ['tab:contacts']},
    ],
  }
}