import { useMemo } from "react";
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonToolbar
} from "@ionic/react";
import { menuController } from '@ionic/core/components';
import config from "../../config";
import { useModule } from "../../libs/ModuleContext";
import { usePanel } from "./usePanel";
import { useAuth } from "../user/AuthContext";
import PartOfModule from "../../components/Module/PartOfModule";
import MenuOfSections from "./MenuOfSections";
import {
  arrowBackCircleOutline
} from 'ionicons/icons';
import { urlPrefix } from "../instance/utilsInstance";


export function MenuMainContent({ 
  history,
  triggerId,
  menuName
}) {
  const moduleLibs = useModule(); 
  const { instance, versionCurrent } = usePanel();
  const { user } = useAuth();
  
  const version = useMemo(() => {
    return versionCurrent ? (
      `${versionCurrent?.bundle?.version} / ${versionCurrent?.native}`
    ) : (
      config?.version
    )
  }, [versionCurrent]);

  const openMenu = async () => {
    await menuController.open(triggerId);
  };

  const closeMenu = async () => {
    await menuController.close(triggerId);
  };

  return (<>
    <IonHeader>
      <IonToolbar color="dark" className="main-toolbar">
        <PartOfModule
          type="main"
          action="SideMenuHeader"
          param={{ user, history, triggerId, openMenu, closeMenu, ...moduleLibs }}
        />
      </IonToolbar>
    </IonHeader>

    <IonContent className="!pt-0">
      {/* sections list */}
      <IonList className="!pt-0">
        <IonItemGroup>
          <MenuOfSections {...{menuName, openMenu, closeMenu}} />
        </IonItemGroup>
      </IonList>

      <PartOfModule
        type="main"
        action="SideMenuUnderSectionsList"
        param={{ user, history, triggerId, openMenu, closeMenu, ...moduleLibs }}
      />
    </IonContent>

    <IonFooter className="py-2">
      <PartOfModule
        type="main"
        action="SideMenuBeforeVersion"
        param={{ user, history, triggerId, openMenu, closeMenu, ...moduleLibs }}
      />

      {/* platform logo and version */}
      <div className="space-y-1">
        <img src={config.platformImgPath} alt={config.platformName} className="px-8 h-6 mx-auto" />
        <div className="font-mono text-sm font-normal text-center text-gray-400">
          {version}
        </div>
      </div>
    </IonFooter>
  </>);
}