import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import { InstanceInlineSelectorForMain } from './InstanceSelector';
import { usePanel } from '../panel/usePanel';
import BadgeLoading from '../../components/ui/BadgeLoading';
import ContractsList from '../sales/ContractsList';
import dayjs from 'dayjs';
import { ViewField } from '../../components/EntityDocView';
import { objectFormToTaxonomyTypesDocList } from '../entity/ObjectForm';
import config, { getConfig, specBundles } from '../../config';
import { useAsyncMemo } from 'use-async-memo';
import Model from '../../libs/ModelClass';
import { withPrefix } from './utilsInstance';
import { ContractPlanSelector, createContractsByBundle, useContractDetails } from './RouteInstanceModules';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonNote, IonTitle, useIonAlert, useIonLoading } from '@ionic/react';
import { inputClasses } from '../../components/EntityTaxonomyForm';
import toast from 'react-hot-toast';
import ObjectList from '../entity/ObjectList';
import { addDurationArray, paymentFields } from '../sales/PaymentsList';
import IonContactBTN from '../../components/ui/IonContactBTN';


export const paymentInstanceFields = {
  'meta_instanceId': {
    type: 'selectOneEntityDocument',
    name: 'Instancia',
    param: {
      entitySlug: config.modules.instances.instancesEntitySlug
    }
  },
  'meta_serviceDuration': {
    type: 'coded',
    name: 'Duración del servicio',
    RenderShow: ({ doc }) => {
      return (
        <div className="">
          {dayjs().from(addDurationArray(dayjs(), doc.data.meta_serviceDuration), true)}
        </div>
      );
    },
  },
  'meta_maxActivationDate': {
    type: 'date',
    name: 'Activado hasta'
  },
  ...paymentFields,
}

export const useMaxActivation = ({ instance, settings }) => {
  const [showActivationAlert, setShowActivationAlert] = useState(false);

  const refreser = settings.loadScope(instance);

  const isInstanceActive = useMemo(() => {
    const maxActivationByBundle = settings.get(instance, "bundles.maxActivation");
    
    if (!maxActivationByBundle || !refreser) {
      return true;
    }
    // max activation of main bundle 'store'
    // TODO consider other main bundles
    const isActive = dayjs().isBefore( maxActivationByBundle['store'] );
    // const isActive = false;
    if (!isActive) {
      setShowActivationAlert(true);
    }
    return isActive;
  }, [instance, refreser]);

  return {
    isInstanceActive,
    showActivationAlert, setShowActivationAlert
  };
};

export function Content(props) {
  let {
    selectedInstance,
    settings
  } = props;
  const mainBundle = specBundles[0]; // store
  const { isInstanceActive } = usePanel();
  const ownerId = selectedInstance?.data.ownerId;
  const instanceId = selectedInstance?.id;
  const [ contractPlan, setContractPlan ] = useState('yearly');
  const [ contractsDocs, setContractsDocs ] = useState();
  const instanceHash = selectedInstance?.data.hash;
  const instanceName = selectedInstance ? `${selectedInstance?.data.name} [${instanceHash}]` : `[${instanceHash}]`;
  const contractsDetails = useContractDetails({ bundleSlug: mainBundle.slug });
  const [ presentAlert ] = useIonAlert();
  const loadingCtrl = useIonLoading();
  const [ refreshCount, setRefreshCount ] = useState(0);
  
  const contractsModelsDocs = useAsyncMemo(async () => {
    return await Model.extend(withPrefix('main', getConfig().modules.sales.contractsModelsEntitySlug))
      .filterByAttributes({ deleted: 'false' });
  }, [refreshCount]);

  const paymentsDocs = useAsyncMemo(async () => {
    if (!contractsDocs) {
      return [];
    }
    return await Model.extend(withPrefix('main', getConfig().modules.sales.paymentsEntitySlug))
      .filterByAttributes({
        'meta_instanceId': instanceId,
        deleted: 'false'
      });
  }, [contractsDocs]);

  const storeDemoModelDoc = contractsModelsDocs?.find(doc => doc.data.nameSlug === mainBundle.slug+'Demo');
  
  const taxonomyTypesDocList = useMemo(() => {
    return objectFormToTaxonomyTypesDocList({
      'meta_servicePrice': {
        type: 'amount',
        name: 'Cuota'
      },
      'meta_maxActivationDate': {
        type: 'date',
        name: 'Activado hasta'
      }
    });
  }, []);

  const queryByInstance = (query) => {
    query['meta_instanceId'] = instanceId;
    return query;
  };

  const filterPaidContracts = (docs) => {
    docs = docs.filter(doc => doc.data.modelId !== storeDemoModelDoc?.id);
    setContractsDocs(docs);
    return docs;
  };

  const filterDemoContracts = (docs) => {
    docs = docs.filter(doc => doc.data.modelId === storeDemoModelDoc?.id);
    setContractsDocs(docs);
    return docs;
  };

  // create contracts
  const handleBundleContratation = async () => {
    loadingCtrl[0]({
      message: 'Contratando'
    }); // show loading
    try {
      await createContractsByBundle(selectedInstance, mainBundle.slug, contractPlan, settings);
      toast.success(`Paquete ${mainBundle.name} contratado en la instancia: ${instanceName}`);
    } catch (error) {
      console.error(error);
      toast.error(`Error al instalar el paquete ${mainBundle.name} en la instancia ${instanceName}`);
    }
    loadingCtrl[1](); // hide loading
    setRefreshCount(refreshCount + 1);
  };

  const openAlert = () => {
    presentAlert({
      header: 'Contratar servicio',
      message: `¿Desea contratar el servicio con pago ${contractPlan === 'yearly' ? 'anual' : 'mensual'}?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'bg-gray-300 text-gray-700'
        },
        {
          text: 'Contratar',
          role: 'confirm',
          handler: () => {
            handleBundleContratation();
          }
        }
      ]
    });
  }

  if (!contractsModelsDocs) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (<>
    <div className="m-2 md:m-4 grid grid-flow-row md:grid-flow-col gap-4">
      <div className="basis-1/2 space-y-4">
        <div className="">
          <IonTitle className={`px-0 ${inputClasses.fieldLabel}`}>
            Activado hasta
          </IonTitle>
          <IonCard className="m-0">
            <IonCardContent className="text-brand-dark text-base font-semibold">
              {dayjs(_.get(settings.get(instanceHash, 'bundles.maxActivation'), 'store'))?.format('dddd, DD MMMM YYYY')}
            </IonCardContent>
          </IonCard>
        </div>
        {/* mostrar lista de contrataciones de la instancia sin demo */}
        <div>
          <IonTitle className={`px-0 ${inputClasses.fieldLabel}`}>
            {!contractsDocs?.length ? (
              "Contratar servicio"
            ) : (
              "Contrataciones"
            )}
          </IonTitle>
          <ContractsList
            {...props}
            title={false}
            forceAllowed={true}
            instance="main"
            userId={ownerId}
            hardFilter={queryByInstance}
            onAfterRead={filterPaidContracts}
            only={['modelId', 'issuedDate', 'totalAmount', 'status']}
            showEmptyMessage={false}
            EmptyContent={() => (
              <div className="p-2">
                <IonTitle className={`px-0 ${inputClasses.fieldLabel}`}>
                  Precios del servicio
                </IonTitle>
                <ContractPlanSelector
                  showTitle={false}
                  contractPlan={contractPlan}
                  setContractPlan={setContractPlan}
                  contractsDetails={contractsDetails}
                />
                <IonButton onClick={openAlert} className="mt-4" color="primary" fill="solid" size="small">
                  Contratar
                </IonButton>
              </div>
            )}
            ExtraFields={({ doc, classes }) => (
              <>
                <ViewField
                  field="meta_maxActivationDate"
                  doc={doc}
                  classes={classes}
                  taxonomyTypesDocList={taxonomyTypesDocList}
                />
              </>
            )}
          />
        </div>

        {/* mostrar contratación de la demo */}
          {/* mostrar vencimiento de la demo (storeDemo) */}
          {/* mostrar botón de solicitar servicio  */}    
        <div>
          <ContractsList
            {...props}
            title="Periodo de demostración"
            forceAllowed={true}
            instance="main"
            userId={ownerId}
            hardFilter={queryByInstance}
            onAfterRead={filterDemoContracts}
            only={['issuedDate', 'status']}
            ExtraFields={({ doc, classes }) => (
              <>
                <ViewField
                  field="meta_maxActivationDate"
                  doc={doc}
                  classes={classes}
                  taxonomyTypesDocList={taxonomyTypesDocList}
                />
              </>
            )}
          />
        </div>
      </div>
      <div className="basis-1/2 space-y-4">
        {/* mostrar lista de pagos pendientes */}
        {/* mostrar lista de pagos previos */}
        <div>
          <IonTitle className={`px-0 ${inputClasses.fieldLabel}`}>
            Lista de Pagos
          </IonTitle>
          <ObjectList {...props}
            design="grid"
            entitySlug={getConfig().modules.sales.paymentsEntitySlug}
            values={paymentsDocs}
            fields={_.pick(paymentFields, ['amount', 'issuedDate', 'dueDate', 'status'])}
            ExtraFields={({ doc }) => (<>
              {doc?.data.status === 'pending' ? (
                <IonContactBTN
                  message={`Hola ${getConfig().siteName}, ajunto el comprobante por este pago. ${getConfig().getURLprefix()}/a/payments/verify/#/id/${doc.id}`}
                  label="Enviar comprobante"
                  color="primary"
                />
              ) : null}
            </>)}      
          />
        </div>
      </div>
    </div>
  </>);
}

export function RouteInstanceBilling(props) {
  const { selectedInstance, settings } = usePanel();
  const [ selectedInner, setSelectedInner ] = useState(selectedInstance);
  const isSettingsLoaded = settings.loadScope(selectedInstance?.data.hash);

  useEffect(() => {
    setSelectedInner(selectedInstance);
  }, [selectedInstance]);

  // show instance selector for Main instance only
  let TitleToolbarRightToUse;
  if (selectedInstance?.data.hash === 'main') {
    TitleToolbarRightToUse = (() => (
      <InstanceInlineSelectorForMain selectedInstance={selectedInner} setSelectedInstance={setSelectedInner} />
    ));
  }

  if (!isSettingsLoaded) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (
    <LayoutAdmin 
      history={props.history} 
      breadcrumbs={[{
        title: "Pagos del servicio"
      }]}
      TitleToolbarRight={TitleToolbarRightToUse}
    >
      {!selectedInner ? (
        <div className="m-2 md:m-4">Seleccione una instancia</div>
      ) : (
        <Content {...props} selectedInstance={selectedInner} settings={settings} />
      )}
    </LayoutAdmin>
  );
}
