// import { getNextManagerAndAssignToUserDoc } from "../../modules/accountManagers";
// import { getUrlVariables } from "../../modules/layoutHome/useURLVariables";


export default function ({ setPermissions }) {
  return {
    slug: 'layout',
    label: 'Layout',
    entities: {},
    permissions: [],
    components: {
      'main': {
        // return true if user has already registered, 
        // if false redirect to register form
        // 'userConditionToLogin': async (userWithCredentials, history) => {
        //   return !!userWithCredentials?.userDoc?.data?.name;
        // },
        // 'userProfileBeforeSave': async (user, values) => {
        //   // assign account manager
        //   if (!user.userDoc.data.accountManager) {
        //     const urlVariables = getUrlVariables();
        //     await getNextManagerAndAssignToUserDoc(user.userDoc, urlVariables?.affiliate);
        //   }
        // },
        //// Example
        // 'userProfileOnRedirect': async (user, history, config) => {
        //   if (user.rolesDoc.data.nameSlug === 'deliveryDriver') {
        //     history.push(config.modules.panel.mainPathForDrivers);
        //   } else {
        //     history.push(config.modules.panel.mainPath);
        //   }
        // },
      }
    }
  };
};