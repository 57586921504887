import { IonBadge, IonButton, IonIcon, IonItem, IonLabel, IonList, IonNote, IonTitle, IonToolbar } from "@ionic/react";
import { usePanel } from "../panel/usePanel";
import _ from "lodash";
import BadgeLoading from "../../components/ui/BadgeLoading";
import {
  peopleOutline
} from 'ionicons/icons';
import config from "../../config";
import Model from "../../libs/ModelClass";
import IonBtnLoading from "../../components/ui/IonBtnLoading";
import { useAsyncMemo } from "use-async-memo";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { CreateInstanceBtn, ListInstancesBtn } from "./RouteInstanceSection";
import { usePrevious } from "../../libs/usePrevious";


const credentialsEntitySlug = config.modules.user.credentialsEntitySlug;
// In Main

export const HomeForUserInMain = (props) => {
  let {
    WelcomeHeader,
    instancesOfUser,
    instance,
    roleOfInstanceSlug,
    roleOfMainSlug,
    credentialOfInstanceDoc, 
    roleOfInstanceDoc,
    instanceDoc,
    isOwner,
    userAuth
  } = props;

  // const instancesDocs = useAsyncMemo(async () => {
  //   const docs = await Model.extend('instances').filterByAttributesCount(filterQuery);
  //   return docs;
  // }, []);

  return (
    <div className="p-4 sm:pt-4 space-y-10">
      <WelcomeHeader />

      {!instancesOfUser?.length ? (
        <CreateInstanceBtn />
      ) : (
        <ListInstancesBtn />
      )}
    </div>
  );
};

export const HomeForSuperAdminInMain = (props) => {
  let {
    WelcomeHeader,
    instance,
    roleOfInstanceSlug,
    roleOfMainSlug,
    credentialOfInstanceDoc, 
    roleOfInstanceDoc,
    instanceDoc,
    isOwner
  } = props;

  return (
    <div className="p-4 sm:pt-4 space-y-10">
      <WelcomeHeader />

      <IonNote color="medium" className="ion-margin-horizontal">
        Super-admin
      </IonNote>
    </div>
  );
};

// In Instances

export const HomeForUserAsForeign = (props) => {
  let {
    WelcomeHeader,
    instance,
    userAuth,
    roleOfInstanceSlug,
    roleOfMainSlug,
    // credentialOfInstanceDoc, 
    roleOfInstanceDoc,
    instanceDoc,
    isOwner
  } = props;
  const [ credentialOfInstanceDocAsked, setCredentialOfInstanceDocAsked ] = useState();

  const credentialOfInstanceDoc = useAsyncMemo(async () => {
    if (credentialOfInstanceDocAsked) {
      return credentialOfInstanceDocAsked;
    }
    if (props.credentialOfInstanceDoc) {
      return props.credentialOfInstanceDoc;
    }
    const Credential = Model.extend(credentialsEntitySlug);
    let docs = await Credential.filterByAttributes({
      profile: userAuth?.userDoc?.id,
      instanceId: instanceDoc?.id,
      deleted: 'false'
    });
    docs = _.sortBy(docs, 'data.createdAt');
    return docs[0];
  }, [credentialOfInstanceDocAsked, userAuth?.userDoc?.id, instanceDoc?.id]);

  const previousCredentialDoc = usePrevious(credentialOfInstanceDoc);

  useEffect(() => {
    return credentialOfInstanceDoc?.onSnapshot(async newDoc => {
      if (
        previousCredentialDoc?.id === newDoc?.id
      ) {
        if (previousCredentialDoc?.data?.status !== newDoc?.data?.status) {
          setCredentialOfInstanceDocAsked(newDoc);
          if (newDoc?.data?.status === 'approved') {
            toast.success('Acceso concedido');
            window.location.reload();
          } else if (newDoc?.data?.status === 'denied') {
            toast.error('Acceso denegado');
          }
        }
      }
    });
  }, [credentialOfInstanceDoc?.id]);
  

  const askAccess = async () => {
    // solicitar acceso y crear credencial
    const Credential = Model.extend(credentialsEntitySlug);
    try {
      const newDoc = await Credential.create({
        instanceId: instanceDoc.id,
        profile: userAuth.userDoc.id,
        isActive: false,
        status: 'pending'
      });
      setCredentialOfInstanceDocAsked(newDoc);
      toast.success('Acceso solicitado');
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  }

  return (
    <div className="p-4 sm:pt-4 space-y-10">
      <WelcomeHeader />

      {/* solicitar acceso y crear credencial */}
      <IonToolbar inset={true}>
        <IonItem lines="none">
          <IonIcon icon={peopleOutline} className="mr-2" slot="start"></IonIcon>
          {!credentialOfInstanceDoc ? (
            <IonBtnLoading
              type="button"
              label="Solicitar Acceso"
              onClickAsync={askAccess}
              color="primary"
              size="small"
              fill="clear"
            ></IonBtnLoading>
          ) : (<>
            {credentialOfInstanceDoc?.data?.status === 'pending' ? (
              <IonBadge size="small" color="warning">Pendiente</IonBadge>
            ) : null}
            {credentialOfInstanceDoc?.data?.status === 'denied' ? (
              <IonBadge size="small" color="danger">Rechazado</IonBadge>
            ) : null}
          </>)}
        </IonItem>
      </IonToolbar>
      {/* <IonNote color="medium" className="ion-margin-horizontal">
        No tienes credenciales de acceso
      </IonNote> */}
    </div>
  );
};

// for user as owner and member
export const HomeForUserAsMember = (props) => {
  let {
    WelcomeHeader,
    instance,
    roleOfInstanceSlug,
    roleOfMainSlug,
    credentialOfInstanceDoc, 
    roleOfInstanceDoc,
    instanceDoc,
    isOwner
  } = props;
  
  return (
    <div className="p-4 sm:pt-4 space-y-10">
      <WelcomeHeader />
      {/* credenciales */}
      <IonToolbar inset={true}>
        <IonItem lines="none">
          <IonIcon icon={peopleOutline} className="mr-2" slot="start"></IonIcon>
          <IonTitle size="small">{roleOfInstanceDoc?.data.name}</IonTitle>
        </IonItem>
      </IonToolbar>
    </div>
  );
};

export const AdminHomeContent = (props) => {
  let {
    instance,
    isAllowed,
    userAuth,
    history
  } = props;
  const { selectedInstance } = usePanel();
  instance = instance || 'main';
  const instancesOfUser = userAuth.instancesDocs?.filter(doc => doc.data.ownerId === userAuth.userDoc.id);
  const roleOfInstanceSlug = userAuth.roleOfInstanceDoc?.data.nameSlug;
  const credentialOfInstanceDoc = userAuth.credentialOfInstanceDoc;
  const roleOfInstanceDoc = userAuth.roleOfInstanceDoc;
  const roleOfMainSlug = userAuth.roleOfMainDoc?.data.nameSlug;
  const instanceDoc = userAuth.instanceDoc || selectedInstance;
  const isOwner = userAuth.userDoc.id === instanceDoc?.data.ownerId;

  const WelcomeHeader = () => {
    return (
      <div className="">
        <IonTitle size="large" className="px-0">
          Hola {userAuth.userDoc.data.name}
        </IonTitle>
        <IonTitle size="medium" color="medium" className="px-0">
          Bienvenido
          {instanceDoc?.data.hash !== 'main' ? (
            <span> a {instanceDoc?.data.name}</span>
          ) : null}
        </IonTitle>
        {isOwner ? (
          <IonBadge size="small" color="secondary" className="">Propietario</IonBadge>
        ) : null}
      </div>
    );
  };

  const context = {
    userAuth,
    instancesOfUser,
    instance, roleOfInstanceSlug, roleOfMainSlug, instanceDoc, isOwner, credentialOfInstanceDoc, roleOfInstanceDoc,
    WelcomeHeader
  };

  if (!selectedInstance?.id) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (<>
    {/* access from main as user */}
    {instance === 'main' && roleOfMainSlug === 'user' ? (
      <HomeForUserInMain {...props} {...context} />
    ) : null}

    {/* access from main as super-admin */}
    {instance === 'main' && roleOfMainSlug === 'super-admin' ? (
      <HomeForSuperAdminInMain {...props} {...context} />
    ) : null}
    
    {/* access from instance without credentials */}
    {instance !== 'main' && (!roleOfInstanceSlug ||  !credentialOfInstanceDoc?.data.isActive) ? (<>
      <HomeForUserAsForeign {...props} {...context} />
    </>) : null}

    {/* access from instance and credentials */}
    {instance !== 'main' && roleOfInstanceSlug && credentialOfInstanceDoc?.data.isActive ? (<>
      <HomeForUserAsMember {...props} {...context} />
    </>) : null}
  </>);
};