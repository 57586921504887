import { Field } from 'react-final-form';
import _ from 'lodash';
import { FieldLabel, ShowFieldHelp } from './formParts';
import { RawInputTextId } from '.';


const FormFieldTextId = (props) => {
  let {
    name,
    title,
    fieldsRequired = null,
    validate,
    // disabled = false,
    showLabel = true,
    showClearBtn = true,
    onChange,
    Render,
    minLength = 1,
    maxLength = 30,
  } = props;

  const validateField = (value) => {
    let error;
    if (value?.length) {
      if (minLength && value?.length < parseInt(minLength)) {
        error = `El valor debe tener al menos ${minLength} caracteres`;
      } else if (maxLength && value?.length > parseInt(maxLength)) {
        error = `El valor debe tener como máximo ${maxLength} caracteres`;
      }
    }
    return error;
  };

  return (
    <div className={`${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={(args) => validateField(args)}>
        {({ input, meta }) => (
          <>
            <FieldLabel {...props} input={input} meta={meta} />
            <RawInputTextId
              value={input.value}
              className={props?.classes?.fieldInput || ''}
              onChange={(value) => {
                input.onChange(value); 
                onChange && onChange(value);
              }}
              minLength={parseInt(minLength)}
              maxLength={parseInt(maxLength)}
              {...props}
            />
            {/* error */}
            <ShowFieldHelp {...props} name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
          </>
        )}
      </Field>
    </div>
  );
};

export default FormFieldTextId;