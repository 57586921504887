import { useState } from "react";
import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
  IonPopover,
} from "@ionic/react";
import { 
  personCircle,
  createOutline,
  logOut,
} from 'ionicons/icons';
import { useAuth } from "../user/AuthContext";
import { Capacitor } from "@capacitor/core";
import { usePanel } from "./usePanel";


export const CurrentUserItem = ({ 
  showUserPic = true,
}) => {
  const { user } = useAuth();

  return (
    <IonItem className="">
      {showUserPic ? (
        <IonAvatar slot="start">
          <img src={user?.fireUser?.photoURL} alt="user profile image" className=" self-start" />
        </IonAvatar>
      ) : null}
      <IonLabel>
        <div className="text-black text-base font-semibold">{user?.userDoc?.data?.name || user?.userAuth?.displayName}</div>
        {user?.userDoc?.data?.email ? (<div className="text-gray-800 !font-light text-sm">{user?.userDoc?.data?.email}</div>) : null}
        {user?.userDoc?.data?.phone ? (<div className="text-gray-800 !font-light text-sm">{user?.userDoc?.data?.phone}</div>) : null}
      </IonLabel>
    </IonItem>
  );
}

export const UserList = ({
  showCurrentUser = true,
  showGoToProfile = true,
  showCurrentUserPic = true,
  triggerMode, // "button" | "menu",
  menuTrigger,
  onMenuClose,
  history
}) => {  
  const { logout } = useAuth();
  const [ isOpen, setIsOpen ] = useState(false);
  const { instance, attachPrefix, setIsUserMenuPopoverOpen } = usePanel();

  const doLogout = async () => {
    await logout();
    if (Capacitor.isNativePlatform()) {
      history.push('/login');
    } else {
      history.push(instance !== 'main' ? `/${instance}` : '/');
    }
  };
  
  return (<>
    {showCurrentUser ? (
      <CurrentUserItem showUserPic={showCurrentUserPic} />
    ) : null}
    {/* Profile */}
    <IonList>
      {showGoToProfile ? (<>
        {triggerMode === 'menu' ? (
          <IonMenuToggle trigger={menuTrigger} autoHide={false}>
            <IonItem button routerLink={attachPrefix("/a/user/data")}>
              <IonIcon icon={createOutline}></IonIcon>
              <IonLabel className="ml-2">Mis datos</IonLabel>
            </IonItem>
          </IonMenuToggle>
        ) : null}
        {triggerMode === 'button' ? (
          <IonItem button routerLink={attachPrefix("/a/user/data")} onClick={() => setIsUserMenuPopoverOpen(false)}>
            <IonIcon icon={createOutline}></IonIcon>
            <IonLabel className="ml-2">Mis datos</IonLabel>
          </IonItem>
        ) : null}
      </>) : null}
      {/* Logout */}
      <IonItem button onClick={() => setIsOpen(true)}>
        <IonIcon icon={logOut}></IonIcon>
        <IonLabel className="ml-2">Cerrar sesión</IonLabel>
      </IonItem>
    </IonList>

    {/* Logout Modal */}
    <IonAlert
      header="Desea cerrar la sesión?"
      isOpen={isOpen}
      buttons={[
        {
          text: 'Si, cerrar la sesión',
          role: 'confirm',
          handler: async () => {
            try {
              await doLogout();
            } catch (error) {
              console.error(error.message);
            }
          },
        },
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
          },
        },
      ]}
      onDidDismiss={() => setIsOpen(false)}
    ></IonAlert>
  </>);
};

export const UserMenu = (props) => {
  const {
    children,
    menuTrigger,
    classes = {}
  } = props;
  const { isUserMenuPopoverOpen, setIsUserMenuPopoverOpen } = usePanel();
  const { user } = useAuth();

  const openPopover = (event) => {
    setIsUserMenuPopoverOpen(!isUserMenuPopoverOpen);
  };

  const closePopover = () => {
    setIsUserMenuPopoverOpen(false);
  };

  return (
    <div className={classes.userMenuContainer}>
      <IonButton
        id={menuTrigger}
        shape="round"
        onClick={(e) => openPopover(e)}
      >
        {user?.fireUser?.photoURL ? (
          <IonAvatar>
            <img src={user?.fireUser?.photoURL} alt="user profile image" />
          </IonAvatar>
        ) : (
          <IonIcon icon={personCircle} size="large"></IonIcon>
        )}
      </IonButton>

      <IonPopover
        trigger={menuTrigger}
        side="bottom" 
        alignment="end"
        isOpen={isUserMenuPopoverOpen}
        onDidDismiss={closePopover}
        className="user-popover"
      >
        {children}
      </IonPopover>
    </div>
  );
};