import { processRenderProps } from './dataTypeUtils';
import {
  FormFieldCustom,
  RawInputCollectionForm
} from '../Form';


const valueFormatter = (value) => {
  return value;
}

export default {
  Render(props) {
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);
    param.formatter = valueFormatter;

    return (
      <FormFieldCustom
        {...props}
        {...style}
        {...param}
        Render={RawInputCollectionForm}
        name={_fieldName}
      />
    );
  },

  RenderInputParams: ({ values, param }) => (null),
  
  RenderShowParams: ({ values }) => (null),

  RenderShowPreview: ({ values }) => (null),
  
  RenderInputPreview: ({ values, formFieldProps }) => (null),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}