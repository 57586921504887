import _ from 'lodash';
import { useMemo } from 'react';
import { RawInputObjectForm } from '../../components/Form';
import { logoFields } from '../blockStack/parts/PartLogo';
import { objectFormToTaxonomyTypes } from '../entity/ObjectForm';


const LogoPicker = (props) => {
  let { 
    fieldName
  } = props;

  const formTaxonomyTypes = useMemo(() => (
    objectFormToTaxonomyTypes(logoFields)
  ), []);

  return (
    <div className="flex flex-row gap-4 flex-wrap">
      <RawInputObjectForm
        {...props}
        name={fieldName}
        fields={formTaxonomyTypes}
      />
    </div>
  );
}

const dataTypeLogo = {
  RenderForm: LogoPicker
};

export default dataTypeLogo;