import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getSelectedValueString } from '../Filters';


export const getSelectedFilters = ({ values, filterMenuTaxonomyTypes }) => {
  const valuesToAdd = [];
  for (const filterPopulated of filterMenuTaxonomyTypes) {
    let taxonomyType = filterPopulated?.taxonomyType;
    let isSelected = filterPopulated?.id && values.hasOwnProperty(taxonomyType?.nameSlug);
    let filterValue = isSelected ? values[taxonomyType?.nameSlug] : null;

    let shouldRenderValue = isSelected && filterValue !== null && filterValue !== undefined && (
      Array.isArray(filterValue) ? filterValue.length > 0 : true
    );

    if (shouldRenderValue) {
      valuesToAdd.push({
        taxonomyType,
        value: filterValue
      });
    }
  }
  return valuesToAdd;
}

const SelectedValuesList = (props) => {
  let {
    form,
    values,
    filterMenuTaxonomyTypes,
    classes = {},
    showClearBtn = true
  } = props;

  const [ valuesToShow, setValToShow ] = useState([]);

  useEffect(() => {
    calcValues();
  }, [values]);

  const calcValues = async () => {
    const valuesToAdd = [];
    for (const filterPopulated of filterMenuTaxonomyTypes) {
      let taxonomyType = filterPopulated?.taxonomyType;
      let filter = _.assign({}, taxonomyType?.filter, filterPopulated?.filter);
      let isSelected = filterPopulated?.id && values.hasOwnProperty(taxonomyType?.nameSlug);
      let filterValue = isSelected ? values[taxonomyType?.nameSlug] : null;
  
      let shouldRenderValue = isSelected && filterValue !== null && filterValue !== undefined && (
        Array.isArray(filterValue) ? filterValue.length > 0 : true
      );
  
      const value = await getSelectedValueString({ ...props, filter, filterValue, taxonomyType });

      if (shouldRenderValue && value) {
        valuesToAdd.push({
          taxonomyType,
          value
        });
      }
    }
    setValToShow(valuesToAdd);
  };

  return ( valuesToShow?.map(({ taxonomyType, value }) => (
    <div key={taxonomyType.id} className={`block text-sm ${classes.itemSelected}`}>
      <span className="">{
        _.get(props, ['paramsByFieldSlug', taxonomyType.nameSlug, 'title']) || taxonomyType.name
      }: </span>
      <span className="pl-1 font-semibold">{value}</span>
      {/* Agregar botón 'x' que elimine el filtro seleccionado utilizando alguna función de form 'react-final-form' */}
      {showClearBtn ? (
        <button
          type="button"
          className="ml-2 text-gray-500 hover:text-red-500"
          onClick={() => form.change(taxonomyType.nameSlug, undefined)}
        >
          X
        </button>
      ) : null}
    </div>
  )) || null);
}

export default SelectedValuesList;