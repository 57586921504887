import _ from 'lodash';
import { Link } from "react-router-dom";
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import { compileStringTemplate, lineBreaks } from "../../../libs/utils";
import { BtnList } from "../parts/PartBtnList";
import ViewGPS from "../../../components/ViewGPS";
import { IonIcon } from "@ionic/react";
import { mailOutline, callOutline, logoFacebook } from 'ionicons/icons';
import { urlPrefix } from '../../instance/utilsInstance';
import { sectionCardBtnsFields } from './BlockSectionCardBtns';
import { useLayoutHome } from '../../layoutHome/useLayoutHome';


export const phoneObjectFields = {
  'useBrandPhone': {
    type: 'boolean',
    name: 'Utilizar mismo que la marca',
    defaultValue: true
  },
  'phoneNumber': {
    type: 'phone',
    name: 'Número personalizado'
  },
};

export const whatsappObjectFields = {
  ...phoneObjectFields,
  'phoneMessage': {
    type: 'textArea',
    name: 'Mensaje predeterminado'
  }
};

export const emailObjectFields = {
  'emailDirection': {
    type: 'text',
    name: 'Email'
  },
  'emailMessage': {
    type: 'textArea',
    name: 'Mensaje predeterminado'
  }
};

export default function () {
  return {
    type: 'pageFooter',
    variant: 'contactsLinksFixedCTA',
    template: PageFooterBContactsLinksFixedCta,
    params: {},
    strings: {
      'socialLinks': {
        type: 'object',
        name: 'Enlaces sociales',
        fields: {
          'socialLinkTitle': {
            type: 'text',
            name: 'Título de los enlaces'
          },
          'linkWebsiteLabel': {
            type: 'text',
            name: 'Enlace a la página: Etiqueta a mostrar'
          },
          'linkWebsite': {
            type: 'text',
            name: 'Enlace a la página: URL'
          },
          'linkFacebook': {
            type: 'text',
            name: 'Redes Sociales: Enlace a Facebook'
          },
          'linkInstagram': {
            type: 'text',
            name: 'Redes Sociales: Enlace a Instagram'
          },
        }
      },
      'phoneCall': {
        type: 'object',
        name: 'Teléfono para llamadas',
        fields: phoneObjectFields
      },
      'whatsapp': {
        type: 'object',
        name: 'Teléfono para Whatsapp',
        fields: whatsappObjectFields
      },
      'email': {
        type: 'object',
        name: 'Correo electrónico',
        fields: emailObjectFields
      },
      'address': {
        type: 'object',
        name: 'Dirección y GPS',
        fields: {
          'useBrandGps': {
            type: 'boolean',
            name: 'Ubicación a utilizar',
            textTrue: 'Utilizar ubicación del branding',
            textFalse: 'Utilizar ubicación personalizada',
            defaultValue: true
          },
          'gps': {
            type: 'gps',
            name: 'Ubicación GPS personalizada',
            accuracyType: 'exact',
            includePlace: true
          },
          'addressString': {
            type: 'textArea',
            name: 'Ubicación en texto',
            descriptionText: 'Dirección y referencias sobre como llegar'
          }
        }
      },
      'showMadeWithLove': {
        type: 'boolean',
        name: 'Mostrar hecho con amor desde',
        defaultValue: true
      },
      'madeWithLoveLabel': {
        type: 'text',
        name: 'Hecho con amor: etiqueta para mostrar a la derecha'
      },
      'showPoliticsLinks': {
        type: 'boolean',
        name: 'Mostrar enlaces de póliticas',
        defaultValue: true,
        descriptionText: 'Políticas de privacidad y Seguridad de datos'
      },
      'showPanelLink': {
        type: 'boolean',
        name: 'Mostrar acceso para usuarios',
        defaultValue: true,
        descriptionText: 'Enlace al panel de administración'
      },
      'btnList': {
        type: 'collection',
        name: 'Botones',
        fields: sectionCardBtnsFields
      }
    }
  };
}

export function PageFooterBContactsLinksFixedCta(props) {
  let { 
    // footerBg, 
    showPanelLink, showPoliticsLinks,
    btnList,
    socialLinks,
    phoneCall,
    whatsapp,
    email,
    address,
    showMadeWithLove,
    madeWithLoveLabel,
    instance, specDesign, history 
  } = props;
  let { socialLinkTitle, linkWebsite, linkWebsiteLabel, linkFacebook, linkInstagram } = socialLinks;

  const { offsetBottom } = useLayoutHome();

  let panelUrl = instance ? (instance !== 'main' ? `/${instance}/login` : '/login') : '/login';
  
  let classes = {
    ctaBtn: `
      w-12 h-12 pt-2.5
      text-brand-primary-contrast text-2xl text-center cursor-pointer rounded-full
      border border-brand-dark-contrast 
      hover:border-brand-secondary hover:scale-110 transition-all duration-300
    `
  };

  linkFacebook = compileStringTemplate(linkFacebook, specDesign.stringsVars);
  linkFacebook = _.startsWith(linkFacebook, 'http') ? linkFacebook : (linkFacebook ? 'https://www.facebook.com/' + linkFacebook : null);
  linkInstagram = compileStringTemplate(linkInstagram, specDesign.stringsVars);
  linkInstagram = _.startsWith(linkInstagram, 'http') ? linkInstagram : (linkInstagram ? 'https://www.instagram.com/' + linkInstagram : null);

  let emailToUse = compileStringTemplate(email?.emailDirection, specDesign.stringsVars);
  let phoneCallNumber = phoneCall?.useBrandPhone ? specDesign.stringsVars?.brand?.callNumber : phoneCall?.phoneNumber;
  let whatsappNumber = whatsapp?.useBrandPhone ? specDesign.stringsVars?.brand?.whatsappNumber : whatsapp?.phoneNumber;

  return (
    <footer className="snap-start">
      <div className="bg-brand-dark-shade text-brand-dark-contrast font-brand-secondary">
        {/* address */}
        <div className="
          px-4 py-12 md:py-20 lg:py-24 
        ">
          <div className="container-width grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-4">
            {/* links */}
            {/* cta y social links */}
            {_.size(socialLinks) ? (
              <AnimationAddClassWhenVisible classToAdd="animate-fadeIn">
                <div className="px-8 md:px-0 mb-8 md:mb-0">
                  {/* cta titles */}
                  {socialLinkTitle ? (
                    <h3 className="mb-2 font-semibold text-4xl md:text-5xl cursor-pointer font-brand-main rounded-sm">
                      {lineBreaks(compileStringTemplate(socialLinkTitle, specDesign.stringsVars))}
                    </h3>
                  ) : null}
                  {/* social links */}
                  {linkFacebook || linkInstagram || whatsappNumber ? (
                    <div className="flex place-content-start space-x-3 lg:space-x-4 mt-4">
                      {linkFacebook ? (
                        <div className={classes.ctaBtn}>
                          <a className="inline-block" href={linkFacebook} target='_blank' rel="noopener noreferrer">
                            <IonIcon icon={logoFacebook} className="text-brand-dark-contrast" />
                          </a>
                        </div>
                      ) : null}
                      {linkInstagram ? (
                        <div className={classes.ctaBtn}>
                          <a className="inline-block" href={linkInstagram} target='_blank' rel="noopener noreferrer">
                            <FaInstagram className="text-brand-dark-contrast" />
                          </a>
                        </div>
                      ) : null}
                      {whatsappNumber ? (
                        <div className={classes.ctaBtn}>
                          {/* TODO utilizar useBrandPhone */}
                          <a className="inline-block" href={`https://wa.me/${whatsappNumber}?text=${whatsapp.phoneMessage}`} target='_blank' rel="noopener noreferrer">
                            <FaWhatsapp className="text-brand-dark-contrast" />
                          </a>
                        </div>
                      ) : null}
                      {emailToUse ? (
                        <div className={classes.ctaBtn}>
                          {/* TODO utilizar useBrandEmail */}
                          <a className="inline-block" href={`mailto:${emailToUse}?subject=${compileStringTemplate(email.emailMessage, specDesign.stringsVars)}`} target="_blank" rel="noopener noreferrer">
                            <IonIcon icon={mailOutline} className="text-brand-dark-contrast" />
                          </a>
                        </div>
                      ) : null}
                      {phoneCallNumber ? (
                        <div className={classes.ctaBtn}>
                          {/* TODO utilizar useBrandEmail */}
                          <a className="inline-block" href={`tel:+${phoneCallNumber}`} target="_blank" rel="noopener noreferrer">
                            <IonIcon icon={callOutline} className="text-brand-dark-contrast" />
                          </a>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <div className="pt-12 text-base">
                    {linkWebsite ? (
                      <a href={linkWebsite} className="mb-2 block font-semibold text-lg md:text-xl cursor-pointer font-brand-mulish rounded-sm">
                        {lineBreaks(compileStringTemplate(linkWebsiteLabel, specDesign.stringsVars)) || linkWebsite}
                      </a>
                    ) : null}
                    {address?.addressString ? (
                      <p className="italic">{lineBreaks(compileStringTemplate(address.addressString, specDesign.stringsVars))}</p>
                    ) : null}
                    {whatsappNumber ? (
                      <p className="opacity-70">
                        Whatsapp: 
                        <a className="ml-2 border-b border-dashed border-brand-dark-contrast cursor-pointer" href={`https://wa.me/${whatsappNumber}?text=${whatsapp.phoneMessage}`} target='_blank' rel="noopener noreferrer">
                          +{whatsappNumber}
                        </a>
                      </p>
                    ) : null}
                    {phoneCallNumber ? (
                      <p className="opacity-70">
                        Llamadas:
                        <a className="ml-2 border-b border-dashed border-brand-dark-contrast cursor-pointer" href={`tel:+${phoneCallNumber}`} target='_blank' rel="noopener noreferrer">
                          +{phoneCallNumber}
                        </a>
                      </p>
                    ) : null}
                    {emailToUse ? (
                      <p className="opacity-70">
                        Correo: 
                        <a className="ml-2 border-b border-dashed border-brand-dark-contrast cursor-pointer" href={`mailto:${emailToUse}?subject=${compileStringTemplate(email.emailMessage, specDesign.stringsVars)}`} target='_blank' rel="noopener noreferrer">
                          {emailToUse}
                        </a>
                      </p>
                    ) : null}
                  </div>
                </div>
              </AnimationAddClassWhenVisible>
            ) : null}

            {/* map */}
            {(address?.useBrandGps 
                ? specDesign.stringsVars?.brand?.defaultGps 
                : address?.gps
              ) ? (
                <div className="px-8 md:px-0">
                  <ViewGPS
                    value={address.useBrandGps ? specDesign.stringsVars?.brand?.defaultGps : address.gps}
                    includePlace={true}
                    mapZoom={15}
                    classes={{
                      mapHeight: 'h-[300px]'
                    }}
                  />
                </div>
              ) : null
            }
          </div>
        </div>

        {/* last menu */}
        {(showPanelLink || showPoliticsLinks || showMadeWithLove) ? (
          <div className="
            text-center px-4 py-4 md:py-8 pb-20
            bg-brand-dark-tint text-brand-dark-contrast
          ">
            {showMadeWithLove ? (
              <div className="mb-4 flex justify-center w-full px-10">
                Hecho con
                <img src="/icons/heart.svg" alt="amor" className="beating mx-1"/>
                {madeWithLoveLabel}
              </div>
            ) : null}
            {showPoliticsLinks ? (<>
              <Link to={urlPrefix(instance, '/m/p/privacy-policy')} className="underline">Política de privacidad</Link>
              <span className=""> | </span>
              <Link to={urlPrefix(instance, '/m/p/data-security')} className="underline">Seguridad de datos</Link>
            </>) : null}
            {(showPanelLink && showPoliticsLinks) ? (
              <span className=""> | </span>
            ) : null}
            {showPanelLink ? (<>
              <Link to={panelUrl} className="underline">Administración</Link>
            </>) : null}
          </div>
        ) : null}

        {/* fixed footer */}
        {btnList?.length ? (
          <div className="fixed bottom-4 right-4 md:right-6 min-w-40 pr-2 z-[350] flex place-content-end" style={{ paddingBottom: offsetBottom }}>
            <BtnList
              specDesign={specDesign}
              strings={{ items: btnList }}
              params={{
                type: 'row', 
                gap: '4',
                // align
                // justify
              }}
              classes={{ btnListContainer: 'mt-8 w-fit' }}
              history={history}
              instance={instance}
            />
          </div>
        ) : null}
      </div>
      {/* <Background params={footerBg} specDesign={specDesign} instance={instance}>
      </Background> */}
    </footer>
  );
}