import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import { InstanceInlineSelectorForMain } from './InstanceSelector';
import { usePanel } from '../panel/usePanel';
import { useModule } from '../../libs/ModuleContext';
import BadgeLoading from '../../components/ui/BadgeLoading';
import { getConfig, specBundles } from '../../config';
import { installModules } from '../entity/RouteEntityModules';
import ModalAlert from '../../components/ui/ModalAlert';
import toast from 'react-hot-toast';
import SwitchInputB from '../../components/Form/SwitchInputB';
import { IonBadge, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonTitle, IonToolbar } from '@ionic/react';
import SwitchInputA from '../../components/Form/SwitchInputA';
import { ShowAmount } from '../../components/Form/utilsCurrency';
import { AlertLogger, useAlertLogger } from '../panel/AlertLogger';
import dayjs from 'dayjs';
import { parseStringToObject } from '../../libs/utils';
import { addDurationArray } from '../sales/PaymentsList';
import Model from '../../libs/ModelClass';
import { withPrefix } from './utilsInstance';
import { nanoid } from 'nanoid';
import { useAsyncMemo } from 'use-async-memo';


export const createContractsByBundle = async (instanceDoc, bundleSlug, contractPlan, settings) => {
  let contractModelSlug;
  if (contractPlan === 'demo') {
    contractModelSlug = bundleSlug+'Demo';
  }
  else if (contractPlan === 'yearly') {
    contractModelSlug = bundleSlug+'Yearly';
  }
  else if (contractPlan === 'monthly') {
    contractModelSlug = bundleSlug+'Monthly';
  }
  
  // get contractModel
  const contractModelDoc = await Model.extend(withPrefix('main', getConfig().modules.sales.contractsModelsEntitySlug))
    .filterOne({ nameSlug: contractModelSlug, status: 'active', deleted: 'false' });
  // validate contractModelDoc
  if (!contractModelDoc) {
    throw new Error(`contractModelDoc not found for ${contractModelSlug}`);
  }
  const paymentConfig = contractModelDoc?.data.paymentConfig;
  // get meta data
  let metaDataModel = parseStringToObject(contractModelDoc.data.metaDataString || '{}');
  let servicePriceAmount = metaDataModel.servicePrice; // numberAmount
  let serviceDuration = metaDataModel.serviceDuration; // durationSpec
  const todayFormated = dayjs().format('YYYY-MM-DD');
  // validate meta data
  if (!serviceDuration) {
    throw new Error('serviceDuration missing in meta data');
  }
  let maxDueTime = paymentConfig?.maxDueTime 
    ? addDurationArray(dayjs(), paymentConfig.maxDueTime).format('YYYY-MM-DD') 
    : todayFormated;
  // calculate max activation date
  let maxActivationDate = maxDueTime; // update with serviceDuration after payment
  // exception for demo on max activation date to apply full serviceDuration
  if (contractPlan === 'demo' && _.size(serviceDuration)) {
    maxActivationDate = addDurationArray(dayjs(), serviceDuration).format('YYYY-MM-DD');
  }
  let metaDataToAssign = {
    meta_maxActivationDate: maxActivationDate,
    meta_serviceDuration: serviceDuration,
    meta_instanceId: instanceDoc.id
  };
  // create contract
  const contractDoc = await Model.extend(withPrefix('main', getConfig().modules.sales.contractsEntitySlug))
    .create({
      key: nanoid(12),
      userId: instanceDoc.data.ownerId,
      modelId: contractModelDoc.id,
      issuedDate: todayFormated,
      firstDueDate: todayFormated,
      status: 'active',
      ...metaDataToAssign
    });
  // create first payment
  if (paymentConfig) {
    const paymentDoc = await Model.extend(withPrefix('main', getConfig().modules.sales.paymentsEntitySlug))
      .create({
        userId: instanceDoc.data.ownerId,
        contractId: contractDoc.id,
        amount: servicePriceAmount,
        status: 'pending',
        issuedDate: todayFormated,
        dueDate: todayFormated,
        maxDueDate: maxDueTime,
        ...metaDataToAssign
      });
  }
  // set max activation of bundle on settings
    // first set as max due date
    // after paid set as max activation date
  await settings.setRemote(instanceDoc.data.hash, `bundles.maxActivation`, {
    [ bundleSlug ]: maxActivationDate
  });
  
  return contractDoc;
};

export const ContractPlanSelector = ({ contractPlan, setContractPlan, contractsDetails, showTitle = true }) => {
  return (
    <div className="rounded-md p-2 shadow-md border border-gray-200">
      {showTitle ? (
        <IonTitle className="px-0 text-xs !font-bold uppercase ">
          Precios del servicio
        </IonTitle>
      ) : null}
      <div>
        <SwitchInputA
          value={contractPlan === 'yearly'}
          onChange={(newValue) => (newValue ? setContractPlan('yearly') : setContractPlan('monthly'))}
          textTrue="Anual"
          textFalse="Mensual"
          colorTrue="blue-700"
        />
      </div>
      <div className="mt-2">
        {contractPlan === 'yearly' ? (<>
          <div className="text-lg">
            <ShowAmount amount={contractsDetails?.yearlyPrice} /> por año
          </div>
          <IonBadge color="primary">2 meses de ahorro</IonBadge>
        </>) : (
          <div className="text-lg">
            <ShowAmount amount={contractsDetails?.monthlyPrice} /> por mes
          </div>
        )}
      </div>
    </div>
  );
};

export const useContractDetails = ({ bundleSlug }) => {
  const contractsDetails = useAsyncMemo(async () => {
    if (!bundleSlug) {
      return null;
    }
    let ContractsModelsModel = Model.extend(withPrefix('main', getConfig().modules.sales.contractsModelsEntitySlug));
    let yearlyContractModel = await ContractsModelsModel.filterOne({ nameSlug: bundleSlug + 'Yearly', status: 'active', deleted: 'false' });
    let monthlyContractModel = await ContractsModelsModel.filterOne({ nameSlug: bundleSlug + 'Monthly', status: 'active', deleted: 'false' });
    let yearlyPrice  = parseStringToObject(yearlyContractModel.data.metaDataString || '{}').servicePrice;
    let monthlyPrice = parseStringToObject(monthlyContractModel.data.metaDataString || '{}').servicePrice;
    return {
      yearlyDoc: yearlyContractModel,
      monthlyDoc: monthlyContractModel,
      yearlyPrice,
      monthlyPrice
    }
  }, [bundleSlug]);

  return contractsDetails;
}

export function Content(props) {
  let {
    selectedInstance
  } = props;
  const { settings } = usePanel();
  const isSettingsLoaded = settings.loadScope(selectedInstance?.data.hash);
  const moduleLibs = useModule();
  const [ moduleToInstall, setModuleToInstall ] = useState();
  const [ bundleToInstall, setBundleToInstall ] = useState();
  const [ contractPlan, setContractPlan ] = useState('yearly');
  const instanceHash = selectedInstance?.data.hash;
  const instanceName = selectedInstance ? `${selectedInstance?.data.name} [${instanceHash}]` : `[${instanceHash}]`;
  const { pushLog, logs, present, dismiss, isModalOpen } = useAlertLogger();
  
  const instalableModules = useMemo(() => {
    const list = moduleLibs.modules.filter(m => {
      if ( _.size(m.entities) > 0 ) {
        // mostrar a instancias sólo lo de instancia
        if (selectedInstance && selectedInstance.data.hash !== 'main') {
          return m.scope === 'instance';
        }
        // mostrar para main todos los modulos
        else {
          return true;
        }
      }
      return false;
    });

    const byBundle = {};
    list.forEach((specModule) => {
      specModule?.bundles?.forEach(bundleSlug => {
        byBundle[bundleSlug] = byBundle[bundleSlug] || [];
        byBundle[bundleSlug].push(specModule);
      });
    });

    return {
      byBundle,
      list
    };
  }, [moduleLibs, selectedInstance]);

  const contractsDetails = useContractDetails({ bundleSlug: bundleToInstall?.slug });

  ////////////////////////////////////////////
  // BUNDLE install
  ////////////////////////////////////////////
  const handleBundleInstallation = async () => {
    console.log(`INSTALLING BUNDLE ${bundleToInstall?.name} on INSTANCE ${instanceHash}: Starting`);
    present();
    try {
      // create contracts
      if (bundleToInstall.needContract) {
        await pushLog('Registrando contratación del servicio');
        await createContractsByBundle(selectedInstance, bundleToInstall.slug, contractPlan, settings);
      }
      // install modules
      await handleModuleInstallation({ modulesSpecs: [instalableModules.byBundle[bundleToInstall.slug]], instanceHash, instanceDoc: selectedInstance, settings });
      // flag as installed bundle
      await settings.setRemote(instanceHash, `bundles.${bundleToInstall.slug}`, { status: 'installed' });
      setBundleToInstall(null);
      toast.success(`Paquete ${bundleToInstall?.name} contratado e instalado en la instancia: ${instanceName}`);
    } catch (error) {
      console.error(error);
      setBundleToInstall(null);
      toast.error(`Error al instalar el paquete ${bundleToInstall?.name} en la instancia ${instanceName}`);
    }
    dismiss();
    console.log(`INSTALLING BUNDLE ${bundleToInstall?.name} on INSTANCE ${instanceHash}: Finished`);
  };

  const handleModuleInstallation = async ({ modulesSpecs, instanceHash, instanceDoc, settings }) => {
    console.log(`INSTALLING MODULE ${moduleToInstall?.name} on INSTANCE ${instanceHash}: Starting`);
    try {
      await installModules({ modulesSpecs, instanceHash, instanceDoc, settings, logger: pushLog });
      setModuleToInstall(null);
    } catch (error) {
      console.error(error);
      setModuleToInstall(null);
      toast.error(`Error al instalar el módulo ${moduleToInstall?.name} en la instancia ${instanceName}`);
    }
    console.log(`INSTALLING MODULE ${moduleToInstall?.name} on INSTANCE ${instanceHash}: Finished`);
  };

  const getIsBundleInstalled = (specBundle) => {
    const bundleSettings = settings.get(instanceHash, `bundles.${specBundle.slug}`, {});
    return bundleSettings.status === 'installed';
  };

  const verifyBundleBeforeInstall = (specBundle) => () => {
    getIsBundleInstalled(specBundle) 
      ? toast('El paquete ya está instalado', { icon: 'ℹ️' })
      : setBundleToInstall(specBundle);
  };

  if (!isSettingsLoaded) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }
    
  return (<>
    <div className="grid grid-cols-1 md:grid-cols-2 m-2">
      {instalableModules && specBundles.map((specBundle, index) => (
        <IonCard key={index} className="">
          <IonCardHeader>
            <IonToolbar>
              <IonCardTitle slot="start">{specBundle.name}</IonCardTitle>
              <IonButtons className="px-4" slot="end">
                <SwitchInputB
                  key={index}
                  value={getIsBundleInstalled(specBundle)}
                  onChange={verifyBundleBeforeInstall(specBundle)}
                  textTrue="Instalado"
                  textFalse="No instalado"
                  colorTrue="blue-700"
                />
              </IonButtons>
            </IonToolbar>
            <IonCardSubtitle>{specBundle.description}</IonCardSubtitle>
          </IonCardHeader>
          {instalableModules.byBundle[specBundle.slug] ? (
            <IonCardContent>
              <div className="">
                <b className="mr-1.5">Incluye:</b>
                {instalableModules.byBundle[specBundle.slug].map((specModule, index) => (
                  specModule.name
                )).join(', ')}
              </div>
            </IonCardContent>
          ) : null}
        </IonCard>
      ))}
    </div>
    {bundleToInstall && (
      <ModalAlert
        onConfirm={handleBundleInstallation}
        onCancel={() => setBundleToInstall(null)}
        confirmClass="bg-brand-primary text-white"
      >
        <div className="text-sm py-2 text-left">
          {bundleToInstall?.needContract ? (<>
            ¿Confirma la contratación e instalación del paquete de módulos <b className="text-base">{bundleToInstall?.name}</b>?
            <div className="mt-4 rounded-md p-2 shadow-md border border-gray-200">
              <ContractPlanSelector
                contractPlan={contractPlan}
                setContractPlan={setContractPlan}
                contractsDetails={contractsDetails}
              />
            </div>
          </>) : <>
            ¿Confirma la instalación del paquete de módulos <b className="text-base">{bundleToInstall?.name}</b>?
            <div className="mt-4">
              <span className="text-xs font-bold uppercase">
                Servicio sin costos asociados
              </span>
            </div>
          </>}
          <hr className="my-4 border-t border-gray-200" />
          <span className="text-gray-500 text-sm">
            Se registrarán entidades y aplicarán configuraciones
          </span>

          {/* Logs */}
          <AlertLogger logs={logs} isOpen={isModalOpen} />
        </div>
      </ModalAlert>
    )}
  </>
  )
}

export function RouteInstanceModules(props) {
  const { selectedInstance } = usePanel();
  const [ selectedInner, setSelectedInner ] = useState(selectedInstance);

  useEffect(() => {
    setSelectedInner(selectedInstance);
  }, [selectedInstance]);

  // show instance selector for Main instance only
  let TitleToolbarRightToUse;
  if (selectedInstance?.data.hash === 'main') {
    TitleToolbarRightToUse = (() => (
      <InstanceInlineSelectorForMain selectedInstance={selectedInner} setSelectedInstance={setSelectedInner} />
    ));
  }

  return (
    <LayoutAdmin 
      history={props.history} 
      breadcrumbs={[{
        title: "Paquetes de módulos"
      }]}
      TitleToolbarRight={TitleToolbarRightToUse}
    >
      {!selectedInner ? (
        <div className="">Seleccione una instancia</div>
      ) : (
        <Content {...props} selectedInstance={selectedInner} />
      )}
    </LayoutAdmin>
  );
}
