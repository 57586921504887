import _ from 'lodash';
import { setConfig } from "../../config";
import { cssToRgb, getImageURL } from "../../libs/utils";


export const colorGroups = ['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger', 'dark', 'medium', 'light'];
export const colorModes = ['contrast', 'shade', 'tint'];

export default function applyLayoutDesign ({
  stringsVars,
  logo,
  fonts,
  colors,
  instance
}) {
  /////////////////////////////////////////
  // PAGE
  /////////////////////////////////////////
  if (stringsVars) {
    let pageTitle = stringsVars.page?.title || stringsVars.brand?.siteName + (stringsVars.siteMoto ? ' | ' + stringsVars.siteMoto : '');
    let newConfigs = {
      ...stringsVars.brand,
      pageTitle
    };
    if (newConfigs.countryId) {
      newConfigs.country = {
        countryId: newConfigs.countryId,
        currency: newConfigs["country_currency"],
        iso3: newConfigs["country_iso3"],
        iso2: newConfigs["country_iso2"],
        symbol: newConfigs["country_currency_symbol"]
      }
    }
    setConfig(newConfigs);
    // Acceso al objeto siteIdentity y sus propiedades
    document.title = pageTitle;
    // Actualizar el meta tag name y content
    const metaTag = document.querySelector('meta[name="description"]');
    metaTag.setAttribute('content', pageTitle);
    // Actualizar el meta tag property og:title
    const metaTagOgTitle = document.querySelector('meta[property="og:title"]');
    metaTagOgTitle.setAttribute('content', pageTitle);
  }

  /////////////////////////////////////////
  // LOGO
  /////////////////////////////////////////
  if (logo) {
    // Actualizar el meta tag property og:image
    const metaOgImage = document.querySelector('meta[property="og:image"]');
    let logoName = logo.logoDark || logo.logoLight || logo.profileImg;
    if (logoName) {
      metaOgImage.setAttribute('content', getImageURL(logoName, 'md', 'profilesImages', instance));
    }
  }

  /////////////////////////////////////////
  // FONTS
  /////////////////////////////////////////
  if (fonts?.main?.fontName) {
    // Actualizar el style de google font
    let fontsLink = fonts.fontsLink || '';
    const getVariants = (variantsList) => {
      // let fonts0 = _.groupBy(variantsList);
      // let link = `:ital,wght@${variantsList.join(';')}`;
      let fontVariants = ':ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900';
      return fontVariants;
    };
    if (!fontsLink && fonts.main) {
      let families = 'family=' + fonts.main.fontName.replace(' ', '+') + getVariants(fonts.main.variants);
      if (fonts.secondary) {
        families += '&family=' + fonts.secondary.fontName.replace(' ', '+') + getVariants(fonts.secondary.variants);
      }
      fontsLink = `https://fonts.googleapis.com/css2?${families}&display=swap`;
    }
    if (fontsLink) {
      const linkFont = document.querySelector('link[resource="google-font"]');
      linkFont.setAttribute('href', fontsLink);
    }
    document.documentElement.style.setProperty('--main-font', '"' + fonts.main.fontName + '", ' + (fonts.mainAlt || 'sans-serif'));
    document.documentElement.style.setProperty('--secondary-font', '"' + fonts.secondary.fontName + '", ' + (fonts.secondaryAlt || 'sans-serif'));
  }

  /////////////////////////////////////////
  // COLORS
  /////////////////////////////////////////
  if (colors) {
    // add rgb modes
    colorGroups.forEach((group) => {
      if (colors[group]) {
        colors[group+'-rgb'] = cssToRgb(colors[group]);
      }
      if (colors[group+'-contrast']) {
        colors[group+'-contrast-rgb'] = cssToRgb(colors[group+'-contrast']);
      }
    });
    setConfig({ colors });
    // set colors
    _.forEach(colors, (colorCode, colorName) => {
      document.documentElement.style.setProperty('--ion-color-' + colorName, colors[colorName]);
    });
  }
}