import dataTypeBlockStack from '../instance/dataTypeBlockStack';
import './definitionsBlocks';
import { RouteRender } from './RouteRender';


export default function () {
  return {
    slug: 'blockStack',
    name: 'Páginas públicas',
    scope: 'instance',
    bundles: ['store'],
    entities: [
      'pages'
    ],
    permissions: [
      { slug: 'pages', label: 'Páginas públicas' },
    ],
    routesPublic: {
      'p': {
        Render: RouteRender
      }
    },
    components: {
      'pages': {
        crud: {
          settings: () => ({
            redirectOnSave: false
          })
        },
        codedDataTypes: {
          'stack': dataTypeBlockStack
        }
      }
    }
  };
};