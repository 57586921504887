import { useEffect } from 'react';
import { useForm } from 'react-final-form';
import _ from 'lodash';


const FormChangeSubscriber = ({ doc, onFormChange }) => {
  const form = useForm();
  let previousValues = form.getState().values;

  useEffect(() => {
    const unsubscribe = form.subscribe(
      ({ values }) => {
        if (!_.isEqual(values, previousValues)) {
          // Execute the onFormChange function if values have changed
          onFormChange(values, previousValues, form, doc);
          previousValues = values;
        }
      },
      { values: true }
    );

    return unsubscribe; // Clean up the subscription on component unmount
  }, [form, onFormChange]);

  return null; // Render nothing in the DOM
};

export default FormChangeSubscriber;
