import Entity from '../entity/Entity';
import { useModule } from "../../libs/ModuleContext";
import _ from 'lodash';
import { sortDocsByField } from '../../libs/utils';
import Model from '../../libs/ModelClass';
import config from '../../config';
import { useAsyncMemo } from 'use-async-memo';


/**
 * 
 * Listado de permisos de entidades
 * 
 * cada entidad se representa por un resource
 * los resources tienen actions
 * 
 * los módulos pueden ser de scope global o instance
 * mostrar las entidades de modulos globales en un grupo al final de la lista
 * mostrar las entidades de modulos de instancias agrupados por instancia
 * 
 * Si es Main
 * mostrar los permisos completos de entidades globales
 * 
 * Si es Instance
 * mostrar los permisos con prefijo instance: de entidades globales según instanceId del documento del formulario
 * 
 */

const entitySlug = config.modules.instances.instancesEntitySlug;
const InstanceModel = Model.extend(entitySlug);

const useHook = (instanceId) => {
  const { actionsByResource } = useModule();

  const entitiesDocsGlobals = useAsyncMemo(async () => {
    let entitiesDocsGlobals = await Entity.filterByAttributes({ isInstance: 'false' });
    entitiesDocsGlobals = entitiesDocsGlobals.filter(entityDoc => !entityDoc.data.deleted && !entityDoc.data.isInstance);
    entitiesDocsGlobals = sortDocsByField(entitiesDocsGlobals, 'sort');
    return entitiesDocsGlobals;
  }, []);
  
  const resourcesByInstance = useAsyncMemo(async () => {
    if (!entitiesDocsGlobals || !instanceId) {
      return null;
    }
    const instanceDoc = await InstanceModel.findById(instanceId);
    let entitiesDocsOfInstances = await Entity.filterByAttributes({ instanceId, isInstance: 'true' });
    entitiesDocsOfInstances = entitiesDocsOfInstances.filter(entityDoc => !entityDoc.data.deleted && entityDoc.data.isInstance);
    entitiesDocsOfInstances = sortDocsByField(entitiesDocsOfInstances, 'sort');
    
    let resourcesByInstance = [{
      instanceDoc: instanceDoc,
      entities: entitiesDocsOfInstances
    }, {
      instanceDoc: {
        data: {
          name: 'Global',
          hash: 'main'
        }
      },
      entities: entitiesDocsGlobals
    }];

    // asignar a cada instancia el listado de acciones para cada entity
    _.forEach(resourcesByInstance, (resourcesByInstance) => {
      let { entities } = resourcesByInstance;
      let actionsMap = {};
      entities.forEach(entityDoc => {
        let entitySlugParts = entityDoc.data.nameSlug.split('.');
        let isGlobal = entitySlugParts.length === 1;
        let resourceSlug = entitySlugParts[0];
        if (entitySlugParts.length > 1) {
          resourceSlug = entitySlugParts[1];
        }
        if (actionsByResource[ resourceSlug ]) {
          // si es no es main, mostrar sólo las acciones prefijadas con "instance:"
          let actions = actionsByResource[resourceSlug].actions;
          if (instanceDoc.data.hash !== 'main' && isGlobal) {
            actions = actions.filter(action => action.startsWith('instance:') || action.startsWith('owner:'));
          }
          actionsMap[resourceSlug] = {
            slug: entityDoc.data.nameSlug,
            label: entityDoc.data.name,
            actions: actions || []
          };
        }
      });
      resourcesByInstance.actionsMap = actionsMap;
    });

    return resourcesByInstance;
  }, [instanceId, entitiesDocsGlobals]);

  return {
    resourcesByInstance
  }
}

const ActionsByResourceSelector = ({ actionsByResource, value, onChange }) => {
  return (
    <>
      {_.map(actionsByResource, ({ slug, label, actions }) => (
        actions?.length ? (
          <div className="my-4 flex flex-row" key={slug}>
            <div className="w-1/3">
              <div className="text-sm text-black font-semibold">{label}</div>
              <div className="text-xs text-gray-500">{slug}</div>
            </div>
            <div className="w-2/3">
              <div className={`inline-block overflow-hidden rounded-md cursor-pointer`}>
                {actions?.map((action, index) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => {
                      value = value || {};
                      value[slug] = value[slug] || [];
                      
                      const updatedActions = _.includes(value[slug], action)
                      ? value[slug].filter((item) => item !== action)
                      : [...value[slug], action];
                      
                      if (updatedActions.length) {
                        value[slug] = updatedActions;
                      }
                      else {
                        delete value[slug];
                      }
                      onChange({ ...value });
                    }}
                    className={`py-1 px-2 text-xs inline-block ${
                      _.includes(value[slug], action) ? 'bg-brand-dark text-white' : 'bg-brand-light'
                    }`}
                  >
                    {action}
                  </button>
                ))}
              </div>
            </div>
          </div>
        ) : null
      ))}
    </>
  )
}

const ActionsByResourceDisplay = ({ actionsByResource, value }) => {
  return (
    <>
      {_.map(actionsByResource, ({ slug, label, actions }, index) => (
        actions?.length ? (
          <div className="my-4 flex flex-row gap-2" key={index}>
            <div className="w-1/3">{label}</div>
            <div className="w-2/3">
              <div className={`inline-block overflow-hidden rounded-md`}>
                {actions?.map((action) => (
                  <div
                    key={action}
                    className={`py-1 px-2 text-xs inline-block ${
                      _.includes(value[slug], action) ? 'bg-brand-dark text-white' : 'bg-brand-light/20 line-through text-gray-400'
                    }`}
                  >
                    {action}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null
      ))}
    </>
  )
}

const RenderForm = ({ value, onChange, form }) => {
  const instanceId = form.getState().values.instanceId;
  const { resourcesByInstance } = useHook(instanceId);

  if (!instanceId) {
    return null;
  }
  return (
    <div className="space-y-4">
      {resourcesByInstance?.map(({ instanceDoc, entities, actionsMap }, index) => (
        _.size(actionsMap) > 0 && (
          <div key={index} className="">
            <label className="text-base text-gray-700 font-semibold block pb-2">
              {instanceDoc.data.name}
            </label>

            <div className='border-gray-400 border px-2 py-1.5 rounded-md'>
              <label className="text-xs text-gray-700 font-semibold uppercase block border-b border-gray-400 pb-1.5">Entidades</label>
              <ActionsByResourceSelector {...{ actionsByResource: actionsMap, value, onChange }} />
            </div>
          </div>
        )
      ))}
    </div>
  );
}

const RenderShow = ({ displayedValue, doc }) => {
  const { resourcesByInstance } = useHook(doc.data.instanceId);

  return (
    <div className="space-y-4">
      {resourcesByInstance?.map(({ instanceDoc, entities, actionsMap }) => (
        _.size(actionsMap) > 0 && (
          <div key={instanceDoc.data.hash} className="">
            <label className="text-base text-gray-700 font-semibold block pb-2">
              {instanceDoc.data.name}
            </label>

            <div className='border-gray-400 border px-2 py-1.5 rounded-md'>
              <label className="text-xs text-gray-700 font-semibold uppercase block border-b border-gray-400 pb-1.5">Entidades</label>
              <ActionsByResourceDisplay {...{ actionsByResource: actionsMap, value: displayedValue }} />
            </div>
          </div>
        )
      ))}
    </div>
  );
}

const permissionsDataType = {
  RenderForm,
  RenderShow
};

export default permissionsDataType;