import _ from 'lodash';
import { useEffect, useState, useRef, useMemo } from "react";
import { Link } from 'react-router-dom';
import { useAuth } from "../../modules/user/AuthContext";
import { animateScroll as scroll } from 'react-scroll';
import { FaBars } from 'react-icons/fa';
import { PartLogo, usePartLogo } from "../../modules/blockStack/parts/PartLogo";
import { useScrollUtils } from "../../modules/panel/useScrollUtils";
import { stackClasses } from "../../libs/utils";
import { urlPrefix } from "../../modules/instance/utilsInstance";
// import useBreakpoints from '../../libs/useBreakpoints';
// import config from '../../config';


export const MenuHome = (props) => {
  let {
    HeaderLeft,
    HeaderMenu,
    fullMenuHeader,
    specDesign,
    instance
  } = props;
  const menuRef = useRef();
  const { elementToScrollRef, isNavbarOpen, setIsNavbarOpen, isScrollUnderOffset } = useScrollUtils();
  const { user } = useAuth();
  const [menuHeight, setMenuHeight] = useState();
  // const { isMinBreakpointActive } = useBreakpoints();
  const homePath = urlPrefix(instance, '/');
  const panelPath = urlPrefix(instance, '/a/panel');
  const showMenuTrigger = HeaderMenu || user?.userDoc?.id;

  let { logoType, logoPath, logoSize } = usePartLogo({
    ...props,
    specPart: specDesign?.parts?.menuLogo
  });
  
  const classes = stackClasses(props.classes, {
    link: `py-1.5 px-4 bg-brand-light hover:bg-brand-light-tint active:bg-brand-light-shade text-brand-light-contrast text-sm rounded`,
  }); // TODO add menu main part

  const showFullHeader = isNavbarOpen || fullMenuHeader || isScrollUnderOffset
  // useMemo(() => {
  //   const topOffset = config.modules.panel.scrollYtoShowFixedNavbar;
  //   const contentScrollTop = elementToScrollRef?.current?.scrollTop;
  //   console.log('memo', topOffset, contentScrollTop, !isMinBreakpointActive('lg'))
  // return ;
  // }, [isNavbarOpen, fullMenuHeader, isScrollUnderOffset]);

  useEffect(() => {
    const handleResize = () => {
      setMenuHeight(menuRef.current.getBoundingClientRect().height);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const MenuItems = () => (<>
    {HeaderMenu ? (
      <HeaderMenu menuHeight={menuHeight} classes={classes}></HeaderMenu>
    ) : ''}
    {user?.userDoc?.id ? (
      <Link
      className={classes.link}
      to={panelPath}>
        Admin
      </Link>
    ) : ''}
  </>);

  const BurguerTrigger = ({ className }) => (
    <div className={`justify-self-end ${className}`}>
      <button
        className={`p-2 outline-none ${isNavbarOpen ? 'text-brand-secondary' : 'text-brand-light'}`}
        onClick={() => setIsNavbarOpen(!isNavbarOpen)}
      >
        <FaBars size={21} />
      </button>
    </div>
  );
  
  return (
    <header ref={menuRef}>
      <div id="menu-header" className={`
        !z-[300] fixed w-full top-0 font-brand-secondary bg-brand-dark shadow-sm overflow-hidden
        ${showFullHeader ? 'transition-opacity-full' : 'transition-opacity-none'}
      `}>
        <div className={`flex justify-between place-items-center px-4 lg:px-10 w-full`}>
          {/* logos  */}
          <div className="py-2">
            <Link
              to={homePath}
              title={specDesign?.siteName}
              onClick={() => scroll.scrollToTop()}
              className="flex flex-row items-center"
            >
              <PartLogo
                specDesign={specDesign}
                logoPath={logoPath}
                logoType={logoType}
                logoSize={logoSize || 'md'}
                logoStyle={logoType === 'profileImg' ? 'circleThin' : null}
                showSiteName={logoType === 'profileImg'}
              />
            </Link>
          </div>

          {/* HeaderLeft */}
          {HeaderLeft ? (
            <HeaderLeft menuHeight={menuHeight}></HeaderLeft>
          ) : ''}

          {/* menu desktop */}
          <div className={`text-xs hidden sm:block space-x-2`}>
            <MenuItems />
          </div>

          {/* menu movil hamburger */}
          {showMenuTrigger ? (<BurguerTrigger className="sm:hidden" />) : null}
        </div>

        {/* menu movil expanded */}
        <div className={`sm:block md:hidden bg-transparent transition-all duration-300 overflow-hidden ease-in-out
          ${isNavbarOpen ? "max-h-96" : "max-h-0"}
        `}>
          <div className="">
            <div className="py-4 mb-2 mx-4 text-xs flex flex-col space-y-2 sm:hidden border-t border-brand-light">
              <MenuItems />
            </div>
          </div>
        </div>
      </div>

      {/* menu desktop on top */}
      <div className={`!z-[200] absolute top-0 flex flex-row flex-nowrap place-content-end px-4 lg:px-10 py-4 w-full text-xs space-x-2`}>
        <div className="hidden sm:block">
          <MenuItems />
        </div>
        <div className="block sm:hidden">
          {/* menu movil hamburger */}
          {showMenuTrigger ? (<BurguerTrigger />) : null}
        </div>
      </div>
    </header>
  );
};