import { IonIcon } from "@ionic/react";
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import { lineBreaks } from "../../../libs/utils";


export default function () {
  return {
    type: 'textGrid',
    variant: 'compact',
    template: TextGridBCompact,
    params: {
      
    },
    // fields of text tab
    strings: {
    // {items: [{icon,title,content}]}
    'items': {
      type: 'collection',
      name: 'items',
      fields: {
        // 'icon': {
        //   type: '',
        //   name: 'icono'
        // },
        'title': {
          type: 'text',
          name: 'Título'
        },
        'content': {
          type: 'text',
          name: 'Contenido'
        },
      }
    },
    }
  };
};

export function TextGridBCompact({ items }) {
  return (
    <div className="px-8 py-24 bg-brand-white text-center">
      <div className="container-width">
        <div className="flex flex-wrap md:grid md:grid-cols-3 gap-8 sm:gap-4 lg:gap-6 ">
          {items?.map(({title, content, ionIcon, faIcon}, index) => (
            <div key={index} className="font-brand-secondary">
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
                <div>
                  {ionIcon ? (
                    <IonIcon icon={ionIcon} className="text-6xl" color="primary" />
                  ) : null}
                  {faIcon ? (
                    faIcon({ className: "inline-block text-6xl mb-1.5 text-brand-primary" })
                  ) : null}
                  <h4 className="font-bold font-brand-main text-black text-2xl mb-2">{lineBreaks(title)}</h4>
                  <p className="font-brand-secondary text-gray-500 text-xl">{lineBreaks(content)}</p>
                </div>
              </AnimationAddClassWhenVisible>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
