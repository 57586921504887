import _ from 'lodash';
import { useState, useEffect } from 'react';
import { IonButton, IonButtons, IonItem, IonList, IonReorder, IonReorderGroup } from '@ionic/react';
import ModalAlert from './ui/ModalAlert';


function SectionCrudObject(props) {
  let {
    instance,
    entitySlug,
    docs, // typeof Model
    editStyle, // onsite modal
    reorder,
    title,
    // show
    showToast = true,
    showBtnAdd = true,
    showBtnUpdate = true,
    showBtnDelete = true,
    showBtnMove = true,
    // validatiion
    fieldsRequired,
    onValidation = (() => null),
    // callbacks 
    onSave = (() => null),
    onDelete = (() => null),
    onReorder = (() => null),
    // UI
    listStyle,
    ListItem,
    ItemExtraActions,
    ListBtns = (() => null),
    FormSection = null,
    formSectionProps = {},
    FormInputFields = null,
    // labels
    addButtonLabel = '+ Agregar',
    // Classes
    classes = {}, //  formSectionContainer
    classNameFormSection = '' // legacy
  } = props;
  const [docList, setDocList] = useState(docs);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [openSelector, setOpenSelector] = useState(false);

  useEffect(() => {
    setDocList(docs);
  }, [docs]);

  ListItem = ListItem || (({ doc }) => (<>
    <span className="">{doc?.data?.name}</span>
  </>));

  const handleSave = (formValues) => {
    onSave(formValues);
    setOpenSelector(false);
    setSelectedItem(null);
  };

  const handleAdd = () => {
    setSelectedItem({});
    setOpenSelector(true);
  };

  const handleEdit = (doc) => {
    setSelectedItem(doc);
    setOpenSelector(true);
  };

  const handleDelete = (doc) => {
    setShowDeleteConfirmation(true);
    setSelectedItem(doc);
  };

  const confirmDelete = () => {
    onDelete(selectedItem);
    setShowDeleteConfirmation(false);
    setSelectedItem(null);
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setSelectedItem(null);
  };

  const handleDragEnd = async (event) => {
    event.stopPropagation();
    if (!event.detail.from) return;
    let newOrder = Array.from(docList);
    const [movedItem] = newOrder.splice(event.detail.from, 1);
    newOrder.splice(event.detail.to, 0, movedItem);
    setDocList(newOrder);
    onReorder(newOrder);
    event.detail.complete();
  };

  _.defaults(classes, {
    listItemContainer: 'flex flex-col mb-2'
  });

  return (
    <div className={`section-crud-object ${classes.sectionCrudContainer}`}>
      {/* header */}
      <div className="flex flex-row place-content-between items-center place-items-center">
        {title ? (
          <h2 className={`text-xs font-semibold text-gray-500 uppercase ${classes.formTitle}`}>{title}</h2>
        ) : ''}
        {showBtnAdd ? (
          <IonButton
            size="small"
            fill="solid"
            color="secondary"
            onClick={handleAdd}
          >
            {addButtonLabel}
          </IonButton>
        ) : ''}
      </div>
      {openSelector && selectedItem && !selectedItem?.id && (
        <div className={`my-5 ${classNameFormSection} ${classes.formSectionContainer}`}>
          {/* form add */}
          <FormSection
            doc={selectedItem}
            onClose={() => {
              setOpenSelector(false);
              setSelectedItem(null);
            }}
            onSave={handleSave}
            index={null}
            {...{ editStyle, fieldsRequired, onValidation, FormInputFields, showToast, classes }}
            {...formSectionProps}
            entitySlug={entitySlug}
            instance={instance}
          />
        </div>
      )}
      <IonList className={`grid grid-cols-1 gap-2 py-0 ${docList.length ? 'my-2' : ''}`} inset={false} lines="full">
        <IonReorderGroup disabled={!showBtnMove || selectedItem?.id} onIonItemReorder={handleDragEnd}>
          {docList.map((doc, index) => (
            // form edit
            openSelector && selectedItem && selectedItem?.id === doc?.id ? (
              <div key={doc?.id} className={`mb-3 ${classNameFormSection} ${classes.formSectionContainer} ${openSelector ? classes.formSectionContainerOpen : ''}`}>
                <FormSection
                  doc={selectedItem}
                  onClose={() => {
                    setOpenSelector(false);
                    setSelectedItem(null);
                  }}
                  onSave={handleSave}
                  index={index}
                  {...{ editStyle, fieldsRequired, onValidation, FormInputFields, showToast, classes }}
                  {...formSectionProps}
                  entitySlug={entitySlug}
                  instance={instance}
                />
              </div>
            ) : (
              <IonItem key={doc?.id} color={classes.ionItemColor} className={classes.listItemContainer}>
                <div className="w-full">
                  <div className="mb-0.5 mt-2">
                    <ListItem doc={doc} />
                  </div>
                  <IonButtons>
                    {(reorder && showBtnMove) ? (
                      <IonReorder />
                    ) : null}
                    <ListBtns doc={doc} />
                    {(showBtnUpdate) ? (
                      <IonButton color="medium" size="small" fill="clear" onClick={() => handleEdit(doc)}>
                        Editar
                      </IonButton>
                    ) : null}
                    {(showBtnDelete) ? (
                      <IonButton color="medium" size="small" fill="clear" onClick={() => handleDelete(doc)}>
                        Eliminar
                      </IonButton>
                    ) : null}
                    {ItemExtraActions ? (<ItemExtraActions doc={doc} />) : null}
                  </IonButtons>
                </div>
              </IonItem>
            )
          ))}
        </IonReorderGroup>
      </IonList>

      {showDeleteConfirmation && (
        <ModalAlert
          text="¿Estás seguro de que deseas eliminar este elemento?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
    </div>
  );
}

export default SectionCrudObject;
