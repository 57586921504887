import { useState } from 'react';
import { AnimationAddClassWhenVisible } from '../../../components/ui/AnimationAddClassWhenVisible';
import { lineBreaks } from '../../../libs/utils';


export default function () {
  return {
    type: 'frecuentAnswers',
    variant: 'columnToggle',
    template: FrecuentAnswersAColumn
  };
};

export function FrecuentAnswersAColumn({ strings }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div id='preguntas' className="px-4 my-40 text-center">
      <div className="container-width">
        <AnimationAddClassWhenVisible classToAdd="animate-fadeIn">
          <h3 className="pb-4 text-3xl lg:text-4xl lg:pb-6 text-center font-bold font-brand-main">
            {lineBreaks(strings.title)}
          </h3>
        </AnimationAddClassWhenVisible>
        <div className="grid grid-flow-row grid-cols-1 font-brand-secondary">
          {strings.items?.map((item, index) => (
            <div
              key={index}
              className={`cursor-pointer border-b-2 py-3 px-4 ${
                index === activeIndex ? 'active' : ''
              }`}
              onClick={() => handleAccordionClick(index)}
            >
              <div className="font-semibold text-lg md:text-lg">
                {lineBreaks(item.title)}
              </div>
              <div
                className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
                  index === activeIndex ? 'max-h-screen' : 'max-h-0'
                }`}
              >
                <div className="my-2 lg:text-lg">
                  {lineBreaks(item.content)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
